import { THREE, loadImage, getMaterial } from './helper.js';
import { loader_module } from './loader_module.js';
import { gui_module, sceneItems } from './gui_smartkits.js';
import { selectColorbond, selectRoofingColorbond, selectPostsColorbond, convertColor2Hex } from './color.js';  


var beam_height = 0.15
var posts_gap = 0.10
var v1 = new THREE.Vector3();
var v2 = new THREE.Vector3();   

var flyover_module = {
    
    add: function(product) {    
        if(product.price_index == 2 || product.price_index == 4 || product.price_index == 6) product.profile = 1
        flyover_module.add_posts(product) 
        flyover_module.posts_color(product, gui_module.posts)
        flyover_module.setup(product)

        flyover_module.add_roofing(product)
        flyover_module.add_gutters(product)   
        flyover_module.add_beams(product)
        flyover_module.add_ceiling(product)
        flyover_module.add_landscaping(product)
        sceneItems.scene.add(product.scene_group)

        flyover_module.add_walls(product)
        flyover_module.setup(product)

    },
    add_posts(product) {
       //console.log("l: " + product.length + " w: " + product.width + " h: " + product.height);
       var postsGeometry = new THREE.BoxGeometry(1, 1, 1); 
       var material = getMaterial(0xffffff, 0)
       for(var i = 0; i < 60; ++i) {           
        var obj = new THREE.Mesh(postsGeometry, material);
        product.posts_base_model.push(obj);
        obj.position.x += 0.3 * i
       
            if(i > 30) {
                obj.scale.set(0.05, 0.2, 0.2)
            } else {
                obj.scale.set(0.2, 0.05, 0.2)
            }
            obj.visible = false
            product.scene_group.add(obj);           
        } 

        var no = 6
        if(product.type == 21) no = 8
       for(var i = 0; i < no; ++i) {           
           var obj = new THREE.Mesh(postsGeometry, material);
           product.front_posts_model.push(obj);         
           product.scene_group.add(obj);
           product.front_posts_offset.push(0);            
       } 

       for(var i = 0; i < 6; ++i) {           
            var obj = new THREE.Mesh(postsGeometry, material);
            product.decking_posts_model.push(obj);
            obj.visible = false
            product.scene_group.add(obj);
            product.decking_posts_offset.push(0);            
        } 

        if(product.type == 2 || product.type == 5 || product.type == 8 || product.type == 11 || product.type == 20 || product.type == 21) {

        } else {
            if(product.type == 1) {
                for(var i = 0; i < 6; ++i) {           
                    var obj = new THREE.Mesh(postsGeometry, material);
                    product.brackets_model.push(obj);
                    product.bracket_length.push(1)
                    obj.scale.set(0.08, product.bracket_length[i], 0.08);
                    product.scene_group.add(obj);
                    product.bracket_offset.push(0);            
                } 
            }                
            for(var i = 0; i < 6; ++i) {           
                var obj = new THREE.Mesh(postsGeometry, material);
                product.back_posts_model.push(obj);
                product.scene_group.add(obj);
                product.back_posts_offset.push(0);            
            } 
        }
        for(var i = 0; i < 6; ++i) {           
            var obj = new THREE.Mesh(postsGeometry, material);
            obj.visible = false
            product.right_posts_model.push(obj);
            product.scene_group.add(obj);
            product.right_posts_offset.push(0);            
        } 

        for(var i = 0; i < 6; ++i) {           
            var obj = new THREE.Mesh(postsGeometry, material);
            product.rafters_model.push(obj);
            obj.visible = false
            product.scene_group.add(obj);
            product.rafter_offset.push(0);            
        } 

        no = 6
        if(product.type > 3) no = 12
        for(var i = 0; i < no; ++i) {           
            var obj = new THREE.Mesh(postsGeometry, material);
            product.purlins_model.push(obj);
            obj.visible = false
            product.scene_group.add(obj);
            product.purlin_offset.push(0);            
        } 
    },
    add_roofing(product) {
        if(product.roofing_group) {
            product.scene_group.remove(product.roofing_group)
        }

        product.roofing_group = new THREE.Group();
        product.roofing_model = [];

        function call_back() {
            flyover_module.populate_parts(product)
            product.scene_group.add(product.roofing_group);
            flyover_module.roofing_color(product, product.roofing)
            flyover_module.setup(product);
        }

        switch(product.profile) {
            case 0:
                loader_module.add_solar_span_type_1(product, 2 * 16 * 4, call_back)
                break
            case 1:
                loader_module.add_flat_deck_type_1(product, 2 * 16 * 4, call_back)
                break    
            case 2:
                loader_module.add_solar_span_type_2(product, 2 * 16 * 4, call_back)
                break                    
            case 11:
                loader_module.add_custom_orb_type_1(product, 2 * 16 * 10, call_back)
                break
            case 12:
                loader_module.add_span_deck_type_1(product, 2 * 16 * 10, call_back)
                break   
            case 10:                               
            default:
                loader_module.add_custom_orb_type_2(product, 2 * 16 * 10, call_back)
                break
        }       
    },
    add_ceiling: function(product) {
        var geometry = new THREE.BoxGeometry(1, 1, 1); 
        var material = getMaterial(0xffffff, 0)
        for(var i = 0; i < 2; ++i) {            
            var obj = new THREE.Mesh(geometry, material);
            flyover_module.roofing_color(product, product.roofing)
            product.scene_group.add(obj);
            product.ceiling_model.push(obj);            
        }
    },
    add_gutters: function(product) {
        loader_module.add_gutters(product, 6, function() {
            flyover_module.populate_parts(product)
            product.scene_group.add(product.roofing_group);
            flyover_module.gutters_color(product, gui_module.gutters)
            flyover_module.setup(product);
        });
    },
    add_beams: function(product) {
        var no = 3
        if(product.type == 2) {
            no = 1
        } 
        loader_module.add_beams(product, no, function() {
            flyover_module.populate_parts(product)
            product.scene_group.add(product.roofing_group);
            flyover_module.beams_color(product, gui_module.beams)
            flyover_module.setup(product);
        });
    },
    add_landscaping: function(product) {
        var geometry = new THREE.BoxGeometry(1, 1, 1);
        var material = loader_module.get_decking_material(); 
        for (var i = 0; i < 1200; ++i) {
            var obj = new THREE.Mesh(geometry, material);
            obj.visible = true;
            product.landscaping_model.push(obj);
            product.scene_group.add(obj);
            obj.scale.set(product.length, 0.1, 0.1)
            obj.position.z = product.position.z + 0.2 * i
        }
    },
    add_walls: function(product) {
        var wall = new THREE.BoxGeometry(1, 1, 1); 
        var material = loader_module.get_wall_material(0); 
        for(var i = 0; i < 1500; ++i) {          
            var obj = new THREE.Mesh(wall, material);
            obj.visible = false;
            product.walls_model.push(obj)
            product.scene_group.add(obj); 
        } 
    },
    populate_parts: function(product) {
        product.parts = {"list": [], "parent": product, "index": 0}
        for(var i = 0; i < product.roofing_model.length; ++i) {
            product.roofing_model[i].traverse(function(object) {
                if(object.isMesh) {
                    product.parts["list"].push(object);
                }                
            })            
        }
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var object = product.front_posts_model[i]
            product.parts["list"].push(object);            
        }
        for(var i = 0; i < product.back_posts_model.length; ++i) {
            var object = product.back_posts_model[i]
            product.parts["list"].push(object);            
        }
        for(var i = 0; i < product.decking_posts_model.length; ++i) {
            var object = product.decking_posts_model[i]
            product.parts["list"].push(object);            
        }
        for(var i = 0; i < product.brackets_model.length; ++i) {
            var object = product.brackets_model[i]
            product.parts["list"].push(object);            
        }
        /*for(var i = 0; i < product.right_posts_model.length; ++i) {
            var object = product.right_posts_model[i]
            product.parts["list"].push(object);            
        }
        for(var i = 0; i < product.left_posts_model.length; ++i) {
            var object = product.left_posts_model[i]
            product.parts["list"].push(object);            
        }*/
        /*for(var i = 0; i < product.gutters_model.length; ++i) {
            product.gutters_model[i].traverse(function(object) {
                if(object.isMesh) {
                    product.parts.push(object);
                }                
            })            
        }
        for(var i = 0; i < product.beams_model.length; ++i) {
            product.beams_model[i].traverse(function(object) {
                if(object.isMesh) {
                    product.parts.push(object);
                }                
            })            
        }*/
    },
    setup_brackets: function(product) {
        if(product.forced_brackets_no == 0) {
            product.brackets_no = 2;
            if(Math.floor(product.length) > 11) {
                product.brackets_no = 4;
            } else if(Math.floor(product.length) > 6) {
                product.brackets_no = 3;
                if(Math.floor(product.projection) == 9) {
                    product.brackets_no = 3;
                } 
            }   
        } else {
            product.brackets_no = product.forced_brackets_no;
        }

        var brackets_pitch;
        if(product.step_length == 0) {
            brackets_pitch = (product.length - product.step_length) / (product.brackets_no * 2)
        } else {
            if(product.projection > product.step_size) {
                brackets_pitch = (product.length - product.step_length) / (product.brackets_no * 2)
            } else {
                brackets_pitch =  product.step_length / (product.brackets_no * 2)
            }
        }

        if(product.brackets_no_buffer != product.brackets_posts_no) {
            product.brackets_no_buffer = product.brackets_posts_no
            
            gui_module.bracket_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.bracket_offset_gui.length; ++i) {                
                if(i < product.brackets_no - 1) gui_module.bracket_offset_gui[i].setValue(0) 
                else gui_module.bracket_offset_gui[i].setValue(40)             
            }  
        } 
        // set positon     
        for(var i = 0; i < product.brackets_model.length; ++i) {
            product.brackets_model[i].scale.set(0.08, product.bracket_length[i], 0.08)
            if(product.brackets_no < 7 && i < product.brackets_no) {
                product.brackets_model[i].visible = true
            } else {
                product.brackets_model[i].visible = false
            }
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 

            var distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;
            
            var scalar = (1 + 2 * (i + product.bracket_offset[i])) * brackets_pitch;
            if(product.step_length > 0) {
                if(product.projection > product.step_size) {
                    distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;            
                    scalar += product.step_length                    
                } else {
                    distance = product.step_size - (product.front_overhang+ product.step_overhang) * product.projection / 2;                   
                }
            }
            v2.multiplyScalar( distance * product.cos_angle)
            v2.y = product.horizon + product.height - product.bracket_length[i] / 2 + posts_gap / 2  + distance * product.sin_angle
            
            v1.multiplyScalar(scalar)
            v1.add(product.position)
            v1.add(v2)
            product.brackets_model[i].position.copy(v1)
        }

    },  
    setup_freestanding_back_posts: function(product) {
        var back_posts_pitch;
        var gable_offset = 0
        
        if(product.type == 4 || product.type == 7 || product.type == 10) {
            gable_offset = 0.15
        }

        if(product.forced_back_posts_no == 0) {
            if(product.type > 12 && product.type < 17) { 
                product.back_posts_no = flyover_module.calculate_automatic_angled_back_posts_no(product)
            } else {
                product.back_posts_no = flyover_module.calculate_automatic_posts_no(product)
            }            
        } else {
            product.back_posts_no = product.forced_back_posts_no;
        }
        if(product.type > 12 && product.type < 17) {            
            back_posts_pitch = product.back_length / (product.back_posts_no * 2)
        } else {
            back_posts_pitch = product.length / (product.back_posts_no * 2)
        }        
               
        if(product.back_posts_no_buffer != product.back_posts_no) {
            product.back_posts_no_buffer = product.back_posts_no
            gui_module.back_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.back_post_offset_gui.length; ++i) {                
                if(i < product.back_posts_no - 1) gui_module.back_post_offset_gui[i].setValue(0) 
                else gui_module.back_post_offset_gui[i].setValue(40)             
            }  
        }
        // set position
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false;
        
        for(var i = 0; i < product.back_posts_model.length; ++i) { 
            if(product.back_posts_no < 7 && i < product.back_posts_no) {
                product.back_posts_model[i].visible = true
                product.posts_base_model[6 + i].visible = decking_not_visible_flag
                product.posts_base_model[36 + i].visible  = decking_not_visible_flag
            } else {
                product.back_posts_model[i].visible = false
                product.posts_base_model[6 + i].visible  = false
                product.posts_base_model[36 + i].visible  = false
            }
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 

            var distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;
            
            var scalar = (1 + 2 * (i + product.back_posts_offset[i])) * back_posts_pitch;
            if(product.step_length > 0) {
                if(scalar > product.step_length) {
                    distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;                               
                } else {
                    distance = product.step_size - (product.front_overhang+ product.step_overhang) * product.projection / 2;              
                }
            }
 
            v2.multiplyScalar(distance * product.cos_angle - gable_offset)
            var height = (product.height + distance * product.sin_angle)     
            product.back_posts_model[i].scale.set(product.front_post_width, height -  gable_offset, product.front_post_width)
            v2.y = product.horizon + height / 2 + posts_gap / 2 - gable_offset / 2
            v1.multiplyScalar(scalar)
            v1.add(product.position)
            v1.add(v2)
            product.back_posts_model[i].position.copy(v1)

            if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[6 + i].position.copy(v1)    
                           
                product.posts_base_model[36 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[36 + i].position.copy(v1)
            }            
        }
    },
    setup_back_posts: function(product) {
        if(product.forced_back_posts_no == 0) {
            product.back_posts_no = 2 
        } else {
            product.back_posts_no = product.forced_back_posts_no;
        }

        var back_posts_pitch;
        if(product.step_length == 0) {
            back_posts_pitch = (product.length - product.step_length) / (product.back_posts_no * 2)
        } else {
            if(product.projection < product.step_size) {
                back_posts_pitch = (product.length - product.step_length) / (product.back_posts_no * 2)
            } else {
                back_posts_pitch =  product.step_length / (product.back_posts_no * 2)
            }
        }
       
        if(product.back_posts_no_buffer != product.back_posts_no) {
            product.back_posts_no_buffer = product.back_posts_no
            gui_module.back_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.back_post_offset_gui.length; ++i) {                
                if(i < product.back_posts_no - 1) gui_module.back_post_offset_gui[i].setValue(0) 
                else gui_module.back_post_offset_gui[i].setValue(40)             
            }  
        }
        // set position
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false;

        for(var i = 0; i < product.back_posts_model.length; ++i) {
            
            product.back_posts_model[i].visible = false
            product.posts_base_model[6 + i].visible  = false
            product.posts_base_model[36 + i].visible  = false

            if(product.back_posts_no < 7 && i < product.back_posts_no) {
                if(product.step_length > 0) {
                    product.back_posts_model[i].visible = true
                    product.posts_base_model[6 + i].visible = decking_not_visible_flag
                    product.posts_base_model[36 + i].visible  = decking_not_visible_flag
                }                
            } 
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 

            var distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;
            
            var scalar = (1 + 2 * (i + product.back_posts_offset[i])) * back_posts_pitch;
            if(product.step_length > 0) {
                if(product.projection < product.step_size) {
                    distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;            
                    scalar += product.step_length                    
                } else {
                    distance = product.step_size - (product.front_overhang+ product.step_overhang) * product.projection / 2;                  
                }
            }
 
            v2.multiplyScalar( distance * product.cos_angle)
            var height = (product.height + distance * product.sin_angle)     
            product.back_posts_model[i].scale.set(product.front_post_width, height, product.front_post_width)
            v2.y = product.horizon + height / 2 + posts_gap / 2
            v1.multiplyScalar(scalar)
            v1.add(product.position)
            v1.add(v2)
            product.back_posts_model[i].position.copy(v1)

            if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[6 + i].position.copy(v1)
                
                product.posts_base_model[36 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)
                v1.y = product.front_post_width / 2 
                product.posts_base_model[36 + i].position.copy(v1)
            }            
        }
    },  
    setup_right_posts: function(product) {
        var angle = (product.rotate) * Math.PI / 180
        var right_posts_pitch, angle;
        var gable_offset = 0
        
        if(product.type == 4 || product.type == 7 || product.type == 10) {
            gable_offset = 0.15
        }   
        if(product.type > 3) {
            if(product.forced_right_posts_no == 0) {
                product.right_posts_no = 9            
            } else {
                product.right_posts_no = product.forced_right_posts_no                
            }  
        } else {
            product.right_posts_no = 9
        }
          
        if(product.type == 13 || product.type == 14) {
            angle += Math.atan((product.length - product.back_length) / product.projection)                    
        } 

        if(product.type > 12 && product.type < 17) {            
            right_posts_pitch = Math.pow(Math.pow(product.projection, 2) + Math.pow(product.length - product.back_length, 2), 0.5) / (product.right_posts_no * 2)
        } else {
            right_posts_pitch = product.projection / (product.right_posts_no * 2)
        }        
                       
        if(product.right_posts_no_buffer != product.right_posts_no) {
            product.right_posts_no_buffer = product.right_posts_no
            gui_module.right_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.right_post_offset_gui.length; ++i) {                
                if(i < product.right_posts_no - 1) gui_module.right_post_offset_gui[i].setValue(0) 
                else gui_module.right_post_offset_gui[i].setValue(40)             
            }  
        }
        // set position
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false

        
        
        for(var i = 0; i < product.right_posts_model.length; ++i) { 
            if(product.right_posts_no < 7 && i < product.right_posts_no) {
                product.right_posts_model[i].visible = true
                product.posts_base_model[24 + i].visible = decking_not_visible_flag
                product.posts_base_model[54 + i].visible  = decking_not_visible_flag
                product.right_posts_model[i].rotation.y = angle
                product.posts_base_model[24 + i].rotation.y = angle
                product.posts_base_model[54 + i].rotation.y = angle
            } else {
                product.right_posts_model[i].visible = false
                product.posts_base_model[24 + i].visible  = false
                product.posts_base_model[54 + i].visible  = false
            }
            v2.copy(product.left_2_right_unit_vector) 
            v1.set(Math.cos(angle), 0, -1 * Math.sin(angle)) 

            var distance = (2 - product.front_overhang - product.back_overhang) * product.length / 2            
            var scalar = (1 + 2 * (i + product.right_posts_offset[i])) * right_posts_pitch;
         
 
            v2.multiplyScalar(product.length - 0.1) //distance * product.cos_angle)
            var height = (product.height + distance * product.sin_angle)     
            product.right_posts_model[i].scale.set(product.front_post_width, height -  gable_offset, product.front_post_width)
            v2.y = product.horizon + height / 2 + posts_gap / 2 - gable_offset / 2 
            v1.multiplyScalar(scalar)
            v1.add(product.position)
            v1.add(v2)
            //v1.z += 
            product.right_posts_model[i].position.copy(v1)

            if(decking_not_visible_flag) {
                v1.y = 0    
                product.posts_base_model[24 + i].position.copy(v1)    
                           
                product.posts_base_model[54 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[54 + i].position.copy(v1)
            }            
        }
    },
    setup_left_posts: function(product) {        
        var left_posts_pitch;
        var gable_offset = 0
        
        if(product.type == 4 || product.type == 7 || product.type == 10) {
            gable_offset = 0.15
        }

        if(product.forced_left_posts_no == 0) {
            if(product.type > 12 && product.type < 17) { 
                product.left_posts_no = flyover_module.calculate_automatic_angled_back_posts_no(product)
            } else {
                product.left_posts_no = flyover_module.calculate_automatic_posts_no(product)
            }            
        } else {
            product.left_posts_no = product.forced_left_posts_no;
        }
        if(product.type > 12 && product.type < 17) {            
            left_posts_pitch = product.back_length / (product.left_posts_no * 2)
        } else {
            left_posts_pitch = product.projection / (product.left_posts_no * 2)
        }        
               
        if(product.left_posts_no_buffer != product.left_posts_no) {
            product.left_posts_no_buffer = product.left_posts_no
            gui_module.left_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.left_post_offset_gui.length; ++i) {                
                if(i < product.left_posts_no - 1) gui_module.left_post_offset_gui[i].setValue(0) 
                else gui_module.left_post_offset_gui[i].setValue(40)             
            }  
        }
        // set position
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false;
        
        for(var i = 0; i < product.left_posts_model.length; ++i) { 
            if(product.left_posts_no < 7 && i < product.left_posts_no) {
                product.left_posts_model[i].visible = true
                product.posts_base_model[18 + i].visible = decking_not_visible_flag
                product.posts_base_model[42 + i].visible  = decking_not_visible_flag
            } else {
                product.left_posts_model[i].visible = false
                product.posts_base_model[18 + i].visible  = false
                product.posts_base_model[42 + i].visible  = false
            }
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 

            var distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;
            
            var scalar = (1 + 2 * (i + product.left_posts_offset[i])) * left_posts_pitch;
            if(product.step_length > 0) {
                if(scalar > product.step_length) {
                    distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;                               
                } else {
                    distance = product.step_size - (product.front_overhang+ product.step_overhang) * product.projection / 2;              
                }
            }
 
            v2.multiplyScalar(distance * product.cos_angle - gable_offset)
            var height = (product.height + distance * product.sin_angle)     
            product.left_posts_model[i].scale.set(product.front_post_width, height -  gable_offset, product.front_post_width)
            v2.y = product.horizon + height / 2 + posts_gap / 2 - gable_offset / 2 + 3
            v1.multiplyScalar(scalar + 1)
            v1.add(product.position)
            v1.add(v2)
            product.left_posts_model[i].position.copy(v1)

            if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[18 + i].position.copy(v1)    
                           
                product.posts_base_model[54 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[54 + i].position.copy(v1)
            }            
        }
    },
    set_forced_front_posts_no: function(product, no) {
        product.forced_front_posts_no = no;
        flyover_module.setup(product)
    },
    set_front_post_width: function(product, value) {
        product.front_post_width = value;
        flyover_module.posts_color(product, gui_module.posts)
        flyover_module.setup(product)
    },
    set_front_post_offset: function(front_post_index, product, front_post_offset) {
        product.front_posts_offset[front_post_index] = front_post_offset;
        flyover_module.setup(product)
    },
    calculate_automatic_posts_no: function(product) {
        var no = 2
        if(product.length > 6) {
            if(product.length < 14) {
                if(product.projection > 3.9) no = 3;
            } else {
                no = 3;
                if(product.projection > 3.9) no = 4;
            }
        }
        return no
    },
    calculate_automatic_angled_back_posts_no: function(product) {
        var no = 2
        if(product.back_length > 6) {
            if(product.back_length < 14) {
                if(product.projection > 3.9) no = 3;
            } else {
                no = 3;
                if(product.projection > 3.9) no = 4;
            }
        }
        if(product.type == 13) no = 0
        return no
    },
    setup_front_posts: function(product) {
        // work out the front posts number
        if(product.forced_front_posts_no == 0) {
            product.front_posts_no = flyover_module.calculate_automatic_posts_no(product)
        } else {
            product.front_posts_no = product.forced_front_posts_no;
        }
           
        if(product.front_posts_no_buffer != product.front_posts_no) {
            product.front_posts_no_buffer = product.front_posts_no
            gui_module.front_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.front_post_offset_gui.length; ++i) {                
                if(i < product.front_posts_no - 1) gui_module.front_post_offset_gui[i].setValue(0) 
                else gui_module.front_post_offset_gui[i].setValue(40)             
            }  
        }        

        product.roofing_type = 1;
        if(Math.floor(product.projection) == 6) {
            product.roofing_type = 1.25;
        } else if(Math.floor(product.projection) > 6) {
            product.roofing_type = 1.5;
        }
        // visible needed
        var decking_not_visible_flag = true;
        if(product.horizon > 0)  decking_not_visible_flag = false;
           
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            if(product.front_posts_no < 7 && i < product.front_posts_no) {
                obj.visible = true;
                product.posts_base_model[0 + i].visible  = decking_not_visible_flag
                product.posts_base_model[30 + i].visible  = decking_not_visible_flag
            } else {
                obj.visible = false;  
                product.posts_base_model[i].visible  = false
                product.posts_base_model[30 + i].visible  = false 
            }           
        } 
    
       var front_post_pitch = product.length / (product.front_posts_no * 2)
       
       // locate       
       for(var i = 0; i < product.front_posts_model.length; ++i) {
           product.front_posts_model[i].scale.set(product.front_post_width, product.height, product.front_post_width);          
           v1.copy(product.left_2_right_unit_vector) 
           var scalar = (1 + 2 * (i + product.front_posts_offset[i])) * front_post_pitch;
           v1.multiplyScalar(scalar)
           v1.add(product.position)

           if(product.type > 3 && product.type < 13) {
            v2.copy(product.front_2_end_unit_vector)            
            v1.y += product.horizon + (product.height + posts_gap) / 2 - 0.05;            
            v2.multiplyScalar(0.15  )
            v1.add(v2)
           } else {
            v1.y += product.horizon + (product.height + posts_gap) / 2;
           }                  
           product.front_posts_model[i].position.copy(v1)

           if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[i].position.copy(v1)
                
                product.posts_base_model[30 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[30 + i].position.copy(v1)
            } 
       }   
    },
    set_forced_decking_posts_no: function(product, no) {
        product.forced_decking_posts_no = no;
        flyover_module.setup(product)
    },
    set_decking_post_width: function(product, value) {
        product.decking_post_width = value;
        flyover_module.posts_color(product, gui_module.posts)
        flyover_module.setup(product)
    },
    set_decking_post_offset: function(decking_post_index, product, decking_post_offset) {
        product.decking_posts_offset[decking_post_index] = decking_post_offset;
        flyover_module.setup(product)
    },
    set_decking_posts_position: function(product) {
        // work out the decking posts number
        if(product.forced_decking_posts_no == 0) {
            product.decking_posts_no = flyover_module.calculate_automatic_posts_no(product)
        } else {
            product.decking_posts_no = product.forced_decking_posts_no;
        }
        
        if(product.decking_posts_no_buffer != product.decking_posts_no) {
            product.decking_posts_no_buffer = product.decking_posts_no
            gui_module.decking_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.decking_post_offset_gui.length; ++i) {                
                if(i < product.decking_posts_no - 1) gui_module.decking_post_offset_gui[i].setValue(0) 
                else gui_module.decking_post_offset_gui[i].setValue(40)             
            }  
        }        
        // visible needed
        var decking_visible_flag = false;
        if(product.landscaping_visible && product.landscaping_type == "decking") decking_visible_flag = true
 
        for(var i = 0; i < product.decking_posts_model.length; ++i) {
            var obj = product.decking_posts_model[i]
            if(product.decking_posts_no < 7 && i < product.decking_posts_no && decking_visible_flag) {
                obj.visible = true;
                product.posts_base_model[12 + i].visible  = true
                product.posts_base_model[48 + i].visible  = true
            }
            else {
                obj.visible = false; 
                product.posts_base_model[12 + i].visible  = false
                product.posts_base_model[48 + i].visible  = false 
            }              
        } 
   
        var decking_post_pitch = product.length / (product.decking_posts_no * 2)
        // locate       
        for(var i = 0; i < product.decking_posts_model.length; ++i) {
            product.decking_posts_model[i].scale.set(product.decking_post_width, product.landscaping_height - posts_gap, product.decking_post_width);
            var v1 = new THREE.Vector3();
            v1.copy(product.left_2_right_unit_vector) 
            var scalar = (1 + 2 * (i + product.decking_posts_offset[i])) * decking_post_pitch;
            v1.multiplyScalar(scalar)

            v1.y += (product.landscaping_height) / 2;

            v1.add(product.position)
            product.decking_posts_model[i].position.copy(v1)

            if(decking_visible_flag) {
                v1.y = 0;
                product.posts_base_model[12 + i].position.copy(v1)

                product.posts_base_model[48 + i].scale.set(product.decking_post_width * 5 / 4, product.decking_post_width, product.decking_post_width / 4)                
                v1.y = product.decking_post_width / 2 
                product.posts_base_model[48 + i].position.copy(v1)
            } 
        }   
    },
    setup_roofing(product) {

        var v1 = new THREE.Vector3();
        var v2 = new THREE.Vector3();

        var roofing_notch_rate = 4
        if(product.profile > 9) {
            roofing_notch_rate = 10            
        }

        var yAngle = (product.rotate + 90) * Math.PI / 180
        var zAngle = (product.angle) * Math.PI / 180

        for(var i = 0; i < product.roofing_model.length; ++i) {

            product.roofing_model[i].rotation.set(0, 0, 0)
            product.roofing_model[i].rotation.y = yAngle
            //product.roofing_model[i].rotation.x = (product.angle) * Math.PI / 180
            product.roofing_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, zAngle) 
        
            if(((i + 1) / roofing_notch_rate) >= product.length) {
                product.roofing_model[i].visible = false;
            } else {
                product.roofing_model[i].visible = true;
                product.roofing_model[i].position.copy(product.position)
                
                
                v1.copy(product.left_2_right_unit_vector) 
                var scalar = (i + 1) / roofing_notch_rate
                v1.multiplyScalar(scalar)
               
                v2.copy(product.front_2_end_unit_vector) 
                
                var factor = (1 - product.front_overhang) * product.projection
                var scalar =  factor * product.cos_angle / 2;
                var offsetY = factor * product.sin_angle / 2;

                if((i) / roofing_notch_rate >= product.step_length) {
                    product.roofing_model[i].scale.set(1, 1, product.projection * 1.007)
                } else {
                    product.roofing_model[i].scale.set(1, 1, product.step_size)
                    factor = (product.step_size - product.front_overhang * product.projection)
                    scalar = factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;
                }
                
                v2.multiplyScalar(scalar)
                v2.add(v1)

                product.roofing_model[i].position.add(v2)                

                var zOffset = + 0.01 * (product.roofing_type) * product.sin_angle
                var y = product.horizon + product.height + beam_height * product.roofing_type + 0.01 * (product.roofing_type) * product.cos_angle + posts_gap + offsetY 
                product.roofing_model[i].position.z += zOffset
                product.roofing_model[i].position.y = y
            }
        }
    },
    setup_ceiling: function(product) {
        var roofing_notch_rate = 4
        if(product.profile > 9) {
            roofing_notch_rate = 10            
        }

        var offset = -0.01
        var factor, scalar, offsetY
        for(var i = 0; i < product.ceiling_model.length; ++i) {

            product.ceiling_model[i].position.copy(product.position)
            product.ceiling_model[i].rotation.set(0, 0, 0)
            product.ceiling_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180

            product.ceiling_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
        
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 

            switch(i) {
                case 0:                    
                case 1:                    
                    var step_fraction = (product.length - Math.floor(product.length)) 
                    var fraction = step_fraction - (Math.floor(step_fraction * roofing_notch_rate)) / roofing_notch_rate

                    if(fraction < 0.1)  fraction = (1 / roofing_notch_rate) - 0.1

                    if(product.profile == 1 && i == 1) product.ceiling_model[i].visible = false
                    else product.ceiling_model[i].visible = true

                    product.ceiling_model[i].scale.set(fraction, 0.02 * product.roofing_type, product.projection)
                    
                    v1.multiplyScalar(product.length - fraction / 2 - 0.05)
                    
                    factor = (product.projection - product.front_overhang * product.projection)
                    scalar = factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;
                        
                    v2.multiplyScalar(scalar)
                    v1.add(v2)  
    
                    product.ceiling_model[i].position.add(v1)                
    
                    var zOffset = + 0.01 * (product.roofing_type) * product.sin_angle
                    var y = product.horizon + product.height + beam_height * product.roofing_type + 0.01 * (product.roofing_type) * product.cos_angle + posts_gap + offsetY 
                  
                    product.ceiling_model[i].position.z += zOffset 
                    product.ceiling_model[i].position.y = y - i * 0.0035
                    break;
                case 2:
                    if(product.step_length == 0)  product.ceiling_model[i].visible = false
                    else product.ceiling_model[i].visible = true 

                    product.ceiling_model[i].scale.set(product.step_length, 0.05 * product.roofing_type, product.step_size)
                    
                    v1.multiplyScalar(product.step_length / 2)                  
                    
                    factor = (product.step_size - product.front_overhang * product.projection)
                    scalar = factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;
                        
                    v2.multiplyScalar(scalar)
                    v1.add(v2)
    
                    product.ceiling_model[i].position.add(v1)                
    
                    product.ceiling_model[i].position.z += offset * product.sin_angle
                    product.ceiling_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2 + offsetY + offset * product.cos_angle; 
                    break;
                //case 1: 
                    //product.ceiling_model[i].visible = false
                case 3:                    
                    product.ceiling_model[i].scale.set(product.length - product.step_length, 0.05 * product.roofing_type, product.projection)
                    
                    v1.multiplyScalar((product.length + product.step_length) / 2)                  
                    
                    factor = (product.projection - product.front_overhang * product.projection)
                    scalar = factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;
                        
                    v2.multiplyScalar(scalar)
                    v1.add(v2)
    
                    product.ceiling_model[i].position.add(v1)                
    
                    product.ceiling_model[i].position.z += offset * product.sin_angle
                    product.ceiling_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2 + offsetY + offset * product.cos_angle;
                    break;                                       
            }

        }
    },
    setup_gutters: function(product) {
        for(var i = 0; i < product.gutters_model.length; ++i) {
            product.gutters_model[i].position.copy(product.position)
            if((i == 1 || i == 2) && product.step_length == 0) {
                product.gutters_model[i].visible = false
            } else product.gutters_model[i].visible = true                     
        }
        var v1 = new THREE.Vector3();
        var v2 = new THREE.Vector3();
        var scalar, factor, offsetY
        var scale = product.roofing_type


        for(var i = 0; i < product.gutters_model.length; ++i) {

            product.gutters_model[i].rotation.set(0, 0, 0)
            product.gutters_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180
            if(i % 2 == 0) product.gutters_model[i].rotation.y += Math.PI / 2
            
            product.gutters_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
        
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 
            
            switch(i) {
                case 0:                    
                    if(product.step_length > 0) {
                        product.gutters_model[i].scale.set(product.step_size, scale, scale)
                        factor = product.step_size - product.front_overhang * product.projection
                    } else {
                        product.gutters_model[i].scale.set(product.projection, scale, scale)
                        factor = (1 - product.front_overhang) * product.projection                 
                    } 
                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                    
            
                    v1.set(0, 0, 0)
                    break;
                case 1:
                    product.gutters_model[i].scale.set((product.step_length), scale, scale)
                    
                    factor = product.step_size * 2 - product.front_overhang * product.projection
                    
                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                    
     
                    v1.multiplyScalar(product.step_length / 2)
                    break; 
                case 2:
                    if(product.projection > product.step_size) {
                        product.gutters_model[i].scale.set((product.step_size - product.projection), scale, scale)                        
                    } else {
                        product.gutters_model[i].scale.set((product.projection - product.step_size), scale, scale)                        
                    }

                    factor = product.projection + product.step_size - product.front_overhang * product.projection
                    
                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                    

                   
                    v1.multiplyScalar(product.step_length)
                    break;
                case 3:
                    var l = product.length + scale / 10
                    product.gutters_model[i].scale.set((l - product.step_length), scale, scale)
                    factor = product.projection + (1 - product.front_overhang) * product.projection 

                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                    
   
                    
                    v1.multiplyScalar((l + product.step_length) / 2 - 0.045)
               
                    break; 
                case 4:
                    product.gutters_model[i].scale.set(product.projection, scale, scale)
                    factor = (1 - product.front_overhang) * product.projection 

                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                      
                    
                    v1.multiplyScalar(product.length)
               
                    break;
                case 5:
                    var l = product.length + scale / 10
                    product.gutters_model[i].scale.set(l, scale, scale)
                    factor = -1 * product.front_overhang * product.projection 

                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                    
                    
                    v1.multiplyScalar(l / 2 - 0.045)
               
                    break;                                        
            }
            v2.multiplyScalar(scalar)
            v2.add(v1)

            product.gutters_model[i].position.add(v2)
            product.gutters_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2 + offsetY
        }
    },
    set_beams_position: function(product) {
        // locate       
        var scale = product.roofing_type

        for(var i = 0; i < product.beams_model.length; ++i) {
            product.beams_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180
            product.beams_model[i].position.copy(product.position)
            product.beams_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2
            v2.copy(product.left_2_right_unit_vector) 
            v1.copy(product.front_2_end_unit_vector) 
            switch(i) {
                case 0:
                    product.beams_model[i].scale.set(product.length, scale, scale) 
                    
                    scalar = 1 *  product.length / 2;
                    v2.multiplyScalar(scalar)

                    product.beams_model[i].position.add(v2)                    
                    break;
                case 1:
                    var distance, scalar

                    product.beams_model[i].scale.set(product.step_length, scale, scale) 
                    distance = product.step_size + (0 - product.front_overhang - product.step_overhang) * product.projection / 2;
                    scalar = product.cos_angle * distance
                    v1.multiplyScalar(scalar)    
                    v2.multiplyScalar(product.step_length / 2)
                    v2.add(v1)    
                                  
                    product.beams_model[i].position.add(v2)  

                    product.beams_model[i].position.y += product.sin_angle * distance
                    break;
                case 2:
                    
                    product.beams_model[i].scale.set((product.length - product.step_length), scale, scale)  

                    var distance = (2 - product.front_overhang - product.back_overhang) * product.projection / 2;

                    var scalar = product.cos_angle * distance
                    v1.multiplyScalar(scalar)

                    v2.multiplyScalar((product.length + product.step_length) / 2)
                    v2.add(v1)
                                  
                    product.beams_model[i].position.add(v2)  

                    product.beams_model[i].position.y += product.sin_angle * distance
                    break;
            }
        }
    },
    setup_beams(product) {
        // visible needed
        if(product.beams_model.length > 2) {
            if(product.step_length > 0) {
                product.beams_model[1].visible = true
            } else {
                product.beams_model[1].visible = false
            }
        }
        flyover_module.set_beams_position(product);
    },
    setup_rafters(product) {    
        var scale = 0.1
        
       if(product.rafters_no_buffer != product.forced_rafters_no) {
            product.rafters_no_buffer = product.forced_rafters_no
            if(product.forced_rafters_no == 0 || product.forced_rafters_no > 8) {
                for(var i = 0; i < gui_module.rafter_offset_gui.length; ++i) {                
                    gui_module.rafter_offset_gui[i].setValue(0)     
                } 
            } else {
                var s = Math.ceil(product.front_overhang * 100)
                var e = Math.ceil((1 - product.back_overhang - product.front_overhang) * 100)
                var st = (e - s) / (product.forced_rafters_no + 1)
                for(var i = 0; i < gui_module.rafter_offset_gui.length; ++i) {                
                    if(i < product.forced_rafters_no) gui_module.rafter_offset_gui[i].setValue(-50 + s + (i + 1) * st) 
                    else  gui_module.rafter_offset_gui[i].setValue(0)     
                } 
            }
        } 

        for(var i = 0; i < product.rafters_model.length; ++i) {
            product.rafters_model[i].rotation.set(0, 0, 0)
            product.rafters_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180
            product.rafters_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
            product.rafters_model[i].position.copy(product.position)
            product.rafters_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2
            v2.copy(product.left_2_right_unit_vector) 
            v1.copy(product.front_2_end_unit_vector) 
            switch(i) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    if(i >= product.forced_rafters_no || product.forced_rafters_no > 8) {
                        product.rafters_model[i].visible = false
                    } else {
                        product.rafters_model[i].visible = true
                        product.rafters_model[i].scale.set(product.length, scale, scale) 
                        
                        var offset = product.projection * (0.5 + product.rafter_offset[i])
                        v1.multiplyScalar(offset * product.cos_angle)
                        var scalar = product.length / 2;
                        v2.multiplyScalar(scalar)
                        v2.add(v1)
    
                        product.rafters_model[i].position.add(v2) 
                        product.rafters_model[i].position.y += offset * product.sin_angle - 0.01
                    }               
                    break
                default:
                    product.rafters_model[i].visible = false
                    break
            }
        }
    },
    setup_purlins(product) {
        var scale = 0.1
        
        if(product.purlins_no_buffer != product.forced_purlins_no) {
             product.purlins_no_buffer = product.forced_purlins_no
             if(product.forced_purlins_no == 0 || product.forced_purlins_no > 8) {
                 for(var i = 0; i < gui_module.purlin_offset_gui.length; ++i) {                
                     gui_module.purlin_offset_gui[i].setValue(0)     
                 } 
             } else {
                 var st = 100 / (product.forced_purlins_no + 1)
                 for(var i = 0; i < gui_module.purlin_offset_gui.length; ++i) {                
                     if(i < product.forced_purlins_no) gui_module.purlin_offset_gui[i].setValue(-50 + (i + 1) * st) 
                     else  gui_module.purlin_offset_gui[i].setValue(0)     
                 } 
             }
         } 
 
         for(var i = 0; i < product.purlins_model.length; ++i) {
             product.purlins_model[i].rotation.set(0, 0, 0)
             product.purlins_model[i].rotation.y = (product.rotate + 180) * Math.PI / 180
             product.purlins_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
             product.purlins_model[i].position.copy(product.position)
             product.purlins_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2
             v2.copy(product.left_2_right_unit_vector) 
             v1.copy(product.front_2_end_unit_vector) 
             switch(i) {
                 case 0:
                 case 1:
                 case 2:
                 case 3:
                 case 4:
                 case 5:
                 case 6:
                     if(i >= product.forced_purlins_no || product.forced_purlins_no > 8) {
                         product.purlins_model[i].visible = false
                     } else {
                        var len = product.projection * (1 - 0.5 * product.front_overhang - 0.5 * product.back_overhang)
                        product.purlins_model[i].visible = true
                        product.purlins_model[i].scale.set(len, scale, scale) 
                         
                        var offset = product.length * (0.5 + product.purlin_offset[i])
                        v2.multiplyScalar(offset)
                        var scalar = len / 2
                        v1.multiplyScalar(scalar * product.cos_angle)
                        v2.add(v1)
     
                        product.purlins_model[i].position.add(v2) 
                        product.purlins_model[i].position.y += scalar * product.sin_angle - 0.01
                     }               
                     break
                 default:
                     product.purlins_model[i].visible = false
                     break
             }
         }
    },
    setup: function(product) {
        //product.position.copy(position);
        flyover_module.setup_landscaping(product);
        flyover_module.set_decking_posts_position(product);
        flyover_module.validate_flyover_size(product) 
        flyover_module.setup_front_posts(product);
        flyover_module.setup_brackets(product);
        if(product.type == 3) {
            flyover_module.setup_freestanding_back_posts(product);
        } else {
            flyover_module.setup_back_posts(product);
        }    
        flyover_module.setup_right_posts(product);    
        flyover_module.setup_left_posts(product);
        flyover_module.setup_roofing(product);
        flyover_module.setup_gutters(product);
        flyover_module.setup_beams(product);
        flyover_module.setup_rafters(product);
        flyover_module.setup_purlins(product);
        flyover_module.setup_ceiling(product);
        //flyover_module.setup_walls(product)
        flyover_module.setup_fans(product);
        flyover_module.setup_downlights(product);
        gui_module.set_dim_text()

    },
    profile: function(product, profile) {
        product.profile = profile;
        flyover_module.add_roofing(product)
        flyover_module.setup(product)
    },
    move: function(product, position) {
        product.position.copy(position)
        flyover_module.setup(product)
        product.position.y = 0 //gui_module.altitude
        sceneItems.controler.target.set(position.x + product.length / 2, product.height, position.z);
    },
    ray_move_posts_check: function(product, object, position, model, offset_array) {
        var got_flag = false;
        for(var i = 0; i < model.length; ++i) {
            if(model[i] == object) {
                var offset = 0
                var post_pos = model[i].position
                var distance = post_pos.distanceTo(position)

                var v = offset_array[i % 6].getValue()
                if(product.type < 4 || product.type > 27) {
                    if(post_pos.x < position.x) v = v + Math.floor(distance)
                    else v = v - Math.floor(distance)
                } else {
                    if(post_pos.z < position.z) v = v + Math.floor(distance)
                    else v = v - Math.floor(distance)
                }

                if(v > 50 ) v = 50
                if(v < -50 ) v = -50
                offset_array[i % 6].setValue(v)
                got_flag = true;
                break;                   
            } 
        }
        return got_flag
    },
    ray_move: function(product, object, position) {
        if(product.lock) return

        var got_flag = flyover_module.ray_move_posts_check(product, object, position, product.front_posts_model, gui_module.front_post_offset_gui);
        if(!got_flag) got_flag = flyover_module.ray_move_posts_check(product, object, position, product.back_posts_model, gui_module.back_post_offset_gui);
        if(!got_flag) got_flag = flyover_module.ray_move_posts_check(product, object, position, product.brackets_model, gui_module.bracket_offset_gui);
        if(!got_flag) got_flag = flyover_module.ray_move_posts_check(product, object, position, product.decking_posts_model, gui_module.decking_post_offset_gui);

        if(!got_flag) {
            for(var i = 0; i < product.roofing_model.length; ++i) {
                product.roofing_model[i].traverse(function(obj) {
                    if(obj.isMesh) {
                        got_flag = true;
                    }                
                }) 

                if(got_flag) break;                         
            }
            if(got_flag) {
                product.position.copy(position)
                product.position.y = 0 //gui_module.altitude
                flyover_module.setup(product)
                got_flag = false
                sceneItems.controler.target.set(position.x + product.length / 2, product.height, position.z);
            }
        }
    },
    scale: function(product, scale) {
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            product.scale = scale;
            product.front_posts_model[i].scale.set(product.scale, product.scale, product.scale)
        }
    },
    length: function(product, length) {
        product.length = length;
        flyover_module.setup(product)
    },
    projection: function(product, projection) {
        product.projection = projection;
        flyover_module.setup(product)
    },
    height: function(product, height) {
        product.height = height;
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            obj.scale.set(product.front_post_width, product.height, product.front_post_width);
        } 
        flyover_module.setup(product)
    },
    set_horizon: function(product, horizon) {
        product.horizon = horizon;
        product.landscaping_height = horizon;
        flyover_module.check_landscaping_horizon(product) 
        flyover_module.setup(product)
    },
    step_length: function(product, step_length) {
        product.step_length = step_length;
        flyover_module.setup(product)
    },
    step_size: function(product, step_size) {
        product.step_size = step_size;
        flyover_module.setup(product)
    },
    bracket_size: function(no, product, bracket_size) {
        product.bracket_length[no] = bracket_size
        flyover_module.setup(product)
    },
    angle: function(product, angle) {
        product.angle = angle;

        product.cos_angle = Math.cos(Math.PI * angle / 180);
        product.sin_angle = Math.sin(Math.PI * angle / 180);
        flyover_module.setup(product)
    },
    rotate: function(product, rotate) {
        product.rotate = rotate;
        var view_radian = Math.PI * rotate / 180
        var cos_view = Math.cos(view_radian);
        var sin_view = Math.sin(view_radian);
        product.left_2_right_unit_vector.set(sin_view, 0, cos_view)
        product.front_2_end_unit_vector.set(cos_view, 0, -1 * sin_view)
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            obj.rotation.y = view_radian
        }
        for(var i = 0; i < product.decking_posts_model.length; ++i) {
            var obj = product.decking_posts_model[i]
            obj.rotation.y = view_radian
        }
        for(var i = 0; i < product.back_posts_model.length; ++i) {
            var obj = product.back_posts_model[i]
            obj.rotation.y = view_radian
        }
        for(var i = 0; i < product.brackets_model.length; ++i) {
            var obj = product.brackets_model[i]
            obj.rotation.y = view_radian
        }
        for(var i = 0; i < product.right_posts_model.length; ++i) {
            var obj = product.right_posts_model[i]
            obj.rotation.y = view_radian
        }
        for(var i = 0; i < product.left_posts_model.length; ++i) {
            var obj = product.left_posts_model[i]
            obj.rotation.y = view_radian
        }

        for(var i = 0; i < product.posts_base_model.length; ++i) {
            var obj = product.posts_base_model[i]
            obj.rotation.y = view_radian
        }

        flyover_module.setup(product)
    },
    front_overhang: function(product, front_overhang) {
        product.front_overhang = front_overhang;
        flyover_module.setup(product)
    },
    back_overhang: function(product, back_overhang) {
        product.back_overhang = back_overhang;
        flyover_module.setup(product)
    },
    step_overhang: function(product, step_overhang) {
        product.step_overhang = step_overhang;
        flyover_module.setup(product)
    },
    set_forced_back_posts_no: function(product, no) {
        product.forced_back_posts_no = no;
        flyover_module.setup(product)
    },
    set_forced_right_posts_no: function(product, no) {
        product.forced_right_posts_no = no;
        flyover_module.setup(product)
    },
    set_forced_left_posts_no: function(product, no) {
        product.forced_left_posts_no = no;
        flyover_module.setup(product)
    },
    set_back_post_offset: function(back_post_index, product, back_post_offset) {
        product.back_posts_offset[back_post_index] = back_post_offset;
        flyover_module.setup(product)
    },
    set_right_post_offset: function(right_post_index, product, right_post_offset) {
        product.right_posts_offset[right_post_index] = right_post_offset;
        flyover_module.setup(product)
    },
    set_left_post_offset: function(left_post_index, product, left_post_offset) {
        product.left_posts_offset[left_post_index] = left_post_offset;
        flyover_module.setup(product)
    },
    set_forced_brackets_no: function(product, no) {
        product.forced_brackets_no = no;
        flyover_module.setup(product)
    },
    set_bracket_offset: function(brakcet_post_index, product, bracket_offset) {
        product.bracket_offset[brakcet_post_index] = bracket_offset;
        flyover_module.setup(product)
    },
    set_forced_rafters_no: function(product, no) {
        product.forced_rafters_no = no
        flyover_module.setup(product)
    },
    set_rafter_offset: function(rafter_index, product, rafter_offset) {
        product.rafter_offset[rafter_index] = rafter_offset
        flyover_module.setup(product)
    },
    set_forced_purlins_no: function(product, no) {
        product.forced_purlins_no = no
        flyover_module.setup(product)
    },
    set_purlin_offset: function(purlin_index, product, purlin_offset) {
        product.purlin_offset[purlin_index] = purlin_offset
        flyover_module.setup(product)
    },
    roofing_color: function(product, color) {
        product.roofing = color
        var material = getMaterial(convertColor2Hex(selectRoofingColorbond(color)), 0);  
        for(var i = 0; i < product.roofing_model.length; ++i) {
            product.roofing_model[i].traverse( function (child) {
                if(child.isMesh) {
                    if(child.name.startsWith("Top")) child.material =  material 
                    else child.material = getMaterial(0xffffff, 0);  
                }                         
            });
        }  
        if(product.type < 32 && product.ceiling_model.length > 0) {
            product.ceiling_model[0].material = material
            //product.ceiling_model[1].material = material
        }
    },
    gutters_color: function(product, color) {
        var material = getMaterial(convertColor2Hex(selectColorbond(color)), 0);
        for(var i = 0; i < product.gutters_model.length; ++i) {
            product.gutters_model[i].traverse( function (child) {
                if(child.isMesh) child.material = material             
            });
        }        
    },
    beams_color: function(product, color) {
        var material = getMaterial(convertColor2Hex(selectPostsColorbond(color)), 0); 
        for(var i = 0; i < product.beams_model.length; ++i) {
            product.beams_model[i].traverse( function (child) {
                if(child.isMesh) child.material = material             
            });
        }      
        
        for(var i = 0; i < product.rafters_model.length; ++i) {
            var obj = product.rafters_model[i]
            obj.material = material;
        }

        for(var i = 0; i < product.purlins_model.length; ++i) {
            var obj = product.purlins_model[i]
            obj.material = material;
        }
    },
    posts_color: function(product, color) {
        var mMaterial = getMaterial(convertColor2Hex(selectPostsColorbond(color)), 0);
        var wMaterial = loader_module.get_wood_material()
        for(var i = 0; i < product.brackets_model.length; ++i) {
            var obj = product.brackets_model[i]
            obj.material = mMaterial;
        } 
   
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            if(product.front_post_width > 0.1 && product.front_post_width < 0.16) {
                obj.material = wMaterial 
            } else obj.material = mMaterial
            
        }     
        for(var i = 0; i < product.back_posts_model.length; ++i) {
            var obj = product.back_posts_model[i]
            if(product.front_post_width > 0.1 && product.front_post_width < 0.16) {
                obj.material = wMaterial 
            } else obj.material = mMaterial
        }    
        for(var i = 0; i < product.right_posts_model.length; ++i) {
            var obj = product.right_posts_model[i]
            if(product.front_post_width > 0.1 && product.front_post_width < 0.16) {
                obj.material = wMaterial 
            } else obj.material = mMaterial
        } 
        for(var i = 0; i < product.left_posts_model.length; ++i) {
            var obj = product.left_posts_model[i]
            if(product.front_post_width > 0.1 && product.front_post_width < 0.16) {
                obj.material = wMaterial 
            } else obj.material = mMaterial
        } 
        for(var i = 0; i < product.decking_posts_model.length; ++i) {
            var obj = product.decking_posts_model[i]
            if(product.decking_post_width > 0.1 && product.decking_post_width < 0.16) {
                obj.material = wMaterial 
            } else obj.material = mMaterial
        } 
    },
    validate_flyover_size(product) {
        const sizeButton = document.getElementById("sizeButton");

        /*if(product.length > 14.9) {
            if(product.projection > 9.9) {
                gui_module.projection_gui.setValue(9);
            } else if(product.projection < 5) {
                gui_module.projection_gui.setValue(5);
            }
        } else if(product.length > 12.9) {
            if(product.projection > 9.9) {
                gui_module.projection_gui.setValue(9.9);
            } else if(product.projection < 4) {
                gui_module.projection_gui.setValue(4);
            }
        } else if(product.length > 10.9) {
            if(product.projection > 9.9) {
                gui_module.projection_gui.setValue(9.9);
            } 
        } else if(product.length > 9.9) {
            if(product.projection > 8.9) {
                gui_module.projection_gui.setValue(8.9);
            } 
        } else if(product.length > 6.9) {
            if(product.projection > 6.9) {
                gui_module.projection_gui.setValue(6.9);
            } 
        }
        if(product.projection > Math.ceil(product.length + 0.1)) {
            gui_module.projection_gui.setValue(Math.ceil(product.length + 0.1) - 0.1);
        } */

        if(sizeButton) {
            if(product.step_length == 0) {
                if(sizeButton) sizeButton.innerHTML = "Size: " + product.length.toFixed(1) + "X" + 
                                                product.projection.toFixed(1) + ": " + 
                                                (product.length * product.projection).toFixed(2);
            } else {
                if(sizeButton) sizeButton.innerHTML = "Size: " + (product.length - product.step_length).toFixed(1) + "X" + 
                                                product.projection.toFixed(1) + "," + 
                                                (product.step_length).toFixed(1) + "X" + 
                                                product.step_size.toFixed(1) + ": " + 
                                                ((product.length - product.step_length) * product.projection +
                                                (product.step_length) * product.step_size).toFixed(2);
            }
        }
    },
    set_fan(product, flag) {
        if(product.fans_model.length > 0) {
            product.fans_model[0].visible = flag
        } else {
            loader_module.add_fan(product, 1, function() {
                flyover_module.set_fan_color(product, product.color)
                product.fans_model[0].visible = flag    
                flyover_module.setup(product)
            })
        }
        
    },
    set_fan_color(product, color) {
        if(product.fans_model.length > 0) {
            product.fans_model[0].traverse( function (child ) {
                if (child.isMesh) child.material = getMaterial(color, 0); 
            } );  
        }
    },
    setup_fans(product) {
        if(product.fans_model.length > 0) {            
            product.fans_model[0].position.copy(product.position)
            product.fans_model[0].rotation.set(0, 0, 0)
            product.fans_model[0].rotation.y = (product.rotate + 90) * Math.PI / 180
            product.fans_model[0].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
        
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 
            
            if(product.type > 12 && product.type < 17) {
                if(product.length < product.back_length) {
                    v1.multiplyScalar(product.length / 2)
                } else {
                    v1.multiplyScalar(product.back_length / 2)
                }                
            } else {
                v1.multiplyScalar(product.length / 2)
            }
            
            
            var factor = (product.projection - product.front_overhang * product.projection)
            if(product.step_length > 0 && product.step_size < product.projection) {
                factor = (product.step_size - product.front_overhang * product.projection)
            }
            var scalar = factor * product.cos_angle / 2;
            var offsetY = factor * product.sin_angle / 2;
                
            v2.multiplyScalar(scalar)
            v1.add(v2)

            product.fans_model[0].position.add(v1)                

            product.fans_model[0].position.z += 0 
            if(product.type > 12 && product.type < 17) {
                product.fans_model[0].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2 + offsetY
            } else if(product.type > 3) {
                product.fans_model[0].position.y = product.horizon + product.height + product.projection * Math.tan(Math.PI / 8) / 2 + offsetY - 0.1
            } else  {
                product.fans_model[0].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2 + offsetY
            }           
        } 
    },
    setup_downlights: function(product) {
        for(var i = 0; i < product.downlights_model.length; ++i) {
            if(i < product.number) product.downlights_model[i].visible = true
            else product.downlights_model[i].visible = false
        }
        if(product.number == 0) return

        var yAngle = (product.rotate + 90) * Math.PI / 180
        var zAngle = (product.angle) * Math.PI / 180

        var downlights_x_pitch,  downlights_y_pitch
        downlights_x_pitch = product.length / (Math.floor((product.number + 1) / 2) + 1)
        var downlights_x2_pitch = downlights_x_pitch
        if(product.type == 13 || product.type == 14) downlights_x2_pitch = product.back_length / (Math.floor((product.number + 1) / 2) + 1)

        downlights_y_pitch = (1 - product.front_overhang - product.back_overhang) * product.projection / 3
        if(product.step_length > 0) {
            if(product.step_size < product.projection) {
                downlights_y_pitch = product.step_size / 3 - (product.front_overhang + product.step_overhang) * product.projection / 3 
            } else {                 
            }
            
        }
        
        var offset = -0.1
        for(var i = 0; i < product.downlights_model.length; ++i) {
            product.downlights_model[i].rotation.set(0, 0, 0)
            product.downlights_model[i].rotation.y = yAngle
            product.downlights_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, zAngle) 
        
            product.downlights_model[i].position.copy(product.position)
            
            
            v1.copy(product.left_2_right_unit_vector) 
            var scalar = (Math.floor(i / 2) + 1) * downlights_x_pitch
            if(i % 2 == 1) scalar = (Math.floor(i / 2) + 1) * downlights_x2_pitch
            v1.multiplyScalar(scalar)
            
            v2.copy(product.front_2_end_unit_vector) 
            
            var factor = downlights_y_pitch + (product.front_overhang) * product.projection
            if(i % 2 == 1) {
                factor = 5 * downlights_y_pitch + (product.front_overhang) * product.projection
            }
            var scalar =  factor * product.cos_angle / 2;
            var offsetY = factor * product.sin_angle / 2;
            
            v2.multiplyScalar(scalar)
            v2.add(v1)

            product.downlights_model[i].position.add(v2)                

            var y = product.height + beam_height * product.roofing_type - 0.05 + offsetY 
            product.downlights_model[i].position.y =  product.horizon + y
        }
    },
    set_downlights_number: function(product, number) {
        product.number = number
        if(product.downlights_model.length > 0) {
            flyover_module.setup(product)            
        } else {
            loader_module.add_downlights(product, 10, function() {                   
                flyover_module.setup(product)
            })
        }
    },
    calculate_decking_depth: function(product) {
        var w = product.projection
        if(product.type == 3) {
            if(product.step_length > 0) {
                if(product.step_size > product.projection) {
                    w = product.step_size
                }
            }
        } 
        if(product.type == 28) w = product.length
        return w
    },
    setup_decking_landscape: function(product) {
        var rotate
        var cnt = 0

        if(product.type == 28 || product.type == 29) {
            var x = product.length / 2
            v1.copy(product.front_2_end_unit_vector) 
            v1.multiplyScalar(x)

            product.horizon = product.height
            rotate = (product.rotate) * Math.PI / 180

            for (var i = 0; i < product.landscaping_model.length; ++i) {
                var obj = product.landscaping_model[i]            
                
                var offset = 0.6
                if(product.type == 29) offset = 0
                if (i * 0.1 < product.projection + offset + 0.2) {               
                    obj.visible = true;
    
                    obj.scale.set(product.length, 0.08, 0.08)
                    obj.rotation.y = rotate
                    obj.position.copy(product.position)            
                    
                    var z = 0.1 * i - offset
                    
                    v2.copy(product.left_2_right_unit_vector)            
                    
                    v2.multiplyScalar(z)
                    v2.add(v1)
    
                    obj.position.x += v2.x
                    obj.position.y = product.horizon + 0.02
                    obj.position.z += v2.z
    
                    obj.material = loader_module.get_decking_material();
                } else if(product.type == 29) {                    
                    if(cnt < (product.step_size - 3) * 4) {
                        obj.visible = true
                        obj.position.copy(product.position)
                        obj.rotation.y = rotate
                        if(cnt % 2 == 1) {
                            obj.scale.set(product.length, 0.1, 0.5)                                       
            
                            var z = product.projection + 0.2
                            v2.copy(product.left_2_right_unit_vector)            
                            
                            v2.multiplyScalar(z + 0.2 * cnt)
                            v2.add(v1)
        
                            obj.position.x += v2.x 
                            obj.position.y = product.horizon - 0.1 * cnt - 0.05
                            obj.position.z += v2.z 
                        } else {
                            obj.scale.set(product.length, 0.2, 0.1)                                                                
            
                            var z = product.projection + 0.2
                            v2.copy(product.left_2_right_unit_vector)            
                            
                            v2.multiplyScalar(z + 0.2 * cnt)
                            v2.add(v1)
        
                            obj.position.x += v2.x 
                            obj.position.y = product.horizon - 0.1 * cnt - 0.05
                            obj.position.z += v2.z 
                        }
                                
                        obj.material = loader_module.get_decking_material();
                        cnt = cnt + 1       
                    } else {
                        obj.visible = false
                    }
                              
                } else {
                    if(cnt < 5) {                                    
                        obj.visible = true;

                        obj.scale.set(product.length, 0.08, 0.08)
                        obj.rotation.y = rotate
                        obj.position.copy(product.position)            
        
                        var z = product.projection + 0.2
                        v2.copy(product.left_2_right_unit_vector)            
                        
                        v2.multiplyScalar(z)
                        v2.add(v1)

                        obj.position.x += v2.x 
                        obj.position.y = product.horizon - 0.1 * cnt
                        obj.position.z += v2.z 
        
                        obj.material = loader_module.get_decking_material();
                        cnt = cnt + 1                   
                                    
                    } else obj.visible = false
                } 
            }

        } else {
            var x = product.length / 2
            v1.copy(product.left_2_right_unit_vector) 
            v1.multiplyScalar(x)
    
            rotate = (product.rotate + 90) * Math.PI / 180
    
            for (var i = 0; i < product.landscaping_model.length; ++i) {
                var obj = product.landscaping_model[i]            
                
                if (i * 0.1 < flyover_module.calculate_decking_depth(product) + 0.8) {               
                    obj.visible = true;
    
                    obj.scale.set(product.length, 0.08, 0.08)
                    obj.rotation.y = rotate
                    obj.position.copy(product.position)            
                    
                    var z = 0.1 * i 
                    
                    v2.copy(product.front_2_end_unit_vector)            
                    
                    v2.multiplyScalar(z)
                    v2.add(v1)
    
                    obj.position.x += v2.x
                    obj.position.y = product.horizon + 0.02
                    obj.position.z += v2.z
    
                    obj.material = loader_module.get_decking_material();
                } else if(cnt < 5) {                
                    if(product.landscaping_height > 0.3 && product.landscaping_height < 0.5 ) {
                        ++cnt
                        obj.visible = true;
    
                        obj.scale.set(product.length, 0.08, 0.08)
                        obj.rotation.y = rotate
                        obj.position.copy(product.position)            
        
                        obj.position.x += v1.x
                        obj.position.y = product.horizon - 0.1 * cnt
                        obj.position.z += v1.z
        
                        obj.material = loader_module.get_decking_material();
                    } else {                    
                        if(cnt == 0) {
                            obj.scale.set(product.length, 0.4, 0.2)
                            obj.rotation.y = rotate
                            obj.position.copy(product.position)            
               
                            obj.position.x += v1.x
                            obj.position.y = product.horizon - 0.2
                            obj.position.z += v1.z
            
                            obj.material = loader_module.get_decking_material();
                            obj.visible = true;
                        } else if(cnt == 1) {
                            if(product.type == 28 || product.type == 29) {
                                obj.visible = false
                            } else {
                                obj.scale.set(product.length, 0.2, product.projection)
                                obj.rotation.y = (product.rotate + 90) * Math.PI / 180
                                obj.position.copy(product.position)            
                
                                v2.copy(product.front_2_end_unit_vector)                       
                                v2.multiplyScalar(product.projection / 2 + .1)
                                v2.add(v1)
            
                                obj.position.x += v2.x
                                obj.position.y = product.horizon - 0.1 
                                obj.position.z += v2.z
                
                                obj.material = getMaterial(0xffffff, 0)
                                obj.visible = true;
                            }
                           
                        } else {
                            obj.visible = false;
                        }
                        cnt = cnt + 1                   
                    }                
                } else obj.visible = false
            }
        }
        
    },
    setup_pavement_landscape: function(product) {
        var length = product.length
        if(product.type == 28) length = product.projection
        var depth = flyover_module.calculate_decking_depth(product)
        var w = Math.ceil(depth) + 1;
        var l = Math.ceil(length) + 1;
        var blocks = w * l
        var xOffset = (l - length) / 2
        
        for (var i = 0; i < product.landscaping_model.length; ++i) {
            for (var i = 0; i < product.landscaping_model.length; ++i) {
                var obj = product.landscaping_model[i]                
                if (i < blocks) {  
                    obj.scale.set(1, 0.2, 1);
                    obj.rotation.y = (product.rotate + 90) * Math.PI / 180
                    obj.position.copy(product.position)

                    var x = Math.floor(i / w) + product.landscaping_x_offset - xOffset
                    var z = +1 * depth - Math.floor(i % w) - product.landscaping_z_offset

                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector) 
                    
                    v1.multiplyScalar(x)
                    v2.multiplyScalar(z)
                    v2.add(v1)

                    obj.position.x += v2.x
                    obj.position.y = -0.08
                    obj.position.z += v2.z
                    obj.visible = true;

                    if(product.landscaping_type == "concrete") {
                        obj.material = loader_module.get_concrete_material();
                    } else if (product.landscaping_type == "stone") {
                        obj.material = loader_module.get_stone_material();
                    } else if (product.landscaping_type == "tile") {
                        obj.material = loader_module.get_tile_material();
                    } else if (product.landscaping_type == "pavement") {
                        obj.material = loader_module.get_pavement_material();
                    } else if (product.landscaping_type == "custom") {
                        if(product.landscaping_custom_material == null) {
                            obj.material = loader_module.get_custom_material()
                        } else {
                            obj.material = product.landscaping_custom_material
                        }                        
                    } 
                } else obj.visible = false;
                
            }
        }
    },
    setup_landscaping: function(product) {
        if(product.landscaping_visible) {
            if(product.landscaping_type == "decking") {
                flyover_module.setup_decking_landscape(product);
            } else {
                flyover_module.setup_pavement_landscape(product);
            }
        } else {
            for (var i = 0; i < product.landscaping_model.length; ++i) {
                product.landscaping_model[i].visible = false                
            }
        }       
        
    },
    set_landscaping_visible: function(product, value) {
        product.landscaping_visible = value
        flyover_module.check_landscaping_horizon(product) 
        flyover_module.setup(product)
    }, 
    set_landscaping_height: function(product, value) {
        product.landscaping_height = value
        flyover_module.check_landscaping_horizon(product)         
        flyover_module.setup(product)
    }, 
    set_landscaping_type: function(product, value) {
        product.landscaping_type = value
        flyover_module.check_landscaping_horizon(product)    
        flyover_module.setup(product)
    }, 
    check_landscaping_horizon: function(product) {
        //product.horizon = product.landscaping_height  
    },
    set_landscaping_x_offset: function(product, value) {
        product.landscaping_x_offset = value
        flyover_module.setup(product)
    }, 
    set_landscaping_z_offset: function(product, value) {
        product.landscaping_z_offset = value
        flyover_module.setup(product)
    }, 
    set_landscaping_custom_material: function(product, material) {
        product.landscaping_custom_material = material
        flyover_module.setup(product)
    },
    show_gallery: function(index) {
        var file = '../product/files/' + index + '.jpg';
        loadImage(file, function(img) {
          sceneItems.scene.background = img;
        });
    },
    setup_walls(product) {
        for(var i = 0; i < product.walls_model.length; ++i) {
            var obj = product.walls_model[i]  
            obj.visible = true
            obj.scale.set(5, product.height, 0.1)
            obj.position.set(i * 5 + product.position.x, product.height / 2, product.position.z)
        }
    },
    periodic_event(product) {
        if(product.fans_model.length > 0) {
            product.fan_speed += 0.5;
            product.fans_model[0].rotation.set(0, 0, 0)
            product.fans_model[0].rotation.y = (product.rotate + 90) * Math.PI / 180 + product.fan_speed
            product.fans_model[0].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
        }
    }
}

export { flyover_module, beam_height }