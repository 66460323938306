import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

class SmartToolbar extends React.Component {

  constructor(props) {
    super(props);
    this.state =  { 
      menu: 0,
      productType: 'insulated_flyover',
    }   
  }
  
  selectSubMenu = (value) => {
    this.setState({
      menu: value
    })
    var create = 'none'
    var size = 'none'
    var sun_light = 'none'
    var output = 'none'
    var threeD = 'none'
    var colour = 'none'
    var basic_setup = 'none'
    var advanced_setup = 'none'
    var accessory_setup = 'none'
    var wing_setup = 'none'
    var landscaping_setup = 'none'
    var post_setup = 'none'
    var scene_setup = 'none'
    var price_toolbar_setup = 'none'
    var projection_2d_setup = 'none'
    var quote_submenu_setup = 'none'

    if(value != 13 && value != 14) {
      this.props.callSelectMenuFromParent(2)
    }
    switch(value) {
      case 0:
          create = 'block'
        break
      case 1:
          size = 'block'
        break
      case 2:
          sun_light = 'block'
        break
      case 3:
          output = 'block'
        break
      case 4:
          threeD = 'block'
        break
      case 5:
          colour = 'block'
        break
      case 6:
          basic_setup = 'block'
        break
      case 7:
          advanced_setup = 'block'
        break
      case 8:
          accessory_setup = 'block'
        break
      case 9:
          wing_setup = 'block'
          break
      case 10:
          landscaping_setup = 'block'
          break
      case 11:
          post_setup = 'block'
          break
      case 12:
          scene_setup = 'block'
        break
      case 13:
          price_toolbar_setup = 'block'
          this.props.callSelectMenuFromParent(0)
        break
      case 14:
          this.props.callSelectMenuFromParent(1)
        break
      case 15:
          this.props.callSelectMenuFromParent(3)
        break
      case 16:
          quote_submenu_setup = 'block'
          this.props.callSelectMenuFromParent(6)
        break
      case 17:
          projection_2d_setup = 'block'
          this.props.callSelectMenuFromParent(5)
        break
      default:
        break
    }

    document.getElementById('create').style.display = create
    document.getElementById('size').style.display = size
    document.getElementById('sun_light').style.display = sun_light
    document.getElementById('output').style.display = output
    document.getElementById('3d').style.display = threeD
    document.getElementById('colour').style.display = colour
    document.getElementById('basic_setup').style.display = basic_setup
    document.getElementById('advanced_setup').style.display = advanced_setup
    document.getElementById('accessory_setup').style.display = accessory_setup
    document.getElementById('wing_setup').style.display = wing_setup
    document.getElementById('landscaping_setup').style.display = landscaping_setup
    document.getElementById('post_setup').style.display = post_setup
    document.getElementById('scene_setup').style.display = scene_setup
    document.getElementById('price_toolbar_setup').style.display = price_toolbar_setup
    document.getElementById('projection_2d_setup').style.display = projection_2d_setup
    document.getElementById('quote_submenu_setup').style.display = quote_submenu_setup
  }

  arrangeMainPage(value) {
    this.props.callSelectMenuFromParent(value, 0.5)
    if(value == 0) {
      this.setState({menu: 13})
      this.selectSubMenu(13)
    }
    if(value == 1) {
      this.setState({menu: 14})
    }
  }

  findIconClass(value) {
    switch(value) {
      case 0:
        if(this.state.menu == 0) return "bi bi-house-add-fill mx-1 my-1 text-warning"
        else return "bi bi-house-add-fill mx-1 my-1 text-success"
      break
      case 1:
        if(this.state.menu == 1) return "bi bi-aspect-ratio mx-1 my-1 text-warning"
        else return "bi bi-aspect-ratio mx-1 my-1 text-success"
        break
      case 2:
        if(this.state.menu == 2) return "bi bi-lightbulb mx-1 my-1 text-warning"
        else return "bi bi-lightbulb mx-1 my-1 text-success"
        break
      case 3:
        if(this.state.menu == 3) return "bi bi-image mx-1 my-1 text-warning"
        else return "bi bi-image mx-1 my-1 text-success"
        break
      case 4:
        if(this.state.menu == 4) return "bi bi-badge-3d mx-1 my-1 text-warning"
        else return "bi bi-badge-3d mx-1 my-1 text-success"
        break
      case 5:
        if(this.state.menu == 5) return "bi bi-palette mx-1 my-1 text-warning"
        else return "bi bi-palette mx-1 my-1 text-success"
        break
      case 6:
        if(this.state.menu == 6) return "bi bi-arrow-clockwise mx-1 my-1 text-warning"
        else return "bi bi-arrow-clockwise mx-1 my-1 text-success"
        break
      case 7:
        if(this.state.menu == 7) return "bi bi-arrows-move mx-1 my-1 text-warning"
        else return "bi bi-arrows-move mx-1 my-1 text-success"
        break
      case 8:
        if(this.state.menu == 8) return "bi bi-door-open-fill mx-1 my-1 text-warning"
        else return "bi bi-door-open-fill mx-1 my-1 text-success"
        break
      case 9:
        if(this.state.menu == 9) return "bi bi-building-gear mx-1 my-1 text-warning"
        else return "bi bi-building-gear mx-1 my-1 text-success"
        break
      case 10:
        if(this.state.menu == 10) return "bi bi-gear-wide mx-1 my-1 text-warning"
        else return "bi bi-gear-wide mx-1 my-1 text-success"
        break
      case 11:
        if(this.state.menu == 11) return "bi bi-align-top mx-1 my-1 text-warning"
        else return "bi bi-align-top mx-1 my-1 text-success"
        break
      case 12:
        if(this.state.menu == 12) return "bi bi-arrows-expand mx-1 my-1 text-warning"
        else return "bi bi-arrows-expand mx-1 my-1 text-success"
        break
      case 13:
        if(this.state.menu == 13) return "bi bi bi-currency-dollar mx-1 my-1 text-warning"
        else return "bi bi bi-currency-dollar mx-1 my-1 text-success"
        break
      case 14:
        if(this.state.menu == 14) return "bi bi-arrow-right-circle mx-1 my-1 text-warning"
        else return "bi bi-arrow-right-circle mx-1 my-1 text-success"
      case 15:
        if(this.state.menu == 15) return "bi bi-card-checklist mx-1 my-1 text-warning"
        else return "bi bi-card-checklist mx-1 my-1 text-success"
        break
      case 16:
        if(this.state.menu == 16) return "bi bi-layers mx-1 my-1 text-warning"
        else return "bi bi-layers mx-1 my-1 text-success"
        break
      case 17:
        if(this.state.menu == 17) return "bi bi-chat-left-quote mx-1 my-1 text-warning"
        else return "bi bi-chat-left-quote mx-1 my-1 text-success"
        break
    }
  }

  componentDidMount() {
    this.selectSubMenu(0)
  }

  render() {
    return (
      <div style={{ backgroundColor: '#e1e1e1' }}>
        <div id='menu'>
          <i className={this.findIconClass(0)} title="Create a Product" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(0)}></i>
          <i className={this.findIconClass(1)} title="Size Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(1)}></i>
          <i className={this.findIconClass(6)} title="Rotation/Angle/Altitude Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(6)}></i>
          <i className={this.findIconClass(7)} title="Overhang/Profile Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(7)}></i>
          <i className={this.findIconClass(10)} title="Lock/Fan/Landscaping Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(10)}></i>
          <i className={this.findIconClass(8)} title="Accessory Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(8)}></i>
          <i className={this.findIconClass(9)} title="Wing Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(9)}></i>
          <i className={this.findIconClass(11)} title="Posts Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(11)}></i>
          <i className={this.findIconClass(3)} title="Image Import/Snapshot" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(3)}></i>
          <i className={this.findIconClass(4)} title="Manage 3D Objects" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(4)}></i>
          <i className={this.findIconClass(5)} title="Colour Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(5)}></i>
          <i className={this.findIconClass(2)} title="Light Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(2)}></i>
          <i className={this.findIconClass(12)} title="Scene Setup" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(12)}></i>
          { false && <i className={this.findIconClass(13)} title="Price Page" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(13)}></i> }
          <i className={this.findIconClass(15)} title="Engineering Menu" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(15)}></i>
          <i className={this.findIconClass(16)} title="2D Menu" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(16)}></i>
          <i className={this.findIconClass(14)} title="Hide Menu" style={{ fontSize: 32 }} onClick={() => this.selectSubMenu(14)}></i>
        </div>
      </div>
     );  
  }
}

export default SmartToolbar;
