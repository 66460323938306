import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';
import { building_module } from '../smartkits/building_module';

const profile = [
    { value: 'trimDeck', label: 'TrimDeck' },
    { value: 'flatDeck', label: 'FlatDeck' },
    { value: 'planeDeck', label: 'PlaneDeck' },
    { value: 'customOrb', label: 'CustomOrb' },
    { value: 'customOrb_Ins', label: 'CustomOrb_Ins' },
    { value: 'spanDeck', label: 'SpanDeck' },
  ];

  const gableType = [
    { value: 'trimDeck', label: 'Close' },
    { value: 'halfClose', label: 'Half Close' },
    { value: 'open', label: 'Open' },
  ];

class SmartAdvancedSetupSubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      flyoverFrontOverhang: 0,
      frontOverhang: 0,
      flyoverBackOverhang: 0,
      backOverhang: 0,
      flyoverStepOverhang: 0,
      profile: profile[0].value,
      gableType: gableType[0].value
    }   
  }
  setFlyoverFrontOverhang = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      flyoverFrontOverhang: v
    })
    var type = this.getProductType()
    if(type < 4) {
      gui_module.front_overhang_gui.setValue(v)
    } 
  }

  setFlyoverBackOverhang = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      flyoverBackOverhang: v
    })
    var type = this.getProductType()
    if(type < 4) {
      gui_module.back_overhang_gui.setValue(v)
    } 
  }

  setFlyoverStepOverhang = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      flyoverStepOverhang: v
    })
    var type = this.getProductType()
    if(type < 4) {
      gui_module.step_overhang_gui.setValue(v)
    } 
  }

  setFrontOverhang = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      frontOverhang: v
    })
    var type = this.getProductType()
    if(type == 32 || type == 33) {
      building_module.overhang_gui.setValue(v)
    } else if(type > 63) {
      shed_module.overhang_gui.setValue(v)
    } 
  }

  setBackOverhang = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      backOverhang: v
    })
    var type = this.getProductType()
    if(type > 63) {
      shed_module.side_overhang_gui.setValue(v)
    } 
  }

  setProfile  = ({ target }) => {
    var v = target.value
    var i = 0
    this.setState({
        profile: v
    });
    switch(v) {
        case 'trimDeck': 
            i = 0
        break
        case 'flatDeck': 
            i = 1
        break
        case 'planeDeck': 
            i = 2
        break
        case 'customOrb': 
            i = 10
        break    
        case 'customOrb_Ins': 
            i = 11
        break
        case 'spanDeck': 
            i = 12
        break      

    }
    gui_module.profile = i
    product_manager_module.profile(gui_module.index - 1, gui_module.profile);
  }
  
  seGableType  = ({ target }) => {
    var v = target.value
    var i = 0
    this.setState({
        gableType: v
    });
    switch(v) {
        case 'close': 
            i = 0
        break
        case 'halfClose': 
            i = 1
        break
        case 'open': 
            i = 2
        break     

    }
    gui_module.gable_type = i
    product_manager_module.gable_type(gui_module.index - 1, gui_module.gable_type);
  }
  

  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type
    var f = product?.front_overhang
    var b = product?.back_overhang
    var ff = product?.front_overhang
    var bf = product?.back_overhang
    var sf = product?.step_overhang
    if(type < 4) {
        ff = ff * 100
        bf = bf * 100
        sf = sf * 100
    } else if(type == 32 || type == 33) {
        f = product.overhang
    } 
    this.setState({
      frontOverhang: f,
      backOverhang: b,
      flyoverFrontOverhang: ff,
      flyoverBackOverhang: bf,
      flyoverStepOverhang: sf,
    })

    if(type < 4) {
      document.getElementById('flyover_overhang').style.display = 'block'
      document.getElementById('shed_overhang').style.display = 'none'
      document.getElementById('profile').style.display = 'block'
      document.getElementById('gable_type').style.display = 'none'
    } else if(type < 7) {
      document.getElementById('flyover_overhang').style.display = 'none'
      document.getElementById('shed_overhang').style.display = 'none'
      document.getElementById('profile').style.display = 'block'
      document.getElementById('gable_type').style.display = 'none'
    } else if(type < 10) {
        document.getElementById('flyover_overhang').style.display = 'none'
        document.getElementById('shed_overhang').style.display = 'none'
        document.getElementById('profile').style.display = 'block'
        document.getElementById('gable_type').style.display = 'block'
    } else if(type < 32) {
        document.getElementById('flyover_overhang').style.display = 'none'
        document.getElementById('shed_overhang').style.display = 'none'
        document.getElementById('profile').style.display = 'block'
        document.getElementById('gable_type').style.display = 'none'
      } else if(type == 32 || type == 33) {
      document.getElementById('flyover_overhang').style.display = 'none'
      document.getElementById('shed_overhang').style.display = 'block'
      document.getElementById('profile').style.display = 'none'
      document.getElementById('gable_type').style.display = 'none'
    } else {
      document.getElementById('flyover_overhang').style.display = 'none'
      document.getElementById('shed_overhang').style.display = 'block'
      document.getElementById('profile').style.display = 'none'
      document.getElementById('gable_type').style.display = 'none'
    }
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='advanced_setup'>
        <div id='flyover_overhang'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Front Overhang</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='50'
            step='1'
            value={this.state.flyoverFrontOverhang}
            onChange={this.setFlyoverFrontOverhang}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='50'
            step='1'
            value={this.state.flyoverFrontOverhang}
            onChange={this.setFlyoverFrontOverhang}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Back Overhang</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='50'
            step='1'
            value={this.state.flyoverBackOverhang}
            onChange={this.setFlyoverBackOverhang}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='50'
            step='1'
            value={this.state.flyoverBackOverhang}
            onChange={this.setFlyoverBackOverhang}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Step Overhang</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='50'
            step='1'
            value={this.state.flyoverStepOverhang}
            onChange={this.setFlyoverStepOverhang}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='50'
            step='1'
            value={this.state.flyoverStepOverhang}
            onChange={this.setFlyoverStepOverhang}
        />
        </div>
        <div id='shed_overhang'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Front Overhang</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='2'
            step='0.1'
            value={this.state.frontOverhang}
            onChange={this.setFrontOverhang}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='2'
            step='0.1'
            value={this.state.frontOverhang}
            onChange={this.setFrontOverhang}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Side Overhang</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='2'
            step='0.1'
            value={this.state.backOverhang}
            onChange={this.setBackOverhang}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='2'
            step='0.1'
            value={this.state.backOverhang}
            onChange={this.setBackOverhang}
        />
        </div>
        <div id='profile'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Roofing Profile</button>
        <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.profile} 
            onChange={this.setProfile}
          >
          {profile.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        </div>
        <div id='gable_type'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Gable Type</button>
        <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.gableType} 
            onChange={this.seGableType}
          >
          {gableType.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        </div>
       </div>
      </div>
     );  
  }
}

export default SmartAdvancedSetupSubmenu;
