import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';

const accessoryTypes = [
    { value: 'roller_door', label: 'Roller Door' },
    { value: 'farm_door', label: 'Farm Door' },
    { value: 'window', label: 'Window' },
    { value: 'PA_door', label: 'PA Door' },
    { value: 'glass_sliding_door', label: 'Glass Sliding Door' },
    { value: 'skylight', label: 'Skylight' },
    { value: 'upper_window', label: 'Upper Window' },
  ];

class SmartWingSubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      wingPosition: 1,
      wingSize: 3,
      storey2Height: 0,
    }   
  }

  setWingPosition = (event) => {
    var v = event.target.valueAsNumber
    var type = this.getProductType()
    if(type > 63) {
      shed_module.step_length_gui.setValue(v)
    } 
    this.setState({
        wingPosition: shed_module.wing_position
    })
  }

  setWingSize = (event) => {
    var v = event.target.valueAsNumber
    var type = this.getProductType()
    if(type > 63) {
      shed_module.step_size_gui.setValue(v)
    } 
    this.setState({
        wingSize: shed_module.wing_size
    })
  }

  setStorey2Height= (event) => {
    var v = event.target.valueAsNumber
    var type = this.getProductType()
    if(type > 63) {
      shed_module.storey_2nd_gui.setValue(v)
    } 
    this.setState({
        storey2Height: shed_module.storey_2nd
    })
  }

  loadCustomWall = (event) => {
    var type = this.getProductType()
    if(type == 32 || type == 33) {
        document.getElementById('loadBuildingImageInput1').click()
    } else if(type == 100) {
        document.getElementById('loadBuildingImageInput2').click()
    }    
  }

  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type
    this.setState({
      wingPosition: product?.step_length,
      wingSize: product?.step_seze,
      storey2Height: product?.back_length
    })
    if(type == 100) {
      document.getElementById('wing_setup_sub').style.display = 'block'
    } else {
      document.getElementById('wing_setup_sub').style.display = 'none'
    }
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div id='wing_setup'>
       <div id='wing_setup_sub'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Wing Position </button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='40'
            step='0.25'
            value={this.state.wingPosition}
            onChange={this.setWingPosition}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='40'
            step='0.25'
            value={this.state.wingPosition}
            onChange={this.setWingPosition}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Wing Size</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='20'
            step='0.25'
            value={this.state.wingSize}
            onChange={this.setWingSize}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='20'
            step='0.25'
            value={this.state.wingSize}
            onChange={this.setWingSize}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">2nd Storey Height</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='6'
            step='0.25'
            value={this.state.storey2Height}
            onChange={this.setStorey2Height}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='6'
            step='0.25'
            value={this.state.storey2Height}
            onChange={this.setStorey2Height}
        />
        <button  className="btn btn-primary col-md-12 mx-1 my-1" onClick={this.loadCustomWall}>Load Custom Wall</button>
        </div>
      </div>
     );  
  }
}

export default SmartWingSubmenu;
