import React, { userState } from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { project_specification } from '../smartkits/gui_smartkits'

const models = [
  { value: 'flyover', label: 'Flyover' },
  { value: 'shed', label: 'Shed'},
  { value: 'patio', label: 'Patio'},
  { value: 'hip', label: 'Hip' },
  { value: 'gable', label: 'Gable' },
  { value: 'dutch', label: 'Dutch' },
  { value: 'gazebo', label: 'Gazebo'},
  { value: 'decking', label: 'Decking'},
  { value: 'angled', label: 'Angled'},
  { value: 'caravan_boatport', label: 'Caravan/Boat Port'},
  { value: 'building', label: 'Building'}

];

export const flyoverTypes = [
  { value: 'insulated_flyover', label: 'Insulated' },
  { value: 'non_insulated_flyover', label: 'Non Insulated' },
];

export const shedTypes = [
  { value: 'single_garage', label: '1 Roller Door' },
  { value: 'double_garage', label: '2 Roller Door' },
  { value: 'triple_garage', label: '3 Roller Door' },
  { value: 'skillion_garage', label: 'Skillion' },
  { value: 'hip_garage', label: 'Hip' },
  { value: 'dutch_garage', label: 'Dutch' },
  { value: 'gara_port', label: 'Gara Port' },
];

export const patioTypes = [
  { value: 'insulated_patio_attached', label: 'Insulated Attached' },
  { value: 'non_insulated_patio_attached', label: 'Non-Insulated Attached' },
  { value: 'insulated_patio_freestanding', label: 'Insulated Freestanding' },
  { value: 'non_insulated_patio_freestanding', label: 'Non-insulated Freestanding' },
  { value: 'patio_carport', label: 'Patio Carport' },
];

export const hipTypes = [
  { value: 'hip', label: 'Hip Freestanding' },
  { value: 'hip_attached', label: 'Hip Attached' },
  { value: 'hip_carport', label: 'Hip Carport' },
];

export const gableTypes = [
  { value: 'insulated_gable', label: 'Insulated Freestanding' },
  { value: 'non_insulated_gable', label: 'Non-insulated Freestanding' },
  { value: 'insulated_gable_attached', label: 'Insulated Attached' },
  { value: 'non_insulated_gable_attached', label: 'Non-Insulated Attached' },
  { value: 'gable_carport', label: 'Gable Carport' },
];

export const dutchTypes = [
  { value: 'dutch', label: 'Dutch Freestanding' },
  { value: 'dutch_attached', label: 'Dutch Attached' },
  { value: 'dutch_carport', label: 'Dutch Carport' },
];

export const gazeboTypes = [
  { value: 'gazebo_6_sided', label: 'Gazebo 6 Sided' },
  { value: 'gazebo_8_sided', label: 'Gazebo 8 Sided' },
];

export const deckingTypes = [
  { value: 'ground_level_decking', label: 'Ground Level Decking' },
  { value: 'high_set_decking', label: 'High Set Decking' },
  { value: 'decking_landing', label: 'Landing' },
];

export const angledTypes = [
  { value: 'angled_freestanding', label: 'Angled Freestanding' },
  { value: 'angled_attached', label: 'Angled Attached' },
];

const buildingTypes = [
  { value: 'building_left', label: 'Left Wing' },
  { value: 'building_right', label: 'Right Wing' },
  { value: 'building', label: 'Configurable' },
];

class SmartCreateSubmenu extends React.Component {

  constructor() {
    super();
    this.state =  { 
      index: 1,
      fileName: this.selectFileName(),
      productModel: models[0].value,
      flyoverType: flyoverTypes[0].value,
      shedType: shedTypes[0].value,
      patioType: patioTypes[0].value,
      gableType: gableTypes[0].value,
      hipType: hipTypes[0].value,
      dutchType: dutchTypes[0].value,
      gazeboType: gazeboTypes[0].value,
      deckingType: deckingTypes[0].value,
      angledType: angledTypes[0].value,
      buildingType: buildingTypes[0].value,
      lock: false
    }   
  }

  selectFileName = () => {
    var name = project_specification.family_name + '_' + project_specification.first_name
    if(name == '_') return 'smart'
    else return name
  }

  add = () => {  
    switch(this.state.productModel) {
      case 'flyover':
        gui_module.product_type = this.state.flyoverType
        break
      case 'shed':
        gui_module.product_type = this.state.shedType
        break
      case 'patio':
        gui_module.product_type = this.state.patioType
        break
      case 'hip':
        gui_module.product_type = this.state.hipType
        break
      case 'gable':
        gui_module.product_type = this.state.gableType
        break
      case 'dutch':
        gui_module.product_type = this.state.dutchType
        break
      case 'gazebo':
        gui_module.product_type = this.state.gazeboType
        gui_module.projection_gui.setValue(5)
        gui_module.length_gui.setValue(5)
        break
      case 'decking':
        gui_module.product_type = this.state.deckingType
        break
      case 'angled':
        gui_module.product_type = this.state.angledType
        break
      case 'building':
        gui_module.product_type = this.state.buildingType
        break
      default:
        gui_module.product_type = this.state.productModel
        break
  }
    gui_module.add()
  }

  remove = () => {
    gui_module.remove()
  }

  load = () => {
    document.getElementById('loadProductInput1').click()
  }

  setFileName = ({ target }) => {
    var v = target.value
    this.setState({
        fileName: v
    });
  }

  save = () => {
    var content  = gui_module.saveProduct.getProductsInfo() 
    const blob = new Blob([content], { type: 'text/plain' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = this.state.fileName + '.sk'
    a.click()
    URL.revokeObjectURL(a.href)
  }

  setIndex = (event) => {
    var v = event.target.valueAsNumber
    gui_module.index_gui.setValue(v)
    this.updateIndex()
  }

  updateIndex = () => {
    this.setState({
      index: gui_module.index
    })
  }

  setLock = (event) => {
    var v = event.target.checked
    this.setState({
      lock: v
    }) 
    gui_module.lock_index = v
  }

  selectProduct = ({ target }) => {
    var v = target.value
    this.setState({
        productModel: v
    });
    this.manageTypeMenus(v)
  }

  manageTypeMenus = (value) => {
    var flyoverTypes = 'none'
    var shedTypes = 'none'
    var patioTypes = 'none'
    var hipTypes = 'none'
    var gableTypes = 'none'
    var dutchTypes = 'none'
    var gazeboTypes = 'none'
    var deckingTypes = 'none'
    var angledTypes = 'none'
    var buildingTypes = 'none'
    switch(value) {
      case 'flyover':
        flyoverTypes = 'block'
        break
      case 'shed':
        shedTypes = 'block'
        break
      case 'patio':
        patioTypes = 'block'
        break
      case 'hip':
        hipTypes = 'block'
        break
      case 'gable':
        gableTypes = 'block'
          break
      case 'dutch':
        dutchTypes = 'block'
        break   
      case 'gazebo':
        gazeboTypes = 'block'
        break     
      case 'decking':
        deckingTypes = 'block'
        break     
      case 'angled':
        angledTypes = 'block'
        break   
      case 'building':
        buildingTypes = 'block'
        break
      default:
        break
    }

    document.getElementById('flyoverTypes').style.display = flyoverTypes
    document.getElementById('shedTypes').style.display = shedTypes
    document.getElementById('patioTypes').style.display = patioTypes
    document.getElementById('hipTypes').style.display = hipTypes
    document.getElementById('gableTypes').style.display = gableTypes
    document.getElementById('dutchTypes').style.display = dutchTypes
    document.getElementById('gazeboTypes').style.display = gazeboTypes
    document.getElementById('deckingTypes').style.display = deckingTypes
    document.getElementById('angledTypes').style.display = angledTypes
    document.getElementById('buildingTypes').style.display = buildingTypes
  }

  selectFlyoverType = ({ target }) => {
    this.setState({
        flyoverType: target.value,
    });
  }

  selectShedType = ({ target }) => {
    this.setState({
        shedType: target.value,
    });
  }

  selectPatioType = ({ target }) => {
    this.setState({
        patioType: target.value,
    });
  }

  selectHipType = ({ target }) => {
    this.setState({
        hipType: target.value,
    });
  }

  selectGableType = ({ target }) => {
    this.setState({
        gableType: target.value,
    });
  }

  selectDutchType = ({ target }) => {
    this.setState({
        dutchType: target.value,
    });
  }

  selectGazeboType = ({ target }) => {
    this.setState({
        gazeboType: target.value,
    });
  }

  selectDeckingType = ({ target }) => {
    this.setState({
        deckingType: target.value,
    });
  }

  selectAngledType = ({ target }) => {
    this.setState({
        angledType: target.value,
    });
  }

  selectBuildingType = ({ target }) => {
    this.setState({
        buildingType: target.value,
    });
  }

  componentDidMount() {
    this.manageTypeMenus(this.state.productModel)
  }

  render() {
    return (
     <div id='create'>
      <button className="btn btn-primary col-md-12 mx-1 mt-1 mb-4" onClick={this.load}>Load Project</button>
        <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.productModel} 
            onChange={this.selectProduct}
          >
          {models.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='flyoverTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.flyoverType} 
            onChange={this.selectFlyoverType}
          >
          {flyoverTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='shedTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.shedType} 
            onChange={this.selectShedType}
          >
          {shedTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='patioTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.patioType} 
            onChange={this.selectPatioType}
          >
          {patioTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='hipTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.hipType} 
            onChange={this.selectHipType}
          >
          {hipTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='gableTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.gableTypesType} 
            onChange={this.selectGableType}
          >
          {gableTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='dutchTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.dutchTypesType} 
            onChange={this.selectDutchType}
          >
          {dutchTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='gazeboTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.gazeboType} 
            onChange={this.selectGazeboType}
          >
          {gazeboTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='deckingTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.deckingType} 
            onChange={this.selectDeckingType}
          >
          {deckingTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='angledTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.angledType} 
            onChange={this.selectAngledType}
          >
          {angledTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  id='buildingTypes' className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.buildingType} 
            onChange={this.selectBuildingType}
          >
          {buildingTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <button className="btn btn-success col-md-5 mt-1 mx-1 mb-4" onClick={this.add}>Add</button>
        <button className="btn btn-warning col-md-6 mt-1 my-1 mb-4" onClick={this.remove}>Remove</button>
        
        <input className="col-md-12 mx-1 my-1"
          type="text"
          value={this.state.fileName}
          onChange={this.setFileName}
          placeholder='smartkits'
        />
        <button className="btn btn-success col-md-12 mx-1 mt-1 mb-4" onClick={this.save}>Save Product</button>
        <button  className="btn btn-secondary col-md9 mx-1 my-1">Lock Index</button>
        <input type="checkbox" checked={this.state.lock} onChange={this.setLock} />
        <button className="btn btn-secondary col-md-5 mx-1 my-1">Index</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='1'
            max='16'
            step='1'
            value={this.state.index}
            onChange={this.setIndex}
        />
        <input type='range' className="col-md-12 mx-1 my-1"
            min='1'
            max='16'
            step='1'
            value={this.state.index}
            onChange={this.setIndex}
        />
      </div>
     );  
  }
}

export default SmartCreateSubmenu;
