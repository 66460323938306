import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';

const postsTypes = [
    { value: 'front_posts', label: 'Front Posts' },
    { value: 'back_posts', label: 'Back Posts' },
    { value: 'right_posts', label: 'Third Posts' },
    { value: 'decking_posts', label: 'Decking Posts' },
    { value: 'brackets', label: 'Bracket' },
    { value: 'rafters', label: 'Purlins' },
    { value: 'purlins', label: 'Rafters' },
  ];

const postsNoTypes = [
    { value: '0', label: 'Automatic' },
    { value: '9', label: 'Zero' },
    { value: '1', label: 'One' },
    { value: '2', label: 'Two' },
    { value: '3', label: 'Three' },
    { value: '4', label: 'Four' },
    { value: '5', label: 'Five' },
    { value: '6', label: 'Six' },
  ];

const postSizes = [
  { value: '0.08', label: 'Metal' },
  { value: '0.16', label: 'Wide Metal' },
  { value: '0.125', label: 'Wood' },
  { value: '0.15', label: 'Wide Wood' },
];

class SmartPostSetupSubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      postsNo: 2,
      position_1: 0,
      position_2: 0,
      position_3: 0,
      position_4: 0,
      position_5: 0,
      position_6: 0,
      postsType: postsTypes[0].value,
      postsNoType: postsNoTypes[0].value,
      postSize: 0.08,
      size_1: 0,
      size_2: 0,
      size_3: 0,
      size_4: 0,
      size_5: 0,
      size_6: 0,
    }   
  }

  setPostsType = ({ target }) => {
    var v = target.value
    var pn = this.findPostsNo(v)
    var index = this.findForcedPostsNo(v)
    if(index == undefined) index = 0
    if(index == 9) {
      index = 1
    } else if(index > 0) {
      index = index + 1
    }
    this.setState({
      postsType: v,
      postsNo: pn,
      postsNoType: postsNoTypes[index].value
    });

    this.setOffsetState(product_manager_module.products[gui_module.index - 1])

    if(v == 'brackets') {
      document.getElementById('brackets_size').style.display = 'block'
    } else {
      document.getElementById('brackets_size').style.display = 'none'
    }
  }

  setPostsNoType = ({ target }) => {
    var v = target.value
    switch(this.state.postsType) {
      case 'front_posts':        
        gui_module.forced_front_posts_no = parseInt(v)
        product_manager_module.forced_front_posts_no(gui_module.index - 1, gui_module.forced_front_posts_no)
        break
      case 'back_posts':        
        gui_module.forced_back_posts_no = parseInt(v)
        product_manager_module.forced_back_posts_no(gui_module.index - 1, gui_module.forced_back_posts_no)
        break
      case 'right_posts':        
        gui_module.forced_right_posts_no = parseInt(v)
        product_manager_module.forced_right_posts_no(gui_module.index - 1, gui_module.forced_right_posts_no)
        break
      case 'decking_posts':        
        gui_module.forced_decking_posts_no = parseInt(v)
        product_manager_module.forced_decking_posts_no(gui_module.index - 1, gui_module.forced_decking_posts_no)
        break
      case 'brackets':        
        gui_module.forced_brackets_no = parseInt(v)
        product_manager_module.forced_brackets_no(gui_module.index - 1, gui_module.forced_brackets_no)
        break
      case 'rafters':
        gui_module.forced_rafters_no = parseInt(v)
        product_manager_module.forced_rafters_no(gui_module.index - 1, gui_module.forced_rafters_no)
        break
      case 'purlins':
        gui_module.forced_purlins_no = parseInt(v)
        product_manager_module.forced_purlins_no(gui_module.index - 1, gui_module.forced_purlins_no)
        break
    }
    this.setOffsetState(product_manager_module.products[gui_module.index - 1])
    var pn = parseInt(v)
    switch(v) {
      case '0':
        pn = this.findPostsNo(this.state.postsType)
        break
      case '9':
        pn = 0
        break
    }
    //console.log('pn: ' + pn + ' v: ' + this.findPostsNo(this.state.postsType))
    this.setState({
      postsNo: pn,
      postsNoType: v
    })
  }

  setOffset = (v, no) => {
    switch(this.state.postsType) {
      case 'front_posts':        
        gui_module.front_post_offset_gui[no].setValue(v)
        break
      case 'back_posts':        
        gui_module.back_post_offset_gui[no].setValue(v)
        break
      case 'right_posts':
        gui_module.right_post_offset_gui[no].setValue(v)
        break
      case 'decking_posts':
        gui_module.decking_post_offset_gui[no].setValue(v)
        break
      case 'brackets':
        gui_module.bracket_offset_gui[no].setValue(v)
        break
      case 'rafters':
        gui_module.rafter_offset_gui[no].setValue(v)
        break
      case 'purlins':
        gui_module.purlin_offset_gui[no].setValue(v)
        break
    }
  }
  setPosition_1 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      position_1: v
    }) 
    this.setOffset(v, 0)
  }

  setPosition_2 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      position_2: v
    }) 
    this.setOffset(v, 1)
  }

  setPosition_3 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      position_3: v
    }) 
    this.setOffset(v, 2)
  }

  setPosition_4 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      position_4: v
    }) 
    this.setOffset(v, 3)
  }

  setPosition_5 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      position_5: v
    }) 
    this.setOffset(v, 4)
  }

  setPosition_6 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      position_6: v
    }) 
    this.setOffset(v, 5)
  }

  setSize_1 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      size_1: v
    }) 
    gui_module.bracket_length_gui[0].setValue(v)
  }

  setSize_2 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      size_2: v
    }) 
    gui_module.bracket_length_gui[1].setValue(v)
  }

  setSize_3 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      size_3: v
    }) 
    gui_module.bracket_length_gui[2].setValue(v)
  }

  setSize_4 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      size_4: v
    }) 
    gui_module.bracket_length_gui[3].setValue(v)
  }

  setSize_5 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      size_5: v
    }) 
    gui_module.bracket_length_gui[4].setValue(v)
  }

  setSize_6 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      size_6: v
    }) 
    gui_module.bracket_length_gui[5].setValue(v)
  }

  findPostsNo = (v) => {
    var product = product_manager_module.products[gui_module.index - 1]
    var pn = product?.front_posts_no
    switch(v) {
      case 'back_posts':        
        pn = product?.back_posts_no
        break
      case 'right_posts':
        pn = product?.right_posts_no
        break
      case 'decking_posts':
        pn = product?.decking_posts_no
        break
      case 'brackets':
        pn = product?.brackets_no
        break
      case 'rafters':
        pn = product?.rafters_no_buffer
        break
      case 'purlins':
        pn = product?.purlins_no_buffer
        break
      default:
        break
    }
    return pn
  }

  findForcedPostsNo = (v) => {
    var product = product_manager_module.products[gui_module.index - 1]
    var pn = product?.forced_front_posts_no
    switch(v) {
      case 'back_posts':        
        pn = product?.forced_back_posts_no
        break
      case 'right_posts':
        pn = product?.forced_right_posts_no
        break
      case 'decking_posts':
        pn = product?.forced_decking_posts_no
        break
      case 'brackets':
        pn = product?.forced_brackets_no
        break
      case 'rafters':
        pn = product?.forced_rafters_no
        break
      case 'purlins':
        pn = product?.forced_purlins_no
        break
      default:
        break
    }
    return pn
  }

  findPostOffset = (product, index) => {
    var o = 0
    if(product?.front_posts_offset?.length >= index) {
      
      switch(this.state.postsType) {
        case 'back_posts':        
          o = product?.back_posts_offset[index]
          break
        case 'right_posts':
          o = product?.right_posts_offset[index]
          break
        case 'decking_posts':
          o = product?.decking_posts_offset[index]
          break
        case 'brackets':
          o = product?.bracket_offset[index]
          break
        case 'rafters':
          o = product?.rafter_offset[index]
          break
        case 'purlins':
          o = product?.purlin_offset[index]
          break
        default:
          o = product?.front_posts_offset[index]
          break
      }
    }
    return o * 100
  }

  setOffsetState = (product) => {
    var product = product_manager_module.products[gui_module.index - 1]
    this.setState({
      position_1: this.findPostOffset(product, 0),
      position_2: this.findPostOffset(product, 1),
      position_3: this.findPostOffset(product, 2),
      position_4: this.findPostOffset(product, 3),
      position_5: this.findPostOffset(product, 4),
      position_6: this.findPostOffset(product, 5),
    })
  }

  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type 
    var pn = this.findPostsNo(this.state.postsType)
    var index = this.findForcedPostsNo(this.state.postsType)
    if(index == undefined) index = 0
    if(index == 9) {
      index = 1
    } else if(index > 0) {
      index = index + 1
    }
    this.setOffsetState(product)

    this.setState({
      postsNo: pn,
      postsNoType: postsNoTypes[index].value
    })

    if(type < 32) {
      document.getElementById('posts_setup_sub').style.display = 'block'
    } else {
      document.getElementById('posts_setup_sub').style.display = 'none'
    }

    
    if(this.state.postsType == 'brackets') {
      document.getElementById('brackets_size').style.display = 'block'
    } else {
      document.getElementById('brackets_size').style.display = 'none'
    }
  }

  setPostSize = (event) => {
    var v = event.target.value
    this.setState({
      postSize: v
    })
    
    switch(this.state.postsType) {
      case 'front_posts':        
        product_manager_module.front_post_width(gui_module.index - 1, parseFloat(v))
      break
      case 'decking_posts':
        product_manager_module.decking_post_width(gui_module.index - 1, parseFloat(v))
        break
      default:
        break
    }
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div id='post_setup'>
       <div id='posts_setup_sub'>
       <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.postsType} 
            onChange={this.setPostsType}
          >
          {postsTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.postsNoType} 
            onChange={this.setPostsNoType}
          >
          {postsNoTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.postSize} 
            onChange={this.setPostSize}
          >
          {postSizes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        {this.state.postsNo > 0 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">1st Position</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_1}
              onChange={this.setPosition_1}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_1}
              onChange={this.setPosition_1}
          />
        </div>)}
        {this.state.postsNo > 1 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">2nd Position</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_2}
              onChange={this.setPosition_2}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_2}
              onChange={this.setPosition_2}
          />
        </div>)}
        {this.state.postsNo > 2 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">3rd Position</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_3}
              onChange={this.setPosition_3}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_3}
              onChange={this.setPosition_3}
          />
        </div>)}
        {this.state.postsNo > 3 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">4th Position</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_4}
              onChange={this.setPosition_4}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_4}
              onChange={this.setPosition_4}
          />
        </div>)}
        {this.state.postsNo > 4 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">5th Position</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_5}
              onChange={this.setPosition_5}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_5}
              onChange={this.setPosition_5}
          />
        </div>)}
        {this.state.postsNo > 5 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">6th Position</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_6}
              onChange={this.setPosition_6}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='-50'
              max='50'
              step='1'
              value={this.state.position_6}
              onChange={this.setPosition_6}
          />
        </div>)}
        <div id='brackets_size'>
        {this.state.postsNo > 0 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">1st Size</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_1}
              onChange={this.setSize_1}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_1}
              onChange={this.setSize_1}
          />
        </div>)}
        {this.state.postsNo > 1 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">2nd Size</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_2}
              onChange={this.setSize_2}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_2}
              onChange={this.setSize_2}
          />
        </div>)}
        {this.state.postsNo > 2 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">3rd Size</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_3}
              onChange={this.setSize_3}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_3}
              onChange={this.setSize_3}
          />
        </div>)}
        {this.state.postsNo > 3 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">4th Size</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_4}
              onChange={this.setSize_4}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_4}
              onChange={this.setSize_4}
          />
        </div>)}
        {this.state.postsNo > 4 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">5th Size</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_5}
              onChange={this.setSize_5}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_5}
              onChange={this.setSize_5}
          />
        </div>)}
        {this.state.postsNo > 5 && (<div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">6th Size</button>
          <input type='number' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_6}
              onChange={this.setSize_6}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
              min='0'
              max='20'
              step='0.1'
              value={this.state.size_6}
              onChange={this.setSize_6}
          />
        </div>)}
        </div>
        </div>
      </div>
     );  
  }
}

export default SmartPostSetupSubmenu;
