import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { initSmartkits } from '../smartkits/initSmartkits.js'

function Smart( { informSelect }) {
  const sceneRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true , toneMapping: THREE.NoToneMapping, preserveDrawingBuffer: true});
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace 
    initSmartkits(scene, camera, renderer, informSelect) 
    sceneRef.current.appendChild(renderer.domElement);

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      sceneRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={sceneRef} />;
};

export default Smart;
