import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// npm install html2canvas jspdf
import { product_manager_module } from '../smartkits/product_manager_module';
import { project_specification } from '../smartkits/gui_smartkits'
import Smart2DProjectionPage from './Smart2DProjectionPage';

class PdfConverter extends Component {
  constructor(props) {
    super(props);
    this.state =  {
        count: 0, 
        products: product_manager_module.products,
        proPageNo: 6,
        epiPageNo: 10,
        imagePath: 'engineering/0025.jpg',
        customerPageRef: React.createRef(),
        drawingPageNo: 4,
        pdfHightRes: 1754, //877, //3508,
        pdfWidthRes: 1240, //620 //2480
    } 
    this.pdfPages = []
    for(var i = 0; i < this.state.drawingPageNo * product_manager_module.products.length; ++i) {
        this.pdfPages.push(React.createRef())
    }
    this.pdf = new jsPDF('p', 'mm', 'a4')
    this.pdf.output('datauristring', { compress: true });
  }

  downloadImage = () => {
    this.Smart2DProjectionPage.downloadImage()
  }

  updatePDFCounter = () => {
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }), () => {
      this.convertToPdf();
    })
  }

  loadImage = (add) => {
    const img = new Image()
    img.src = add
    img.onload = () => {
      this.pdf.addImage(img, 'JPEG', 0, 0, 210, 270, '', 'FAST')  
      this.updatePDFCounter()
    };
  }

  initPdf = () => {
    var length = this.state.drawingPageNo * product_manager_module.products.length
    if(length == 0) return 0
    var no= this.state.count
    if(no >= length + this.state.proPageNo + this.state.epiPageNo) {
        this.pdf.save(project_specification.family_name + '_' + project_specification.first_name + '_quote_1.pdf')
        return 0;
    } else if(no < this.state.proPageNo) {      
      
      switch(no) {
        case 0:
          this.loadImage('engineering/00' + (no + 17) + '.jpg') 
          break
        case 1:
          this.pdf.addPage()
          var canvas = this.state.customerPageRef.current
          var imgData = canvas.toDataURL('image/png')
          this.pdf.addImage(imgData, 'JPEG', 0, 0, 210, 270, '', 'FAST')   
          this.updatePDFCounter()
          break
        default:
          this.loadImage('engineering/00' + (no + 17) + '.jpg') 
          this.pdf.addPage() 
          break
      }
      return 0     
    } else if(no < length + this.state.proPageNo){
      return 1       
    } else {
      var no1 = no - length - this.state.proPageNo + 1
      var s = 'engineering/00'
      if(no1 < 10) s =  s + '0'
      this.loadImage(s + no1 + '.jpg') 
      this.pdf.addPage()
      return 0    
    }  
  }

  convertToPdf = () => {
    if(this.initPdf() == 0) return
    var no = this.state.count - this.state.proPageNo
    var i = Math.floor(no / this.state.drawingPageNo)
    if(this.state.products[i].type < 15 || this.state.products[i].type == 20 || this.state.products[i].type == 21 || this.state.products[i].type == 28 || this.state.products[i].type == 67) {
      switch(no % 4) {
        case 0:
        case 1:
          this.pdf.addPage()
          break
        default:
          this.updatePDFCounter()
          return
      }      
    } else if(this.state.products[i].type > 63 && this.state.products[i].type < 100) {
      this.pdf.addPage()
    } else {
      this.updatePDFCounter()
      return
    }
    const input = this.pdfPages[no].current 
    html2canvas(input).then((canvas) => {
        var imgData = canvas.toDataURL('image/png')
        this.pdf.addImage(imgData, 'JPEG', 0, 0, 210, 270, '', 'FAST')   
        this.updatePDFCounter()
    })
  }

  makeCustomerPage = () => {
    const canvas = this.state.customerPageRef.current
    const context = canvas.getContext('2d', { willReadFrequently: true })
    const image = new Image();

    image.src = this.state.imagePath

    image.onload = () => {
      context.drawImage(image, 0, 0, canvas.width, canvas.height)
      context.fillStyle = 'black'
      context.font = '39px Arial'
      //context.fillText('Name' , 205, 1105)
      //context.fillText('ALik' , 205, 1190)
      //context.fillText('Name' , 205, 1275)
      //context.fillText('ALik' , 235, 1355)
      context.fillText(project_specification.first_name + ' ' + project_specification.family_name , 205, 1105) //, 410, 2210)
      context.fillText(project_specification.email , 205, 1190) //410, 2380)
      context.fillText(project_specification.phone_no , 205, 1275) //410, 2550)
      context.fillText(project_specification.build_street_no , 235, 1355) // 470, 2710)
      context.fillText(project_specification.build_street , 235, 1388) // 470, 2710)
      context.fillText(project_specification.build_suburb + ' ' +  project_specification.build_post_code , 235, 1435) //470, 2870)
      context.fillText(project_specification.build_state , 235, 1520) //470, 3040)   
    };
  }

  drawCanvas2Context = (i, canvas1, canvas2Ref) => {
    if(canvas1 == null || canvas2Ref == null || canvas2Ref.current == null) return
    const ctx1 = canvas1.getContext('2d', { willReadFrequently: true })
    ctx1.fillStyle = 'white'
    ctx1.fillRect(0, 0, canvas1.width, canvas1.height);
    ctx1.fillStyle = 'black'
    if(canvas2Ref) {
      const canvas2 = canvas2Ref.current
      const image = new Image();
      image.src = canvas2.toDataURL('image/png');
      var sx = canvas1.width / (canvas2.width * 1)
      var sy = canvas1.height / (canvas2.height * 1)
      const s = sx < sy ? sx: sy
      image.onload = () => {
          ctx1.drawImage(image, (canvas1.width - canvas2.width * s) / 2, (canvas1.height - canvas2.height * s) / 2, canvas2.width * s, canvas2.height * s);
      }
    }
  }

  componentDidMount() {
    this.makeCustomerPage()

    for(var i = 0; i < this.pdfPages.length; ++i) {
        var j = Math.floor(i / this.state.drawingPageNo)
        var product = this.state.products[j]
        const canvas1 = this.pdfPages[i].current
        switch(i % 4) {
          case 0:
            var canvas2Ref = product.canvasRef
            var scaledCanvas2 = product.scaledCanvasRef.current
            this.drawCanvas2Context(j, canvas1, canvas2Ref)
            this.drawCanvas2Context(j, scaledCanvas2, canvas2Ref)
            break
          case 1:
            if(product.type < 32 || product.type == 67) {
              var canvas3Ref = product.postLocationCanvasRef
              var scaledPostLocationCanvas = product.scaledPostLocationCanvasRef.current
              this.drawCanvas2Context(j, canvas1, canvas3Ref)
              this.drawCanvas2Context(j, scaledPostLocationCanvas, canvas3Ref)
            } else if(product.type > 63)  {
              var canvas3Ref = product.canvasRef1
              var scaledPostLocationCanvas = product.scaledCanvasRef1.current
              this.drawCanvas2Context(j, canvas1, canvas3Ref)
              this.drawCanvas2Context(j, scaledPostLocationCanvas, canvas3Ref)
            }
            break
          case 2:
            var canvas2Ref = product.canvasRef2
            var scaledCanvas2 = product.scaledCanvasRef2.current
            this.drawCanvas2Context(j, canvas1, canvas2Ref)
            this.drawCanvas2Context(j, scaledCanvas2, canvas2Ref)
            break
          case 3:
            var canvas2Ref = product.canvasRef3
            var scaledCanvas2 = product.scaledCanvasRef3.current
            this.drawCanvas2Context(j, canvas1, canvas2Ref)
            this.drawCanvas2Context(j, scaledCanvas2, canvas2Ref)
            break
        }
    }
  }

  render() {
    const canvasStyle = {
        width: '1px',  
        height: '1px',  
        overflow: 'auto',
        border: '1px solid #000',
        
    };
    return (
      <div>
       <Smart2DProjectionPage ref={ref => this.Smart2DProjectionPage = ref} />
       <div style={canvasStyle}>
        <canvas ref={this.state.customerPageRef} src={this.state.imagePath} alt="Description of the image" width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       {this.state.products.map(({length, projection, height, angle, front_posts_no, brackets_no, canvasRef}, index) => 
       (<div style={{     
          margin: '5px',
        }}>
        <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index + 1]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index + 2]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index + 3]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       </div>))} 
      </div>     
    );
  }
}

export default PdfConverter;