import { THREE, getMaterial } from './helper.js';

import { gui_module, sceneItems } from './gui_smartkits.js';
import { loader_module } from './loader_module.js';
import { flyover_module, beam_height } from './flyover_module.js';

var roof_slope = Math.PI / 8
var cos22p5 = Math.cos(roof_slope)
var sin22p5 = Math.sin(roof_slope)

var delta_angle = Math.PI / 6
var cos30 = Math.cos(delta_angle)

var cos45 = Math.sin(roof_slope * 2)
var sin45 = Math.sin(roof_slope * 2)

var hip_oblique_gutter_ratio = (Math.pow(2 + Math.pow(sin22p5 / cos22p5, 2), 0.5))

var v1 = new THREE.Vector3();
var v2 = new THREE.Vector3();  

var origion = new THREE.Vector3(0, 0, 0);

var posts_gap = 0.10

var gable_hip_module = {  
    add: function(product) {
        if(product.type == 28) gable_hip_module.add_posts_decking(product)
        else if(product.type == 29) {} 
        else {
            flyover_module.add_posts(product)            
        }
        if(product.type > 12 && product.type < 17) flyover_module.add_ceiling(product)
        gable_hip_module.add_gutters(product, gable_hip_module.setup)
        flyover_module.posts_color(product, gui_module.posts);
        gable_hip_module.rotate(product, 0)
        gable_hip_module.setup(product);

        product.profile = 10
        if(product.price_index == 9 || product.price_index == 11) product.profile = 11

        gable_hip_module.add_roofing(product)
        gable_hip_module.add_beams(product, gable_hip_module.setup)        
        flyover_module.add_landscaping(product)
        
    },
    add_roofing: function(product, call) {

        if(product.roofing_group) {
            product.scene_group.remove(product.roofing_group)            
        }

        product.roofing_group = new THREE.Group();
        product.roofing_model = [];

        function call_back() {
            if(product.type < 28) flyover_module.populate_parts(product) 
            product.scene_group.add(product.roofing_group);
            flyover_module.roofing_color(product, product.roofing)
            gable_hip_module.setup(product);
            for(var i = 0; i < product.roofing_model.length; ++i) {
                if(product.type != 28 && product.type != 29) {
                    product.roofing_model[i].traverse( function (child) {
                        if(child.isMesh) product.parts["list"].push(child)       
                    }); 
                } else {
                    product.roofing_model[i].visible = false  
                }                                    
            }
            sceneItems.scene.add(product.scene_group);
            if(call) call()
        }

        switch(product.profile) {   
            case 0:
                if(product.type == 13 || product.type == 14) loader_module.add_solar_span_type_1(product, 2 * 16 * 4, call_back)
                else loader_module.add_custom_orb_type_2(product, 4 * 64 * 10, call_back)
                break
            case 1:
                if(product.type == 13 || product.type == 14) loader_module.add_flat_deck_type_1(product, 2 * 16 * 4, call_back)
                else loader_module.add_custom_orb_type_2(product, 4 * 64 * 10, call_back)
                break    
            case 2:
                if(product.type == 13 || product.type == 14) loader_module.add_solar_span_type_2(product, 2 * 16 * 4, call_back)
                else loader_module.add_custom_orb_type_2(product, 4 * 64 * 10, call_back)
                break                 
            case 11:
                loader_module.add_custom_orb_type_1(product, 4 * 64 * 10, call_back)
                break
            case 12:
                loader_module.add_span_deck_type_1(product, 4 * 64 * 10, call_back)
                break   
            case 10:                               
            default:
                loader_module.add_custom_orb_type_2(product, 4 * 64 * 10, call_back)
                break
        }        
    },
    add_gutters: function(product, call) {
        var no = 9
        if(product.type == 10 || product.type == 11) no = 13
        else if(product.type == 20) no = 12
        else if(product.type == 21) no = 16
        else if(product.type == 28 || product.type == 29) no = 1
        else if(product.type > 12 && product.type < 17) no = 6
        else if(product.type == 100) no = 15
        else if(product.type > 31) no = 14
        loader_module.add_gutters(product, no, function() {
            flyover_module.gutters_color(product, gui_module.gutters)
            for(var i = 0; i < product.gutters_model.length; ++i) {
                var gutter = product.gutters_model[i]
                if(product.type == 28 || product.type == 29) gutter.visible = false                                
                else gutter.visible = true                                
            }      
            if(call) call(product)     
        });
    },    
    add_beams: function(product, call) {
        var no = 3
        if(product.type == 20) no = 12
        else if(product.type == 21) no = 16
        else if(product.type == 28 || product.type == 29)  no = 37  
        else if(product.type == 67)  no = 2          

        loader_module.add_beams(product, no, function() {
            flyover_module.beams_color(product, gui_module.beams)
            for(var i = 0; i < product.beams_model.length; ++i) {
                var beam = product.beams_model[i]
                beam.visible = true;  
                if(product.type == 28 || product.type == 29) {
                    beam.traverse( function (child) {
                        if(child.isMesh) product.parts["list"].push(child)       
                    });   
                } 
                                         
            }      
            if(call) call(product)   
        });
    },
    add_posts_decking: function(product) { 
        product.parts = {"list": [], "parent": product, "index": 0}

        var postsGeometry = new THREE.BoxGeometry(1, 1, 1); 
        var material = getMaterial(0xffffff, 0)
        for(var i = 0; i < 48; ++i) {           
            var obj = new THREE.Mesh(postsGeometry, material);
            product.posts_base_model.push(obj);
            obj.position.x += 0.3 * i

            if(i > 24) {
                obj.scale.set(0.05, 0.2, 0.2)
            } else {
                obj.scale.set(0.2, 0.05, 0.2)
            }
            obj.visible = false
            product.scene_group.add(obj);           
        } 

        for(var i = 0; i < 24; ++i) {           
            var obj = new THREE.Mesh(postsGeometry, material);
            product.front_posts_model.push(obj);   
            product.parts["list"].push(obj)       
            product.scene_group.add(obj);
            product.front_posts_offset.push(0);   
                  
        } 

    },
    setup_gutters_gazebo_6_patio: function(product) {
        var size 

        var angle = (product.rotate + 90) * Math.PI / 180
        
        var projection = product.projection
        var gutter_scale = 1.0

        var d = (projection / 2) 
        var scale = d * 1.145   
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.rotation.set(0, 0, 0)
            gutter.visible = true
                
            if(i < 6) {                 
                gutter.position.y = product.horizon + product.height
                switch(i) {
                    case 0:
                        gutter.scale.set(scale, gutter_scale, gutter_scale)
                        gutter.rotation.y = angle    
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)  
                        v1.multiplyScalar(d)
                        v2.multiplyScalar(0)
                        break;   
                    case 1:
                        gutter.scale.set(scale, gutter_scale, gutter_scale) 
                        gutter.rotation.y = product.angle_60  
                        v1.copy(product.left_2_right_60_unit_vector) 
                        v2.copy(product.front_2_end_60_unit_vector)                          
                        v1.multiplyScalar(d * 0.63)
                        v2.multiplyScalar(d * -1.37)
                        break;   
                    case 2:
                        gutter.scale.set(scale, gutter_scale, gutter_scale) 
                        gutter.rotation.y = product.angle_120  
                        v1.copy(product.left_2_right_120_unit_vector) 
                        v2.copy(product.front_2_end_120_unit_vector)                           
                        v1.multiplyScalar(d * -2.36)
                        v2.multiplyScalar(d * -0.37)
                        break;                          
                    case 3:
                        gutter.scale.set(scale, gutter_scale, gutter_scale)
                        gutter.rotation.y = angle                         
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)                 
                        v1.multiplyScalar(d)
                        v2.multiplyScalar(d * 2)                         
                        break; 
                    case 4:
                        gutter.scale.set(scale, gutter_scale, gutter_scale) 
                        gutter.rotation.y = product.angle_60
                        v1.copy(product.left_2_right_60_unit_vector) 
                        v2.copy(product.front_2_end_60_unit_vector)
                        v1.multiplyScalar(d * -1.36)
                        v2.multiplyScalar(d * -1.365)
                        break;
                    case 5:
                        gutter.scale.set(scale, gutter_scale, gutter_scale) 
                        gutter.rotation.y = product.angle_120  
                        v1.copy(product.left_2_right_120_unit_vector) 
                        v2.copy(product.front_2_end_120_unit_vector)                          
                        v1.multiplyScalar(d * -0.37)
                        v2.multiplyScalar(d * -0.37)
                        break;                      
                    default:
                        break;      
                                                                                                    
                }  
                v1.add(v2)
                gutter.position.add(v1)
            } else if(i < 12) {  
                size = d * 1.22
                gutter.scale.set(size , gutter_scale, gutter_scale)
                gutter.position.y = product.horizon + product.height + size * 0.17 + 0.03
                switch(i) {                         
                    case 6:   
                        gutter.rotation.y = angle        
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 5.95) 

                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector) 
                        v1.multiplyScalar(size * 0.35)
                        v2.multiplyScalar(d)                                         
                        break;  
                    case 7:   
                        gutter.rotation.y = angle      
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 3.48) 

                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector) 
                        v1.multiplyScalar(d * 1.56)                        
                        v2.multiplyScalar(d)   
                        break;     
                    case 8:  
                        gutter.rotation.y = angle + Math.PI / 3        
                        gutter.rotateOnWorldAxis(product.left_2_right_60_unit_vector, 5.94) 

                        v1.copy(product.front_2_end_60_unit_vector) 
                        v2.copy(product.left_2_right_60_unit_vector) 
                        v1.multiplyScalar(size * -0.64)
                        v2.multiplyScalar(size * -0.3)  
                        break;      
                    case 9: 
                        gutter.rotation.y = angle + 2 * Math.PI / 3        
                        gutter.rotateOnWorldAxis(product.left_2_right_120_unit_vector, 5.94 ) 

                        v1.copy(product.front_2_end_120_unit_vector) 
                        v2.copy(product.left_2_right_120_unit_vector) 
                        v1.multiplyScalar(1 * size * 0.18)
                        v2.multiplyScalar(size * -1.12)  
                        break;  
                    case 10:    
                        gutter.rotation.y = angle + 5 * Math.PI / 3        
                        gutter.rotateOnWorldAxis(product.left_2_right_120_unit_vector, 3.49 ) 

                        v1.copy(product.front_2_end_120_unit_vector) 
                        v2.copy(product.left_2_right_120_unit_vector) 
                        v1.multiplyScalar(size * -0.76)
                        v2.multiplyScalar(size * -1.12)    
                        break;    
                    case 11: 
                        gutter.rotation.y = angle + 4 * Math.PI / 3        
                        gutter.rotateOnWorldAxis(product.left_2_right_60_unit_vector, 0.345) 

                        v1.copy(product.front_2_end_60_unit_vector) 
                        v2.copy(product.left_2_right_60_unit_vector) 
                        v1.multiplyScalar(size * -1.59)
                        v2.multiplyScalar(size * -0.3) 
                        break;                                        
                    default:
                        break;                                                                                                                     
                }  
                v1.add(v2)
                gutter.position.add(v1)  
            } else {
            }            
        }
    },
    setup_beams_gazebo_6_patio: function(product) {
        var size
        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.5)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.5)
        origion.add(v2)  

        var angle = (product.rotate + 90) * Math.PI / 180

        var projection = product.projection
        var beam_scale = 1.0

        var d = (projection / 2) 
        size = d * 0.6
        var scale = size * 1.14
        for(var i = 0; i < product.beams_model.length; ++i) {
            var beam = product.beams_model[i]
            if(i == 6) {
                size = d * 0.97
                scale = size * 1.16
            }
            beam.position.copy(origion)
            beam.rotation.set(0, 0, 0)
            
            beam.position.y = product.horizon + product.height 
            beam.visible = true
            if(i < 6)  beam.position.y += size * 0.24       
            switch(i) {
                case 0:
                case 6:
                    beam.scale.set(scale, beam_scale, beam_scale)
                    beam.rotation.y = angle                         
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size)  
                    break;
                case 1:
                case 7:                    
                    beam.scale.set(scale, beam_scale, beam_scale)
                    beam.rotation.y = angle                         
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -1)                       
                    break; 
                case 2:
                case 8:                    
                    beam.scale.set(scale, beam_scale, beam_scale)
                    beam.rotation.y = angle + 1.05 // ( 1 + (product.step_size - 3) / 10)                      
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_60_unit_vector)                 
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size)   
                    break;   
                case 3:
                case 9:                    
                    beam.scale.set(scale, beam_scale, beam_scale)
                    beam.rotation.y = angle + 1.035                     
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_60_unit_vector)                 
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -1)                      
                    break;                                               
                case 4:
                case 10:
                    beam.scale.set(scale, beam_scale, beam_scale)
                    beam.rotation.y = angle + 2.09                       
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_120_unit_vector)                 
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -1) 
                    break;  
                case 5:
                case 11:
                    beam.scale.set(scale, beam_scale, beam_scale)
                    beam.rotation.y = angle + 2.09                       
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_120_unit_vector)                 
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size) 
                    break;                                                                                                     
                default:
                    beam.visible = false
                    break;      
                                                                                                
            }  
            v1.add(v2)
            beam.position.add(v1)          
        }
    },   
    setup_posts_gazbo_6_patio: function(product) {       
        // visible needed
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false;

        for(var i = 0; i < product.front_posts_model.length; ++i) {
            product.front_posts_model[i].visible = true;
            product.posts_base_model[0 + i].visible  = decking_not_visible_flag
            product.posts_base_model[30 + i].visible  = decking_not_visible_flag        
        } 

        var size

        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.5)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.5)
        origion.add(v2) 

        var d = (product.projection / 2) 
        size = d * 1


        // locate       
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var post =  product.front_posts_model[i]
            post.scale.set(product.front_post_width, product.height, product.front_post_width);
            post.position.copy(origion)
            post.rotation.set(0, 0, 0)
           
            switch(i) {
                case 0:
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 0.56)
                    v2.multiplyScalar(size * 0.97)  
                    break;
                case 1:                                    
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 0.55)
                    v2.multiplyScalar(size * -0.97)                       
                    break; 
                case 2:                                      
                    v1.copy(product.front_2_end_60_unit_vector) 
                    v2.copy(product.left_2_right_60_unit_vector)                 
                    v1.multiplyScalar(size * 1.1)
                    v2.multiplyScalar(0)   
                    break;   
                case 3:                                     
                    v1.copy(product.front_2_end_120_unit_vector) 
                    v2.copy(product.left_2_right_60_unit_vector)                 
                    v1.multiplyScalar(size * -1.1)
                    v2.multiplyScalar(0)                    
                    break;                                               
                case 4:                   
                    v1.copy(product.left_2_right_60_unit_vector) 
                    v2.copy(product.front_2_end_60_unit_vector)                 
                    v1.multiplyScalar(size * 0.96)
                    v2.multiplyScalar(size * 0.56)  
                    break;  
                case 5:                   
                    v1.copy(product.left_2_right_60_unit_vector) 
                    v2.copy(product.front_2_end_60_unit_vector)                 
                    v1.multiplyScalar(size * -1 * 0.93)
                    v2.multiplyScalar(size * 0.53 * -1) 
                    break;                                                                                                   
                default:
                    post.visible = false
                    break;                  
            }

            v1.y += product.horizon + (product.height + posts_gap) / 2;   
            v1.add(v2)                           
            post.position.add(v1)

            if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[i].position.copy(origion)
                product.posts_base_model[30 + i].position.copy(origion)

                product.posts_base_model[i].position.add(v1)
                
                product.posts_base_model[30 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[30 + i].position.add(v1)
            } 
        }    

    },    
    setup_downlights_gazbo_6_patio: function(product) {
        for(var i = 0; i < product.downlights_model.length; ++i) {
            if(i < product.number) product.downlights_model[i].visible = true
            else product.downlights_model[i].visible = false
        }
        if(product.number == 0) return

        var size
        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.27)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.24)
        origion.add(v2)  

        var d = (product.projection / 2) 
        size = d * 0.4

        for(var i = 0; i < product.downlights_model.length; ++i) {
            var light = product.downlights_model[i]

            light.position.copy(origion)
            light.rotation.set(0, 0, 0)
            
            light.position.y = product.horizon + product.height + size * 0.1
            switch(i) {
                case 0:   
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 1.31)
                    v2.multiplyScalar(size * -0.09)  
                    break;
                case 1:
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 1.26)
                    v2.multiplyScalar(size * 2.41)   
                    break;                    
                case 2:   
                    v1.copy(product.left_2_right_60_unit_vector) 
                    v2.copy(product.front_2_end_60_unit_vector)                          
                    v1.multiplyScalar(size * -1.8)
                    v2.multiplyScalar(size * -1.61)       
                    break;  
                case 3:
                    v1.copy(product.left_2_right_60_unit_vector) 
                    v2.copy(product.front_2_end_60_unit_vector)                          
                    v1.multiplyScalar(size * 0.71)
                    v2.multiplyScalar(size * -1.72)                      
                    break;
                case 4:
                    v1.copy(product.left_2_right_120_unit_vector) 
                    v2.copy(product.front_2_end_120_unit_vector)                          
                    v1.multiplyScalar(size * -3.0)
                    v2.multiplyScalar(size * -0.42)    
                    break;
                case 5:
                    v1.copy(product.left_2_right_120_unit_vector) 
                    v2.copy(product.front_2_end_120_unit_vector)                          
                    v1.multiplyScalar(size * -0.4)
                    v2.multiplyScalar(size * -0.43 )  
                    break;                                                                                                                              
                default:
                    light.visible = false
                    break;      
                                                                                                
            }  
            v1.add(v2)
            light.position.add(v1)         
        }
    }, 
    setup_gazebo_6_side: function(product) {
        var size, dd

        var j = 0, jj = 0

        var l = 0, ll = 0
        var m = 0, mm = 0

        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.5)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.5)
        origion.add(v2)  

        var angle = (product.rotate + 90) * Math.PI / 180

        var d = product.length / 2
        

        var no = d * 11.4
        var half_way = no / 2
        var ratio = 1.75 // + (product.step_size - 3) / 10
        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(origion)            
            roofing.rotation.set(0, 0, 0)            
            if(j < no) {
                roofing.visible = true
                roofing.rotation.y = angle             
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector)                   

                if(j < half_way) {
                    dd = ratio * j * 0.1                    
                    size = dd / cos22p5 + 0.05 
                } else {                                      
                    dd = ratio * (no - j + 0.5) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                }
                roofing.scale.z = size  

                v1.multiplyScalar(0.1 * (j - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d)
                ++j
            } else if(jj < no) {
                roofing.visible = true
                roofing.rotation.y = angle               
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 
                if(jj < half_way) {
                    dd = ratio * jj * 0.1                    
                    size = dd / cos22p5 + 0.05 
                } else {                                      
                    dd = ratio * (no - jj + 0.5) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (jj - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2)
                ++jj
            } else if(l < no) {
                roofing.visible = true
                roofing.rotation.y = angle +  4 * Math.PI / 3                
                roofing.rotateOnWorldAxis(product.front_2_end_60_unit_vector, 15 * roof_slope) 
                v1.copy(product.front_2_end_60_unit_vector) 
                v2.copy(product.left_2_right_60_unit_vector) 
                if(l < half_way) {
                    dd = ratio * l * 0.1                    
                    size = dd / cos22p5 + 0.05 
                } else {                                      
                    dd = ratio * (no - l + 0.5) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (l - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d) 
                ++l
            } else if(ll < no) {                
                roofing.visible = true
                roofing.rotation.y = angle +  4 * Math.PI / 3                
                roofing.rotateOnWorldAxis(product.front_2_end_60_unit_vector, 1 * roof_slope) 
                v1.copy(product.front_2_end_60_unit_vector) 
                v2.copy(product.left_2_right_60_unit_vector) 
                if(ll < half_way) {
                    dd = ratio * ll * 0.1                    
                    size = dd / cos22p5 + 0.05 
                } else {                                      
                    dd = ratio * (no - ll + 0.5) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                }
                roofing.scale.z = size  
                v1.multiplyScalar(0.1 * (ll - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2)
                ++ll
            } else if(m < no) {
                roofing.visible = true
                roofing.rotation.y = angle +  5 * Math.PI / 3                
                roofing.rotateOnWorldAxis(product.front_2_end_120_unit_vector, 1 * roof_slope) 
                v1.copy(product.front_2_end_120_unit_vector) 
                v2.copy(product.left_2_right_120_unit_vector)
                if(m < half_way) {
                    dd = ratio * m * 0.1                    
                    size = dd / cos22p5 + 0.05 
                } else {                                      
                    dd = ratio * (no - m + 0.5) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (m - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2) 
                ++m
            } else if(mm < no) {                
                roofing.visible = true
                roofing.rotation.y = angle +  5 * Math.PI / 3                
                roofing.rotateOnWorldAxis(product.front_2_end_120_unit_vector, 15 * roof_slope) 
                v1.copy(product.front_2_end_120_unit_vector) 
                v2.copy(product.left_2_right_120_unit_vector)
                if(mm < half_way) {
                    dd = ratio * mm * 0.1                    
                    size = dd / cos22p5 + 0.05 
                } else {                                      
                    dd = ratio * (no - mm + 0.5) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                }
                roofing.scale.z = size 
                v1.multiplyScalar(0.1 * (mm - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d)
                ++mm
            } else {
                roofing.visible = false
            }
            v1.add(v2)
            roofing.position.add(v1)
            roofing.position.y = product.horizon + product.height + size * sin22p5 / 2 
            
        }
    },
    setup_gutters_gazebo_8_patio: function(product) {
        var size, angle_1

        var angle = (product.rotate + 90) * Math.PI / 180

        var projection = product.projection
        var gutter_scale = 1.2

        var d = (projection / 2) 
        size = d * 0.82  
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.rotation.set(0, 0, 0)
            gutter.visible = true
                
            if(i < 8) {                 
                gutter.position.y = product.horizon + product.height                
                switch(i) {
                    case 0:
                        gutter.scale.set(size, gutter_scale, gutter_scale)
                        gutter.rotation.y = angle                         
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)                 
                        v1.multiplyScalar(size * 1.22)
                        v2.multiplyScalar(size * 2.44)  
                        break;
                    case 1:
                        gutter.scale.set(size, gutter_scale, gutter_scale) 
                        gutter.rotation.y = angle + 0.77
                        v1.copy(product.left_2_right_45_unit_vector) 
                        v2.copy(product.front_2_end_45_unit_vector)                          
                        v1.multiplyScalar(size * 1.22)
                        v2.multiplyScalar(size * -1.73)                        
                        break; 
                    case 2:
                        gutter.scale.set(size, gutter_scale, gutter_scale) 
                        gutter.rotation.y = angle + 1.57 
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)                          
                        v1.multiplyScalar(size * 0)
                        v2.multiplyScalar(size * 1.22)     
                        break;   
                    case 3:
                        gutter.scale.set(size, gutter_scale, gutter_scale) 
                        gutter.rotation.y = angle + 2.35
                        v1.copy(product.left_2_right_45_unit_vector) 
                        v2.copy(product.front_2_end_45_unit_vector)                          
                        v1.multiplyScalar(0)
                        v2.multiplyScalar(size * -0.5)                        
                        break;                                               
                    case 4:
                        gutter.scale.set(size, gutter_scale, gutter_scale)
                        gutter.rotation.y = angle    
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)  
                        v1.multiplyScalar(size * 1.22)
                        v2.multiplyScalar(0)
                        break;  
                    case 5:
                        gutter.scale.set(size, gutter_scale, gutter_scale) 
                        gutter.rotation.y = angle + 0.77
                        v1.copy(product.left_2_right_45_unit_vector) 
                        v2.copy(product.front_2_end_45_unit_vector)                          
                        v1.multiplyScalar(size * -1.22)
                        v2.multiplyScalar(size * -1.73) 
                        break; 
                    case 6:
                        gutter.scale.set(size, gutter_scale, gutter_scale) 
                        gutter.rotation.y = angle + 1.57 
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)                          
                        v1.multiplyScalar(size * 2.44)
                        v2.multiplyScalar(size * 1.22)     
                        break; 
                    case 7:
                        gutter.scale.set(size, gutter_scale, gutter_scale) 
                        gutter.rotation.y = angle + 2.35
                        v1.copy(product.left_2_right_45_unit_vector) 
                        v2.copy(product.front_2_end_45_unit_vector)                          
                        v1.multiplyScalar(0)
                        v2.multiplyScalar(size * -2.94)                        
                        break;                                                                                                                 
                    default:
                        break;      
                                                                                                    
                }  
                v1.add(v2)
                gutter.position.add(v1)
            } else if(i < 16) {                  
                size = d * 1.155 
                gutter.scale.set(size , gutter_scale, gutter_scale)
                gutter.position.y = product.horizon + product.height + size * 0.17 + 0.07
                switch(i) {                         
                    case 8:   
                        angle_1 = angle + Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1   
                        gutter.rotateOnWorldAxis(v1, 2.78) 
                       
                        v1.multiplyScalar(size * 0.47)
                        v2.multiplyScalar(size * -.65)                                        
                        break;  
                    case 9:                                
                        angle_1 = angle + 3 * Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1
                        gutter.rotateOnWorldAxis(v1, 2.78)
                                              
                        v1.multiplyScalar(size * -0.47)                        
                        v2.multiplyScalar(size * -0.65)   
                        break; 
                    case 10:   
                        angle_1 = angle + 5 * Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1   
                        gutter.rotateOnWorldAxis(v1, 2.78) 
                       
                        v1.multiplyScalar(size * -1.13)
                        v2.multiplyScalar(size * 0)                                        
                        break;    
                    case 11:   
                        angle_1 = angle + 7 * Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1   
                        gutter.rotateOnWorldAxis(v1, 2.78) 
                       
                        v1.multiplyScalar(size * -1.13)
                        v2.multiplyScalar(size * 0.94)                                        
                        break;                                                                           
                    case 12:   
                        angle_1 = angle + Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1   
                        gutter.rotateOnWorldAxis(v1, .37) 
                       
                        v1.multiplyScalar(size * 0.47)
                        v2.multiplyScalar(size * -1.6)                                        
                        break;                        
                    case 13:                                
                        angle_1 = angle + 3 * Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1
                        gutter.rotateOnWorldAxis(v1, .37)
                                              
                        v1.multiplyScalar(size * -0.47)                        
                        v2.multiplyScalar(size * -1.6)   
                        break; 
                    case 14:   
                        angle_1 = angle + 5 * Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1   
                        gutter.rotateOnWorldAxis(v1, .37) 
                       
                        v1.multiplyScalar(size * -1.13)
                        v2.multiplyScalar(size * -0.94)                                        
                        break;    
                    case 15:   
                        angle_1 = angle + 7 * Math.PI / 8
                        v2.set(Math.cos(angle_1), 0, -1 * Math.sin(angle_1))
                        v1.set(Math.sin(angle_1), 0, Math.cos(angle_1)) 
                        gutter.rotation.y = angle_1   
                        gutter.rotateOnWorldAxis(v1, .37) 
                       
                        v1.multiplyScalar(size * -1.13)
                        v2.multiplyScalar(size * 0)                                        
                        break;                                                              
                    default:
                        break;                                                                                                                     
                }  
                v1.add(v2)
                gutter.position.add(v1)  
            } else  {
            }            
        }
    },
    setup_posts_gazbo_8_patio: function(product) {       
        // visible needed
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false;

        for(var i = 0; i < product.front_posts_model.length; ++i) {
            product.front_posts_model[i].visible = true;
            product.posts_base_model[0 + i].visible  = decking_not_visible_flag
            product.posts_base_model[30 + i].visible  = decking_not_visible_flag        
        } 

        var size

        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.5)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.5)
        origion.add(v2) 

        var d = (product.projection / 2) 
        size = d * 1


        // locate       
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var post =  product.front_posts_model[i]
            post.scale.set(product.front_post_width, product.height, product.front_post_width);
            post.position.copy(origion)
            post.rotation.set(0, 0, 0)
            // ( 1 + (product.step_size - 3) / 10 )
            switch(i) {
                case 0:
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 0.95)
                    v2.multiplyScalar(size * 0.41 * -1)  
                    break;
                case 1:                                    
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 0.94)
                    v2.multiplyScalar(size * 0.4 * 1)                    
                    break; 
                case 2:                                      
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * -0.95)
                    v2.multiplyScalar(size * 0.41 * -1)    
                    break;   
                case 3:                                     
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * -0.94)
                    v2.multiplyScalar(size * 0.40 * 1)                   
                    break;                                               
                case 4:                   
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_unit_vector)                 
                    v1.multiplyScalar(size * 0.94)
                    v2.multiplyScalar(size * 0.40 * -1)   
                    break;  
                case 5:                                    
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_unit_vector)               
                    v1.multiplyScalar(size * 0.94)
                    v2.multiplyScalar(size * 0.40 * 1)                    
                    break; 
                case 6:                                      
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_unit_vector)             
                    v1.multiplyScalar(size * -0.94)
                    v2.multiplyScalar(size * 0.4 * -1)    
                    break;   
                case 7:                                     
                    v1.copy(product.front_2_end_unit_vector) 
                    v2.copy(product.left_2_right_unit_vector)              
                    v1.multiplyScalar(size * -0.94)
                    v2.multiplyScalar(size * 0.4 * 1)                      
                    break;                                                                        
                default:
                    post.visible = false
                    break;                  
            }

            v1.y += product.horizon + (product.height + posts_gap) / 2;   
            v1.add(v2)                           
            post.position.add(v1)

            if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[i].position.copy(origion)
                product.posts_base_model[30 + i].position.copy(origion)

                product.posts_base_model[i].position.add(v1)
                
                product.posts_base_model[30 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[30 + i].position.add(v1)
            } 
        }     

    },
    setup_beams_gazebo_8_patio: function(product) {
        var size

        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.25)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.25)
        origion.add(v2)  

        var angle = (product.rotate + 90) * Math.PI / 180

        var projection = product.projection
        var beam_scale = 1.0

        var d = (projection / 2) 
        size = d * 0.4
        for(var i = 0; i < product.beams_model.length; ++i) {
            var beam = product.beams_model[i]
            if(i == 8) {
                size = d * 0.8
                origion.copy(product.position)
                v1.copy(product.front_2_end_unit_vector) 
                v1.multiplyScalar(product.length * 0.01)
                origion.add(v1)
                v2.copy(product.left_2_right_unit_vector) 
                v2.multiplyScalar(product.length * 0.01)
                origion.add(v2)
            }
            beam.position.copy(origion)
            beam.rotation.set(0, 0, 0)
            beam.visible = true;
            beam.position.y = product.horizon + product.height
            if(i < 8)  beam.position.y += size * 0.5             
            switch(i) {
                case 0:
                case 8:
                    beam.scale.set(size, beam_scale, beam_scale)
                    beam.rotation.y = angle                         
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 1.22)
                    v2.multiplyScalar(size * 2.44)  
                    break;
                case 1:
                case 9:                    
                    beam.scale.set(size, beam_scale, beam_scale) 
                    beam.rotation.y = angle + 0.77
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(size * 1.22)
                    v2.multiplyScalar(size * -1.73)                        
                    break; 
                case 2:
                case 10:                    
                    beam.scale.set(size, beam_scale, beam_scale) 
                    beam.rotation.y = angle + 1.57 
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                          
                    v1.multiplyScalar(size * 0)
                    v2.multiplyScalar(size * 1.22)     
                    break;   
                case 3:
                case 11:                    
                    beam.scale.set(size, beam_scale, beam_scale) 
                    beam.rotation.y = angle + 2.35
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -0.5)                        
                    break;                                               
                case 4:
                case 12:
                    beam.scale.set(size, beam_scale, beam_scale)
                    beam.rotation.y = angle    
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)  
                    v1.multiplyScalar(size * 1.22)
                    v2.multiplyScalar(0)
                    break;  
                case 5:
                case 13:
                    beam.scale.set(size, beam_scale, beam_scale) 
                    beam.rotation.y = angle + 0.77
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(size * -1.22)
                    v2.multiplyScalar(size * -1.73) 
                    break; 
                case 6:
                case 14:
                    beam.scale.set(size, beam_scale, beam_scale) 
                    beam.rotation.y = angle + 1.57 
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                          
                    v1.multiplyScalar(size * 2.44)
                    v2.multiplyScalar(size * 1.22)     
                    break; 
                case 7:
                case 15:
                    beam.scale.set(size, beam_scale, beam_scale) 
                    beam.rotation.y = angle + 2.35
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -2.94)                        
                    break;                                                                                                                 
                default:
                    beam.visible = false
                    break;      
                                                                                                
            }  
            v1.add(v2)
            beam.position.add(v1)          
        }
    },
    setup_downlights_gazbo_8_patio: function(product) {
        for(var i = 0; i < product.downlights_model.length; ++i) {
            if(i < product.number) product.downlights_model[i].visible = true
            else product.downlights_model[i].visible = false
        }
        if(product.number == 0) return

        var size

        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.28)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.25)
        origion.add(v2)  

        var d = (product.projection / 2) 
        size = d * 0.4

        for(var i = 0; i < product.downlights_model.length; ++i) {
            var light = product.downlights_model[i]

            light.position.copy(origion)
            light.rotation.set(0, 0, 0)
            
            light.position.y = product.horizon + product.height + size * 0.1
           
            switch(i) {
                case 0:   
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                 
                    v1.multiplyScalar(size * 1.22)
                    v2.multiplyScalar(size * 2.44)  
                    break;
                case 1:   
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)  
                    v1.multiplyScalar(size * 1.22)
                    v2.multiplyScalar(0)
                    break;  
                case 2:
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                          
                    v1.multiplyScalar(size * 0)
                    v2.multiplyScalar(size * 1.22)     
                    break;  
                case 3: 
                    v1.copy(product.left_2_right_unit_vector) 
                    v2.copy(product.front_2_end_unit_vector)                          
                    v1.multiplyScalar(size * 2.44)
                    v2.multiplyScalar(size * 1.22)     
                    break;                                                          
                case 4: 
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(size * 1.22)
                    v2.multiplyScalar(size * -1.73)                        
                    break; 
                case 5:  
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(size * -1.22)
                    v2.multiplyScalar(size * -1.73) 
                    break; 
                case 6:  
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -0.5)                        
                    break;   
                case 7:   
                    v1.copy(product.left_2_right_45_unit_vector) 
                    v2.copy(product.front_2_end_45_unit_vector)                          
                    v1.multiplyScalar(0)
                    v2.multiplyScalar(size * -2.94)                        
                    break;                                                                                                                 
                default:
                    light.visible = false
                    break;      
                                                                                                
            }  
            v1.add(v2)
            light.position.add(v1)         
        }
    },
    setup_gazebo_8_side: function(product) {
        var size, dd

        var j = 0, jj = 0
        var k = 0, kk = 0
        var l = 0, ll = 0
        var m = 0, mm = 0

        origion.copy(product.position)
        v1.copy(product.front_2_end_unit_vector) 
        v1.multiplyScalar(product.length * 0.5)
        origion.add(v1)
        v2.copy(product.left_2_right_unit_vector) 
        v2.multiplyScalar(product.length * 0.5)
        origion.add(v2)  

        var angle = (product.rotate + 90) * Math.PI / 180

        var d = product.length / 2
        size =  d / cos22p5 

        var no = d * 8.2
        var half_way = no / 2
        var ratio = 2.35 + (product.length - 3) * 0.01
        //if(product.length > 6) ratio = 2.0 + (product.step_size - 3) / 10 
        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(origion)
             
            roofing.rotation.set(0, 0, 0)            

            if(j < no) {
                roofing.visible = true
                roofing.rotation.y = angle             
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 
                if(j < half_way) {
                    dd = ratio * j * 0.1                    
                    size = dd * 1.05
                } else {                                      
                    dd = ratio * (no - j + 0.5) * 0.1                    
                    size = dd * 1.05                    
                }
                roofing.scale.z = size    
                v1.multiplyScalar(0.1 * (j - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d)
                ++j
            } else if(jj < no) {
                roofing.visible = true
                roofing.rotation.y = angle               
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 
                if(jj < half_way) {
                    dd = ratio * jj * 0.1                    
                    size = dd * 1.05   
                } else {                                      
                    dd = ratio * (no - jj + 0.5) * 0.1                    
                    size = dd  * 1.05                 
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (jj - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2)
                ++jj
            } else if(k < no) {
                roofing.visible = true
                roofing.rotation.y = angle -  3 * Math.PI / 2                
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope) 
                v1.copy(product.front_2_end_unit_vector) 
                v2.copy(product.left_2_right_unit_vector) 
                if(k < half_way) {
                    dd = ratio * k * 0.1                    
                    size = dd  * 1.05
                } else {                                      
                    dd = ratio * (no - k + 0.5) * 0.1                    
                    size = dd  * 1.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (k - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d)
                ++k
            } else if(kk < no) {
                roofing.visible = true
                roofing.rotation.y = angle -  3 * Math.PI / 2                
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 1 * roof_slope) 
                v1.copy(product.front_2_end_unit_vector) 
                v2.copy(product.left_2_right_unit_vector) 
                if(kk < half_way) {
                    dd = ratio * kk * 0.1                    
                    size = dd  * 1.05 
                } else {                                      
                    dd = ratio * (no - kk + 0.5) * 0.1                    
                    size = dd  * 1.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (kk - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2)
                ++kk
            } else if(l < no) {
                roofing.visible = true
                roofing.rotation.y = angle +  5 * Math.PI / 4                
                roofing.rotateOnWorldAxis(product.front_2_end_45_unit_vector, 15 * roof_slope) 
                v1.copy(product.front_2_end_45_unit_vector) 
                v2.copy(product.left_2_right_45_unit_vector)
                if(l < half_way) {
                    dd = ratio * l * 0.1                    
                    size = dd  * 1.05 
                } else {                                      
                    dd = ratio * (no - l + 0.5) * 0.1                    
                    size = dd  * 1.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (l - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d) 
                ++l
            } else if(ll < no) {                
                roofing.visible = true
                roofing.rotation.y = angle +  5 * Math.PI / 4                               
                roofing.rotateOnWorldAxis(product.front_2_end_45_unit_vector, 1 * roof_slope) 
                v1.copy(product.front_2_end_45_unit_vector) 
                v2.copy(product.left_2_right_45_unit_vector)
                if(ll < half_way) {
                    dd = ratio * ll * 0.1                    
                    size = dd  * 1.05 
                } else {                                      
                    dd = ratio * (no - ll + 0.5) * 0.1                    
                    size = dd  * 1.05                    
                }
                roofing.scale.z = size  
                v1.multiplyScalar(0.1 * (ll - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2)
                ++ll
            } else if(m < no) {
                roofing.visible = true
                roofing.rotation.y = angle + 3 * Math.PI / 4                 
                roofing.rotateOnWorldAxis(product.left_2_right_45_unit_vector, 15 * roof_slope) 
                v1.copy(product.left_2_right_45_unit_vector) 
                v2.copy(product.front_2_end_45_unit_vector)
                if(m < half_way) {
                    dd = ratio * m * 0.1                    
                    size = dd  * 1.05 
                } else {                                      
                    dd = ratio * (no - m + 0.5) * 0.1                    
                    size = dd  * 1.05                    
                }
                roofing.scale.z = size               
                v1.multiplyScalar(0.1 * (m - half_way))
                v2.multiplyScalar(d - size * cos22p5 / 2) 
                ++m
            } else if(mm < no) {                
                roofing.visible = true
                roofing.rotation.y = angle + 3 * Math.PI / 4                 
                roofing.rotateOnWorldAxis(product.left_2_right_45_unit_vector, 1 * roof_slope) 
                v1.copy(product.left_2_right_45_unit_vector) 
                v2.copy(product.front_2_end_45_unit_vector)
                if(mm < half_way) {
                    dd = ratio * mm * 0.1                    
                    size = dd  * 1.05 
                } else {                                      
                    dd = ratio * (no - mm + 0.5) * 0.1                    
                    size = dd  * 1.05                    
                }
                roofing.scale.z = size 
                v1.multiplyScalar(0.1 * (mm - half_way))
                v2.multiplyScalar(size * cos22p5 / 2 - d)
                ++mm
            } else {
                roofing.visible = false
            }
            v1.add(v2)
            roofing.position.add(v1)
            roofing.position.y = product.horizon + product.height + size * sin22p5 / 2 
            
        }
    },
    setup_beams_dutch_patio: function(product) {
        var angle = (product.rotate + 90) * Math.PI / 180
     
        for(var i = 0; i < product.beams_model.length; ++i) {
            var beam = product.beams_model[i]
            beam.position.copy(product.position)
            beam.rotation.set(0, 0, 0)
            
            beam.rotation.y = angle
            
            beam.position.y = product.horizon + product.height

            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)   
            beam.visible = true;

            switch(i) {
                case 0:         
                    beam.scale.set(product.length, 1, 1)           
                    v1.multiplyScalar(product.length / 2)                    
                    v2.multiplyScalar(0.1)                    
                    break;
                case 1:
                    beam.scale.set(product.length, 1, 1)
                    v1.multiplyScalar(product.length / 2)
                    v2.multiplyScalar(product.projection - 0.1)
                    break;    
                case 2:
                    beam.scale.set(product.projection, 1, 1)
                    v1.multiplyScalar(product.length - 0.05)                    
                    v2.multiplyScalar(product.projection / 2)
                    beam.rotation.y += Math.PI / 2
                    break                
            }
            v1.add(v2)
            beam.position.add(v1)
        }
    },
    setup_gutters_dutch_patio: function(product) {
        var size 

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180 
        var angle2 = (product.rotate - 133) * Math.PI / 180

        var projection = product.projection
        var gutter_scale = 1
        
        var d = (projection / 2)       
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.rotation.set(0, 0, 0)
            gutter.visible = true
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)            
            if(i < 4) {                 
                gutter.position.y = product.horizon + product.height
                switch(i) {
                    case 0:
                        gutter.rotation.y = angle                        
                        gutter.scale.set(product.length, gutter_scale, gutter_scale)
                        v1.multiplyScalar(product.length / 2)
                        v2.multiplyScalar(0)
                        break;   
                    case 1:
                        gutter.rotation.y = angle1                          
                        gutter.scale.set((projection), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length)
                        v2.multiplyScalar(projection / 2)
                        break;   
                    case 2:
                        gutter.rotation.y = angle                        
                        gutter.scale.set((product.length), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length / 2)
                        v2.multiplyScalar( 1 * projection)                         
                        break; 
                    case 3:
                        gutter.rotation.y = angle1
                        gutter.scale.set((projection), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(0.05)
                        v2.multiplyScalar(projection / 2)
                        break;      
                                                                                                    
                }  
                v1.add(v2)
                gutter.position.add(v1)
            } else if(i < 8) {
                gutter.rotation.y = angle2 
                size = 0.41 * d * hip_oblique_gutter_ratio  
                gutter.position.y = product.horizon + product.height + size * sin22p5 / 3  + 0.05
                gutter.scale.set(size, gutter_scale, gutter_scale)
                switch(i) {                         
                    case 4:         
                        if(product.symmetric) {
                            gutter.visible = true    
                            gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 7 * roof_slope)                        
                            v1.multiplyScalar(size / 3)
                            v2.multiplyScalar(size / 3 + 0.05)   
                        } else {
                            gutter.visible = false 
                        }
                        break;  
                    case 5:       
                                           
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 9 * roof_slope)                        
                        v1.multiplyScalar(product.length - size / 3)
                        v2.multiplyScalar(projection - size / 3 - 0.05)
                        break;     
                    case 6:                                               
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 1 * roof_slope)                        
                        v1.multiplyScalar(product.length - size / 3)
                        v2.multiplyScalar(size / 3 + 0.05) 
                        break;      
                    case 7:    
                        if(product.symmetric) {
                            gutter.visible = true                    
                            gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope)                       
                            v1.multiplyScalar(size / 3)
                            v2.multiplyScalar(projection - size / 3 - 0.05)    
                        } else {
                            gutter.visible = false 
                        }
                        break;                                                                                                                       
                }  
                v1.add(v2)
                gutter.position.add(v1)  
            } else if(i < 12) {
                gutter.rotation.y = angle1 
                size = 0.6 * d / cos22p5 
                switch(i) {                         
                    case 8:     
                         gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)
                        if(product.symmetric) {    
                            v1.multiplyScalar(0.4 * d + 0.05)
                            v2.multiplyScalar(1.2 * size * cos22p5) 
                            gutter.position.y = product.horizon + product.height + 1.2 * size * sin22p5
                        }  else {
                            size = d / cos22p5
                            v1.multiplyScalar(0.05)
                            v2.multiplyScalar(0.5 * size * cos22p5)  
                            gutter.position.y = product.horizon + product.height + 0.5 * size * sin22p5
                        } 
                        break;  
                    case 9: 
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope)                        
                        v1.multiplyScalar(product.length - 0.4 * d)
                        v2.multiplyScalar(projection - 1.2 * size * cos22p5)
                        gutter.position.y = product.horizon + product.height + 1.2 * size * sin22p5
                        break;     
                    case 10:                        
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)                        
                        v1.multiplyScalar(product.length - 0.4 * d)
                        v2.multiplyScalar(1.2 * size * cos22p5) 
                        gutter.position.y = product.horizon + product.height + 1.2 * size * sin22p5
                        break;      
                    case 11:                           
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope) 
                        if(product.symmetric) {    
                            v1.multiplyScalar(0.4 * d + 0.05)
                            v2.multiplyScalar(projection - 1.2 * size * cos22p5)  
                            gutter.position.y = product.horizon + product.height + 1.2 * size * sin22p5
                        }  else {
                            size = d / cos22p5
                            v1.multiplyScalar(0.05)
                            v2.multiplyScalar(projection - 0.5 * size * cos22p5)   
                            gutter.position.y = product.horizon + product.height + 0.5 * size * sin22p5
                        }       
                        break;                                                                                                                       
                }                   
                gutter.scale.set(size, gutter_scale, gutter_scale)
                v1.add(v2)
                gutter.position.add(v1)  
            } else  {
                gutter.rotation.y = angle
                if(product.symmetric) {
                    size = product.length - 0.8 * d 
                    v1.multiplyScalar(product.length / 2)
                } else {
                    size = product.length - 0.4 * d 
                    v1.multiplyScalar(product.length / 2 - 0.2 * d)
                }                
                v2.multiplyScalar(product.projection / 2)
                v1.add(v2)
                gutter.position.add(v1)   
                gutter.scale.set(size, gutter_scale, gutter_scale)
                gutter.position.y = product.horizon + product.height + d * sin22p5 / cos22p5 + 0.05
            }
        }
    },
    setup_dutch_patio: function(product) {  
        var size, factor, scalar, offsetY, dd

        var j = 1, jj = 1
        var k = 1, kk = 1, kkk = 1, kkkk = 1

        var projection = product.projection
        var d = projection / 2 
        var projection_size = d / cos22p5
        var front_delta_no =  (d / 1) * 4 + 1 // projection_size * sin22p5 * 10 + 1 // (d / sin22p5) * 10 + 1
        var front_len_no = product.length * 10 + 1
        
        var big_delta_no = 2 * d * 10
        var half_big_delta_no = d * 10 + 1

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180      

        var h = 0.4 * d * sin22p5 / cos22p5

        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(product.position)
            roofing.rotation.set(0, 0, 0)
            
            

            if(j < front_len_no) {
                roofing.visible = true
                roofing.rotation.y = angle
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 

                if(j < front_delta_no && product.symmetric) {
                    dd = j * 0.1                    
                    size = (dd)/ cos22p5 + 0.05                     

                } else if((j > front_len_no - front_delta_no)) {                                      
                    dd = (front_len_no - j) * 0.1                    
                    size = (dd / cos22p5) - 0.05                    
                    if(size > projection_size) size = projection_size
                    else if(size < 0.1) roofing.visible = false

                } else {                   
                    size = projection_size
                }
                roofing.scale.z = size               

                factor = size
                scalar =  factor * cos22p5 / 2;
                offsetY = factor * sin22p5 / 2;

                v1.multiplyScalar(0.1 * (j))
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY     
                ++j
            } else if(jj < front_len_no) {
                roofing.visible = true
                roofing.rotation.y = angle
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 
                
                if(jj < front_delta_no && product.symmetric) {
                    dd = jj * 0.1                    
                    size = (dd)/ cos22p5 + 0.05
                    if(size < 0.1) roofing.visible = false
                    scalar =  projection - size * cos22p5 / 2;
                                    
                } else if((jj > front_len_no - front_delta_no)) {
                    dd = (front_len_no - jj) * 0.1
                    size = (dd / cos22p5) - 0.05
                    if(size < 0.1) roofing.visible = false
                   
                    scalar =  projection - size * cos22p5 / 2;  
                } else {                   
                    size = projection_size
                    scalar =  3 * size * cos22p5 / 2;                                        
                }  
                offsetY = size * sin22p5 / 2;  

                roofing.scale.z = size     

                v1.multiplyScalar(0.1 * (jj))
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY   

                ++jj
            } else if((k < big_delta_no) && product.symmetric) {
               roofing.visible = true              
               roofing.rotation.y = angle1
               roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope) 
               v1.copy(product.front_2_end_unit_vector)
               v2.copy(product.left_2_right_unit_vector) 
               
               if(k < half_big_delta_no) {                     
                    size = k * 0.1 / (cos22p5) - 0.05                     
                } else {                       
                    size = (2 * half_big_delta_no - k) * 0.1 / (cos22p5) - 0.25  
                }   
                if(size > 0.5 * d / cos22p5) size = 0.5 * d / cos22p5
                scalar = size / 2.1 //??
                
                offsetY = size * sin22p5 / 2; 

                roofing.scale.z = size   

                v1.multiplyScalar(k * 0.1)
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY  

                ++k                           
            } else if(kk < big_delta_no) {
                roofing.visible = true    

                roofing.rotation.y = angle1
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 1 * roof_slope) 

                v1.copy(product.front_2_end_unit_vector)
                v2.copy(product.left_2_right_unit_vector) 
                
                if(kk < half_big_delta_no) {                     
                     size = kk * 0.1 / (cos22p5) - 0.05  
                      
                 } else {     
                     size = (2 * half_big_delta_no - kk) * 0.1 / (cos22p5) - 0.25     
                 }   
                 if(size > 0.5 * d / cos22p5) size = 0.5 * d / cos22p5

                 scalar = product.length + 0.0 - size / 2.1  
                 offsetY = size * sin22p5 / 2
 
                 roofing.scale.z = size    
 
                 v1.multiplyScalar(kk * 0.1)
                 v2.multiplyScalar(scalar)
                 v1.add(v2)
                 roofing.position.add(v1)
                 roofing.position.y = product.horizon + product.height + offsetY
 
                 ++kk                           
             } else if((kkk < big_delta_no)) {
                roofing.visible = true              
                roofing.rotation.y = angle1
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 12 * roof_slope) 
                v1.copy(product.front_2_end_unit_vector)
                v2.copy(product.left_2_right_unit_vector) 
                
                if(kkk < half_big_delta_no) {                     
                    size = kkk * 0.1 * sin22p5 / cos22p5                      
                } else {                       
                    size = (2 * half_big_delta_no - kkk) * 0.1 * sin22p5 / (cos22p5) - 0.1 
                }     
                
                if(product.symmetric) {
                    if(size < h) roofing.visible = false
                    else size = size - h
                    offsetY = size / 2 + h; 

                } else {
                    offsetY = size / 2
                }
 
                 roofing.scale.z = size
                 v1.multiplyScalar(kkk * 0.1)
                 if(product.symmetric) {
                    v2.multiplyScalar(0.4 * d)
                 } else {
                    v2.multiplyScalar(0.05)
                 }
                 v1.add(v2)
                 roofing.position.add(v1)
                 roofing.position.y = product.horizon + product.height + offsetY 
 
                 ++kkk                           
             } else if(kkkk < big_delta_no) {
                roofing.visible = true    

                roofing.rotation.y = angle1
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 4 * roof_slope) 

                v1.copy(product.front_2_end_unit_vector)
                v2.copy(product.left_2_right_unit_vector) 
                
                if(kkkk < half_big_delta_no) {                     
                     size = kkkk * 0.1 * sin22p5 / (cos22p5)    
                      
                 } else {     
                     size = (2 * half_big_delta_no - kkkk) * 0.1 * sin22p5 / (cos22p5) - 0.1    
                 }   
                 
                 if(size < h) roofing.visible = false
                 else size = size - h

                 scalar = product.length - 0.4 * d 
                 offsetY = size / 2 + h
 
                 roofing.scale.z = size    
                
                 v1.multiplyScalar(kkkk * 0.1)
                 v2.multiplyScalar(scalar)
                 v1.add(v2)
                 roofing.position.add(v1)
                 roofing.position.y = product.horizon + product.height + offsetY
 
                 ++kkkk                           
             } else {
                roofing.visible = false
             }            
        }
    },
    setup_gutters_gable_patio: function(product) {
        var size 

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180 

        var projection = product.projection
        var gutter_scale = 1.3
        
        var d = (projection / 2)       
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.rotation.set(0, 0, 0)
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)   
            gutter.visible = true         
            if(i < 4) {                 
                gutter.position.y = product.horizon + product.height
                switch(i) {
                    case 0:
                        gutter.rotation.y = angle                        
                        gutter.scale.set(product.length, gutter_scale, gutter_scale)
                        v1.multiplyScalar(product.length / 2)
                        v2.multiplyScalar(0)
                        break;   
                    case 1:
                        gutter.rotation.y = angle1                          
                        gutter.scale.set((projection), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length)
                        v2.multiplyScalar(projection / 2)
                        break;   
                    case 2:
                        gutter.rotation.y = angle                        
                        gutter.scale.set((product.length), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length / 2)
                        v2.multiplyScalar( 1 * projection)                         
                        break; 
                    case 3:
                        gutter.rotation.y = angle1
                        gutter.scale.set((projection), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(0.05)
                        v2.multiplyScalar(projection / 2)
                        break;      
                                                                                                    
                }  
                v1.add(v2)
                gutter.position.add(v1)
            } else if(i < 8) {
                gutter.rotation.y = angle1 
                size = d / cos22p5  
                gutter.position.y = product.horizon + product.height + size * sin22p5 * 0.5    
                gutter.scale.set(size, gutter_scale, gutter_scale)
                gutter.visible = true
                switch(i) {                         
                    case 4:                          
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)                        
                        v1.multiplyScalar(0)
                        v2.multiplyScalar(size * cos22p5 / 2)                                            
                        break;  
                    case 5:                         
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope)                        
                        v1.multiplyScalar(product.length)
                        v2.multiplyScalar(projection / 2 + size * cos22p5 / 2)
                        break;     
                    case 6:                        
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)                        
                        v1.multiplyScalar(product.length)
                        v2.multiplyScalar(size * cos22p5 / 2)     
                        break;      
                    case 7:                        
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope)                       
                        v1.multiplyScalar(0.0)
                        v2.multiplyScalar(projection / 2 + size * cos22p5 / 2)    
                        break;                                                                                                                       
                }  
                v1.add(v2)
                gutter.position.add(v1)  
            } else  {
                gutter.rotation.y = angle
                size = product.length 
                gutter.position.y = product.horizon + product.height + d * sin22p5 / cos22p5 + 0.05
                gutter.scale.set(size, gutter_scale, gutter_scale)
                v1.multiplyScalar(product.length / 2)   
                v2.multiplyScalar(product.projection / 2)
                v1.add(v2)
                gutter.position.add(v1)     
            }            
        }
    },
    setup_gable_patio: function(product) {  
        var size, factor, scalar, offsetY

        var j = 0, jj = 0
        var k = 0, kk = 0

        var projection = product.projection
        var d = projection / 2 
        var projection_size = d / cos22p5
        var front_len_no = product.length * 10 
        
        var big_delta_no = 2 * d * 10
        var half_big_delta_no = d * 10 + 1

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180      

        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(product.position)
            roofing.rotation.set(0, 0, 0)
            

            if(j < front_len_no) {
                roofing.visible = true
                roofing.rotation.y = angle
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 

                size = projection_size  

                roofing.scale.z = size               

                factor = size
                scalar =  factor * cos22p5 / 2;
                offsetY = factor * sin22p5 / 2;

                v1.multiplyScalar(0.1 * (j))
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y =product.horizon + product.height + offsetY     
                ++j
            } else if(jj < front_len_no) {
                roofing.visible = true
                roofing.rotation.y = angle
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 
                
                size = projection_size
                scalar =  3 * size * cos22p5 / 2; 
                offsetY = size * sin22p5 / 2;  

                roofing.scale.z = size     

                v1.multiplyScalar(0.1 * (jj))
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY   

                ++jj
            } else if(k < big_delta_no) {
               if(product.gable_type == 2) roofing.visible = false
               else roofing.visible = true               
               roofing.rotation.y = angle1
               roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 12 * roof_slope) 
               v1.copy(product.front_2_end_unit_vector)
               v2.copy(product.left_2_right_unit_vector) 
               
               if(k < half_big_delta_no) {                     
                    size = k * 0.1 * sin22p5 / cos22p5                      
                } else {                       
                    size = (2 * half_big_delta_no - k) * 0.1 * sin22p5 / (cos22p5) - 0.1 
                }      
                
                offsetY = size / 2; 

                roofing.scale.z = size
                v1.multiplyScalar(k * 0.1)

                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY 

                ++k                           
            } else if(kk < big_delta_no) {
                if(product.gable_type > 0) roofing.visible = false
                else roofing.visible = true     

                roofing.rotation.y = angle1
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 4 * roof_slope) 

                v1.copy(product.front_2_end_unit_vector)
                v2.copy(product.left_2_right_unit_vector) 
                
                if(kk < half_big_delta_no) {                     
                     size = kk * 0.1 * sin22p5 / (cos22p5)    
                      
                 } else {     
                     size = (2 * half_big_delta_no - kk) * 0.1 * sin22p5 / (cos22p5) - 0.1    
                 }   
                 scalar = product.length 
                 offsetY = size / 2
 
                 roofing.scale.z = size    
                
                 v1.multiplyScalar(kk * 0.1)
                 v2.multiplyScalar(scalar)
                 v1.add(v2)
                 roofing.position.add(v1)
                 roofing.position.y = product.horizon + product.height + offsetY
 
                 ++kk                           
             } else {
                roofing.visible = false
             }
            
        }
    },
    setup_gutters_hip_patio: function(product) {
        var size 

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180 
        var angle2 = (product.rotate - 133) * Math.PI / 180
        //var angle3 = (product.rotate - 137) * Math.PI / 180

        var projection = product.projection
        var gutter_scale = 1.2
        
        var d = (projection / 2)       
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.rotation.set(0, 0, 0)
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)     
            gutter.visible = true       
            if(i < 4) {                 
                gutter.position.y = product.horizon + product.height
                switch(i) {
                    case 0:
                        gutter.rotation.y = angle                        
                        gutter.scale.set(product.length, gutter_scale, gutter_scale)
                        v1.multiplyScalar(product.length / 2)
                        v2.multiplyScalar(0)
                        break;   
                    case 1:
                        gutter.rotation.y = angle1                          
                        gutter.scale.set((projection), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length)
                        v2.multiplyScalar(projection / 2)
                        break;   
                    case 2:
                        gutter.rotation.y = angle                        
                        gutter.scale.set((product.length), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length / 2)
                        v2.multiplyScalar( 1 * projection)                         
                        break; 
                    case 3:
                        gutter.rotation.y = angle1
                        gutter.scale.set((projection), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(0.05)
                        v2.multiplyScalar(projection / 2)
                        break;      
                                                                                                    
                }  
                v1.add(v2)
                gutter.position.add(v1)
            } else if(i < 8) {
                
                switch(i) {                         
                    case 4:   
                        if(product.symmetric) {
                            gutter.rotation.y = angle2 
                            size = d * hip_oblique_gutter_ratio  
                            gutter.position.y = product.horizon + product.height + size * sin22p5 / 3  + 0.08                    
                            gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 7 * roof_slope)                        
                            v1.multiplyScalar(size *0.335)
                            v2.multiplyScalar(size / 3)
                        } else {
                            gutter.rotation.y = angle1
                            size = d / cos22p5  
                            gutter.position.y = product.horizon + product.height + size * sin22p5 / 2 
                            gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)                        
                            v1.multiplyScalar(0.05)
                            v2.multiplyScalar(size * cos22p5 / 2)     
                        }                                                                    
                        break;  
                    case 5:      
                        gutter.rotation.y = angle2  
                        size = d * hip_oblique_gutter_ratio  
                        gutter.position.y = product.horizon + product.height + size * 0.14                  
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 9 * roof_slope)                        
                        v1.multiplyScalar(product.length - projection / 2 + size * 0.34)
                        v2.multiplyScalar(projection / 2 + size / 3)
                        break;     
                    case 6: 
                        gutter.rotation.y = angle2 
                        size = d * hip_oblique_gutter_ratio  
                        gutter.position.y = product.horizon + product.height + size * 0.14                     
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 1 * roof_slope)                        
                        v1.multiplyScalar(product.length - projection / 2 + size * 0.34)
                        v2.multiplyScalar(size / 3) 
                        break;      
                    case 7:     
                        if(product.symmetric) {
                            gutter.rotation.y = angle2       
                            size = d * hip_oblique_gutter_ratio  
                            gutter.position.y = product.horizon + product.height + size * 0.14            
                            gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope)                       
                            v1.multiplyScalar(size * 0.35)
                            v2.multiplyScalar(projection / 2 + size / 3)  
                        } else {
                            gutter.rotation.y = angle1
                            size = d / cos22p5  
                            gutter.position.y = product.horizon + product.height + size * sin22p5 / 2 
                            gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope)                       
                            v1.multiplyScalar(0.05)
                            v2.multiplyScalar(projection / 2 + size * cos22p5 / 2)    
                        }
                          
                        break;                                                                                                                       
                }  
                gutter.scale.set(size, gutter_scale, gutter_scale)
                v1.add(v2)
                gutter.position.add(v1)  
            } else  {
                gutter.rotation.y = angle
                gutter.position.y = product.horizon + product.height + d * sin22p5 / cos22p5 + 0.038
                if(product.symmetric) {
                    size = product.length - 2 * d                    
                    v1.multiplyScalar(product.length / 2)
                } else {
                    size = product.length - 1 * d 
                    v1.multiplyScalar(product.length / 2 - d / 2)
                }
                gutter.scale.set(size, gutter_scale, gutter_scale)
                v2.multiplyScalar(product.projection / 2)
                v1.add(v2)
                gutter.position.add(v1)                          

            }
            
        }
    },
    setup_hip_patio: function(product) {  
        var size, factor, scalar, offsetY, dd

        var j = 0, jj = 0
        var k = 0, kk = 0

        var length = product.length
        var projection = product.projection
        var d = projection / 2 
        var projection_size = d / cos22p5
        var front_delta_no =  (d / 1) * 10 + 1 // projection_size * sin22p5 * 10 + 1 // (d / sin22p5) * 10 + 1
        var front_len_no = length * 10 + 1
        
        var big_delta_no = 2 * d * 10
        var half_big_delta_no = d * 10 + 1

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180      

        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(product.position)
            roofing.rotation.set(0, 0, 0)
            

            if(j < front_len_no) {
                roofing.visible = true
                roofing.rotation.y = angle
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 

                if(j < front_delta_no && product.symmetric) {
                    dd = j * 0.1                    
                    size = dd / cos22p5
                } else if(j > front_len_no - front_delta_no) {                                      
                    dd = (front_len_no - j - 1) * 0.1                    
                    size = dd / cos22p5                

                } else {                   
                    size = projection_size
                    if(j == 0) roofing.visible = false 
                }   

                roofing.scale.z = size               

                factor = size
                scalar =  factor * cos22p5 / 2;
                offsetY = factor * sin22p5 / 2;

                v1.multiplyScalar(0.1 * (j))
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY     
                ++j
            } else if(jj < front_len_no) {
                roofing.visible = true
                roofing.rotation.y = angle
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope) 
                v1.copy(product.left_2_right_unit_vector) 
                v2.copy(product.front_2_end_unit_vector) 
                
                if(jj < front_delta_no && product.symmetric) {
                    dd = jj * 0.1                    
                    size = dd / cos22p5 + 0.05
                    scalar =  projection - size * cos22p5 / 2;      
                    if(jj == 0) roofing.visible = false          
                } else if(jj > front_len_no - front_delta_no) {
                    dd = (front_len_no - jj) * 0.1
                    size = dd / cos22p5 
                   
                    scalar =  projection - size * cos22p5 / 2;  
                } else {                   
                    size = projection_size
                    scalar =  3 * size * cos22p5 / 2;    
                    if(jj == 0) roofing.visible = false                                    
                }  
                offsetY = size * sin22p5 / 2;  

                roofing.scale.z = size     

                v1.multiplyScalar(0.1 * (jj))
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY   

                ++jj
            } else if(k < big_delta_no) {
               roofing.visible = true              
               roofing.rotation.y = angle1
                
               v1.copy(product.front_2_end_unit_vector)
               v2.copy(product.left_2_right_unit_vector) 
               
               if(product.symmetric) {
                    roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope)
                    if(k < half_big_delta_no) {                     
                        size = k * 0.1 / cos22p5                   
                    } else {                       
                        size = (big_delta_no - k - 0.5) * 0.1 / cos22p5 + 0.05 
                    }   
                    scalar = size * cos22p5 / 2

                    offsetY = size * sin22p5 / 2; 
               } else {
                    roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 12 * roof_slope)
                    if(k < half_big_delta_no) {                     
                        size = k * 0.1 * sin22p5 / cos22p5 + 0.05    
                         
                    } else {     
                        size = (big_delta_no - k - 0.5) * 0.1 * sin22p5 / cos22p5 + 0.05   
                    }   
                    scalar = 0.05   
                    offsetY = size / 2                
               }               

                roofing.scale.z = size 
                //roofing.scale.y = 1.1      

                v1.multiplyScalar(k * 0.1)
                v2.multiplyScalar(scalar)
                v1.add(v2)
                roofing.position.add(v1)
                roofing.position.y = product.horizon + product.height + offsetY  

                ++k                           
            } else if(kk < big_delta_no) {
                roofing.visible = true    

                roofing.rotation.y = angle1
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 1 * roof_slope) 

                v1.copy(product.front_2_end_unit_vector)
                v2.copy(product.left_2_right_unit_vector) 
                
                if(kk < half_big_delta_no) {                     
                     size = kk * 0.1 / (cos22p5) + 0.05    
                      
                 } else {     
                     size = (big_delta_no - kk - 0.5) * 0.1 / (cos22p5) + 0.05   
                 }   
                 scalar = length - size * cos22p5 / 2 
                 offsetY = size * sin22p5 / 2
 
                 roofing.scale.z = size    
                 //roofing.scale.x = 1.1 
 
                 v1.multiplyScalar(kk * 0.1)
                 v2.multiplyScalar(scalar)
                 v1.add(v2)
                 roofing.position.add(v1)
                 roofing.position.y = product.horizon + product.height + offsetY
 
                 ++kk                           
             } else {
                roofing.visible = false
             }
            
        }
    },    
    // Angled
    setup_roofing_angled_right_side(product) {

        var v1 = new THREE.Vector3();
        var v2 = new THREE.Vector3();

        var roofing_notch_rate = 4      
        if(product.profile > 9) roofing_notch_rate = 10

        var yAngle = (product.rotate + 90) * Math.PI / 180
        var zAngle = (product.angle) * Math.PI / 180

        var scalar, factor, offsetY, scale, itemNo, tg
        var len = product.length

        if(product.length != product.back_length) {
            if(product.back_length > product.length) {
                len = product.back_length            
            }
            tg = product.projection / (product.length - product.back_length)
        } else {
            tg = 0
        }  

        for(var i = 0; i < product.roofing_model.length; ++i) {

            product.roofing_model[i].rotation.set(0, 0, 0)
            product.roofing_model[i].rotation.y = yAngle
            //product.roofing_model[i].rotation.x = (product.angle) * Math.PI / 180
            product.roofing_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, zAngle) 
        
            itemNo = ((i + 1) / roofing_notch_rate)
            if(itemNo >= len) {
                product.roofing_model[i].visible = false;
            } else {
                product.roofing_model[i].visible = true;
                product.roofing_model[i].position.copy(product.position)                
                
                v1.copy(product.left_2_right_unit_vector) 
                //var scalar = (i + 1) / roofing_notch_rate
                v1.multiplyScalar(itemNo)
               
                v2.copy(product.front_2_end_unit_vector) 

                if(product.length > product.back_length) {
                    if(itemNo >= product.back_length) {
                        scale = product.projection - (itemNo - product.back_length) * tg + 0.03
                        product.roofing_model[i].scale.set(1, 1, scale * 1.01)
                        factor = scale                     
                    } else {
                        product.roofing_model[i].scale.set(1, 1, product.projection * 1.01)
                        factor = product.projection                       
                    }
                } else {
                    if(itemNo >= product.length) {
                        scale = product.projection - (product.length - itemNo) * tg + 0.058
                        product.roofing_model[i].scale.set(1, 1, scale * 1.01)
                        factor = 2 * product.projection - scale  
                    } else {
                        product.roofing_model[i].scale.set(1, 1, product.projection * 1.01)
                        factor = product.projection                       
                    }
                }         
                
                scalar =  factor * product.cos_angle / 2;
                offsetY = factor * product.sin_angle / 2;

                v2.multiplyScalar(scalar)
                v2.add(v1)

                product.roofing_model[i].position.add(v2)                

                var zOffset = + 0.01 * (product.roofing_type) * product.sin_angle
                var y = product.horizon + product.height + beam_height * product.roofing_type + 0.01 * (product.roofing_type) * product.cos_angle + posts_gap + offsetY 
                product.roofing_model[i].position.z += zOffset
                product.roofing_model[i].position.y = y
            }
        }
    },
    setup_ceiling_angled_right_side: function(product) {
        var roofing_notch_rate = 4      
        if(product.profile > 9) roofing_notch_rate = 10

        var offset = -0.01
        var factor, scalar, offsetY

        var len = product.length
        if(product.back_length > product.length) len = product.back_length

        for(var i = 0; i < product.ceiling_model.length; ++i) {

            product.ceiling_model[i].position.copy(product.position)
            product.ceiling_model[i].rotation.set(0, 0, 0)            
            product.ceiling_model[i].visible = false
            //product.ceiling_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
        
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)             
            switch(i) {
                case 0: 
                    product.ceiling_model[i].visible = true      

                    var step_fraction = (len - Math.floor(len)) 
                    var fraction = step_fraction - (Math.floor(step_fraction * roofing_notch_rate)) / roofing_notch_rate
                    if(fraction < 0.1)  fraction = (1 / roofing_notch_rate) - 0.1
                    
                    var ang = Math.PI / 2
                    if(product.length != product.back_length) ang = Math.atan(product.projection / (product.length - product.back_length))
                    product.ceiling_model[i].rotation.y = (product.rotate + 180) * Math.PI / 180 - ang
                    factor = product.projection / Math.sin(ang)    

                    product.ceiling_model[i].scale.set(fraction, 0.02 * product.roofing_type, factor)
                    
                    v1.multiplyScalar(len - fraction / 2 - 0.05 - Math.abs(factor * Math.cos(ang) / 2))
                    
                    factor = (product.projection - product.front_overhang * product.projection)
                    scalar = factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;
                        
                    v2.multiplyScalar(scalar)
                    v1.add(v2)  
    
                    product.ceiling_model[i].position.add(v1)          
                    
                    var y = product.horizon + product.height + beam_height * product.roofing_type + 0.01 * (product.roofing_type) * product.cos_angle + posts_gap + offsetY 
                                     
                    product.ceiling_model[i].position.y = y - i * 0.0035
                    break;                                                      
            }
        }
    },
    setup_gutters_angled_right_side: function(product) {
        for(var i = 0; i < product.gutters_model.length; ++i) {
            product.gutters_model[i].position.copy(product.position)
            if((i == 1 || i == 2) && product.step_length == 0) {
                product.gutters_model[i].visible = false
            } else product.gutters_model[i].visible = true                     
        }
        var v1 = new THREE.Vector3();
        var v2 = new THREE.Vector3();
        var scalar, factor, offsetY
        var scale = product.roofing_type
        
        for(var i = 0; i < product.gutters_model.length; ++i) {

            product.gutters_model[i].rotation.set(0, 0, 0)
            if(i != 4) {
                product.gutters_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180
                if(i % 2 == 0) product.gutters_model[i].rotation.y += Math.PI / 2
            }            
            //product.gutters_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180)         
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 
            
            switch(i) {
                case 0:                    
                    product.gutters_model[i].scale.set(product.projection, scale, scale)
                    factor = (1 - product.front_overhang) * product.projection   
                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;
                    v1.set(0, 0, 0)
                    break;
                case 1:
                case 2: 
                    product.gutters_model[i].visible = false
                    break; 
                case 3:
                    product.gutters_model[i].scale.set(product.back_length, scale, scale)
                    factor = product.projection + (1 - product.front_overhang) * product.projection 
                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;  
                    v1.multiplyScalar(product.back_length / 2)
                    break; 
                case 4:
                    var ang = Math.PI / 2
                    if(product.length != product.back_length) ang = Math.atan(product.projection / (product.length - product.back_length))
                    product.gutters_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180 - ang
                    factor = product.projection / Math.sin(ang)
                    product.gutters_model[i].scale.set(factor, scale, scale)                     

                    scalar =  product.projection / 2
                    offsetY = factor * product.sin_angle / 2;                      
                    
                    v1.multiplyScalar(product.length - (product.length - product.back_length) / 2)
               
                    break;
                case 5:
                    product.gutters_model[i].scale.set(product.length, scale, scale)
                    factor = -1 * product.front_overhang * product.projection 

                    scalar =  factor * product.cos_angle / 2;
                    offsetY = factor * product.sin_angle / 2;                    
                    
                    v1.multiplyScalar(product.length / 2)
               
                    break;                                        
            }
            v2.multiplyScalar(scalar)
            v2.add(v1)

            product.gutters_model[i].position.add(v2)
            product.gutters_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2 + offsetY
        }
    },
    set_beams_position_angled_right_side: function(product) {
        // locate       
        var scale = product.roofing_type
        for(var i = 0; i < product.beams_model.length; ++i) {
           
            product.beams_model[i].position.copy(product.position)
            product.beams_model[i].position.y = product.horizon + product.height + beam_height * product.roofing_type + posts_gap / 2
            v2.copy(product.left_2_right_unit_vector) 
            v1.copy(product.front_2_end_unit_vector) 
            switch(i) {
                case 0:
                    product.beams_model[i].scale.set(product.length, scale, scale) 
                    product.beams_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180

                    scalar = 1 *  product.length / 2;
                    v2.multiplyScalar(scalar)

                    product.beams_model[i].position.add(v2)                    
                    break;
                case 1:
                    var distance, scalar
                    product.beams_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180
                    if(product.type > 12 & product.type < 17) {
                        product.beams_model[i].scale.set(product.back_length, scale, scale) 
                        distance = product.projection;
                        scalar = product.cos_angle * distance
                        v1.multiplyScalar(scalar)    
                        v2.multiplyScalar(product.back_length / 2)
                        v2.add(v1)
                    } else {
                        product.beams_model[i].scale.set(product.step_length, scale, scale) 
                        distance = product.step_size + (0 - product.front_overhang - product.step_overhang) * product.projection / 2;
                        scalar = product.cos_angle * distance
                        v1.multiplyScalar(scalar)    
                        v2.multiplyScalar(product.step_length / 2)
                        v2.add(v1) 
                    }    
                                  
                    product.beams_model[i].position.add(v2)  

                    product.beams_model[i].position.y += product.sin_angle * distance
                    break;
                case 2:
                    var angle = Math.atan((product.length - product.back_length) / product.projection) 
                    product.beams_model[i].scale.set(Math.pow(Math.pow(product.projection, 2) + Math.pow(product.length - product.back_length, 2), 0.5), scale, scale)  
                    product.beams_model[i].rotation.y = (product.rotate) * Math.PI / 180 + angle

                    var distance = (1 - product.front_overhang - product.back_overhang) * product.projection / 2;

                    var scalar = product.cos_angle * distance
                    v1.multiplyScalar(scalar)

                    v2.multiplyScalar((product.length + product.back_length) / 2 - 0.1)
                    v2.add(v1)
                                  
                    product.beams_model[i].position.add(v2)  

                    product.beams_model[i].position.y += product.sin_angle * distance
                    break;
            }
        }
    },
    setup_beams_decking: function(product) {
        var j = 0
        var length = product.projection
        var projection = product.length

        var angle = (product.rotate + 90) * Math.PI / 180
     
        for(var i = 0; i < product.beams_model.length; ++i) {
            var beam = product.beams_model[i]
            beam.position.copy(product.position)
            beam.rotation.set(0, 0, 0)
            beam.position.y = product.height 

            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)  
            beam.visible = true;
            var no = Math.ceil(projection * 2)
            if(i <= no) { 
                beam.visible = true                
                beam.rotation.y = angle
                beam.scale.set(length, 1, 1)                 
    
                v1.multiplyScalar(length / 2)                    
                v2.multiplyScalar(i * projection / no)
               
            } else {
                beam.rotation.y = angle + Math.PI / 2
                beam.scale.set(projection, 1, 1)
                v2.multiplyScalar(projection / 2)
                switch(j++) {
                    case 0:
                        beam.visible = true 
                        v1.multiplyScalar(0) 
                        break;
                    case 1:
                        beam.visible = true  
                        v1.multiplyScalar(length)  
                        break;
                    case 2:
                        if(length > 8.3) {
                            beam.visible = true
                            v1.multiplyScalar(length / 3)
                        } else if(length > 4.1) {
                            beam.visible = true
                            v1.multiplyScalar(length / 2)
                        } else {
                            beam.visible = false
                        }                        
                        break;
                    case 3:
                        if(length > 8.3) {
                            beam.visible = true
                            v1.multiplyScalar(2 * length / 3)
                        } else {
                            beam.visible = false
                        }  
                        break;
                    default:
                        beam.visible = false
                        break;
                }
            } 
            v1.add(v2)
            beam.position.add(v1)          
        }
    },
    setup_posts_decking: function(product) {
        // work out the front posts number
        var length = product.projection
        var projection = product.length

        if(product.forced_front_posts_no == 0) {
            product.front_posts_no = flyover_module.calculate_automatic_posts_no(product)
        } else {
            product.front_posts_no = product.forced_front_posts_no;
        }
            
        if(product.front_posts_no_buffer != product.front_posts_no) {
            product.front_posts_no_buffer = product.front_posts_no
            gui_module.front_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < gui_module.front_post_offset_gui.length; ++i) {                
                if(i < product.front_posts_no - 1) gui_module.front_post_offset_gui[i].setValue(0) 
                else gui_module.front_post_offset_gui[i].setValue(40)             
            }  
        }        

        product.roofing_type = 1;
        if(Math.floor(projection) == 6) {
            product.roofing_type = 1.25;
        } else if(Math.floor(projection) > 6) {
            product.roofing_type = 1.5;
        }
    
        var front_post_pitch = projection / (product.front_posts_no * 2)
        var scalar      
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            var base1 = product.posts_base_model[i]
            var base2 = product.posts_base_model[i + 24]
            base1.visible = true
            base2.visible = true

            obj.scale.set(product.front_post_width, product.height - posts_gap, product.front_post_width);
         
            v1.copy(product.front_2_end_unit_vector) 
            v2.copy(product.left_2_right_unit_vector)
            

            if(i < product.front_posts_no) {
                if(product.forced_front_posts_no > 8) obj.visible = false
                else {
                    obj.visible = true
                    v2.multiplyScalar(length)
                    scalar = (1 + 2 * (i + product.front_posts_offset[i % product.front_posts_no])) * front_post_pitch;
                }
            } else if(i < 2 * product.front_posts_no) {
                if(product.forced_back_posts_no > 8) obj.visible = false
                else {
                    obj.visible = true
                    v2.multiplyScalar(0)
                    scalar = (1 + 2 * (i -  1 * product.front_posts_no + product.front_posts_offset[i % product.front_posts_no])) * front_post_pitch                    
                }
            } else if(i < 3 * product.front_posts_no) {
                if(product.forced_front_posts_no > 8) obj.visible = false
                else {
                    obj.visible = true
                    if(length > 8.3) v2.multiplyScalar(length / 3)
                    else if(length > 4.1) v2.multiplyScalar(length / 2)
                    else {
                        obj.visible = false
                        base1.visible = false
                        base2.visible = false
                    }
                    scalar = (1 + 2 * (i - 2 * product.front_posts_no + product.front_posts_offset[i % product.front_posts_no])) * front_post_pitch;
                }
            } else if(i < 4 * product.front_posts_no){    
                if(product.forced_front_posts_no > 8) obj.visible = false
                else {
                    obj.visible = true            
                    if(length > 8.3) v2.multiplyScalar(2 * length / 3)
                    else {
                        obj.visible = false
                        base1.visible = false
                        base2.visible = false
                    }
                    scalar = (1 + 2 * (i - 3 * product.front_posts_no + product.front_posts_offset[i % product.front_posts_no])) * front_post_pitch;
                }
            } else {
                obj.visible = false
                base1.visible = false
                base2.visible = false
            }
            v1.multiplyScalar(scalar)
            v1.add(product.position)
            v1.add(v2)

            v1.y += (product.height) / 2;            
                             
            obj.position.copy(v1)

            v1.y = 0;
            base1.position.copy(v1)            
            base2.scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
            v1.y = product.front_post_width / 2 
            base2.position.copy(v1)
        } 
    },
    setup_rafters(product) {    
        var scale = 0.1
        
       if(product.rafters_no_buffer != product.forced_rafters_no) {
            product.rafters_no_buffer = product.forced_rafters_no
            if(product.forced_rafters_no == 0 || product.forced_rafters_no > 8) {
                for(var i = 0; i < gui_module.rafter_offset_gui.length; ++i) {                
                    gui_module.rafter_offset_gui[i].setValue(0)     
                } 
            } else {
                var s = Math.ceil(product.front_overhang * 100)
                var e = Math.ceil((1 - product.back_overhang - product.front_overhang) * 100)
                var st = (e - s) / (product.forced_rafters_no + 1)
                for(var i = 0; i < gui_module.rafter_offset_gui.length; ++i) {                
                    if(i < product.forced_rafters_no) gui_module.rafter_offset_gui[i].setValue(-50 + s + (i + 1) * st) 
                    else  gui_module.rafter_offset_gui[i].setValue(0)     
                } 
            }
        } 

        for(var i = 0; i < product.rafters_model.length; ++i) {
            product.rafters_model[i].rotation.set(0, 0, 0)
            product.rafters_model[i].rotation.y = (product.rotate + 90) * Math.PI / 180
            product.rafters_model[i].rotateOnWorldAxis(product.left_2_right_unit_vector, (product.angle) * Math.PI / 180) 
            product.rafters_model[i].position.copy(product.position)
            product.rafters_model[i].position.y = product.horizon + product.height - 0.2
            v2.copy(product.left_2_right_unit_vector) 
            v1.copy(product.front_2_end_unit_vector) 
            switch(i) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    var length = product.length
                    var centre = product.length / 2
                    var rafter_off =  product.rafter_offset[i]
                    switch(product.type) {
                        case 4: //hip
                        case 5:
                            if(product.symmetric) {
                                if(rafter_off > 0) length -= (1 - 2 * rafter_off) * product.projection 
                                else length -= (2 * rafter_off + 1) * product.projection 
                            } else {
                                if(rafter_off > 0) length -= (0.5 - rafter_off) * product.projection 
                                else length -= (rafter_off + 0.5) * product.projection 
                                centre = length / 2 + 0.1
                            }
                            break
                        case 10:
                        case 11:
                            if(product.symmetric) {
                                if(rafter_off < -0.25 || rafter_off > 0.25) {
                                    if(rafter_off > 0) length -= (1 - 2 * rafter_off) * product.projection 
                                    else length -= (2 * rafter_off + 1) * product.projection 
                                } else length -= product.projection / 2
                            } else {
                                if(rafter_off < -0.25 || rafter_off > 0.25) {
                                    if(rafter_off > 0) length -= (0.5 - rafter_off) * product.projection 
                                    else length -= (rafter_off + 0.5) * product.projection 
                                } else length -= product.projection / 4 
                                centre = length / 2 + 0.1
                            }
                            break
                    }
                    if(i >= product.forced_rafters_no || product.forced_rafters_no > 8) {
                        product.rafters_model[i].visible = false
                    } else {
                        product.rafters_model[i].visible = true
                        product.rafters_model[i].scale.set(length, scale, scale) 
                        
                        if(rafter_off < 0) v1.multiplyScalar(product.projection * (0.5 + rafter_off) * cos22p5)
                        else v1.multiplyScalar(product.projection * (0.58 + rafter_off) * cos22p5)
                        var scalar = centre 
                        v2.multiplyScalar(scalar)
                        v2.add(v1)
    
                        product.rafters_model[i].position.add(v2) 
                        if(rafter_off < 0) product.rafters_model[i].position.y += product.projection * (0.5 + rafter_off) * sin22p5
                        else product.rafters_model[i].position.y += product.projection * (0.5 - rafter_off) * sin22p5
                    }               
                    break
                default:
                    product.rafters_model[i].visible = false
                    break
            }
        }
    },
    setup_purlins(product) {
        var scale = 0.1
        
        if(product.purlins_no_buffer != product.forced_purlins_no) {
             product.purlins_no_buffer = product.forced_purlins_no
             if(product.forced_purlins_no == 0 || product.forced_purlins_no > 8) {
                 for(var i = 0; i < gui_module.purlin_offset_gui.length; ++i) {                
                     gui_module.purlin_offset_gui[i].setValue(0)     
                 } 
             } else {
                 var st = 100 / (product.forced_purlins_no + 1)
                 for(var i = 0; i < gui_module.purlin_offset_gui.length; ++i) {                
                     if(i < product.forced_purlins_no) gui_module.purlin_offset_gui[i].setValue(-50 + (i + 1) * st) 
                     else  gui_module.purlin_offset_gui[i].setValue(0)     
                 } 
             }
         } 
 
         for(var i = 0; i < product.purlins_model.length; ++i) {
            var obj = product.purlins_model[i]
            var off = product.purlin_offset[i % 6]
             obj.rotation.set(0, 0, 0)
             obj.rotation.y = (product.rotate + 180) * Math.PI / 180
             var ang = 22.5 / 180
             if(i > 5) ang = -22.5 / 180
             obj.rotateOnWorldAxis(product.left_2_right_unit_vector, ang * Math.PI) 
             obj.position.copy(product.position)
             obj.position.y = product.horizon + product.height
             v2.copy(product.left_2_right_unit_vector) 
             v1.copy(product.front_2_end_unit_vector) 
             switch(i) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    if(product.forced_purlins_no > 8) {
                         obj.visible = false
                    } else if(i >= product.forced_purlins_no && i < 6){
                        obj.visible = false
                    } else if(i >= 6 + product.forced_purlins_no){
                        obj.visible = false
                    } else {
                        var len = product.projection * 0.52
                        obj.visible = true
                        obj.scale.set(len, scale, scale) 
                         
                        var offset = product.length * (0.5 + off)
                        v2.multiplyScalar(offset)
                        var scalar = len / 2 
                        if(i > 5) v1.multiplyScalar((scalar + len) * cos22p5)
                        else v1.multiplyScalar(scalar * cos22p5)
                        v2.add(v1)
     
                        obj.position.add(v2) 
                        obj.position.y += scalar * sin22p5 - 0.22
                    }               
                    break
                 default:
                     obj.visible = false
                     break
             }
         }
    },
    set_forced_front_posts_no: function(product, no) {
        product.forced_front_posts_no = no;
        gable_hip_module.setup(product)
    },
    set_front_post_offset: function(front_post_index, product, front_post_offset) {
        product.front_posts_offset[front_post_index] = front_post_offset;
        gable_hip_module.setup(product)
    },
    set_front_post_width: function(product, value) {
        product.front_post_width = value;
        flyover_module.posts_color(product, gui_module.posts)
        gable_hip_module.setup(product)
    },
    set_forced_back_posts_no: function(product, no) {
        product.forced_back_posts_no = no;
        gable_hip_module.setup(product)
    },
    set_forced_right_posts_no: function(product, no) {
        product.forced_right_posts_no = no;
        gable_hip_module.setup(product)
    },
    set_forced_left_posts_no: function(product, no) {
        product.forced_left_posts_no = no;
        flyover_module.setup(product)
    },
    set_back_post_offset: function(back_post_index, product, back_post_offset) {
        product.back_posts_offset[back_post_index] = back_post_offset;
        gable_hip_module.setup(product)
    },
    set_right_post_offset: function(right_post_index, product, right_post_offset) {
        product.right_posts_offset[right_post_index] = right_post_offset;
        gable_hip_module.setup(product)
    },
    set_left_post_offset: function(left_post_index, product, left_post_offset) {
        product.left_posts_offset[left_post_index] = left_post_offset;
        gable_hip_module.setup(product)
    },
    move: function(product, position) {
        product.position.copy(position)
        product.position.y = 0        
        gable_hip_module.setup(product)
    },
    ray_move: function(product, object, position) {
        if(product.lock) return
        //console.log("Hi")
        var got_flag = flyover_module.ray_move_posts_check(product, object, position, product.front_posts_model, gui_module.front_post_offset_gui);
        if(!got_flag) got_flag = flyover_module.ray_move_posts_check(product, object, position, product.back_posts_model, gui_module.back_post_offset_gui);
        if(!got_flag) got_flag = flyover_module.ray_move_posts_check(product, object, position, product.decking_posts_model, gui_module.decking_post_offset_gui);
        if(!got_flag) {
            gable_hip_module.move(product, position)
            got_flag = false
            sceneItems.controler.target.set(position.x + product.length / 2, product.height, position.z);
        }    
    },
    profile: function(product, profile) {
        product.profile = profile
        gable_hip_module.add_roofing(product)
        gable_hip_module.setup(product)
    },
    length: function(product, length) {
        product.length = length;
        gable_hip_module.setup(product) 
    },
    back_length: function(product, back_length) {
        product.back_length = back_length;
        gable_hip_module.setup(product) 
    },
    projection: function(product, projection) {
        product.projection = projection;
        gable_hip_module.setup(product)
    },
    height: function(product, height) {
        product.height = height;
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            obj.scale.set(product.front_post_width, product.height, product.front_post_width);
        } 
        gable_hip_module.setup(product)
    },
    set_horizon: function(product, horizon) {
        product.horizon = horizon;
        product.landscaping_height = horizon;
        gable_hip_module.check_landscaping_horizon(product)
        gable_hip_module.setup(product)
    },
    step_length: function(product, step_length) {
        product.step_length = step_length;
        gable_hip_module.setup(product)
    },
    step_size: function(product, step_size) {
        product.step_size = step_size;
        gable_hip_module.setup(product)
    },
    front_overhang: function(product, overhang) {
        product.front_overhang = overhang;
        gable_hip_module.setup(product)
    },
    gable_type: function(product, type) {
        product.gable_type = type
        gable_hip_module.setup(product)
    },
    rotate: function(product, rotate) {
        product.rotate = rotate;
        var view_radian = Math.PI * rotate / 180
        var cos_view = Math.cos(view_radian);
        var sin_view = Math.sin(view_radian);
        product.left_2_right_unit_vector.set(sin_view, 0, cos_view)
        product.front_2_end_unit_vector.set(cos_view, 0, -1 * sin_view)
        product.angle_60 = (product.rotate + 90) * Math.PI / 180 + Math.PI / 3

        product.front_2_end_60_unit_vector.set(Math.cos(product.angle_60), 0, -1 * Math.sin(product.angle_60))
        product.left_2_right_60_unit_vector = new THREE.Vector3(Math.sin(product.angle_60), 0, Math.cos(product.angle_60))

        product.angle_120 = (product.rotate + 90) * Math.PI / 180 + 2 * Math.PI / 3
        product.front_2_end_120_unit_vector.set(Math.cos(product.angle_120), 0, -1 * Math.sin(product.angle_120))
        product.left_2_right_120_unit_vector.set(Math.sin(product.angle_120), 0, Math.cos(product.angle_120))
        
        product.angle_45 = (product.rotate + 90) * Math.PI / 180 + Math.PI / 4
        product.front_2_end_45_unit_vector.set(Math.cos(product.angle_45), 0, -1 * Math.sin(product.angle_45))
        product.left_2_right_45_unit_vector.set(Math.sin(product.angle_45), 0, Math.cos(product.angle_45))

        if(product.type < 32) {
            for(var i = 0; i < product.front_posts_model.length; ++i) {
                var obj = product.front_posts_model[i]
                obj.rotation.y = view_radian
            }
    
            for(var i = 0; i < product.posts_base_model.length; ++i) {
                var obj = product.posts_base_model[i]
                obj.rotation.y = view_radian
            }
            for(var i = 0; i < product.back_posts_model.length; ++i) {
                var obj = product.back_posts_model[i]
                obj.rotation.y = view_radian
            }
            for(var i = 0; i < product.right_posts_model.length; ++i) {
                var obj = product.right_posts_model[i]
                obj.rotation.y = view_radian
            }
            for(var i = 0; i < product.left_posts_model.length; ++i) {
                var obj = product.left_posts_model[i]
                obj.rotation.y = view_radian
            }
            for(var i = 0; i < product.brackets_model.length; ++i) {
                var obj = product.brackets_model[i]
                obj.rotation.y = view_radian
            }
        }
        
        for(var i = 0; i < product.decking_posts_model.length; ++i) {
            var obj = product.decking_posts_model[i]
            obj.rotation.y = view_radian
        }

        gable_hip_module.setup(product)
    },
    set_symmetric: function(product, flag) {
        product.symmetric = flag;
        gable_hip_module.setup(product)
    },
    validate_size(product) {
        switch(product.type) {
            case 4:
            case 5:
            case 6:
                if(product.symmetric) {
                    if(product.length < product.projection) gui_module.projection_gui.setValue(product.length)
                } else {
                    if(product.length + 3 < product.projection) gui_module.projection_gui.setValue(product.length + 3)
                }
                break
            case 10:
            case 11:
            case 12:
                if(product.symmetric) {
                    if(product.length + 3 < product.projection) gui_module.projection_gui.setValue(product.length + 3)
                } else {
                    if(product.length + 6 < product.projection) gui_module.projection_gui.setValue(product.length + 6)
                }
                break;

        } 

        const sizeButton = document.getElementById("sizeButton");
        var txt = "Size: " + product.length.toFixed(1) + "X" + product.projection.toFixed(1) + ": "
        //var txt = "Size: " + product.length + "X" + product.projection + ": "

        var hip_area = (product.length * product.projection / cos22p5)
        var gable_comp_area  = (product.projection * product.projection * sin22p5 / (cos22p5 * 2))
        if(sizeButton) {
            switch(product.type) {
                case 4:
                case 5:    
                case 6:
                    if(product.symmetric) {
                        txt +=  (hip_area).toFixed(2) //+ " ~ Hip S"
                    } else {
                        txt +=  (hip_area + gable_comp_area / 2).toFixed(2) //+ " ~ Hip A"
                    }                    
                    break;
                case 7:
                case 8:    
                case 9:
                    txt +=  (hip_area + gable_comp_area).toFixed(2) //+ " ~ Gable"
                    break;
                case 10:
                case 11:    
                case 12:
                    if(product.symmetric) {
                        txt += (hip_area + gable_comp_area / 4).toFixed(2) //+ " ~ Dutch S"
                    } else {
                        txt += (hip_area + 5 * gable_comp_area / 8).toFixed(2) //+ " ~ Dutch A"
                    }                    
                    break; 
                case 13:
                case 14:
                    txt += (0.5 * product.projection * (product.length + product.back_length)).toFixed(2)  //+ " ~ Angled"
                    var angle = 90 - Math.atan(Math.abs(product.length - product.back_length) / product.projection) * 180 / Math.PI
                    //console.log(angle)
                    txt = ' Angle: ' + angle.toFixed(1) + ' ' + txt
                    
                    break;
                case 20:
                    txt +=  (0.937 * product.length * product.length).toFixed(2)  //+ " ~ 6"
                    product.projection = product.length
                    break;
                case 21:
                    txt +=   (0.896 * product.length * product.length).toFixed(2) //    + " ~ 8"
                    product.projection = product.length
                    break;  
                case 28:
                case 29:
                    break;            

            }            
        }

        if(sizeButton) sizeButton.innerHTML = txt
    },
    setup: function(product) {       
        switch(product.type) {
            case 4:
            case 5:    
            case 6:   
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                flyover_module.setup_freestanding_back_posts(product) 
                flyover_module.setup_right_posts(product)   
                flyover_module.setup_left_posts(product)           
                flyover_module.setup_front_posts(product) 
                flyover_module.set_decking_posts_position(product)
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)

                gable_hip_module.setup_hip_patio(product) 
                gable_hip_module.setup_gutters_hip_patio(product)
                gable_hip_module.setup_beams_dutch_patio(product)  
                gable_hip_module.setup_downlights(product)          
                gable_hip_module.setup_rafters(product)
                gable_hip_module.setup_purlins(product)
                break;
            case 7:
            case 8:    
            case 9:  
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                flyover_module.setup_freestanding_back_posts(product)  
                flyover_module.setup_right_posts(product) 
                flyover_module.setup_left_posts(product)           
                flyover_module.setup_front_posts(product) 
                flyover_module.set_decking_posts_position(product)
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)
                           
                gable_hip_module.setup_gable_patio(product) 
                gable_hip_module.setup_gutters_gable_patio(product)
                gable_hip_module.setup_beams_dutch_patio(product)                
                gable_hip_module.setup_downlights(product) 
                gable_hip_module.setup_rafters(product)
                gable_hip_module.setup_purlins(product)
                break;                  
            case 10:
            case 11:    
            case 12:
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                flyover_module.setup_freestanding_back_posts(product) 
                flyover_module.setup_right_posts(product)   
                flyover_module.setup_left_posts(product)             
                flyover_module.setup_front_posts(product) 
                flyover_module.set_decking_posts_position(product)
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)
               
                gable_hip_module.setup_dutch_patio(product) 
                gable_hip_module.setup_gutters_dutch_patio(product)
                gable_hip_module.setup_beams_dutch_patio(product)
                gable_hip_module.setup_downlights(product)
                gable_hip_module.setup_rafters(product)
                gable_hip_module.setup_purlins(product)               
                break; 
            case 13:
            case 14:
            case 15:
            case 16:                
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                flyover_module.setup_freestanding_back_posts(product)    
                flyover_module.setup_right_posts(product) 
                flyover_module.setup_left_posts(product)            
                flyover_module.setup_front_posts(product) 
                flyover_module.set_decking_posts_position(product)
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)

                gable_hip_module.setup_roofing_angled_right_side(product)
                gable_hip_module.setup_ceiling_angled_right_side(product)
                gable_hip_module.setup_gutters_angled_right_side(product)
                gable_hip_module.set_beams_position_angled_right_side(product)
                flyover_module.setup_downlights(product)
                break;
            case 20:
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                
                flyover_module.set_decking_posts_position(product)
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)


                gable_hip_module.setup_gazebo_6_side(product)
                gable_hip_module.setup_gutters_gazebo_6_patio(product) 
                gable_hip_module.setup_beams_gazebo_6_patio(product)
                gable_hip_module.setup_posts_gazbo_6_patio(product)    
                gable_hip_module.setup_downlights_gazbo_6_patio(product)              
                                
                break;                     
            case 21:
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                
                flyover_module.set_decking_posts_position(product)
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)


                gable_hip_module.setup_gazebo_8_side(product)
                gable_hip_module.setup_gutters_gazebo_8_patio(product)                               
                gable_hip_module.setup_beams_gazebo_8_patio(product)
                gable_hip_module.setup_posts_gazbo_8_patio(product)  
                gable_hip_module.setup_downlights_gazbo_8_patio(product)                               
                break; 
            case 28:
            case 29:
                gable_hip_module.validate_size(product)
                product.back_overhang = 0
                product.front_overhang = 0 
                product.step_length = 0
                
                flyover_module.setup_landscaping(product)
                flyover_module.setup_fans(product)

                gable_hip_module.setup_beams_decking(product)
                gable_hip_module.setup_posts_decking(product)
                break;  
                         
        }
        gui_module.set_dim_text()
    },  
    set_forced_decking_posts_no: function(product, no) {
        product.forced_decking_posts_no = no;
        gable_hip_module.setup(product)
    },
    set_decking_post_width: function(product, value) {
        product.decking_post_width = value;
        flyover_module.posts_color(product, gui_module.posts)
        gable_hip_module.setup(product)
    },
    set_decking_post_offset: function(decking_post_index, product, decking_post_offset) {
        product.decking_posts_offset[decking_post_index] = decking_post_offset;
        gable_hip_module.setup(product)
    },
    set_forced_rafters_no: function(product, no) {
        product.forced_rafters_no = no
        gable_hip_module.setup(product)
    },
    set_rafter_offset: function(rafter_index, product, rafter_offset) {
        product.rafter_offset[rafter_index] = rafter_offset
        gable_hip_module.setup(product)
    },
    set_forced_purlins_no: function(product, no) {
        product.forced_purlins_no = no
        gable_hip_module.setup(product)
    },
    set_purlin_offset: function(purlin_index, product, purlin_offset) {
        product.purlin_offset[purlin_index] = purlin_offset
        gable_hip_module.setup(product)
    },
    set_landscaping_visible: function(product, value) {
        product.landscaping_visible = value
        gable_hip_module.check_landscaping_horizon(product) 
        gable_hip_module.setup(product)
    }, 
    set_landscaping_height: function(product, value) {
        product.landscaping_height = value
        gable_hip_module.check_landscaping_horizon(product)         
        gable_hip_module.setup(product)
    }, 
    set_landscaping_type: function(product, value) {
        product.landscaping_type = value
        gable_hip_module.check_landscaping_horizon(product)    
        gable_hip_module.setup(product)
    }, 
    check_landscaping_horizon: function(product) {
        if(product.type == 28) {
            product.horizon = product.height            
        } else {
            //product.horizon = product.landscaping_height
        }
    },
    set_landscaping_x_offset: function(product, value) {
        product.landscaping_x_offset = value
        gable_hip_module.setup(product)
    }, 
    set_landscaping_z_offset: function(product, value) {
        product.landscaping_z_offset = value
        gable_hip_module.setup(product)
    }, 
    set_landscaping_custom_material: function(product, material) {
        product.landscaping_custom_material = material
        gable_hip_module.setup(product)
    },
    set_fan(product, flag) {
        if(product.fans_model.length > 0) {
            product.fans_model[0].visible = flag
        } else {
            loader_module.add_fan(product, 1, function() {
                flyover_module.set_fan_color(product, product.color)
                product.fans_model[0].visible = flag    
                gable_hip_module.setup(product)
            })
        }
        
    },
    set_downlights_number: function(product, number) {
        if(product.type< 28) {
            product.number = number
            if(product.downlights_model.length > 0) {
                gable_hip_module.setup(product)            
            } else {
                loader_module.add_downlights(product, 10, function() {                   
                    gable_hip_module.setup(product)
                })
            }
        } 
    },
    setup_downlights: function(product) {
        for(var i = 0; i < product.downlights_model.length; ++i) {
            if(i < product.number) product.downlights_model[i].visible = true
            else product.downlights_model[i].visible = false
        }
        if(product.number == 0) return

        var rotate = (product.rotate + 90) * Math.PI / 180
        
        var downlights_x_pitch,  downlights_y_pitch
        downlights_x_pitch = (product.length - 1)/ (Math.floor((product.number + 1) / 2) + 1)

        downlights_y_pitch = (1 - product.front_overhang - product.back_overhang) * product.projection / 3
        
        var offset = -0.1
        for(var i = 0; i < product.downlights_model.length; ++i) {
            var light = product.downlights_model[i]
            light.rotation.set(0, 0, 0)
            light.rotation.y = rotate
            if(i % 2 == 0) light.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
            else light.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope)
        
            light.position.copy(product.position)
            
            
            v1.copy(product.left_2_right_unit_vector) 
            var scalar = (Math.floor(i / 2) + 1.1) * downlights_x_pitch
            v1.multiplyScalar(scalar)
            
            v2.copy(product.front_2_end_unit_vector) 
            
            var factor = downlights_y_pitch + (product.front_overhang) * product.projection
            if(i % 2 == 1) {
                factor = 5 * downlights_y_pitch + (product.front_overhang) * product.projection
            }
            var scalar =  factor * product.cos_angle / 2;
            var offsetY = factor * product.sin_angle / 2;
            
            v2.multiplyScalar(scalar)
            v2.add(v1)

            light.position.add(v2)                

            var y = product.height + offset * product.cos_angle - 0.01 + offsetY + product.projection * sin22p5 / (6 * cos22p5) 
            light.position.y =  product.horizon + y
        }
    },
    setup_roofing_building: function(product) {
        var test = 0
        var wing_offset = 0
        if(product.type == 33) wing_offset = product.length;
        var projection, overhang, step_length, step_size

        projection = product.width
        overhang = product.overhang
        step_length = product.wing_length
        step_size = projection

        var size
        var j = 0, jj = 0, jjj = 0, jjjj = 0
        var k = 0, kk = 0, kkk = 0


        var d = (projection / 2 + overhang)
        var projection_size = d / cos22p5
        var front_len_no = (product.length + step_size + 2 * overhang) * 10 + 1
        var front_delta_no = projection_size * sin22p5 * 10 + 1

        var d1 = (step_size / 2 + overhang)
        var front_wing_len_no = (step_length + projection / 2 + 1 * overhang) * 10 
        var front_wing_delta_no = d1 * sin22p5 * 10 / (cos22p5) + 1
        
        if(step_length == 0) {
            front_wing_len_no = 0
        }

        var big_delta_no = 2 * d * 10 + 1
        var half_big_delta_no = d * 10 + 1

        var yAngle = product.rotate * Math.PI / 180
        var yAngle1 = (product.rotate + 90) * Math.PI / 180

        var dd
        for(var i = 0; i < product.roofing_model.length; ++i) {
            
            var roofing = product.roofing_model[i]
            roofing.position.copy(product.position)

            v1.copy(product.front_2_end_unit_vector) 
            v2.copy(product.left_2_right_unit_vector) 
            if(j < front_len_no) {
                roofing.visible = true
                roofing.rotation.set(0, yAngle, 0)
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, roof_slope)                
                
                if(j < front_delta_no) {
                    dd = j * 0.1                    
                    size = (dd)/ sin22p5 + 0.05
                    if(size > projection_size) size = projection_size
                    roofing.scale.z = size 
                    var scalar = 0.1 * j - step_size - 1 * overhang - 0.4 
                    v1.multiplyScalar(scalar)                 
                    v2.multiplyScalar(-1 * size * cos22p5 / 2 + 1 * overhang)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += product.height + size * sin22p5 / 2
                } else if(j > front_len_no - front_delta_no) {                                         
                    dd = (front_len_no - j) * 0.1                    
                    size = (dd / sin22p5)                    
                    if(size > projection_size) size = projection_size
                    roofing.scale.z = size 
                    var scalar = 0.1 * j - step_size - 1 * overhang - 0.7 
                    v1.multiplyScalar(scalar)                 
                    v2.multiplyScalar(-1 * size * cos22p5 / 2 + 1 * overhang)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += product.height + size * sin22p5 / 2
                } else {                   
                    roofing.scale.z = projection_size                    
                    var scalar = 0.1 * j - step_size - 1 * overhang - 0.5 
                    v1.multiplyScalar(scalar)
                    v2.multiplyScalar((d - projection) / 2)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += projection_size * sin22p5 / 2 + product.height
                }        
                ++j
            } else if(jj < front_len_no) {
                roofing.visible = true
                roofing.rotation.set(0, yAngle, 0)
                roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope)   
                if(jj < front_delta_no) {
                    dd = jj * 0.1                    
                    size = (dd)/ sin22p5
                    if(size > projection_size) size = projection_size
                    roofing.scale.z = size
                    var scalar = 0.1 * jj - step_size - 1 * overhang - 0.4 
                    v1.multiplyScalar(scalar)                 
                    v2.multiplyScalar(size * cos22p5 / 2 - overhang - projection)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += product.height + size * sin22p5 / 2  
                } else if(jj > front_len_no - front_delta_no) {
                    dd = (front_len_no - jj) * 0.1
                    size = (dd / sin22p5) + 0.05
                    if(size > projection_size) size = projection_size
                    roofing.scale.z = size
                    var scalar = 0.1 * jj - step_size - 1 * overhang - 0.7 
                    v1.multiplyScalar(scalar)                 
                    v2.multiplyScalar(size * cos22p5 / 2 - overhang - projection)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += product.height + size * sin22p5 / 2   
                } else {                   
                    roofing.scale.z = projection_size
                    var scalar = 0.1 * jj - step_size - 1 * overhang - 0.5 
                    v1.multiplyScalar(scalar)
                    v2.multiplyScalar((d + projection) / -2)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += projection_size * sin22p5 / 2 + product.height
                }   
                ++jj
            } else if(jjj < front_wing_len_no) {
                roofing.visible = true
                roofing.rotation.set(0, yAngle1, 0)
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 15 * roof_slope)   
                if(jjj < front_wing_delta_no) {
                    dd = jjj * 0.1                   
                    size = (dd) / sin22p5
                    if(size > projection_size) size = projection_size

                    roofing.scale.z = size
                    var scalar = -0.1 * jjj + product.wing_length + overhang  
                    v2.multiplyScalar(scalar)
                    v1.multiplyScalar(overhang - 0.5 - size * cos22p5 / 2 + wing_offset) 
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += 0 + product.height + size * sin22p5 / 2
                } else {                    
                    roofing.scale.z = d1 / cos22p5 
                    var scalar = -0.1 * jjj + product.wing_length + overhang  
                    v2.multiplyScalar(scalar)
                    v1.multiplyScalar(overhang - 0.5 - d1 / 2 + wing_offset)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += d1 * sin22p5 / (cos22p5 * 2) + product.height 
                }                   
                ++jjj
            } else if(jjjj < front_wing_len_no) {
                roofing.visible = true
                roofing.rotation.set(0, yAngle1, 0)
                roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)   
                if(jjjj < front_wing_delta_no) {
                    dd = jjjj * 0.1                   
                    size = (dd)/ sin22p5
                    if(size > projection_size) size = projection_size

                    roofing.scale.z = size
                    var scalar = -0.1 * jjjj + step_length + overhang  
                    v2.multiplyScalar(scalar)
                    v1.multiplyScalar(size * cos22p5 / 2 - overhang - 0.5 + wing_offset - projection)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += size * sin22p5 / 2 + product.height 
                } else {                    
                    roofing.scale.z = d1 / cos22p5 
                    var scalar = -0.1 * jjjj + step_length + overhang  
                    v2.multiplyScalar(scalar)
                    v1.multiplyScalar(+ d1 / 2 - overhang - 0.5 + wing_offset - projection)
                    v2.add(v1)
                    roofing.position.add(v2)  
                    roofing.position.y += d1 * sin22p5 / (cos22p5 * 2) + product.height 
                }                   
                ++jjjj
            } else if(k < big_delta_no) {
               roofing.visible = true
               roofing.rotation.set(0, yAngle1, 0)
               roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 2 * roof_slope)   

               var scalar = 0.1 * k - projection - overhang 
               if(k < half_big_delta_no) {
                    dd = k * 0.1
                    size = dd / (2 * cos30)   
                } else {       
                    dd = (k - half_big_delta_no) * 0.1
                    size = dd / (2 * cos30)     
                    scalar = 0.1 * (half_big_delta_no - k) + overhang 
                }   
                size += 0.1
                roofing.scale.z = size
                v2.multiplyScalar(scalar)
                v1.multiplyScalar(size * cos45 / 2 - step_size - overhang - 0.5)
                v2.add(v1)
                roofing.position.add(v2)  
                roofing.position.y += size * sin45 / 2 + product.height               
                ++k                           
            } else if(kk < big_delta_no) {
               roofing.visible = true
               roofing.rotation.set(0, yAngle1, 0)
               roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, 14 * roof_slope)   

               var scalar = 0.1 * (kk) - projection - overhang 
               if(kk < half_big_delta_no) {
                    dd = kk * 0.1
                    size = dd / (2 * cos30)   
                } else {  
                    dd = (kk - half_big_delta_no) * 0.1
                    size = dd / (2 * cos30)  
                    scalar = overhang - 0.1 * (kk - half_big_delta_no) 
                } 
                size += 0.1
                roofing.scale.z = size
                v2.multiplyScalar(scalar)
                v1.multiplyScalar(-1 * size * cos45 / 2 + product.length + overhang - 0.5)
                v2.add(v1)
                roofing.position.add(v2)  
                roofing.position.y += product.height + 1 * size * sin45 / 2             
                ++kk                           
            } else if(kkk < big_delta_no) {
                if(step_length == 0) {
                    roofing.visible = false
                } else {
                    roofing.visible = true
                    roofing.rotation.set(0, yAngle, 0)
                    roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 2 * roof_slope) 

                    var scalar = 0.1 * (kkk) - projection - overhang - 0.5 + wing_offset 
                    if(kkk < half_big_delta_no) {
                        dd = kkk * 0.1          
                     } else {   
                         dd = (kkk - half_big_delta_no) * 0.1
                         scalar =  overhang - 0.5 + wing_offset - 0.1 * (kkk - half_big_delta_no)                    
                     }
                     size = dd / (2 * cos30)                   
                     roofing.scale.z = size   
                     v1.multiplyScalar(scalar)
                     v2.multiplyScalar(-1 * size * cos45 / 2 + step_length + overhang)
                     v2.add(v1)
                     roofing.position.add(v2)  
                     roofing.position.y += product.height + 1 * size * sin45 / 2             
                }                     
                ++kkk                           
             } else {
                roofing.visible = false
             }
            
        }
    },
    setup_gutters_building: function(product) {
        var projection, overhang, step_length, step_size, gutter_scale

        var wing_offset = 0
        if(product.type == 33) wing_offset = product.length

        projection = product.width
        overhang = product.overhang
        step_length = product.wing_length
        step_size = product.wing_width
        gutter_scale = product.gutter_scale

        var d = (projection / 2 + overhang)  + 0.2      
        
        
        var yAngle = product.rotate * Math.PI / 180
        var yAngle1 = (product.rotate + 90) * Math.PI / 180
        var yAngle2 = (product.rotate + 67.5) * Math.PI / 180
        var yAngle3 = (product.rotate + 202.5) * Math.PI / 180
        var xOffset, zOffset  
            
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.visible = true
            v1.copy(product.front_2_end_unit_vector) 
            v2.copy(product.left_2_right_unit_vector)
            if(i < 6) {
                if(i % 2 == 0)  gutter.rotation.set(0, yAngle, 0)
                else gutter.rotation.set(0, yAngle1, 0)     
            } else {
                switch(i) {   
                    case 6:
                        gutter.rotation.set(0, yAngle2, 0)  
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 9 * roof_slope) 
                        break;    
                    case 7:
                        gutter.rotation.set(0, yAngle2, 0)                
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 15 * roof_slope) 
                        break;    
                    case 8:
                        gutter.rotation.set(0, yAngle2, 0)                
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, roof_slope) 
                        break;                           
                    case 9:
                        gutter.rotation.set(0, yAngle2, 0)                
                        gutter.rotateOnWorldAxis(product.front_2_end_unit_vector, 7 * roof_slope) 
                        break;   
                    case 10:
                        gutter.rotation.set(0, yAngle3, 0)                
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope)
                        break;                                                      
                    case 11:  
                        gutter.rotation.set(0, yAngle3, 0)                
                        gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 7 * roof_slope)
                        break; 
                    case 12:
                        gutter.rotation.set(0, yAngle, 0)                              
                        break;   
                    case 13: 
                        gutter.rotation.set(0, yAngle1, 0)
                       break;                                                                                                          
                }
            }

            if(i < 6) gutter.position.y = product.height  
            else if(i > 11) {
                gutter.position.y += d * sin22p5 / (cos22p5) + product.height 
            } else {
                gutter.scale.set(d / cos22p5, gutter_scale, gutter_scale)
                gutter.position.y += d * sin22p5 / (cos22p5 * 2) + product.height 
            }
            switch(i) {
                case 0:
                    gutter.scale.set((step_size + 2 * overhang), gutter_scale, gutter_scale) 
                    xOffset = wing_offset - step_size / 2 - 0.5
                    zOffset = step_length + overhang
                    break;
                case 1:
                    gutter.scale.set(step_length, gutter_scale, gutter_scale)
                    if(wing_offset == 0) xOffset = overhang - 0.5
                    else xOffset = product.length - step_size - overhang - 0.5 
                    zOffset = overhang + step_length / 2
                    break;
                case 2:
                    gutter.scale.set(product.length, gutter_scale, gutter_scale)
                    if(wing_offset == 0) xOffset = product.length / 2 + overhang - 0.5
                    else xOffset = product.length / 2 - step_size - overhang - 0.5 
                    zOffset = overhang  
                    break;   
                case 3:
                    gutter.scale.set((projection + 2 * overhang), gutter_scale, gutter_scale)
                    if(wing_offset == 0) xOffset = product.length + overhang - 0.5
                    else xOffset = -1 * (step_size + overhang + 0.5)
                    zOffset = -1 * projection / 2
                    break;   
                case 4:
                    gutter.scale.set((product.length + step_size + 2 * overhang), gutter_scale, gutter_scale)
                    xOffset = (product.length - step_size) / 2 - 0.5                     
                    zOffset = -1 * (overhang + projection)
                    break; 
                case 5:
                    gutter.scale.set((projection + step_length + 2 * overhang), gutter_scale, gutter_scale)
                    if(wing_offset == 0) xOffset = -1 * (step_size + overhang + 0.5)
                    else xOffset = product.length + overhang - 0.5
                    zOffset = -1 * (projection - step_length) / 2
                    break;      
                case 6:
                    xOffset = product.length - d * sin22p5 / 2 + overhang - 0.625 
                    zOffset = overhang / 2 - projection / 4
                    break;   
                case 7:
                    xOffset = product.length - d * sin22p5 / 2 + overhang - 0.625
                    zOffset = overhang / 2 - projection / 4 - d + 0.2
                    break;    
                case 8:
                    xOffset = d * sin22p5 / 2 - overhang - 0.45 - step_size
                    zOffset = overhang / 2 - projection / 4
                    break;                                                          
                case 9: 
                    xOffset = d * sin22p5 / 2 - overhang - 0.45 - step_size 
                    zOffset = overhang / 2 - (d + projection / 4 - 0.2)
                    break;                        
                case 10:  
                    if(step_length > 0) {
                        zOffset = step_length - d * sin22p5 / 2 + overhang 
                        xOffset = wing_offset - (3 * step_size / 4 + overhang / 2 + 0.5) 
                    } else {
                        gutter.visible = false
                    }
                    break;                     
                case 11: 
                    if(step_length > 0) {
                        zOffset = step_length - d * sin22p5 / 2 + overhang 
                        xOffset = wing_offset + d - (3 * step_size / 4 + overhang / 2 + 0.7) 
                    } else {
                        gutter.visible = false
                    }           
                    break;                       
                case 12:
                    var s = (product.length + step_size + 2 * overhang - 2 * d * sin22p5) - 0.4
                    gutter.scale.set(s, gutter_scale, gutter_scale)
                    xOffset = (product.length - step_size) / 2 - 0.5
                    zOffset = -1 * projection / 2                   
                break;   
                case 13: 
                    if(step_length > 0) {
                        var s = (projection / 2 + step_length + 1 * overhang - 1 * d * sin22p5)
                        gutter.scale.set(s, gutter_scale, gutter_scale)
                        xOffset = wing_offset - step_size / 2 - 0.5 
                        zOffset = s / 2 - projection / 2
                        
                    } else {
                        gutter.visible = false
                    }    
                    break;                                                                                      
            } 
            v1.multiplyScalar(xOffset)
            v2.multiplyScalar(zOffset)
            v2.add(v1)
            gutter.position.add(v2)                     
            
        }
    }
}

export { gable_hip_module }

 