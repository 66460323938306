import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';
import { building_module } from '../smartkits/building_module';

class SmartBasicSetupSubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      angle: 3,
      rotate: 90,
      altitude: 0,
      symmetric: false,
    }   
  }

  setAngle = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      angle: v
    })
    var type = this.getProductType()
    if(type < 32) {
      gui_module.angle_gui.setValue(v)
    } else if(type > 63) {
      shed_module.angle_gui.setValue(v)
    } 
  }

  setRotate = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      rotate: v
    })
    var type = this.getProductType()
    if(type < 32) {
      gui_module.view_gui.setValue(v)
    } else if(type == 32 || type == 33) {
      building_module.rotation_gui.setValue(v)
    } else if(type > 63) {
      shed_module.rotation_gui.setValue(v)
    } 
  }

  setAltitude = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      altitude: v
    })
    var type = this.getProductType()
    if(type < 32) {
      gui_module.horizon_gui.setValue(v)
    } 
  }

  setSymmetric = (event) => {
    var v = event.target.checked
    this.setState({
      symmetric: v
    }) 
    var type = this.getProductType()
    if(type < 32) {
      gui_module.symmetric_gui.setValue(v)
    } else if(type > 63) {
      shed_module.symmetric_gui.setValue(v)
    } 
  }
  
  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type
    var rot = product?.rotate
    if(type == 32 || type == 32) rot = product?.rotation
    this.setState({
      angle: product?.angle,
      rotate: rot,
      altitude: product?.horizon,
      symmetric: product?.symmetric
    })

    if(type < 4) {
      document.getElementById('angle').style.display = 'block'
      document.getElementById('symmetric').style.display = 'none'
      document.getElementById('altitude').style.display = 'block'
    } else if(type < 32) {
      document.getElementById('angle').style.display = 'none'
      document.getElementById('symmetric').style.display = 'block'
      document.getElementById('altitude').style.display = 'block'
    } else if(type == 32 || type == 33) {
      document.getElementById('angle').style.display = 'none'
      document.getElementById('symmetric').style.display = 'none'
      document.getElementById('altitude').style.display = 'none'
    } else {
      document.getElementById('angle').style.display = 'block'
      document.getElementById('symmetric').style.display = 'block'
      document.getElementById('altitude').style.display = 'none'
    }
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='basic_setup'>
         <button className="btn btn-secondary col-md-12 mx-1 my-1">Rotate</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotate}
            onChange={this.setRotate}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotate}
            onChange={this.setRotate}
        />
        <div id='angle'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Angle</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-45'
            max='45'
            step='1'
            value={this.state.angle}
            onChange={this.setAngle}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-45'
            max='45'
            step='1'
            value={this.state.angle}
            onChange={this.setAngle}
        />
        </div>
        <div id='altitude'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Altitude</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='6'
            step='0.1'
            value={this.state.altitude?.toFixed(2)}
            onChange={this.setAltitude}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='6'
            step='0.1'
            value={this.state.altitude}
            onChange={this.setAltitude}
        />
        </div>
        <div id='symmetric'>
          <button className="btn btn-secondary col-md9 mx-1 my-1">Symmetric</button>
          <input  type="checkbox"  checked={this.state.symmetric} onChange={this.setSymmetric} /> 
        </div>
        </div>
      </div>
     );  
  }
}

export default SmartBasicSetupSubmenu;
