import { THREE, getMaterial, updatePrice, getObjectsKeys, loadMaterial, selectShedGUISubmenu, hideElement } from './helper.js';

import { selectColorbond, convertColor2Hex } from './color.js';
import { gui_module, sceneItems } from './gui_smartkits.js';
import { loader_module } from './loader_module.js';
import { gable_hip_module } from './gable_hip_module.js';
import { flyover_module } from './flyover_module.js';


var v1 = new THREE.Vector3();
var v2 = new THREE.Vector3(); 
var v3 = new THREE.Vector3();
var v4 = new THREE.Vector3(); 

var shed_module = {
    shed: null,
    length: 12,
    height: 3,
    projection: 5,
    wing_width: 5,
    angle:10,
    front_overhang: 0,
    side_overhang: 0,
    wall_index: 1,
    window_length: 2,
    window_width: 1,
    window_padding: 0,
    window_type: 0,
    window_hide: false,    
    overhang: .3,
    symmetric: false,
    wing_position: 6,
    wing_size: 6,
    storey_2nd: 0,
    test: 0,

    length_gui: null,
    projection_gui: null,
    angle_gui: null,
    wing_length_gui: null,
    overhang_gui: null,
    side_overhang_gui: null,
    height_gui: null,
    window_padding_gui: null,
    wall_index_gui: null,
    window_lenght_gui: null,
    window_width_gui: null,
    window_type_gui: null,
    symmetric_gui: null,
    step_length_gui: null,
    step_size_gui: null,
    storey_2nd_gui: null,

    lock: false,
    product_lock_gui: null,

    rotation: 0,
    rotation_gui: null,
    
    base: true,
    acc_index: 0,
    acc_type: 0,
    acc_width: 1,
    acc_height: 2,
    acc_location: 1,
    acc_horizon: 1,
    acc_place: 1,
    acc_side: 0,
    shed_accessory_index_gui: null,
    shed_accessory_type_gui: null,
    shed_accessory_length_gui: null,
    shed_accessory_height_gui: null,
    shed_accessory_location_gui: null,
    shed_accessory_place_gui: null,    
    shed_accessory_side_gui: null,  
    shed_accessory_horizon_gui: null,  

    shed_acc_type: null,

    shed_base_gui: null,

    building_folder_gui: null,

    test: 1,

    set_product: function(shed, set_acc) {
        if(shed) {
            shed_module.shed = shed
            shed_module.length_gui.setValue(shed.length)
            shed_module.projection_gui.setValue(shed.projection)
            shed_module.angle_gui.setValue(shed.angle)
            shed_module.overhang_gui.setValue(shed.front_overhang)
            shed_module.side_overhang_gui.setValue(shed.back_overhang)
            shed_module.height_gui.setValue(shed.height)
            shed_module.product_lock_gui.setValue(shed.lock)
            shed_module.rotation_gui.setValue(shed.rotate)
            if(shed.type == 100) {
                shed_module.step_length_gui.setValue(shed.step_length)
                shed_module.step_size_gui.setValue(shed.step_size)
                shed_module.storey_2nd_gui.setValue(shed.back_length)
                shed.symmetric = true
            }
            shed_module.symmetric_gui.setValue(shed.symmetric)
            if(set_acc) shed_module.set_shed_accessories_gui(shed)
            selectShedGUISubmenu(shed, shed_module)

            if(shed_module.shed.landscaping_visible == 0) shed_module.shed_base_gui.setValue(false)
            else shed_module.shed_base_gui.setValue(true)
        }
    },
    set_shed_accessories_gui(shed) {    
        var acc = shed.accessories_model[shed_module.acc_index]
        if(acc) {
            shed_module.shed_accessory_type_gui.setValue(acc.type)
            shed_module.shed_accessory_length_gui.setValue(acc.length)
            shed_module.shed_accessory_height_gui.setValue(acc.height)
            shed_module.shed_accessory_location_gui.setValue(acc.location)
            shed_module.shed_accessory_place_gui.setValue(acc.place)
            shed_module.shed_accessory_side_gui.setValue(acc.side)
        }
    },
    update_size(shed) {
        const sizeButton = document.getElementById("sizeButton");
        var txt = "Size: " + shed.length.toFixed(1) + "X" + shed.projection.toFixed(1) 
        if(sizeButton) sizeButton.innerHTML = txt      
        updatePrice()       
    },
    add_gui: function(gui) {
        loader_module.load_gutters()
        loader_module.load_solar_span_type_1()
        var shed_gui = gui.addFolder('Shed Setup');

        shed_module.product_lock_gui = shed_gui.add(shed_module, "lock").onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.lock = shed_module.lock
            }
        });

        shed_module.length_gui = shed_gui.add(shed_module, 'length', 6, 40, 0.25).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.length = shed_module.length
                if(shed_module.shed.type == 100) {
                    shed_module.step_length_gui.setValue(shed_module.wing_position)                
                }
                shed_module.setup(shed_module.shed)   
                shed_module.update_size(shed_module.shed) 
            }        
        });
        shed_module.projection_gui = shed_gui.add(shed_module, 'projection', 3, 40, 0.25).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.projection = shed_module.projection
                if(shed_module.shed.type == 100) {
                    shed_module.step_length_gui.setValue(shed_module.wing_position)                
                }
                shed_module.setup(shed_module.shed)      
                shed_module.update_size(shed_module.shed)          
            }        
        });
        shed_module.rotation_gui = shed_gui.add(shed_module, 'rotation', 0, 360, 1).onChange(function() {
            shed_module.rotate(shed_module.shed, shed_module.rotation)
        });
        shed_module.angle_gui = shed_gui.add(shed_module, 'angle', 2.5, 45, 0.5).onChange(function() {
            if(shed_module.shed) {                
                shed_module.shed.angle = shed_module.angle
                shed_module.setup(shed_module.shed)                
            }        
        });        
        shed_module.height_gui = shed_gui.add(shed_module, 'height', 1, 6, 0.1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.height = shed_module.height;
                shed_module.setup(shed_module.shed)                
            }        
        }); 

        shed_module.overhang_gui = shed_gui.add(shed_module, 'front_overhang', 0, 2, 0.1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.front_overhang = shed_module.front_overhang
                if(shed_module.shed.type == 100) {
                    shed_module.step_length_gui.setValue(shed_module.wing_position)                
                }
                shed_module.setup(shed_module.shed)                
            }
        });

        shed_module.side_overhang_gui = shed_gui.add(shed_module, 'side_overhang', 0, 2, 0.25).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.back_overhang = shed_module.side_overhang
                if(shed_module.shed.type == 100) {
                    shed_module.step_length_gui.setValue(shed_module.wing_position)                
                }
                shed_module.setup(shed_module.shed)                
            }
        });

        shed_module.symmetric_gui = shed_gui.add(shed_module, "symmetric").onChange(function() {           
            if(shed_module.shed) {
                if(shed_module.shed.type == 100) shed_module.symmetric = true
                shed_module.shed.symmetric = shed_module.symmetric
                shed_module.setup(shed_module.shed)                
            }
        });

        var placement_gui = shed_gui.addFolder('Placement')
        hideElement(placement_gui.domElement, true)

        shed_module.shed_accessory_index_gui = placement_gui.add(shed_module, 'acc_index', 0, 15, 1).onChange(function() {
            if(shed_module.shed) {                                        
                shed_module.set_shed_accessories_gui(shed_module.shed)                
            }        
        }); 

        shed_module.shed_accessory_type_gui = placement_gui.add(shed_module, 'acc_type', 0, 7, 1).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    acc.type = shed_module.acc_type   
                }                           
                shed_module.setup(shed_module.shed)                
            }        
        }); 

        shed_module.shed_accessory_length_gui = shed_gui.add(shed_module, 'acc_width', 1, 6, 0.1).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    acc.length = shed_module.acc_width   
                }                           
                shed_module.setup(shed_module.shed)                
            }        
        }); 

        shed_module.shed_accessory_height_gui = shed_gui.add(shed_module, 'acc_height', 0.5, 5, 0.25).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    if(acc.type == 6) {
                        if(shed_module.acc_height >= shed_module.projection / 2) shed_module.acc_height = shed_module.projection / 2 - 0.1
                        acc.height = shed_module.acc_height  
                    } else {
                        if(shed_module.acc_height >= shed_module.height) shed_module.acc_height = shed_module.height - 0.1
                        acc.height = Math.ceil(4 * shed_module.acc_height) / 4  
                    }                                                     
                }                           
                shed_module.setup(shed_module.shed)                
            }        
        }); 

        shed_module.shed_accessory_horizon_gui = shed_gui.add(shed_module, 'acc_horizon', 0, 5, 0.25).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    if(shed_module.shed.type == 100 && acc.type == 7) {
                        if(shed_module.acc_horizon + acc.height >= shed_module.shed.back_length) shed_module.acc_horizon = shed_module.shed.back_length - acc.height - 0.4
                        if(shed_module.acc_horizon < 0) shed_module.acc_horizon = 0
                    } else if(shed_module.acc_horizon + acc.height >= shed_module.height) shed_module.acc_horizon = shed_module.height - acc.height - 0.4
                    acc.horizon = Math.ceil(4 * shed_module.acc_horizon) / 4                                                    
                }                           
                shed_module.setup(shed_module.shed)                
            }        
        }); 

        /*shed_gui.add(shed_module, 'test', 1, 100, 1).onChange(function() {
            if(shed_module.shed) {                                          
                shed_module.setup(shed_module.shed)                
            }        
        }); */

        shed_module.shed_accessory_location_gui = shed_gui.add(shed_module, 'acc_location', 0, 160, 0.25).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    var max = (shed_module.shed.length + shed_module.shed.projection) * 2
                    if(shed_module.shed.type == 100) max += 2 * shed_module.shed.step_size + shed_module.shed.projection 
                    if(shed_module.acc_location > max) shed_module.acc_location = max - 1 
                    acc.location = shed_module.acc_location 
                    switch(acc.type) {
                        case 6:
                            if(shed_module.shed.type == 75) {
                                shed_module.shed_accessory_side_gui.setValue(0)
                                if(acc.location + acc.length < shed_module.shed.length) {                                    
                                    shed_module.shed_accessory_place_gui.setValue(acc.location)
                                } 
                            } else {
                                if(acc.location + acc.length < shed_module.shed.length) {
                                    shed_module.shed_accessory_side_gui.setValue(0)
                                    shed_module.shed_accessory_place_gui.setValue(acc.location)
                                } else if(acc.location < 2 * shed_module.shed.length) {
                                    shed_module.shed_accessory_side_gui.setValue(1)
                                    if(acc.location > shed_module.shed.length) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.length)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } 
                            }                            
                            break
                        default:
                            if(shed_module.shed.type == 100) {
                                if(acc.location + acc.length < shed_module.shed.step_length + shed_module.shed.front_overhang * Math.cos(shed_module.shed.angle * Math.PI / 180) - shed_module.shed.back_overhang / 2) {
                                    shed_module.shed_accessory_side_gui.setValue(0)
                                    shed_module.shed_accessory_place_gui.setValue(acc.location)
                                } else if(acc.location + acc.length < shed_module.shed.step_length + shed_module.shed.step_size) {
                                    shed_module.shed_accessory_side_gui.setValue(4)
                                    if(acc.location > shed_module.shed.step_length) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.step_length)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else if(acc.location + acc.length < shed_module.shed.step_length + shed_module.shed.step_size + shed_module.shed.projection) {
                                    shed_module.shed_accessory_side_gui.setValue(5)
                                    if(acc.location > shed_module.shed.step_length + shed_module.shed.step_size) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.step_length - shed_module.shed.step_size)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else if(acc.location + acc.length < shed_module.shed.step_length + 2 * shed_module.shed.step_size + shed_module.shed.projection) {
                                    shed_module.shed_accessory_side_gui.setValue(6)
                                    if(acc.location > shed_module.shed.step_length + shed_module.shed.step_size + shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.step_length - shed_module.shed.step_size - shed_module.shed.projection)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else if(acc.location + acc.length < shed_module.shed.length + 2 * shed_module.shed.step_size) {
                                    shed_module.shed_accessory_side_gui.setValue(0)
                                    if(acc.location > shed_module.shed.step_length + 2 * shed_module.shed.step_size + shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - 2 * shed_module.shed.step_size)
                                    else shed_module.shed_accessory_place_gui.setValue(shed_module.shed.step_length + shed_module.shed.projection)
                                } else if(acc.location + acc.length < shed_module.shed.length + 2 * shed_module.shed.step_size + 2 * shed_module.shed.projection) {
                                    shed_module.shed_accessory_side_gui.setValue(1)
                                    if(acc.location > shed_module.shed.length + 2 * shed_module.shed.step_size + shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.length - 2 * shed_module.shed.step_size - shed_module.shed.projection)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else if(acc.location + acc.length < 2 * shed_module.shed.length + 2 * shed_module.shed.step_size + 2 * shed_module.shed.projection) {
                                    shed_module.shed_accessory_side_gui.setValue(2)
                                    if(acc.location > shed_module.shed.length + 2 * shed_module.shed.step_size + 2 * shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.length - 2 * shed_module.shed.step_size - 2 * shed_module.shed.projection)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else /*if(acc.location + acc.length < 2 * shed_module.shed.length + 2 * shed_module.shed.step_size + 3 * shed_module.shed.projection)*/{
                                    shed_module.shed_accessory_side_gui.setValue(3)
                                    if(acc.location > 2 * shed_module.shed.length + 2 * shed_module.shed.step_size + 2 * shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - 2 * shed_module.shed.length - 2 * shed_module.shed.step_size - 2 * shed_module.shed.projection)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } 
                            } else {
                                if(acc.location + acc.length < shed_module.shed.length) {
                                    shed_module.shed_accessory_side_gui.setValue(0)
                                    shed_module.shed_accessory_place_gui.setValue(acc.location)
                                } else if(acc.location + acc.length < shed_module.shed.length + shed_module.shed.projection) {
                                    shed_module.shed_accessory_side_gui.setValue(1)
                                    if(acc.location > shed_module.shed.length) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.length)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else if(acc.location + acc.length < 2 * shed_module.shed.length + shed_module.shed.projection) {
                                    shed_module.shed_accessory_side_gui.setValue(2)
                                    if(acc.location > shed_module.shed.length + shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - shed_module.shed.length - shed_module.shed.projection)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                } else /*if(acc.location + acc.length < 2 * (shed_module.shed.length + shed_module.shed.projection))*/ {
                                    shed_module.shed_accessory_side_gui.setValue(3)
                                    if(acc.location > 2 * shed_module.shed.length + shed_module.shed.projection) shed_module.shed_accessory_place_gui.setValue(acc.location - 2 * shed_module.shed.length - shed_module.shed.projection)
                                    else shed_module.shed_accessory_place_gui.setValue(0)
                                }
                            }                                
                            break
                    }                                                         
                }             
            }              
        })

        shed_module.shed_accessory_place_gui = placement_gui.add(shed_module, 'acc_place', 0, 40, 0.25).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    acc.place = shed_module.acc_place   
                }                           
                shed_module.setup(shed_module.shed)                
            }     
        })

        shed_module.shed_accessory_side_gui = placement_gui.add(shed_module, 'acc_side', 0, 6, 1).onChange(function() {
            if(shed_module.shed) {
                var acc = shed_module.shed.accessories_model[shed_module.acc_index]
                if(acc) {
                    acc.side = shed_module.acc_side   
                }                           
                shed_module.setup(shed_module.shed)                
            }     
        })
        
        const shedAccTypes = (function () {
            return {
              roller_door: "Roller Door",   
              farm_door: "Farm Door",
              window: "Window",
              PA_door: "PA Door",
              glass_sliding_door: "Glass Sliding Door",
              upper_window: "Upper Window",
              skylight: "Skylight"           
            };
        })();
      
        const shedAccTypeKeys = getObjectsKeys(shedAccTypes)        
       
        var shedAccTypeGUI = shed_gui.add(shed_module, 'shed_acc_type', shedAccTypeKeys).onChange(function() {   
        });
        shedAccTypeGUI.domElement.id = 'shedAccTypeGUI';
        shedAccTypeGUI.setValue('roller_door')
       
        shed_gui.add(shed_module, "shed_add_accessory").name('Add Acc');

        shed_gui.add(shed_module, 'shed_remove_accessory').name('Remove Acc');
        
        shed_gui.add(gui_module, "reset").name("Reset Location");
        shed_module.setup_posts_gui(shed_gui)

        shed_module.building_folder_gui = shed_gui.addFolder('Building')
        shed_module.step_length_gui = shed_module.building_folder_gui.add(shed_module, 'wing_position', 0, 40, 0.25).onChange(function() {
            if(shed_module.shed) {
                if(shed_module.wing_position > shed_module.shed.length - shed_module.shed.projection + shed_module.shed.back_overhang - 2 * shed_module.shed.front_overhang) shed_module.wing_position = shed_module.shed.length - shed_module.shed.projection + shed_module.shed.back_overhang - 2 * shed_module.shed.front_overhang
                shed_module.shed.step_length = shed_module.wing_position
                shed_module.setup(shed_module.shed)   
                shed_module.update_size(shed_module.shed) 
            }        
        });
        shed_module.step_size_gui = shed_module.building_folder_gui.add(shed_module, 'wing_size', 0, 20, 0.25).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.step_size = shed_module.wing_size
                shed_module.setup(shed_module.shed)      
                shed_module.update_size(shed_module.shed)          
            }        
        });
        shed_module.storey_2nd_gui = shed_module.building_folder_gui.add(shed_module, 'storey_2nd', 0, 6, 0.25).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.back_length = shed_module.storey_2nd
                shed_module.setup(shed_module.shed)      
                shed_module.update_size(shed_module.shed)          
            }        
        })

        shed_module.building_folder_gui.add(shed_module, 'load_wall_material').name('custom wall');
        const loadBuilldingImageInput2 = document.getElementById("loadBuildingImageInput2");
        if(loadBuilldingImageInput2) loadBuilldingImageInput2.addEventListener("change", function() {
            var file = document.getElementById('loadBuildingImageInput2').files[0];
            document.getElementById('loadBuildingImageInput2').value = ''
            var path = (window.URL || window.webkitURL).createObjectURL(file);
            var material = (loadMaterial(path));
            for(var i = 0; i < shed_module.shed.walls_model.length; ++i) {                               
                shed_module.shed.walls_model[i].traverse( function (child) {
                    if(child.isMesh) child.material = material             
                });
            }
        }, false);
        
        return shed_gui
    },
    front_post_offset_folder_gui: null,
    front_post_offset_gui: [],
    forced_front_posts_no: 0,
    front_post_width: 0.08,
    f_p_1_offset: -0.5,
    f_p_2_offset: +0.5,
    f_p_3_offset: 0,
    f_p_4_offset: 0,
    f_p_5_offset: 0,
    f_p_6_offset: 0,
    setup_posts_gui: function(shed_gui) {
        //var main_setup_posts_folder_gui = shed_gui.addFolder('Posts Setup');
        shed_module.front_post_offset_folder_gui = shed_gui.addFolder('Posts Setup');

        const postTypes = (function () {
            return {
              metal: "Metal",
              wide_metal: "Wide Metal",
              wood: "Wood",
              wide_wood: "Wide Wood",
            };
        })();
      
        const postTypeKeys = getObjectsKeys(postTypes);
      
        const postTypesData = {
           post_type: postTypeKeys[0],
        };      
      
        var postTypeGUI = shed_module.front_post_offset_folder_gui.add(postTypesData, 'post_type', postTypeKeys).onChange(function() {
            if(postTypesData.post_type == "metal") {                 
                shed_module.front_post_width = 0.08;        
            } else if(postTypesData.post_type == "wide_metal") {                 
                shed_module.front_post_width = 0.16;        
            } else if(postTypesData.post_type == "wood") {                
                shed_module.front_post_width = 0.125;           
            } else {
                shed_module.front_post_width = 0.15;                 
            } 

            if(shed_module.shed) {
                shed_module.shed.front_post_width = shed_module.front_post_width
                flyover_module.posts_color(shed_module.shed, gui_module.posts)
                shed_module.setup(shed_module.shed)                
            }  
        });
    
        postTypeGUI.domElement.id = 'shedPostTypeGUI';
    
        const posts_no = (function () {
            return {
              automatic: "Automatic",
              two: "2",
              three: "3",
              four: "4",
              five: "5",
              six: "6"
            };
        })();
      
        const postNoKeys = getObjectsKeys(posts_no);
      
        const postsNoData = {
           post_no: postNoKeys[0],
        };      
      
        var postsNoGUI = shed_module.front_post_offset_folder_gui.add(postsNoData, 'post_no', postNoKeys).onChange(function() {
            if(postsNoData.post_no == "automatic") {
                shed_module.forced_front_posts_no = 0                
            } else {
                shed_module.forced_front_posts_no = parseInt(posts_no[postsNoData.post_no]);
            } 
            if(shed_module.shed) {
                shed_module.shed.forced_front_posts_no = shed_module.forced_front_posts_no
                shed_module.shed.forced_back_posts_no = shed_module.forced_front_posts_no
                shed_module.setup(shed_module.shed)                
            }  
        });
    
        postsNoGUI.domElement.id = 'shedPostsNoGUI'
        
        var _gui;
        _gui = shed_module.front_post_offset_folder_gui.add(this, 'f_p_1_offset', -50, 50, 1).onChange(function() {
            //product_manager_module.front_post_offset(0, gui_module.index - 1, gui_module.f_p_1_offset / 100);            
            if(shed_module.shed) {
                shed_module.shed.front_posts_offset[0] = shed_module.f_p_1_offset / 100
                shed_module.shed.back_posts_offset[0] = shed_module.f_p_1_offset / 100
                shed_module.setup(shed_module.shed)                
            } 
        });
        shed_module.front_post_offset_gui.push(_gui);
        _gui = shed_module.front_post_offset_folder_gui.add(this, 'f_p_2_offset', -50, 50, 1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.front_posts_offset[1] = shed_module.f_p_2_offset / 100
                shed_module.shed.back_posts_offset[1] = shed_module.f_p_2_offset / 100
                shed_module.setup(shed_module.shed)                
            }
        });
        shed_module.front_post_offset_gui.push(_gui);
        _gui = shed_module.front_post_offset_folder_gui.add(this, 'f_p_3_offset', -50, 50, 1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.front_posts_offset[2] = shed_module.f_p_3_offset / 100
                shed_module.shed.back_posts_offset[2] = shed_module.f_p_3_offset / 100
                shed_module.setup(shed_module.shed)                
            }
        });
        shed_module.front_post_offset_gui.push(_gui);
        _gui = shed_module.front_post_offset_folder_gui.add(this, 'f_p_4_offset', -50, 50, 1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.front_posts_offset[3] = shed_module.f_p_4_offset / 100
                shed_module.shed.back_posts_offset[3] = shed_module.f_p_4_offset / 100
                shed_module.setup(shed_module.shed)                
            }
        });
        shed_module.front_post_offset_gui.push(_gui);
        _gui = shed_module.front_post_offset_folder_gui.add(this, 'f_p_5_offset', -50, 50, 1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.front_posts_offset[4] = shed_module.f_p_5_offset / 100
                shed_module.shed.back_posts_offset[4] = shed_module.f_p_5_offset / 100
                shed_module.setup(shed_module.shed)                
            }
        });
        shed_module.front_post_offset_gui.push(_gui);
        _gui = shed_module.front_post_offset_folder_gui.add(this, 'f_p_6_offset', -50, 50, 1).onChange(function() {
            if(shed_module.shed) {
                shed_module.shed.front_posts_offset[5] = shed_module.f_p_6_offset / 100
                shed_module.shed.back_posts_offset[5] = shed_module.f_p_6_offset / 100
                shed_module.setup(shed_module.shed)                
            }
        });
        shed_module.front_post_offset_gui.push(_gui);

        shed_module.shed_base_gui = shed_gui.add(shed_module, "base").onChange(function() {
            if(shed_module.shed) {
                if(shed_module.base) shed_module.shed.landscaping_visible = 1
                else shed_module.shed.landscaping_visible = 0
                shed_module.setup(shed_module.shed) 
            }
        });
    },
    shed_add_accessory() {
        var type = 0
        if(shed_module.shed_acc_type == 'window') {
            type = 2
        } else if(shed_module.shed_acc_type == 'farm_door') {
            type = 3
        } else if(shed_module.shed_acc_type == 'PA_door') {
            type = 4
        } else if(shed_module.shed_acc_type == 'glass_sliding_door') {
            type = 5
        } else if(shed_module.shed_acc_type == 'skylight') {
            type = 6
        } else if(shed_module.shed_acc_type == 'upper_window') {
            if(shed_module.shed.type == 100) type = 7
            else type = 2
        } else {
            type = 1
        }

        if(shed_module.shed) {
            for(var i = 0; i < shed_module.shed.accessories_model.length; ++i) {
                if(shed_module.shed.accessories_model[i].type == 0) {
                    shed_module.shed.accessories_model[i].type = type
                    shed_module.shed.accessories_model[i].place = 0
                    shed_module.shed.accessories_model[i].location = 0

                    switch(type) {
                        case 1:
                        case 3:
                        case 6:
                            shed_module.shed.accessories_model[i].length = 3
                            shed_module.shed.accessories_model[i].height = 2
                            break                        
                        case 7:
                        case 2:
                            shed_module.shed.accessories_model[i].length = 1
                            shed_module.shed.accessories_model[i].height = 0.75
                            shed_module.shed.accessories_model[i].horizon = 1 
                            break
                        case 4:
                            shed_module.shed.accessories_model[i].length = 1
                            shed_module.shed.accessories_model[i].height = 2
                            break 
                        case 5:
                            shed_module.shed.accessories_model[i].length = 2.25
                            shed_module.shed.accessories_model[i].height = 2
                            break
                        case 6:
                            shed_module.shed.accessories_model[i].length = 1
                            shed_module.shed.accessories_model[i].height = 1
                            break           
                    }
                    shed_module.shed_accessory_index_gui.setValue(i)
                    break
                }                            
            }            
            shed_module.setup(shed_module.shed)
        }
    },
    shed_remove_accessory() {
        if(shed_module.shed) {
            for(var i = shed_module.shed.accessories_model.length - 1; i >= 0 ; --i) {
                if(shed_module.shed.accessories_model[i].type != 0) {
                    shed_module.shed.accessories_model[i].type = 0
                    break
                }                            
            }            
            shed_module.setup(shed_module.shed)
        }
    },
    getShedAccessoryObject: function(type, length, height, location) {
        var obj = { "type": type, "frames": [], "decoration": [], "parts": [], "length": length, "height": height, "horizon": 0, "location": location, "place": location, "side": 0, "state": 0, "step": 0};
        shed_module.shed_accessory_location_gui.setValue(location)
        return obj;
    },
    add: function(shed) {  
        shed_module.set_product(shed, false)   
        switch(shed.type) {
            case 67:
                flyover_module.add_posts(shed)
                gable_hip_module.add_beams(shed, shed.setup)
            case 64:
                shed.length = 6
                break
            case 65:
                shed.length = 8
                break         
            case 66:
            case 75: 
            case 80:
            case 85:
                shed.length = 11
                break
            case 100:
                shed.length = 17 
                shed.height = 2.8
                shed.step_size = 7 
                shed.step_length = 8 
                shed.back_length = 0
                break                  
        }
        shed.projection = 5
        shed.angle = 10
        shed.front_overhang = 0
        shed.back_overhang = 0

        shed.accessories_model = []
        
        var postsGeometry = new THREE.BoxGeometry(1, 1, 1); 
        var material = getMaterial(0xffffff, 0)    


        var glassMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff, metalness: 0, roughness: 0.1, transmission: 0.9, transparent: true
        });

        gable_hip_module.add_gutters(shed)   
        sceneItems.scene.add(shed.scene_group);
        shed_module.shed = shed;
        
        var no = 1
        if(shed.type == 100) no = 2
        for (var i = 0; i < no; ++i) {
            var obj = new THREE.Mesh(new THREE.PlaneGeometry(1, 1 ), getMaterial(0x808080, 0))            
            obj.rotation.x = 3 * Math.PI / 2
            shed.landscaping_model.push(obj)
            shed.scene_group.add(obj)
        }
        shed.landscaping_visible = 1

        shed_module.add_roofing(shed, function() { 
            if(shed.type == 67) return
            for(var i = 0; i < 16; ++i) {
                var frames = []
                var decoration = []
                for(var j = 0; j < 4; ++j) {  
                    var obj = new THREE.Mesh(postsGeometry, material)                               
                    obj.visible = true
                    shed.scene_group.add(obj)
                    frames.push(obj)    
                    shed.parts["list"].push(obj);   
                } 
                
                for(var k = 0; k < 8; ++k) {  
                    var obj 
                    if(k < 4) obj = new THREE.Mesh(postsGeometry, material) 
                    else obj = new THREE.Mesh(postsGeometry, glassMaterial)                              
                    obj.visible = true
                    shed.scene_group.add(obj)
                    decoration.push(obj)    
                    obj.position.y = k                
                }   

                var acc            
                if(i == 0 && shed.type == 100) acc =  shed_module.getShedAccessoryObject(4, 1.5, 2.4, 1)
                else if(i == 0) acc =  shed_module.getShedAccessoryObject(1, 3, 2, 1)  
                else if(i == 1 && shed.type > 64 && shed.type != 100) acc =  shed_module.getShedAccessoryObject(1, 3, 2, 4.25)                
                else if(i == 2  && shed.type > 65 && shed.type != 100) acc =  shed_module.getShedAccessoryObject(1, 3, 2, 7.25)
                else acc =  shed_module.getShedAccessoryObject(0, 2, 2, 1)
                acc.frames = frames 
                acc.decoration = decoration
                shed.accessories_model.push(acc)                
                loader_module.add_solar_span_roller_door_type_1(shed,  acc.parts, 24, function() {                      
                    shed_module.rollerDoors_color(shed, gui_module.roller_doors)    
                    shed_module.overhang_gui.setValue(0)
                })                                  
            }               
        })     

        return shed
    },
    add_roofing: function(product, call) {        
        product.profile  = 0
        if(product.type == 80 || product.type == 85 || product.type == 100) product.profile = 1
        if(product.roofing_group) {
            product.scene_group.remove(product.roofing_group)            
        }

        product.roofing_group = new THREE.Group();
        product.roofing_model = [];

        function call_back() {
            flyover_module.populate_parts(product) 
            product.scene_group.add(product.roofing_group);
            flyover_module.roofing_color(product, product.roofing)
            if(product.type == 100) shed_module.add_building_walls(product)
            else if(product.type != 67) shed_module.add_walls(product)              
            sceneItems.scene.add(product.scene_group);
            if(call) call()
            shed_module.setup(product)
        }

        if(product.profile == 0) {
            loader_module.add_solar_span_type_1(product, 2 * 64 * 2 * 4, call_back);
        } else if(product.profile == 1) {
            loader_module.add_custom_orb_type_1(product, 2 * 64 * 2 * 10, call_back);
        }        
    },
    rotate(shed, rotation) {
        if(shed && rotation) {
            var view_radian = Math.PI * rotation / 180
            var cos_view = Math.cos(view_radian);
            var sin_view = Math.sin(view_radian);
            shed.left_2_right_unit_vector.set(sin_view, 0, cos_view)
            shed.front_2_end_unit_vector.set(cos_view, 0, -1 * sin_view)
            shed.rotate = rotation
            shed_module.rotation = rotation

            for(var i = 0; i < shed.front_posts_model.length; ++i) {
                var obj = shed.front_posts_model[i]
                obj.rotation.y = view_radian
            }
            
            for(var i = 0; i < shed.back_posts_model.length; ++i) {
                var obj = shed.back_posts_model[i]
                obj.rotation.y = view_radian
            }
            
            for(var i = 0; i < shed.posts_base_model.length; ++i) {
                var obj = shed.posts_base_model[i]
                obj.rotation.y = view_radian
            }
    
            shed_module.setup(shed)                
        } 
    },
    add_walls: function(shed) {
        shed.walls_model = [];
        loader_module.add_solar_span_wall_type_1(shed, 2 * 64 * 4 * 4, function() {
            shed_module.walls_color(shed, gui_module.walls)
        });
    },
    add_building_walls: function(shed) {
        shed.walls_model = []
        var wall = new THREE.BoxGeometry(1, 1, 1); 
        var material = loader_module.get_wall_material(0); 
        for(var i = 0; i < 8 * 64 * 4 * 4; ++i) {          
            var obj = new THREE.Mesh(wall, material);
            obj.visible = false;
            shed.walls_model.push(obj)
            shed.scene_group.add(obj)
        } 
    },
    load_wall_material: function () {
        document.getElementById('loadBuildingImageInput2').click();
    },
    update_wall_material: function(material) {
        for(var i = 0; i < shed_module.shed.walls_model.length; ++i) {                               
            shed_module.shed.walls_model[i].traverse( function (child) {
                if(child.isMesh) child.material = material             
            });
        } 
        shed_module.setup(shed_module.shed)
    },   
    acc_animate_index: null,
    find_roofing_notch_rate(shed) {
        var roofing_notch_rate = 4
        if(shed.profile == 1) {
            roofing_notch_rate = 10            
        }  
        return roofing_notch_rate
    },
    find_wall_notch_rate(shed) {
        var roofing_notch_rate = 4          
        return roofing_notch_rate
    },
    animate_accessories_parts(shed, acc, frame) {
        if(!acc) return
        switch(acc.type) {
            case 1:                
                var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)                

                var length = Math.ceil(acc.height * roofing_notch_rate) 
                var len = Math.ceil(acc.length * roofing_notch_rate)
                var l = acc.place + (len - 1) / roofing_notch_rate

                for(var i = length; i < acc.parts.length; ++i) acc.parts[i].visible = false  
                if(acc.state == 0) {
                    for(var i = 0; i < length; ++i) if(acc.parts[i]) acc.parts[i].visible = true
                    acc.state = 1
                    return 
                }

                if(shed_module.acc_animate_index) return 

                if(frame != 1) { 
                    var valid = true
                    switch(acc.side) {
                        case 0:
                        case 2:
                            if(l >= shed.length) valid = false
                            break
                        case 1:
                        case 3:
                            if(l >= shed.projection) valid = false
                            break                    
                    }            
                    
                    if(valid) {
                        if(acc.state == 1) {
                            for(var i = 0; i < length; ++i) if(acc.parts[i]) acc.parts[i].visible = true
                        } 
                    } else {
                        for(var i = 0; i < acc.parts.length; ++i) if(acc.parts[i]) acc.parts[i].visible = false                  
                    }          
                    return
                }     
                   
                if(acc.state == 1) {                    
                    var n = 0
                    shed_module.acc_animate_index = acc
                    acc.state = 2         
                    var intervalId = setInterval(function() {                        
                        acc.parts[n].visible = false
                        if(n++ == length) {
                            clearInterval(intervalId)                     
                            shed_module.acc_animate_index = null             
                        } 
                    }, 500);
                } else if(acc.state == 2) {
                    var n = 1
                    shed_module.acc_animate_index = acc   
                    acc.state = 1       
                    var intervalId = setInterval(function() {                        
                        acc.parts[length - n].visible = true
                        if(n++ == length) {
                            clearInterval(intervalId)  
                            shed_module.acc_animate_index = null
                        }
                    }, 500);
                } else {
                    acc.state = 1
                }         
                break
            case 4:
                if(shed_module.acc_animate_index) return                 
                if(frame != 1) return
                if(acc.state < 6) {                    
                    shed_module.acc_animate_index = acc                            
                    var intervalId = setInterval(function() {                       
                        shed_module.setup_accessories_decoration_position(shed, acc, acc.decoration[0])
                        if(++acc.state == 6) {
                            clearInterval(intervalId)                     
                            shed_module.acc_animate_index = null             
                        } 
                    }, 100);

                } else if(acc.state < 12) {
                    shed_module.acc_animate_index = acc   
                    var intervalId = setInterval(function() {
                        shed_module.setup_accessories_decoration_position(shed, acc, acc.decoration[0])
                        if(++acc.state == 12) {
                            clearInterval(intervalId)                     
                            shed_module.acc_animate_index = null    
                            acc.state = 0         
                        } 
                    }, 100);
                } else {
                    acc.state = 0
                }         
                break
        }
        
    },
    setup_accessories_parts(shed, acc, len, angle) {  
        var cos = Math.cos((shed.angle) * Math.PI / 180)
        for(var i = 0; i < acc.parts.length; ++i) {
            var obj = acc.parts[i]
            v1.copy(shed.front_2_end_unit_vector) 
            v2.copy(shed.left_2_right_unit_vector)   
            obj.rotation.set(0, 0, 0)   
            
            obj.position.copy(shed.position)
            switch(acc.type) {
                case 1:
                    obj.scale.z = (len - 1) / 4
                    switch(acc.side) {
                        case 0:
                            obj.rotation.y = angle + Math.PI / 2
                            obj.rotation.z = 3 * Math.PI / 2 
                            v1.multiplyScalar(0)
                            v2.multiplyScalar(acc.place + (len + 1) / 8)   
                            obj.position.y = i / 4 + 0.175             
                            break
                        case 1:
                            obj.rotation.y = angle 
                            obj.rotation.z = Math.PI / 2 
                            v2.multiplyScalar(shed.length)
                            v1.multiplyScalar(acc.place + (len + 1) / 8)  
                            obj.position.y = i / 4    
                            break
                        case 2:
                            obj.rotation.y = angle + Math.PI / 2 
                            obj.rotation.z = Math.PI / 2 
                            v1.multiplyScalar(shed.projection)
                            v2.multiplyScalar(shed.length - acc.place - (len + 1) / 8)  
                            obj.position.y = i / 4     
                            break
                        case 3:
                            obj.rotation.y = angle 
                            obj.rotation.z = 3 * Math.PI / 2 
                            v2.multiplyScalar(0)
                            v1.multiplyScalar(shed.projection - acc.place - (len + 1) / 8)
                            obj.position.y = i / 4 + 0.175
                            break    
                        case 4:
                            obj.rotation.y = angle 
                            obj.rotation.z = 3 * Math.PI / 2 
                            v2.multiplyScalar(shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2)
                            v1.multiplyScalar(0 - acc.place - (len - 3) / 8)
                            obj.position.y = i / 4 + 0.175
                            break
                        case 5:
                            obj.rotation.y = angle + Math.PI / 2
                            obj.rotation.z = 3 * Math.PI / 2 
                            v1.multiplyScalar(-1 * shed.step_size)
                            v2.multiplyScalar(shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2 + acc.place + (len + 1) / 8)   
                            obj.position.y = i / 4 + 0.175             
                            break
                        case 6:
                            obj.rotation.y = angle 
                            obj.rotation.z = Math.PI / 2 
                            v2.multiplyScalar(shed.projection + shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2)
                            v1.multiplyScalar(-1 * shed.step_size + acc.place + (len + 1) / 8)  
                            obj.position.y = i / 4  
                            break
                    }      
                    v2.add(v1)
                    obj.position.add(v2)   
                    break
                default:
                    obj.visible = false
                    break
            }            
        }
        shed_module.animate_accessories_parts(shed, acc, 10)
    },
    setup_accessories_decoration_position(shed, acc, obj) {
        var roof_slope = (shed.angle) * Math.PI / 180
        var cos = Math.cos(roof_slope)
        var sin = Math.sin(roof_slope)

        var x_excess = 0
        var y_excess = 0
        var angle = (shed.rotate + 90) * Math.PI / 180 

        var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)  
        var len = Math.ceil(acc.length * roofing_notch_rate)

        var _scale = 1
        if(shed.type == 75) _scale = 2

        if(acc.type == 4) {
            var angle_excess = 0
            if(acc.state < 6) angle_excess = acc.state / 6
            else angle_excess = (11 - acc.state) / 6
            angle -= angle_excess
            y_excess = (len - 1) * (1- Math.cos(angle_excess)) / (2 * roofing_notch_rate) 
            x_excess = (1 - len) * Math.sin(angle_excess) / (2 * roofing_notch_rate) 
        }

        v1.copy(shed.front_2_end_unit_vector) 
        v2.copy(shed.left_2_right_unit_vector)   
        obj.rotation.set(0, 0, 0)                    
        obj.position.copy(shed.position)

        if(acc.type == 2) {
            obj.position.y = acc.height / 2 + acc.horizon
        } else if(acc.type == 7) {             
            if(shed.back_length < acc.height + acc.horizon) obj.position.y = shed.height + acc.height / 2
            else obj.position.y = shed.height + acc.height / 2 + acc.horizon
        } else {
            obj.position.y = acc.height / 2
        }
        
        if(acc.type == 6) {
            var height = shed.horizon + shed.height
            if(shed.type == 100) height += shed.back_length // back_length: storey_2nd
            if(acc.side == 0) {
                obj.rotation.z = -1 * roof_slope 
                obj.position.y = height + (_scale * shed.projection / 4) * sin / cos  
                v2.multiplyScalar(acc.place + (len + 1) / (2 * roofing_notch_rate))
                v1.multiplyScalar(_scale * shed.projection / 4 + 0.1)
            } else {
                obj.rotation.z = roof_slope 
                obj.position.y = height + (shed.projection / 4) * sin / cos 
                v2.multiplyScalar(acc.place + (len + 1) / (2 * roofing_notch_rate)) 
                v1.multiplyScalar(3 * shed.projection / 4 - 0.1)
            }
            obj.rotation.y = angle + Math.PI / 2
        } else {
            switch(acc.side) {
                case 0:
                    obj.rotation.y = angle + Math.PI / 2
                    obj.rotation.z = 3 * Math.PI / 2 
                    v1.multiplyScalar(x_excess)
                    v2.multiplyScalar(acc.place - y_excess + (len + 1) / (2 * roofing_notch_rate) )                                                    
                    break
                case 1:
                    obj.rotation.y = angle 
                    obj.rotation.z = Math.PI / 2 
                    v2.multiplyScalar(shed.length - x_excess)
                    v1.multiplyScalar(acc.place + (len + 1) / (2 * roofing_notch_rate) - y_excess)  
                    break
                case 2:
                    obj.rotation.y = angle + Math.PI / 2 
                    obj.rotation.z = Math.PI / 2 
                    v1.multiplyScalar(shed.projection - x_excess)
                    v2.multiplyScalar(shed.length - acc.place - (len + 1) / (2 * roofing_notch_rate) + y_excess) 
                    break
                case 3:
                    obj.rotation.y = angle 
                    obj.rotation.z = 3 * Math.PI / 2 
                    v2.multiplyScalar(x_excess)
                    v1.multiplyScalar(shed.projection - acc.place - (len + 1) / (2 * roofing_notch_rate) + y_excess)
                    break 
                case 4:
                    obj.rotation.y = angle 
                    obj.rotation.z = 3 * Math.PI / 2 
                    v2.multiplyScalar(shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2 + x_excess)
                    v1.multiplyScalar(y_excess - acc.place - (len - 3) / (2 * roofing_notch_rate))
                    break     
                case 5:
                    obj.rotation.y = angle + Math.PI / 2
                    obj.rotation.z = 1 * Math.PI / 2 
                    v1.multiplyScalar(x_excess - shed.step_size)
                    v2.multiplyScalar(shed.step_length + acc.place + (len + 1) / (2 * roofing_notch_rate) - y_excess + shed.front_overhang * cos - shed.back_overhang / 2)
                    break  
                case 6:
                    obj.rotation.y = angle 
                    obj.rotation.z = Math.PI / 2 
                    v2.multiplyScalar(-1 * x_excess + shed.projection + shed.front_overhang * cos + shed.step_length - shed.back_overhang / 2)
                    v1.multiplyScalar(-1 * shed.step_size + acc.place + (len + 1) / (2 * roofing_notch_rate) - y_excess)  
                    break       
            }
        }              
        v2.add(v1)
        obj.position.add(v2) 
    },
    setup_accessories_decoration(shed, acc) {           
        var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)  
        var len = Math.ceil(acc.length * roofing_notch_rate)
        var _scale = 1
        if(shed.type == 75) _scale = 2
        for(var i = 0; i < acc.decoration.length; ++i) {
            var obj = acc.decoration[i]            
            switch(acc.type) {
                case 2:
                case 4:
                case 5:
                case 6:
                case 7:
                    switch(i) {
                        case 0:
                        case 4:
                            if(acc.type == 5 && i == 0) {                                
                                if(i == 0) obj.scale.set(acc.height, 0.1, 0.1)    
                            } else if(acc.type == 4) {
                                obj.scale.z = (len - 1) / 4 - 0.1
                                obj.scale.x = acc.height - 0.1
                                obj.scale.y = 0.05
                            } else if(acc.type == 6) {
                                obj.scale.z = (len - 1) / 4
                                obj.scale.x = _scale * shed.projection / (2 * Math.cos((shed.angle) * Math.PI / 180))
                                obj.scale.y = 0.2 
                            } else {
                                obj.scale.z = (len - 1) / 4
                                obj.scale.x = acc.height
                                obj.scale.y = 0.2
                            }   
                            
                            if(i == 0 && (acc.type == 2 || acc.type == 6 || acc.type == 7)) obj.visible = false
                            else if(i == 4 && acc.type == 4) obj.visible = false
                            else obj.visible = true                                                      

                            shed_module.setup_accessories_decoration_position(shed, acc, obj)                              
                            break
                        default:
                            obj.visible = false
                            break
                    }                    
                    break
                default:
                    obj.visible = false
                    break
            }            
        }
       
    },
    setup_accessories_shed(shed) {
        var frame_w = 0.1

        var roof_slope = (shed.angle) * Math.PI / 180
        var cos = Math.cos(roof_slope)
        var sin = Math.sin(roof_slope)

        var angle = (shed.rotate + 90) * Math.PI / 180 
        var angle1 = (shed.rotate) * Math.PI / 180 

        var roofing_notch_rate           
        var x, h, l
        
        var _scale = 1
        if(shed.type == 75) _scale = 2

        for(var i = 0; i < shed.accessories_model.length; ++i) {
            var acc = shed.accessories_model[i]
            if(acc.type == 6 && (shed.type == 80 || shed.type == 85 || shed.type == 100))  roofing_notch_rate = 10
            else roofing_notch_rate = 4
            if(acc.height >= shed.height) {
                acc.height =  Math.ceil(4 * shed.height - 1) / 4 
            }
            var len = Math.ceil(acc.length * roofing_notch_rate)
            shed_module.setup_accessories_parts(shed, acc, len, angle) 
            shed_module.setup_accessories_decoration(shed, acc) 
            for(var j = 0; j < acc.frames.length; ++j) {
                var obj = acc.frames[j]                    
                switch(acc.type) {
                    case 1:                        
                    case 2:     
                    case 3:  
                    case 4:
                    case 5:  
                    case 6:   
                    case 7:                                
                        v1.copy(shed.front_2_end_unit_vector) 
                        v2.copy(shed.left_2_right_unit_vector)   
                        obj.rotation.set(0, 0, 0)   
                        obj.rotation.y = angle
                        obj.position.copy(shed.position)

                        x = acc.place
                        h = acc.height / 2
                        l = acc.place 
                        switch(acc.type) {
                            case 2:
                            case 7:                                 
                                if(acc.type == 7) {
                                    if(acc.type == 7) {             
                                        if(shed.back_length < acc.height + acc.horizon) h = shed.height + acc.height / 2 
                                        else h = shed.height + acc.height / 2 + acc.horizon
                                    } 
                                } else h = acc.height / 2 + acc.horizon
                            case 1:
                            case 3:
                            case 4:
                            case 5:
                                switch(j) {
                                    case 0:
                                        obj.scale.set(frame_w, acc.height, frame_w) 
                                        break
                                    case 1:      
                                        l += ((len - 1) / roofing_notch_rate) / 2                         
                                        if(acc.side == 0 || acc.side == 2 || acc.side == 5) {
                                            obj.scale.set((len - 1) / roofing_notch_rate, frame_w, frame_w) 
                                        } else {
                                            obj.scale.set(frame_w, frame_w, (len - 1) / roofing_notch_rate) 
                                        }
                                        if(acc.type == 2 || acc.type == 7) {
                                            h += acc.height / 2
                                        } else h = acc.height
                                        break
                                    case 2:
                                        l += (len - 1) / roofing_notch_rate
                                        obj.scale.set(frame_w, acc.height, frame_w) 
                                        break
                                    case 3:
                                        l += ((len - 1) / roofing_notch_rate) / 2 
                                        if(acc.side == 0 || acc.side == 2 || acc.side == 5) {
                                            obj.scale.set((len - 1) / roofing_notch_rate, frame_w, frame_w) 
                                        } else {
                                            obj.scale.set(frame_w, frame_w, (len - 1) / roofing_notch_rate) 
                                        }
                                        if(acc.type == 2 || acc.type == 7) {
                                            h -= acc.height / 2
                                        } else if(acc.type == 5) {
                                            h = 0.05 
                                        } else h = acc.height
                                        break
                                }
                                switch(acc.side) {
                                    case 0:
                                        obj.visible = true
                                        v1.multiplyScalar(0)                 
                                        v2.multiplyScalar(l + 1 / roofing_notch_rate)             
                                        break
                                    case 1:
                                        obj.visible = true
                                        v2.multiplyScalar(shed.length)                 
                                        v1.multiplyScalar(l + 1 / roofing_notch_rate)          
                                        break
                                    case 2:
                                        obj.visible = true
                                        v1.multiplyScalar(shed.projection)                 
                                        v2.multiplyScalar(shed.length - l - 1 / roofing_notch_rate)  
                                        break
                                    case 3:
                                        obj.visible = true
                                        v2.multiplyScalar(0)                 
                                        v1.multiplyScalar(shed.projection - l - 1 / roofing_notch_rate)                                                                
                                        break    
                                    case 4:
                                        obj.visible = true
                                        v2.multiplyScalar(shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2)                 
                                        v1.multiplyScalar(-1 * l + 1 / roofing_notch_rate)             
                                        break   
                                    case 5:
                                        obj.visible = true
                                        v1.multiplyScalar(-1 * shed.step_size)                 
                                        v2.multiplyScalar(shed.step_length + l + 1 / roofing_notch_rate + shed.front_overhang * cos - shed.back_overhang / 2)             
                                        break   
                                    case 6:
                                        obj.visible = true
                                        v2.multiplyScalar(shed.projection + shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2)                 
                                        v1.multiplyScalar(-1 * shed.step_size + l + 1 / roofing_notch_rate)             
                                        break                         
                                    default: 
                                        obj.visible = false
                                        break
                                }
                                break
                            case 6:
                                switch(j) {
                                    case 0: 
                                    case 1:                                                                            
                                        l += ((len - 1) / roofing_notch_rate) / 2
                                        obj.scale.set((len - 1) / roofing_notch_rate, frame_w, frame_w)                                        
                                        h = shed.horizon + shed.height 
                                        if(shed.type == 100) h += shed.back_length // back_lenght: storey_2nd
                                        if(j == 1) {
                                            x = _scale * shed.projection / 2 - 0.1
                                            h += _scale * shed.projection * sin / (2 * cos)  + 0.1
                                        } else {
                                            x = 0 
                                            h += 0.1
                                        }                                       

                                        if(acc.side == 1) {                                            
                                            x = shed.projection - 0.05 - x
                                            if(j == 1) {
                                                x += 0.05
                                                h -= 0.025
                                            }
                                        }                                                                            
                                        
                                        obj.visible = true
                                        v1.multiplyScalar(x)                 
                                        v2.multiplyScalar(l + 1 / roofing_notch_rate)           
                                        break
                                    case 2:  
                                    case 3:                                         
                                        obj.scale.set(frame_w, frame_w, (_scale * shed.projection / (cos * 2)))                                              
                                        x = _scale * shed.projection / 4                                                                               
                                        
                                        if(j == 3) l += ((len - 1) / roofing_notch_rate)  
                                        if(acc.side == 1) {
                                            x += _scale * shed.projection / 2
                                        } 
                                        h = shed.horizon + shed.height + (_scale * shed.projection / 4) * sin / cos + 0.1
                                        if(shed.type == 100) h += shed.back_length // back_lenght: storey_2nd
                                        obj.visible = true
                                        v1.multiplyScalar(x)                 
                                        v2.multiplyScalar(l + 1 / roofing_notch_rate)                     

                                        break                                                             
                                }
                                switch(acc.side) {
                                    case 0:
                                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, roof_slope)                                                                         
                                        break
                                    case 1:
                                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, -1 * roof_slope)                                 
                                        break                               
                                    default: 
                                        obj.visible = false
                                        break
                                }
                                break
                        }            
                        v2.add(v1)
                        obj.position.add(v2)                        
                        obj.position.y =  h
                        break
                    default:
                        obj.visible = false
                        break
                }         
            }            
        }
       
    }, 
    check_wall_visibility(shed, side, up, no) {
        var ret = 0
        if(up) ret = shed.height        

        var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)  
        
        var h = ret
        for(var i = 0; i < shed.accessories_model.length; ++i) {
            var acc = shed.accessories_model[i]
            if(acc.side == side) {
                var place = acc.place * roofing_notch_rate
                var len = acc.length * roofing_notch_rate                
                if(up) {     
                    switch(acc.type) {
                        case 1:
                        case 3:
                        case 4:
                        case 5:
                            if(no > place && no < len + place ) {
                                if(shed.height > acc.height) h = shed.height - acc.height
                                else h = 0.01
                            }                                
                            if(h < ret) ret = h
                            break
                        case 2:
                            if(no > place && no < len + place) {
                                if(shed.height > acc.height) h = shed.height - acc.height - acc.horizon 
                                else h = 0.01
                            }                                
                            if(h < ret) ret = h
                            break                        
                        default:
                            break
                    }               
                               
                } else {
                    switch(acc.type) {                                             
                        case 2:
                            if(no > place && no < len + place + 1) {
                                if(shed.height > acc.height) h = acc.horizon  
                                //if(acc.side != 3 && no > len + place - 1) h = 0                                 
                            } else {
                                h = 0
                            }                                              
                            if(h > ret) ret = h
                            break                           
                       
                    }

                }
            }            
        }        
        return ret
    },
    setup_walls_shed: function(shed) {
       
        var cnt = 0
        var order = 0

        var angle = (shed.rotate + 90) * Math.PI / 180     
        var roof_angle = shed.angle * Math.PI / 180    
        var tan = Math.tan(roof_angle)
        var ex = -1 * shed.front_overhang * Math.sin(roof_angle)

        var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)  
        var half_projection = shed.projection * roofing_notch_rate / 2

        var h, extra_h

        for(var i = 0; i < shed.walls_model.length; ++i) {
            
            var obj = shed.walls_model[i]     
            v1.copy(shed.front_2_end_unit_vector) 
            v2.copy(shed.left_2_right_unit_vector)    
            obj.rotation.set(0, 0, 0)    
            obj.position.copy(shed.position)
            switch(order) {
                case 0:
                    h = shed_module.check_wall_visibility(shed, 0, false, cnt)                    
                    if(h == 0) obj.visible = false                    
                    else {
                        obj.scale.set(1, 1, h)      
                        obj.rotation.y = angle          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v1.multiplyScalar(0)                 
                        v2.multiplyScalar((cnt + 1) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  h / 2
                        obj.visible = true
                    }
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 1
                    }
                    break
                case 1:
                    h =  shed_module.check_wall_visibility(shed, 0, true, cnt) 
                    if(h == 0) obj.visible = false
                    else {
                        if(shed.type > 79) {
                            if(cnt < 7 || cnt > shed.length * roofing_notch_rate - 7) {
                                obj.scale.set(1, 1, h + ex)
                                h = h - ex
                            } else obj.scale.set(1, 1, h)
                        } else obj.scale.set(1, 1, h)                              
                        obj.rotation.y = angle          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v1.multiplyScalar(0)                 
                        v2.multiplyScalar((cnt + 1) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  shed.height  - h / 2
                        obj.visible = true
                    }  
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 2
                    }
                    break
                case 2:
                    h = shed_module.check_wall_visibility(shed, 2, false, cnt)
                    if(h == 0) obj.visible = false
                    else {
                        obj.scale.set(1, 1, h)      
                        obj.rotation.y = angle          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, 3 * Math.PI / 2)                                
                        v1.multiplyScalar(shed.projection)                 
                        v2.multiplyScalar((shed.length * roofing_notch_rate - cnt) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  h / 2
                        obj.visible = true
                    }
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 3
                    }
                    break
                case 3:
                    h = shed_module.check_wall_visibility(shed, 2, true, cnt)
                    extra_h = 0
                    if(shed.type == 75) {
                        extra_h = 4 * shed.projection * tan / roofing_notch_rate - 0.02 
                    }
                    if(h == 0) obj.visible = false
                    else {
                        if(shed.type > 79) {
                            if(cnt < 7 || cnt > shed.length * roofing_notch_rate - 7) {
                                obj.scale.set(1, 1, h + ex)
                                h = h - ex
                            } else obj.scale.set(1, 1, h + extra_h)
                        } else obj.scale.set(1, 1, h + extra_h)                               
                        obj.rotation.y = angle          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, 3 * Math.PI / 2)                                
                        v1.multiplyScalar(shed.projection)                 
                        v2.multiplyScalar((shed.length * roofing_notch_rate - cnt) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y = shed.height - h / 2 + extra_h / 2
                        obj.visible = true
                    }
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 4
                    }
                    break
                case 4:
                    h = shed_module.check_wall_visibility(shed, 1, false, cnt)
                    if(h == 0) obj.visible = false
                    else {        
                        obj.scale.set(1, 1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.length)                 
                        v1.multiplyScalar((cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  h / 2
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 5
                    }
                    break  
                case 5:
                    h = shed_module.check_wall_visibility(shed, 1, true, cnt)                      
                    if(h == 0) obj.visible = false
                    else {   
                        switch(shed.type) {                           
                            case 80:
                            case 85:
                            case 100:
                                extra_h = ex
                                break
                            default:
                                extra_h = cnt * tan / roofing_notch_rate - 0.02  
                            break                                
                        }      
                        obj.scale.set(1, 1, h + extra_h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.length)                 
                        v1.multiplyScalar((cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  shed.height - h / 2 + extra_h / 2
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 6
                    }
                    break      
                case 6:
                    h = shed_module.check_wall_visibility(shed, 1, false, cnt + half_projection)
                    if(h == 0) obj.visible = false
                    else {     
                        obj.scale.set(1, 1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.length)                 
                        v1.multiplyScalar(shed.projection / 2 + (cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  h / 2 
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 7
                    }
                    break
                case 7:
                    h = shed_module.check_wall_visibility(shed, 1, true, cnt + half_projection) 
                    if(h == 0) obj.visible = false
                    else {  
                        switch(shed.type) {
                            case 75:
                                extra_h = (half_projection + cnt) * tan / roofing_notch_rate - 0.02
                                break
                            case 80:
                            case 85:
                            case 100:
                                extra_h = ex
                                break
                            default:
                                extra_h = (half_projection - cnt) * tan / roofing_notch_rate - 0.02
                            break                                
                        }                                
                        obj.scale.set(1, 1, h + extra_h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.length)                 
                        v1.multiplyScalar(shed.projection / 2 + (cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y = shed.height - h / 2 + extra_h / 2 
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 8
                    }
                    break
                case 8:
                    h = shed_module.check_wall_visibility(shed, 3, false, cnt)
                    if(h == 0) obj.visible = false
                    else {      
                        obj.scale.set(1, 1, h)      
                        obj.rotation.set(0, angle, 3 * Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(0.05)                 
                        v1.multiplyScalar(shed.projection - (cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  h / 2
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 9
                    }
                    break
                case 9:
                    h = shed_module.check_wall_visibility(shed, 3, true, cnt)                      
                    if(h == 0) obj.visible = false
                    else {  
                        switch(shed.type) {  
                            case 75:
                                extra_h = (2 * half_projection - cnt) * tan / roofing_notch_rate - 0.05
                                break                          
                            case 80:
                            case 85:  
                            case 100:                              
                                if(shed.symmetric) extra_h = ex
                                else extra_h =   cnt * tan / roofing_notch_rate - 0.02
                                break
                            default:
                                extra_h = cnt * tan / roofing_notch_rate - 0.02 
                            break
                        }       
                        obj.scale.set(1, 1, h + extra_h)      
                        obj.rotation.set(0, angle, 3 * Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(0.05)                 
                        v1.multiplyScalar(shed.projection - (cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y = shed.height - h / 2 + extra_h / 2
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 10
                    }
                    break
                case 10:
                    h = shed_module.check_wall_visibility(shed, 3, false, cnt + half_projection)
                    if(h == 0) obj.visible = false
                    else {    
                        obj.scale.set(1, 1, h)      
                        obj.rotation.set(0, angle, 3 * Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(0.05)                 
                        v1.multiplyScalar(shed.projection / 2 - (cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y =  h / 2
                        obj.visible = true
                    }
                    if(cnt < half_projection - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 11
                    }
                    break
                case 11: 
                    h = shed_module.check_wall_visibility(shed, 3, true, 2 * half_projection - cnt - 1) 
                    if(h == 0) obj.visible = false
                    else { 
                        switch(shed.type) {                           
                            case 80:
                            case 85:
                            case 100:
                                if(shed.symmetric) extra_h = ex
                                else extra_h =   cnt * tan / roofing_notch_rate - 0.02
                                break
                            default:
                                extra_h = (cnt) * tan / roofing_notch_rate - 0.02 
                            break                                
                        }                                
                        obj.scale.set(1, 1, h + extra_h)      
                        obj.rotation.set(0, angle, 3 * Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(0.05)                 
                        v1.multiplyScalar(cnt / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        obj.position.y = shed.height - h / 2 + extra_h / 2
                        obj.visible = true
                    }
                    if(cnt < half_projection) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        order = 12
                    }
                    break                                
                default:
                    obj.visible = false
                    break
            }    
        }
    }, 
    check_2_storey_wall_visibility(shed, side, up, no) {
        var ret = 0
        if(up) ret = shed.back_length        

        var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)  
        
        var h = ret
        for(var i = 0; i < shed.accessories_model.length; ++i) {
            var acc = shed.accessories_model[i]
            if(acc.side == side) {
                var place = acc.place * roofing_notch_rate
                var len = acc.length * roofing_notch_rate                
                if(up) {     
                    switch(acc.type) {
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            if(no > place && no < len + place ) {
                                if(shed.back_length > acc.height) h = shed.back_length - acc.height
                                else h = 0.01
                            }                                
                            if(h < ret) ret = h
                            break
                        case 7:
                            if(no > place && no < len + place) {
                                if(shed.back_length > acc.height) h = shed.back_length - acc.height - acc.horizon 
                                else h = 0.01
                            }                                
                            if(h < ret) ret = h
                            break                        
                        default:
                            break
                    }               
                               
                } else {
                    switch(acc.type) {                                             
                        case 7:
                            if(no > place && no < len + place + 1) {
                                if(shed.back_length > acc.height) h = acc.horizon 
                                else h = 0
                            } else {
                                h = 0
                            }                                         
                            if(h > ret) ret = h
                            break                          
                       
                    }

                }
            }            
        }        
        return ret
    },
    setup_walls_building: function(shed) {
       
        var cnt = 0
        var order = 0

        var angle = (shed.rotate + 90) * Math.PI / 180     
        var roof_angle = shed.angle * Math.PI / 180    
        var front_overhang_offset = shed.front_overhang * Math.cos(roof_angle)

        var roofing_notch_rate = shed_module.find_wall_notch_rate(shed)  
        var cos = Math.cos(shed.angle * Math.PI / 180)
        var h

        for(var i = 0; i < shed.walls_model.length; ++i) {
            
            var obj = shed.walls_model[i]     
            v1.copy(shed.front_2_end_unit_vector) 
            v2.copy(shed.left_2_right_unit_vector)    
            obj.rotation.set(0, 0, 0)    
            obj.position.copy(shed.position)
            
            switch(order) {
                case 16: 
                case 0:
                    if(order == 0) h = shed_module.check_wall_visibility(shed, 0, false, cnt)  
                    else h = shed_module.check_2_storey_wall_visibility(shed, 0, false, cnt)                   
                    if(h == 0) obj.visible = false                    
                    else {
                        obj.scale.set(0.25, h, 0.1)      
                        obj.rotation.y = angle                          
                        v1.multiplyScalar(0.05)                 
                        v2.multiplyScalar((cnt + 0.5) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 0) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < (shed.step_length + front_overhang_offset - shed.back_overhang / 2) * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break
                case 17:                 
                case 1:
                    if(order == 1) h = shed_module.check_wall_visibility(shed, 0, true, cnt)  
                    else h = shed_module.check_2_storey_wall_visibility(shed, 0, true, cnt) 
                    if(h == 0) obj.visible = false
                    else {
                        obj.scale.set(0.25, h, 0.1)                             
                        obj.rotation.y = angle                               
                        v1.multiplyScalar(0.05)                 
                        v2.multiplyScalar((cnt + 0.5) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 1) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2 
                        obj.visible = true
                    }  
                    if(cnt < (shed.step_length + front_overhang_offset - shed.back_overhang / 2) * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break
                case 18:
                case 2:
                    if(order == 2) h = shed_module.check_wall_visibility(shed, 5, false, cnt) 
                    else h = shed_module.check_2_storey_wall_visibility(shed, 5, false, cnt)                      
                    if(h == 0) obj.visible = false                    
                    else {
                        obj.scale.set(0.25, h, 0.1)      
                        obj.rotation.y = angle                          
                        v1.multiplyScalar(0.05 - shed.step_size)                 
                        v2.multiplyScalar((cnt + 0.5) / roofing_notch_rate + shed.step_length + front_overhang_offset - shed.back_overhang / 2)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 2) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.projection * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break
                case 19:
                case 3:
                    if(order == 3) h = shed_module.check_wall_visibility(shed, 5, true, cnt) 
                    else h = shed_module.check_2_storey_wall_visibility(shed, 5, true, cnt)   
                    if(h == 0) obj.visible = false
                    else {
                        obj.scale.set(0.25, h, 0.1)                             
                        obj.rotation.y = angle                               
                        v1.multiplyScalar(0.05 - shed.step_size)                 
                        v2.multiplyScalar((cnt + 0.5) / roofing_notch_rate + shed.step_length + front_overhang_offset - shed.back_overhang / 2)
                        v2.add(v1)
                        obj.position.add(v2)
                        if(order == 3) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2                         
                        obj.visible = true
                    }  
                    if(cnt < shed.projection * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = (shed.step_length + shed.projection) * roofing_notch_rate
                        ++order
                    }
                    break
                case 20:                    
                case 4:
                    if(order == 4) h = shed_module.check_wall_visibility(shed, 0, false, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 0, false, cnt)    
                    if(h == 0) obj.visible = false                    
                    else {
                        obj.scale.set(0.25, h, 0.1)      
                        obj.rotation.y = angle                          
                        v1.multiplyScalar(0.05)                 
                        v2.multiplyScalar((cnt + 0.5) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 4) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = (shed.step_length + shed.projection) * roofing_notch_rate
                        ++order
                    }
                    break
                case 21:
                case 5:
                    if(order == 5) h = shed_module.check_wall_visibility(shed, 0, true, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 0, true, cnt)
                    if(h == 0) obj.visible = false
                    else {
                        obj.scale.set(0.25, h, 0.1)                             
                        obj.rotation.y = angle                               
                        v1.multiplyScalar(0.05)                 
                        v2.multiplyScalar((cnt + 0.5) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 5) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2   
                        obj.visible = true
                    }  
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break  
                case 22:                  
                case 6:
                    if(order == 6) h = shed_module.check_wall_visibility(shed, 2, false, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 2, false, cnt) 
                    if(h == 0) obj.visible = false
                    else {
                        obj.scale.set(0.25, h, 0.1)       
                        obj.rotation.y = angle                                
                        v1.multiplyScalar(shed.projection)                 
                        v2.multiplyScalar((shed.length * roofing_notch_rate - cnt - 0.5) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 6) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break
                case 23:
                case 7:
                    if(order == 7) h = shed_module.check_wall_visibility(shed, 2, true, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 2, true, cnt)
                    if(h == 0) obj.visible = false
                    else {
                        obj.scale.set(0.25, h, 0.1)                              
                        obj.rotation.y = angle       
                        v1.multiplyScalar(shed.projection)                 
                        v2.multiplyScalar((shed.length * roofing_notch_rate - cnt - 0.5) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 7) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2  
                        obj.visible = true
                    }
                    if(cnt < shed.length * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break
                case 24:
                case 8:
                    if(order == 8) h = shed_module.check_wall_visibility(shed, 3, false, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 3, false, cnt) 
                    if(h == 0) obj.visible = false
                    else {        
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(0.01)                 
                        v1.multiplyScalar((shed.projection * roofing_notch_rate - cnt - 0.7) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 8) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.projection * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break 
                case 25: 
                case 9:
                    if(order == 9) h = shed_module.check_wall_visibility(shed, 3, true, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 3, true, cnt)                   
                    if(h == 0) obj.visible = false
                    else {    
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(0.01)                 
                        v1.multiplyScalar((shed.projection * roofing_notch_rate - cnt - .7) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 9) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2 
                        obj.visible = true
                    }
                    if(cnt < shed.projection * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break 
                case 26:   
                case 10:
                    if(order == 10) h = shed_module.check_wall_visibility(shed, 1, false, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 1, false, cnt) 
                    if(h == 0) obj.visible = false
                    else {        
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.length - 0.05)                 
                        v1.multiplyScalar((cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 10) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.projection * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break  
                case 27:
                case 11:
                    if(order == 11) h = shed_module.check_wall_visibility(shed, 1, true, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 1, true, cnt)                   
                    if(h == 0) obj.visible = false
                    else {    
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.length - 0.05)                 
                        v1.multiplyScalar((cnt + 0.75) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 11) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2 
                        obj.visible = true
                    }
                    if(cnt < shed.projection * roofing_notch_rate - 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break  
                case 28:                      
                case 12:
                    if(order == 12) h = shed_module.check_wall_visibility(shed, 4, false, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 4, false, cnt) 
                    if(h == 0) obj.visible = false
                    else {        
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2 + 0.025)                 
                        v1.multiplyScalar((1.5 - cnt) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 12) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.step_size * roofing_notch_rate + 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break  
                case 29:
                case 13:
                    if(order == 13) h = shed_module.check_wall_visibility(shed, 4, true, cnt) 
                    else h = shed_module.check_2_storey_wall_visibility(shed, 4, true, cnt)                        
                    if(h == 0) obj.visible = false
                    else {    
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.step_length + shed.front_overhang * cos - shed.back_overhang / 2 + 0.025)                 
                        v1.multiplyScalar((1.5 - cnt) / roofing_notch_rate)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 13) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2 
                        obj.visible = true
                    }
                    if(cnt < shed.step_size * roofing_notch_rate + 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break 
                case 30:
                case 14:
                    if(order == 14) h = shed_module.check_wall_visibility(shed, 6, false, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 6, false, cnt) 
                    if(h == 0) obj.visible = false
                    else {        
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.step_length + shed.projection + shed.front_overhang * cos - shed.back_overhang / 2 - 0.025)                 
                        v1.multiplyScalar((cnt + 0.5) / roofing_notch_rate - shed.step_size)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 14) obj.position.y =  h / 2 
                        else obj.position.y = h / 2 + shed.height
                        obj.visible = true
                    }
                    if(cnt < shed.step_size * roofing_notch_rate + 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break 
                case 31: 
                case 15:
                    if(order == 15) h = shed_module.check_wall_visibility(shed, 6, true, cnt)
                    else h = shed_module.check_2_storey_wall_visibility(shed, 6, true, cnt)      
                    if(h == 0) obj.visible = false
                    else {    
                        obj.scale.set(0.25, 0.1, h)      
                        obj.rotation.set(0, angle, Math.PI / 2)          
                        obj.rotateOnWorldAxis(shed.left_2_right_unit_vector, Math.PI / 2)                                
                        v2.multiplyScalar(shed.step_length + shed.projection + shed.front_overhang * cos - shed.back_overhang / 2 - 0.025)                 
                        v1.multiplyScalar((cnt + 0.5) / roofing_notch_rate - shed.step_size)
                        v2.add(v1)
                        obj.position.add(v2)  
                        if(order == 15) obj.position.y =  shed.height  - h / 2 
                        else obj.position.y =  shed.height + shed.back_length - h / 2 
                        obj.visible = true
                    }
                    if(cnt < shed.step_size * roofing_notch_rate + 1) {                  
                        ++cnt;
                    } else {
                        cnt = 0
                        ++order
                    }
                    break                                                                   
                default:
                    obj.visible = false
                    break
            }    
        }
    },       
    move: function(shed, position) {
        shed.position.copy(position)
        shed.position.y = 0
        shed_module.setup(shed)
    },
    ray_move: function(shed, object, position) {        
        var found = false
        for(var i = 0; i < shed.accessories_model.length; ++i) {
            var acc = shed.accessories_model[i]
            for(var j = 0; j < 4; ++j) {  
                var obj = acc.frames[j]                               
                if(obj == object) {
                    found = true
                    shed_module.shed_accessory_index_gui.setValue(i)
                    shed_module.animate_accessories_parts(shed, acc, j)
                }
            }                          
        }    
        if(shed.lock) return
        if(!found) {            
            shed.position.copy(position)
            shed.position.y = 0 
            shed_module.setup(shed)
        }       
    },
    setup: function(shed) {        
        switch(shed.type) {           
            case 64:
            case 65:
            case 66:
            case 75:
                shed_module.setup_walls_shed(shed)
                shed_module.setup_accessories_shed(shed)
                shed_module.setup_gutters_shed_patio(shed)
                shed_module.setup_roofing_shed(shed) 
                shed_module.setup_landscaping(shed)
                break
            case 67:                           
                shed_module.setup_front_posts(shed) 
                shed_module.setup_freestanding_back_posts(shed)
                
                gable_hip_module.setup_beams_dutch_patio(shed)
                shed_module.setup_accessories_shed(shed)
                shed_module.setup_gutters_shed_patio(shed)
                shed_module.setup_roofing_shed(shed) 
                shed_module.setup_landscaping(shed)
                break   
            case 80:
            case 85:
                shed_module.setup_walls_shed(shed)
                shed_module.setup_accessories_shed(shed)
                shed_module.setup_gutters_shed_patio(shed)
                shed_module.setup_roofing_hip_shed(shed) 
                shed_module.setup_landscaping(shed) 
                break
            case 100:
                shed_module.setup_walls_building(shed)
                shed_module.setup_accessories_shed(shed)
                shed_module.setup_gutters_shed_patio(shed)
                shed_module.setup_roofing_hip_shed(shed) 
                shed_module.setup_landscaping(shed)                   
                break                        
        }
        gui_module.set_dim_text()        
    },
    scale: function(shed, scale) {
       
    },
    check_roofing_visibility(shed, up, side, no) {
        var ret = 0
        if(up) ret = shed.projection / 2       

        var roofing_notch_rate = shed_module.find_roofing_notch_rate(shed)  
        
        var _pro = (((shed.projection / 2) + shed.front_overhang)) 
        var h = ret
        switch(shed.type) {
            case 80:
                if(side == 2) shed_typo_3()
                else shed_typo_2(1)
                break
            case 85:
                if(side == 2) shed_typo_4()
                else shed_typo_2(2)
                break
            case 100:
                if(side == 4) shed_typo_5()
                else if(side == 2) shed_typo_3()
                else shed_typo_2(1)
                break
            default:
                shed_typo_1()
                break
        }
        var acc
        var place
        var len 
        var overhang 
        function shed_typo_1() {
            for(var i = 0; i < shed.accessories_model.length; ++i) {
                acc = shed.accessories_model[i]
                if(acc.side == side) {
                    place = acc.place * roofing_notch_rate
                    len = acc.length * roofing_notch_rate 
                    overhang = Math.floor(shed.back_overhang * roofing_notch_rate / 2)              
                    if(up) {     
                        switch(acc.type) {
                            case 6:
                                if(no > place + overhang && no < len + place + overhang) {
                                    h = 0.01
                                }                                
                                if(h < ret) ret = h
                                break                            
                            default:
                                break
                        }               
                                   
                    } else {
                        switch(acc.type) {                                             
                            case 6:
                                if(no > place + overhang && no < len + place + overhang ) {
                                    h = 0.01                            
                                } else {
                                    h = 0
                                }                                              
                                if(h > ret) ret = h
                                break                        
                        }
                    }
                }            
            }        
        } 

        var f1, f2        
        function shed_typo_2(c) {     
            f1 = _pro * roofing_notch_rate / c

            f2 = (shed.length + shed.back_overhang) * roofing_notch_rate - f1
            ret = c * f1 / roofing_notch_rate - 0.01

            if(no < f1) {
                if(up) ret = 0
                else if(shed.symmetric) ret = no / roofing_notch_rate
                else shed_type_2_acc()
            } else if(no > f2) {
                if(up) ret = 0
                else ret = (f1 - no + f2) / roofing_notch_rate  
            } else {
                shed_type_2_acc()
            } 
            function shed_type_2_acc() {
                for(var i = 0; i < shed.accessories_model.length; ++i) {
                    acc = shed.accessories_model[i]
                    if(acc.side == side) {
                        place = acc.place * roofing_notch_rate 
                        len = acc.length * roofing_notch_rate 
                        overhang = Math.floor(shed.back_overhang * roofing_notch_rate / 2)              
                        if(up) {     
                            switch(acc.type) {
                                case 6:
                                    if(no > place + overhang && no < len + place + overhang) {
                                        h = 0.01
                                    }                                
                                    if(h < ret) ret = h 
                                    break                            
                                default:                            
                                    break
                            }               
                                       
                        } else {
                            switch(acc.type) {                                             
                                case 6:
                                    if(no > place + overhang && no < len + place + overhang ) {
                                        ret = 0.01                            
                                    }   
                                    break                        
                            }
                        }
                    }            
                } 
            }                   
        } 

        function shed_typo_3() {
            f1 = _pro * roofing_notch_rate 
            if(no < f1) {
                if(up) ret = 0
                else ret = no  / roofing_notch_rate 
            } else if(no < 2 * f1) {
                if(up) ret = 0
                else ret = (2 * f1 - no)  / roofing_notch_rate 
            } else {
                ret = 0
            }                         
        }

        function shed_typo_4() {
            f1 = _pro * roofing_notch_rate 
            if(no < f1 / 2) {
                if(up) ret = 0
                else ret = no  / roofing_notch_rate 
            } else if(no < 3 * f1 / 2) {
                if(up) ret = 0
                else ret = f1  / (2 * roofing_notch_rate) + 0.2
            } else if(no < 2 * f1) {
                if(up) ret = 0
                else ret = (2 * f1 - no)  / roofing_notch_rate 
            } else {
                ret = 0
            }                         
        } 
        
        function shed_typo_5() {
            f1 = _pro * roofing_notch_rate 
            if(no < f1) {
                if(up) ret = 0
                else ret = no  / roofing_notch_rate 
            } else if(no < f1 + (shed.step_size - shed.front_overhang + shed.back_overhang) * roofing_notch_rate) {
                if(up) ret = 0
                else ret = f1  / roofing_notch_rate 
            } else {
                ret = 0
            }                         
        }
        return ret        
    },
    setup_roofing_shed: function(product) {  
        var size, factor, scalar, offsetY

        var roofing_notch_rate = shed_module.find_roofing_notch_rate(product)  

        var roof_slope = (product.angle) * Math.PI / 180
        var cos = Math.cos(roof_slope)
        var sin = Math.sin(roof_slope)

        var cnt = 0, order = 0

        var projection = product.projection
        var d = projection / 2 
        var projection_size = d / cos
        var front_len_no = Math.floor((product.length + product.back_overhang) * roofing_notch_rate)

        var angle = (product.rotate + 90) * Math.PI / 180  
        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(product.position)
            roofing.rotation.set(0, 0, 0)
            switch(order) {
                case 0:
                    size = shed_module.check_roofing_visibility(product, false, 0, cnt) / cos                    
                    if(size == 0) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector)  

                        roofing.scale.z = size + product.front_overhang           
    
                        factor = size - product.front_overhang 
                        scalar =  (factor - 0.1) * cos / 2;
                        offsetY = (factor - 0.1) * sin / 2;
    
                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - product.back_overhang / 2)
                        v2.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = product.horizon + product.height + offsetY + 0.05
                    }                   
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 1                   
                    }
                    break
                case 1:
                    size = shed_module.check_roofing_visibility(product, true, 0, cnt)                     
                    if(size == 0) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector) 
                                    
                        if(size == product.projection / 2) {
                            switch(product.type) {
                                case 75:
                                    factor = 2 * size  / cos 
                                    roofing.scale.z = factor + 2 * product.front_overhang 
                                    scalar =  (factor - (1 + product.projection / 40) / 10) * cos / 2
                                    offsetY = (factor - (1 + product.projection / 40) / 10) * sin / 2
                                    break
                                default:
                                    factor = size  / cos 
                                    roofing.scale.z = factor + product.front_overhang
                                    scalar =  (factor - product.front_overhang - 0.1) * cos / 2
                                    offsetY = (factor - product.front_overhang - 0.1) * sin / 2
                                    break
                            }                                   
                        } else {
                            switch(product.type) {
                                case 75:
                                    roofing.scale.z = 2 * size / cos + product.front_overhang
                                    factor = 4 * projection_size - 2 * size + (product.front_overhang + product.projection / 400) * cos
                                    break
                                default:
                                    roofing.scale.z = size / cos 
                                    factor = 2 * projection_size - size  
                                    break
                            }                             
                            scalar =  factor * cos / 2
                            offsetY = factor * sin / 2
                        }

                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - product.back_overhang / 2)
                        v2.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = product.horizon + product.height + offsetY + 0.05
                    }
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 2
                    }
                    break  
                case 2:                       
                    size = shed_module.check_roofing_visibility(product, false, 1, cnt) 
                    if(size == 0 || product.type == 75) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, -1 * roof_slope) 
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector) 
                                                
                        factor = size / cos 
                        roofing.scale.z = factor + product.front_overhang

                        scalar =  product.projection - (factor - product.front_overhang) * cos / 2 
                        offsetY = (factor - product.front_overhang) * sin / 2                        

                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - product.back_overhang / 2)
                        v2.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = product.horizon + product.height + offsetY + 0.1
                    }   
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 3
                    }
                    break 
                case 3:
                    size = shed_module.check_roofing_visibility(product, true, 1, cnt) 
                    if(size == 0 || product.type == 75) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, -1 * roof_slope) 
                        v1.copy(product.left_2_right_unit_vector) 
                        v2.copy(product.front_2_end_unit_vector) 
 
                        
                        if(size == product.projection / 2) {
                            factor = 2 * projection_size - size / cos 
                            scalar =  product.projection - (factor - product.front_overhang) * cos / 2 
                            offsetY = (factor - product.front_overhang) * sin / 2
    
                            roofing.scale.z = size / cos + 0.05 + product.front_overhang                           
                        } else {
                            factor = size  / cos 
                            roofing.scale.z = factor 
                            scalar =  product.projection / 2 + (factor) * cos / 2
                            offsetY = (2 * projection_size - size) * sin / 2                             
                        }                            

                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - product.back_overhang / 2)
                        v2.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = product.horizon + product.height + offsetY + 0.1  
                    }
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 4
                    }
                    break                                      
                default:
                    roofing.visible = false
                    break
            }            
        }
    },
    setup_roofing_hip_shed(product) {
        var size, factor, scalar, offsetY

        var roofing_notch_rate = shed_module.find_roofing_notch_rate(product)  

        var roof_slope = (product.angle) * Math.PI / 180
        var cos = Math.cos(roof_slope)
        var sin = Math.sin(roof_slope)

        var cnt = 0, order = 0

        var projection = product.projection
        var d = projection / 2 
       
        var front_len_no = Math.floor((product.length +  product.back_overhang) * roofing_notch_rate)
        var len_no = Math.floor((product.projection + 2 * product.front_overhang) * roofing_notch_rate)
        var half_len_no = Math.floor((product.projection / 4 + product.front_overhang / 2) * roofing_notch_rate)
        var wing_len_no = Math.floor((product.step_size + product.projection + product.back_overhang - product.front_overhang) * roofing_notch_rate)
         
        var angle = (product.rotate + 90) * Math.PI / 180  
        var angle1 = (product.rotate) * Math.PI / 180
        var height = product.horizon + product.height
        if(product.type == 100) height = product.horizon + product.height + product.back_length + product.front_overhang * sin// back_length: storey_2nd
        for(var i = 0; i < product.roofing_model.length; ++i) {     
            
            var roofing = product.roofing_model[i]            
            roofing.position.copy(product.position)
            roofing.rotation.set(0, 0, 0)
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 
            switch(order) {
                case 0:
                    size = shed_module.check_roofing_visibility(product, false, 0, cnt) / cos                    
                    if(size == 0) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                       
                        roofing.scale.z = size          
    
                        factor = size - 2 * product.front_overhang 
                        scalar =  (factor + 0.0) * cos / 2;
                        offsetY = (factor + 0.0) * sin / 2;
    
                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - product.back_overhang / 2)
                        v2.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY + 0.05
                    }                   
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 1                   
                    }
                    break
                case 1:
                    size = shed_module.check_roofing_visibility(product, false, 2, cnt) / cos                    
                    if(!product.symmetric) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle - Math.PI / 2
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 2 * Math.PI  - roof_slope) 
                       
                        roofing.scale.z = size          
    
                        factor = size 
                        scalar =  (factor - product.back_overhang - 0.1) * cos / 2;
                        offsetY = (factor - 2 * product.front_overhang - 0.1) * sin / 2;
    

                        v2.multiplyScalar((cnt) / roofing_notch_rate - product.front_overhang)
                        v1.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY + 0.05
                    }
                    if(++cnt < len_no) {
                    } else {
                        cnt = 0
                        order = 2
                    }
                    break  
                case 2:                       
                    size = shed_module.check_roofing_visibility(product, false, 1, cnt) / cos
                    if(size == 0 || product.type == 75) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, -1 * roof_slope) 
                        
                        roofing.scale.z = size          
    
                        factor = size - 2 * product.front_overhang 
                        scalar =  product.projection - (factor - 0.1) * cos / 2;
                        offsetY = (factor - 0.1) * sin / 2;
    
                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - product.back_overhang / 2)
                        v2.multiplyScalar(scalar)
                        
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY + 0.1
                    }   
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 3
                    }
                    break 
                case 3:
                    size = shed_module.check_roofing_visibility(product, false, 2, cnt) / cos                    
                    if(size == 0) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle - Math.PI / 2
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, roof_slope) 
                                 
                        roofing.scale.z = size          
    
                        factor = size 
                        scalar =  product.length - (factor - product.back_overhang - 0.1) * cos / 2
                        offsetY = (factor - 2 * product.front_overhang - 0.1) * sin / 2
    

                        v2.multiplyScalar((cnt) / roofing_notch_rate - product.front_overhang)
                        v1.multiplyScalar(scalar)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY + 0.05
                    }
                    if(++cnt < len_no) {
                    } else {
                        cnt = 0
                        if(product.type > 99) order = 8
                        else if(product.type > 80) order = 4
                        else order = 12
                    }
                    break    
                case 4:
                    if(!product.symmetric) roofing.visible = false
                    else {
                        roofing.visible = true
                        size = (cnt) * sin / (cos * roofing_notch_rate)
                        
                        roofing.rotation.y = angle1
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 3 * Math.PI / 2)                    
                                    
                        roofing.scale.z = size        

                        v1.multiplyScalar(projection / 4 + 0.2 - product.back_overhang / 2 + 0.5 * cos * product.front_overhang) 
                        v2.multiplyScalar(cnt / roofing_notch_rate + d / 2 - 0.5 * cos * product.front_overhang) 
                        
                        v2.add(v1)                    
                        roofing.position.add(v2)
                        roofing.position.y = height + size / 2 + d * sin / (2 * cos)  - product.front_overhang * sin / 2  
                    }
                    if(++cnt < half_len_no + 1  ) {
                    } else {
                        cnt = 0
                        order = 5
                    }
                    break
                case 5:
                    if(!product.symmetric) roofing.visible = false
                    else {
                        roofing.visible = true
                        size = (half_len_no - cnt) * sin / (cos * roofing_notch_rate)
                        
                        roofing.rotation.y = angle1
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 3 * Math.PI / 2)                    
                                    
                        roofing.scale.z = size        
    
                        v1.multiplyScalar(projection / 4 + 0.2 - product.back_overhang / 2 + 0.5 * cos * product.front_overhang) 
                        v2.multiplyScalar(cnt / roofing_notch_rate + d) 
                        
                        v2.add(v1)                    
                        roofing.position.add(v2)
                        roofing.position.y = height + size / 2 + d * sin / (2 * cos)  - product.front_overhang * sin / 2                          
                    }
                    if(++cnt < half_len_no) {
                    } else {
                        cnt = 0
                        order = 6
                    }
                    
                    break   
                case 6:
                    if(product.type != 85) roofing.visible = false
                    else {
                        roofing.visible = true
                        size = (cnt) * sin / (cos * roofing_notch_rate)
                        
                        roofing.rotation.y = angle1
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector,1 * Math.PI / 2)                    
                                    
                        roofing.scale.z = size        
    
                        v1.multiplyScalar(product.length - projection / 4 + product.back_overhang / 2 - 0.5 * cos * product.front_overhang) 
                        v2.multiplyScalar(cnt / roofing_notch_rate + d / 2 - 0.5 * cos * product.front_overhang) 
                        
                        v2.add(v1)                    
                        roofing.position.add(v2)
                        roofing.position.y = height + size / 2 + d * sin / (2 * cos)  - product.front_overhang * sin / 2  
                        
                    }
                    if(++cnt < half_len_no + 1  ) {
                    } else {
                        cnt = 0
                        order = 7
                    }
                    break
                case 7:
                    if(product.type != 85) roofing.visible = false
                    else {
                        roofing.visible = true
                        size = (half_len_no - cnt) * sin / (cos * roofing_notch_rate)
                        
                        roofing.rotation.y = angle1
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 1 * Math.PI / 2)                    
                                    
                        roofing.scale.z = size        

                        v1.multiplyScalar(product.length - projection / 4  + product.back_overhang / 2 - 0.5 * cos * product.front_overhang) 
                        v2.multiplyScalar(cnt / roofing_notch_rate + d) 
                        
                        v2.add(v1)                    
                        roofing.position.add(v2)
                        roofing.position.y = height + size / 2 + d * sin / (2 * cos)  - product.front_overhang * sin / 2  
                    }
                    if(++cnt < half_len_no) {
                    } else {
                        cnt = 0
                        order = 12
                    }
                    break   
                case 8:
                    size = shed_module.check_roofing_visibility(product, false, 2, cnt) / cos                    
                    if(size == 0 || product.step_size == 0) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle
                        roofing.rotateOnWorldAxis(product.left_2_right_unit_vector, roof_slope) 
                       
                        roofing.scale.z = size          
    
                        factor = size   
                        scalar =  (factor) * cos / 2 
                        offsetY = (factor - 2 * product.front_overhang ) * sin / 2
    
                        v1.multiplyScalar((cnt + 1) / roofing_notch_rate - 0.075 - 1 * product.back_overhang / 2 + product.step_length)
                        v2.multiplyScalar(scalar - product.step_size - product.back_overhang)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY + 0.05
                    }                   
                    if(++cnt < front_len_no) {
                    } else {
                        cnt = 0
                        order = 10                   
                    }
                    break
                case 9:
                    break
                case 10:
                    size = shed_module.check_roofing_visibility(product, false, 4, cnt) / cos                    
                    if(size == 0 || product.step_size == 0) roofing.visible = false 
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle - Math.PI / 2
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, 2 * Math.PI  - roof_slope) 
                       
                        roofing.scale.z = size        
    
                        factor = size 
                        scalar =  (factor - 0.1) * cos / 2 + product.step_length  
                        offsetY = (factor - 2 * product.front_overhang - 0.1) * sin / 2 
    
                        v2.multiplyScalar((cnt) / roofing_notch_rate - product.back_overhang - product.step_size)
                        v1.multiplyScalar(scalar - 0.5 * product.back_overhang)
                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY 
                    }
                    if(++cnt < wing_len_no) {
                    } else {
                        cnt = 0
                        order = 11
                    }
                    break  
                case 11:
                    size = shed_module.check_roofing_visibility(product, false, 4, cnt) / cos                    
                    if(size == 0 || product.step_size == 0) roofing.visible = false
                    else {
                        roofing.visible = true
                        roofing.rotation.y = angle - Math.PI / 2
                        roofing.rotateOnWorldAxis(product.front_2_end_unit_vector, roof_slope) 
                                 
                        roofing.scale.z = size          
    
                        factor = size 
                        scalar =  product.step_length + product.projection + 2 * product.front_overhang - (factor - 0.1) * cos / 2
                        offsetY = (factor - 2 * product.front_overhang - 0.1) * sin / 2 
    
                        v2.multiplyScalar((cnt) / roofing_notch_rate - product.back_overhang - product.step_size)
                        v1.multiplyScalar(scalar - 0.5 * product.back_overhang)

                        v1.add(v2)
                        roofing.position.add(v1)
                        roofing.position.y = height + offsetY + 0.05
                    }
                    if(++cnt < wing_len_no) {
                    } else {
                        cnt = 0
                        order = 12
                    }
                    break                                                                             
                default:
                    roofing.visible = false
                    break
            }            
        }
    },
    setup_gutters_shed_patio: function(product) {
        var size 

        var roof_slope = (product.angle) * Math.PI / 180
        var cos = Math.cos(roof_slope)
        var sin = Math.sin(roof_slope)
        var ang = Math.atan(0.707 * Math.tan(roof_slope))

        var angle = (product.rotate + 90) * Math.PI / 180
        var angle1 = (product.rotate) * Math.PI / 180 
        

        var projection = product.projection
        var gutter_scale = 1.5 * (1 + product.projection / 80)
        
        var d = (projection / 2)       

        var height = product.horizon + product.height
        if(product.type == 100) height = product.horizon + product.height + product.back_length + product.front_overhang * sin // back_length: storey_2nd
        
        for(var i = 0; i < product.gutters_model.length; ++i) {
            var gutter = product.gutters_model[i]
            gutter.position.copy(product.position)
            gutter.rotation.set(0, 0, 0)
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector)   
            gutter.visible = true         
            if(i < 4) {                 
                
                switch(i) {
                    case 0:
                        gutter.rotation.y = angle                        
                        gutter.scale.set(product.length + product.back_overhang, gutter_scale, gutter_scale)
                        v1.multiplyScalar(product.length / 2  - Math.floor(product.back_overhang / 4))
                        v2.multiplyScalar(-1 * product.front_overhang * cos)
                        gutter.position.y = height - product.front_overhang * sin
                        break; 
                    case 1:
                        gutter.rotation.y = angle                        
                        gutter.scale.set((product.length + product.back_overhang), gutter_scale, gutter_scale)                        
                        v1.multiplyScalar(product.length / 2 - Math.floor(product.back_overhang / 4))
                        v2.multiplyScalar(projection + product.front_overhang * cos)   
                        switch(product.type) {
                            case 75:
                                gutter.position.y = height + (product.projection + product.front_overhang) * sin / cos
                                break  
                            default:
                                gutter.position.y = height - product.front_overhang * sin
                                break                          
                        }                       
                        break; 
                    case 2:
                        if(product.type < 79 || !product.symmetric) gutter.visible = false
                        else {
                            gutter.rotation.y = angle1                        
                            gutter.scale.set(product.projection + 2 * product.front_overhang, gutter_scale, gutter_scale)
                            v1.multiplyScalar(product.back_overhang / -2)
                            v2.multiplyScalar(product.projection / 2)
                            gutter.position.y = height - product.front_overhang * sin
                        }
                        break
                    case 3:
                        if(product.type < 79) gutter.visible = false
                        else {
                            gutter.rotation.y = angle1                        
                            gutter.scale.set(product.projection + 2 * product.front_overhang, gutter_scale, gutter_scale)
                            v1.multiplyScalar(product.length + product.back_overhang / 2)
                            v2.multiplyScalar(product.projection / 2)
                            gutter.position.y = height - product.front_overhang * sin

                        }
                        break
                    default:
                        gutter.visible = false
                        break                      
                                                                                                    
                }  
                v1.add(v2)
                gutter.position.add(v1)
            } else if(i < 8) {                                   
                switch(product.type) {
                    case 75:
                        size = 2 * d / cos + product.front_overhang
                        gutter.rotation.y = angle1
                        gutter_type_1()
                        break
                    case 80:                        
                    case 85:
                    case 100:
                        var c = 1
                        if(product.type == 85) c = 2                        
                        switch(i) {
                            case 4:
                                if(product.symmetric) {
                                    gutter.rotation.y = angle1 + Math.PI * 0.75
                                    gutter_type_2(c)
                                } else {
                                    size = d / cos 
                                    gutter.rotation.y = angle1
                                    gutter_type_1()
                                }                                   
                                break
                            case 5:
                                gutter.rotation.y = angle1 + Math.PI * 0.75
                                gutter_type_2(c)
                                break
                            case 6:
                                gutter.rotation.y = angle1 + Math.PI * 0.25  
                                gutter_type_2(c)                          
                                break
                            case 7:
                                if(product.symmetric) {
                                    gutter.rotation.y = angle1 + Math.PI * 0.25
                                    gutter_type_2(c)
                                } else {
                                    size = d / cos 
                                    gutter.rotation.y = angle1
                                    gutter_type_1()
                                }                                   
                                break
                        }                                    
                        break
                    default:
                        size = d / cos 
                        gutter.rotation.y = angle1
                        gutter_type_1()
                        break
                } 

                function gutter_type_2(c) {
                    size = 1 / (0.708 * Math.cos(ang) * c)   
                    v3.set(Math.sin(gutter.rotation.y), 0, Math.cos(gutter.rotation.y))
                    v4.set(Math.cos(gutter.rotation.y), 0, -1 * Math.sin(gutter.rotation.y))
                    if(c == 1) gutter.position.y = height + size * (d - product.front_overhang)* Math.sin(ang) / 2 + 0.11 
                    else gutter.position.y = height + size * (d - 3 * product.front_overhang) * Math.sin(ang) / 2 + 0.11 
                    gutter.scale.set(size * (d + product.front_overhang), gutter_scale, gutter_scale)
                    gutter.visible = true
                    switch(i) {                         
                        case 4:                                                      
                        gutter.rotateOnWorldAxis(v3, -1 * ang)                             
                        v1.multiplyScalar(product.back_overhang / -2) 
                        v2.multiplyScalar(product.front_overhang / -1)
                        v3.multiplyScalar(-0.02)
                        v4.multiplyScalar((projection + 2 * product.front_overhang) * -0.36 / c)                                                                  
                        break;  
                    case 5:                        
                        gutter.rotateOnWorldAxis(v3, ang)                               
                        v1.multiplyScalar(product.length + product.back_overhang / 2) 
                        v2.multiplyScalar(projection + product.front_overhang)
                        v3.multiplyScalar(0)     
                        v4.multiplyScalar((projection + 2 * product.front_overhang) * 0.36 / c)    
                        break;     
                    case 6:                       
                        gutter.rotateOnWorldAxis(v3, ang)                             
                        v1.multiplyScalar(product.length + product.back_overhang / 2) 
                        v2.multiplyScalar(-1 * product.front_overhang)
                        v3.multiplyScalar(0.1)     
                        v4.multiplyScalar((projection + 2 * product.front_overhang) * 0.36 / c)
                        break;      
                    case 7:                      
                        gutter.rotateOnWorldAxis(v3, -1 * ang)                            
                        v1.multiplyScalar(-1 * product.back_overhang / 2) 
                        v2.multiplyScalar(projection + product.front_overhang)
                        v3.multiplyScalar(0.02)    
                        v4.multiplyScalar((projection + 2 * product.front_overhang) * -0.36 / c)
                        break;                                                                                                                           
                    }  
                    v2.add(v1)
                    v3.add(v2)
                    v4.add(v3)
                    gutter.position.add(v4)                        
                }

                function gutter_type_1() {
                    gutter.position.y = height + (size - product.front_overhang) * sin / 2
                    gutter.scale.set(size + product.front_overhang, gutter_scale, gutter_scale)
                    gutter.visible = true
                    switch(i) {                         
                        case 4:                          
                            gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)                        
                            v1.multiplyScalar(product.back_overhang / -2)
                            v2.multiplyScalar((size - product.front_overhang) * cos / 2)                                            
                            break;  
                        case 5:                         
                            gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, -1 * roof_slope)                        
                            v1.multiplyScalar(product.length + product.back_overhang / 2 - 0.1)
                            v2.multiplyScalar(projection / 2 + (size + product.front_overhang) * cos / 2) 
                            switch(product.type) {
                                case 75:
                                    gutter.visible = false
                                    break                            
                            }                       
                            break;     
                        case 6:                        
                            gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, 1 * roof_slope)                        
                            v1.multiplyScalar(product.length + product.back_overhang / 2 - 0.1)
                            v2.multiplyScalar((size - product.front_overhang) * cos / 2)  
                            break;      
                        case 7:                        
                            gutter.rotateOnWorldAxis(product.left_2_right_unit_vector, -1 * roof_slope)                       
                            v1.multiplyScalar(product.back_overhang / -2)
                            v2.multiplyScalar(projection / 2 + (size + product.front_overhang) * cos / 2) 
                            switch(product.type) {
                                case 75:
                                    gutter.visible = false
                                    break                            
                            }   
                            break;                                                                                                                       
                    }  
                    v1.add(v2)
                    gutter.position.add(v1)  
                }               
            } else if(i == 8) {                  
                switch(product.type) {
                    case 75:
                        gutter.visible = false
                        break  
                    default:
                        gutter.rotation.y = angle                 
                        size = product.length + product.back_overhang                               
                        gutter.position.y = height + d * sin / cos + 0.1
                        if(product.symmetric || product.type < 80) {
                            if(product.type ==  85) {
                                size -= projection / 2 + product.front_overhang 
                            } else if(product.type > 79) {
                                size -= projection + product.front_overhang * 2
                            }
                            v1.multiplyScalar(product.length / 2)                     
                        } else {
                            if(product.type == 85) {
                                size -= projection / 4 + product.front_overhang / 2
                                v1.multiplyScalar(product.length / 2 - projection / 8 - product.front_overhang / 4) 
                            } else {
                                size -= projection / 2 + product.front_overhang 
                                v1.multiplyScalar(product.length / 2 - projection / 4 - product.front_overhang / 2) 
                            }  
                        }                         
                        v2.multiplyScalar(product.projection / 2)
                        gutter.scale.set(size, gutter_scale, gutter_scale)               
                        v1.add(v2)
                        gutter.position.add(v1) 
                        break                          
                }   
            } else {
                if(product.type == 100 && product.step_size > 0)  {                    
                    gutter.visible = true
                    switch(i) {                         
                        case 9:   
                            size = 1 / (0.708 * Math.cos(ang))  
                            gutter.rotation.y = angle1 + Math.PI * 0.75
                            v3.set(Math.sin(gutter.rotation.y), 0, Math.cos(gutter.rotation.y))
                            v4.set(Math.cos(gutter.rotation.y), 0, -1 * Math.sin(gutter.rotation.y))
                            gutter.position.y = height + size * (d - product.front_overhang)* Math.sin(ang) / 2 + 0.11 
                            
                            gutter.scale.set(size * (d + product.front_overhang), gutter_scale, gutter_scale)                                                 
                            gutter.rotateOnWorldAxis(v3, -1 * ang)                             
                            v1.multiplyScalar(product.back_overhang / -2 + product.step_length) 
                            v2.multiplyScalar(-1 * product.back_overhang - product.step_size)
                            v3.multiplyScalar(-0.02)
                            v4.multiplyScalar((projection + 2 * product.front_overhang) * -0.36)   
                            break
                        case 10: 
                            size = 1 / (0.708 * Math.cos(ang))  
                            gutter.rotation.y = angle1 + Math.PI * 0.25
                            v3.set(Math.sin(gutter.rotation.y), 0, Math.cos(gutter.rotation.y))
                            v4.set(Math.cos(gutter.rotation.y), 0, -1 * Math.sin(gutter.rotation.y))
                            gutter.position.y = height + size * (d - product.front_overhang) * Math.sin(ang) / 2 + 0.11 
                            
                            gutter.scale.set(size * (d + product.front_overhang), gutter_scale, gutter_scale)                                                  
                            gutter.rotateOnWorldAxis(v3, 1 * ang)                             
                            v1.multiplyScalar(product.back_overhang / -2 + product.step_length + projection) 
                            v2.multiplyScalar(0 - product.back_overhang + 2 * product.front_overhang - product.step_size)
                            v3.multiplyScalar(-0.02)
                            v4.multiplyScalar((projection - 6 * product.front_overhang) * 0.36)   
                            break 
                        case 11:   
                            gutter.rotation.y = angle                        
                            gutter.scale.set(product.projection + 2 * product.front_overhang, gutter_scale, gutter_scale)
                            v1.multiplyScalar(product.projection / 2 + product.front_overhang - product.back_overhang / 2 + product.step_length)
                            v2.multiplyScalar(-1 * product.back_overhang - product.step_size + 0.05)
                            gutter.position.y = height - product.front_overhang * sin
                            v3.multiplyScalar(0)
                            v4.multiplyScalar(0)   
                            break             
                        case 12:  
                            gutter.rotation.y = angle1                        
                            gutter.scale.set(product.step_size + product.back_overhang - product.front_overhang, gutter_scale, gutter_scale)
                            v1.multiplyScalar(product.step_length - product.back_overhang / 2)
                            v2.multiplyScalar(0.05 - product.step_size / 2 - product.back_overhang / 2 - product.front_overhang / 2)
                            gutter.position.y = height - product.front_overhang * sin
                            v3.multiplyScalar(0)
                            v4.multiplyScalar(0)   
                            break 
                        case 13:  
                            gutter.rotation.y = angle1                        
                            gutter.scale.set(product.step_size + product.back_overhang - product.front_overhang, gutter_scale, gutter_scale)
                            v1.multiplyScalar(product.projection - product.back_overhang / 2 + product.step_length + 2 * product.front_overhang)
                            v2.multiplyScalar(0.05 - product.step_size / 2 - product.back_overhang / 2 - product.front_overhang / 2)
                            gutter.position.y = height - product.front_overhang * sin 
                            v3.multiplyScalar(0)
                            v4.multiplyScalar(0)   
                            break   
                        case 14:  
                            gutter.rotation.y = angle1                        
                            gutter.scale.set(product.step_size + product.back_overhang - product.front_overhang, gutter_scale, gutter_scale)
                            v1.multiplyScalar(product.projection / 2 + product.front_overhang - product.back_overhang / 2 + product.step_length)
                            v2.multiplyScalar((product.projection + product.front_overhang - product.back_overhang - product.step_size) / 2)
                            gutter.position.y = height + + d * sin / cos + 0.1 
                            v3.multiplyScalar(0)
                            v4.multiplyScalar(0)   
                            break                                                                               
                        default:
                            gutter.visible = false
                            break
                    } 
                    v2.add(v1)
                    v3.add(v2)
                    v4.add(v3)
                    gutter.position.add(v4)   
                } else if(product.type == 85) {
                    size = (d / (2 * cos)) +  product.front_overhang / (2 * cos) 
                    gutter.position.y = height  + 0.75 * d * sin / cos  - product.front_overhang * sin / 4
                    gutter.scale.set(size, gutter_scale, gutter_scale)
                    gutter.rotation.y = angle1                    
                    gutter.visible = true   
                    switch(i) {
                        case 9:
                            if(!product.symmetric) gutter.visible = false
                            else {
                                gutter.rotateOnWorldAxis(v1, roof_slope)                            
                                v1.multiplyScalar(projection / 4 + 0.15 - product.back_overhang / 2  + 0.5 * cos * product.front_overhang) 
                                v2.multiplyScalar(3 * projection / 8 - 0.25 * cos * product.front_overhang) 
                            }                            
                            break                        
                        case 10:                            
                            gutter.rotateOnWorldAxis(v1, roof_slope)                            
                            v1.multiplyScalar(product.length - projection / 4 + 0.1 + product.back_overhang / 2 - 0.5 * cos * product.front_overhang) 
                            v2.multiplyScalar(3 * projection / 8 - 0.25 * cos * product.front_overhang) 
                            break
                        case 11:
                            if(!product.symmetric) gutter.visible = false
                            else {
                                gutter.rotateOnWorldAxis(v1, -1 * roof_slope)                            
                                v1.multiplyScalar(projection / 4 + 0.15 - product.back_overhang / 2  + 0.5 * cos * product.front_overhang) 
                                v2.multiplyScalar(5 * projection / 8 + 0.25 * cos * product.front_overhang) 
                            }
                            break                            
                        case 12:
                            gutter.rotateOnWorldAxis(v1, -1 * roof_slope)                            
                            v1.multiplyScalar(product.length - projection / 4 + 0.1 + product.back_overhang / 2 - 0.5 * cos * product.front_overhang) 
                            v2.multiplyScalar(5 * projection / 8 + 0.25 * cos * product.front_overhang) 
                            break
                        default:
                            gutter.visible = false
                            break
                    } 
                    v1.add(v2)
                    gutter.position.add(v1)
                } else gutter.visible = false
            }         
        }
    },  
    setup_front_posts: function(product) {
        if(product.forced_front_posts_no == 0) {
            product.front_posts_no = flyover_module.calculate_automatic_posts_no(product)
        } else {
            product.front_posts_no = product.forced_front_posts_no;
        }
           
        if(product.front_posts_no_buffer != product.front_posts_no) {
            product.front_posts_no_buffer = product.front_posts_no
            shed_module.front_post_offset_gui[0].setValue(-40)
            for(var i = 1; i < shed_module.front_post_offset_gui.length; ++i) {                
                if(i < product.front_posts_no - 1) shed_module.front_post_offset_gui[i].setValue(0) 
                else shed_module.front_post_offset_gui[i].setValue(40)             
            }  
        }        

        product.roofing_type = 1;
        if(Math.floor(product.projection) == 6) {
            product.roofing_type = 1.25;
        } else if(Math.floor(product.projection) > 6) {
            product.roofing_type = 1.5;
        }
        // visible needed
        var decking_not_visible_flag = true;
        if(product.horizon > 0)  decking_not_visible_flag = false;
           
        for(var i = 0; i < product.front_posts_model.length; ++i) {
            var obj = product.front_posts_model[i]
            if(i < product.front_posts_no) {
                obj.visible = true;
                product.posts_base_model[0 + i].visible  = decking_not_visible_flag
                product.posts_base_model[30 + i].visible  = decking_not_visible_flag
            } else {
                obj.visible = false;  
                product.posts_base_model[i].visible  = false
                product.posts_base_model[30 + i].visible  = false 
            }           
        } 
    
       var front_post_pitch = product.length / (product.front_posts_no * 2)
       
       // locate       
       for(var i = 0; i < product.front_posts_model.length; ++i) {
           product.front_posts_model[i].scale.set(product.front_post_width, product.height, product.front_post_width);          
           v1.copy(product.left_2_right_unit_vector) 
           var scalar = (1 + 2 * (i + product.front_posts_offset[i])) * front_post_pitch;
           v1.multiplyScalar(scalar)
           v1.add(product.position)


           v2.copy(product.front_2_end_unit_vector)              
           v2.multiplyScalar(0.13)
           v1.add(v2)
           v1.y += product.horizon + (product.height + 0) / 2

           product.front_posts_model[i].position.copy(v1)

           if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[i].position.copy(v1)
                
                product.posts_base_model[30 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[30 + i].position.copy(v1)
            } 
       }   
    },
    setup_freestanding_back_posts: function(product) {
        var back_posts_pitch
        
        if(product.forced_back_posts_no == 0) {
            product.back_posts_no = flyover_module.calculate_automatic_posts_no(product)         
        } else {
            product.back_posts_no = product.forced_back_posts_no;
        }

        back_posts_pitch = product.length / (product.back_posts_no * 2)       
               
        if(product.back_posts_no_buffer != product.back_posts_no) {
            product.back_posts_no_buffer = product.back_posts_no
        }
        // set position
        var decking_not_visible_flag = true;
        if(product.horizon > 0) decking_not_visible_flag = false;
        
        for(var i = 0; i < product.back_posts_model.length; ++i) { 
            if(i < product.back_posts_no) {
                product.back_posts_model[i].visible = true
                product.posts_base_model[6 + i].visible = decking_not_visible_flag
                product.posts_base_model[36 + i].visible  = decking_not_visible_flag
            } else {
                product.back_posts_model[i].visible = false
                product.posts_base_model[6 + i].visible  = false
                product.posts_base_model[36 + i].visible  = false
            }
            v1.copy(product.left_2_right_unit_vector) 
            v2.copy(product.front_2_end_unit_vector) 

            var distance = product.projection - 0.13
            
            var scalar = (1 + 2 * (i + product.back_posts_offset[i])) * back_posts_pitch;
           
            v2.multiplyScalar(distance)
            var height = product.height     
            product.back_posts_model[i].scale.set(product.front_post_width, height, product.front_post_width)
            v2.y = product.horizon + height / 2 
            v1.multiplyScalar(scalar)
            v1.add(product.position)
            v1.add(v2)
            product.back_posts_model[i].position.copy(v1)

            if(decking_not_visible_flag) {
                v1.y = 0;
                product.posts_base_model[6 + i].position.copy(v1)    
                           
                product.posts_base_model[36 + i].scale.set(product.front_post_width * 5 / 4, product.front_post_width, product.front_post_width / 4)                
                v1.y = product.front_post_width / 2 
                product.posts_base_model[36 + i].position.copy(v1)
            }            
        }
    }, 
    setup_landscaping(shed) {
        for(var i = 0; i < shed.landscaping_model.length; ++i) {
            var obj = shed.landscaping_model[i]
            var v1Value = shed.length / 2
            var v2Value = shed.projection / 2
            var l1 = shed.length
            var l2 = shed.projection
            if(i == 1) {
                v1Value = shed.step_length + (shed.projection) / 2 + shed.front_overhang * Math.cos((shed.angle) * Math.PI / 180) - shed.back_overhang / 2
                v2Value = -1 * shed.step_size / 2  
                l2 = shed.step_size
                l1 = shed.projection
            } 
            if(obj) {
               if(shed.landscaping_visible == 1) {
                   obj.scale.set(l1, l2)
                   obj.rotation.z = (shed.rotate + 90) * Math.PI / 180 
                   obj.position.copy(shed.position)            
                  
                   v1.copy(shed.left_2_right_unit_vector) 
                   v2.copy(shed.front_2_end_unit_vector)   
                   v1.multiplyScalar(v1Value)
                   v2.multiplyScalar(v2Value)
                   v1.add(v2)
                   obj.position.add(v1)
                   obj.position.y = 0.05
                   obj.visible = true 
               } else {
                   obj.visible = false 
               }
            }
        }        
    },
    set_front_post_offset: function(front_post_index, product, front_post_offset) {
        product.front_posts_offset[front_post_index] = front_post_offset
        product.back_posts_offset[front_post_index] = front_post_offset
        shed_module.setup(product)
    },
    set_accessories_parameters(product, index, type, length, height, location, horizon) {        
        var acc = product.accessories_model[index]
        if(acc) {
            acc.type = type
            acc.length = length
            acc.height = height
            acc.location = location   
            if(horizon) acc.horizon = horizon            
            shed_module.acc_index = index
            shed_module.set_product(product, true)                 
        }        
    },    
    gutters_color: function(product, color) {
        flyover_module.gutters_color(product, color)
    },
    roofing_color: function(product, color) {
        flyover_module.roofing_color(product, color)
    },
    walls_color: function(product, color) {
        var material = getMaterial(convertColor2Hex(selectColorbond(color)), 0);
        for(var i = 0; i < product.walls_model.length; ++i) {
            product.walls_model[i].traverse( function (child) {
                if(child.isMesh) child.material = material             
            });
        }        
    },
    rollerDoors_color: function(product, color) {
        var material = getMaterial(convertColor2Hex(selectColorbond(color)), 0);
        for(var i = 0; i < product.accessories_model.length; ++i) {
            var acc = product.accessories_model[i]
            for(var j = 0; j < acc.frames.length; ++j) {
                acc.frames[j].traverse( function (child) {
                    if(child.isMesh) child.material = material             
                });                
            }   
            for(var j = 0; j < acc.parts.length; ++j) {
                acc.parts[j].traverse( function (child) {
                    if(child.isMesh) child.material = material             
                });                
            }   
            for(var j = 0; j < acc.parts.length; ++j) {
                if(j < 4) acc.decoration[j].traverse( function (child) {
                    if(child.isMesh) child.material = material             
                });                
            }         
        }        
    },   
}

export { shed_module }

  