import React from 'react';
import { gui_module, sceneItems } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';

const landscapingTypes = [
    { value: 'invisible', label: 'Invisible' },
    { value: 'decking', label: 'Decking' },
    { value: 'concrete', label: 'Concrete' },
    { value: 'tile', label: 'Tile' },
    { value: 'stone', label: 'Stone' },
  ];

class SmartSceneSetupubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      x: 0,
      y: 0,
      z: 0,
      rotation_x: 0,
      rotation_y: 0,
      rotation_z: 0,
    }   
  }

  setX  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      x: v
    });
    sceneItems.scene.position.x = v
  }

  setY  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      y: v
    });
    sceneItems.scene.position.y = v
  }

  setZ  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      z: v
    });
    sceneItems.scene.position.z = v
  }

  setRoationX  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      rotation_x: v
    });
    sceneItems.scene.rotation.x = Math.PI * v / 180
  }

  setRoationY  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      rotation_y: v
    });
    sceneItems.scene.rotation.y = Math.PI * v / 180
  }

  setRoationZ  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      rotation_z: v
    });
    sceneItems.scene.rotation.z = Math.PI * v / 180
  }

  resetPosition  = (event) => {
    sceneItems.controler.reset()
    sceneItems.scene.position.set(0, 0, -6)
    sceneItems.scene.rotation.set(0, 0, 0)
  }

  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type
    var fan = true
    if(product?.fan > 0) fan = false
    this.setState({
        fan: fan,
        downlightNumber: product?.number,
        lock: product?.lock,
    })
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='scene_setup'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Scene X</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-25'
            max='25'
            step='0.1'
            value={this.state.x}
            onChange={this.setX}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-25'
            max='25'
            step='0.1'
            value={this.state.x}
            onChange={this.setX}
        />
                <button className="btn btn-secondary col-md-12 mx-1 my-1">Scene Y</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-25'
            max='25'
            step='0.1'
            value={this.state.y}
            onChange={this.setY}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-25'
            max='25'
            step='0.1'
            value={this.state.y}
            onChange={this.setY}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Scene Z</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-25'
            max='25'
            step='0.1'
            value={this.state.z}
            onChange={this.setZ}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-25'
            max='25'
            step='0.1'
            value={this.state.z}
            onChange={this.setZ}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Scene Roation X</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation_x}
            onChange={this.setRoationX}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation_x}
            onChange={this.setRoationX}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Scene Roation Y</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation_y}
            onChange={this.setRoationY}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation_y}
            onChange={this.setRoationY}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Scene Roation Z</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation_z}
            onChange={this.setRoationZ}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation_z}
            onChange={this.setRoationZ}
        />
        <button className="btn btn-warning col-md-12 mx-1 my-1" onClick={this.resetPosition}>Reset Position</button>
        </div>
      </div>
     );  
  }
}

export default SmartSceneSetupubmenu;
