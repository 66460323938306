import React from 'react'
import SmartMain from './SmartMain'

class Entry extends React.Component { 
  
  constructor() {
    super();
    this.state =  { 
      users_button_hidden: false,
      session: []     
    }    
    this.state.session = JSON.parse(window.sessionStorage.getItem('smartkits_token')) 
    if(this.state.session.access !== 'web_admin' && this.state.session.access !== 'service_admin' && this.state.session.access !== 'branch_admin') this.state.users_button_hidden = true
  }

  routeUsers = () => {
    window.location.replace('/users');
  }

  route3d_smartkits = () => {    
    var token = this.state.session.token
    if(token === null) return
    window.sessionStorage.setItem('user_info', JSON.stringify({access: 'smartkits_auth'}))
    window.location.href = '/3d?token=' + token
  }

  route3d_ezyblox = () => {    
    var token = this.state.session.token
    if(token === null) return
    window.sessionStorage.setItem('user_info', JSON.stringify({access: 'ezyblox_auth'}))
    window.location.href = '/3d?token=' + token
  }

  changePassword() {
    window.location.replace('/password');
  }

  switchSmart() {
    window.location.href = '/smart'
  }

  render() {
    return (
      <div className="App">    
        <button className="btn btn-success mx-3 col-md-2" hidden={this.state.users_button_hidden} onClick={this.routeUsers}>Users &#9977;</button>
        <button className="btn btn-warning mx-3 col-md-2" onClick={this.switchSmart}>S.M.A.R.T  &#9971;</button>
        <button className="btn btn-primary mx-3 col-md-2" hidden={true} onClick={() => this.changePassword()}>Change Password &#10030;</button><br/><br/>       
      </div>
    );
  }
}

export default Entry

  
