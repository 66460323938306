//import { data } from 'jquery';
import React from 'react'

class Users extends React.Component {
  
  constructor() {
    super();
    this.state =  {
      add: true,
      edit: true,
      users: [],
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirm: '',
      access: 'Sales',
      branch: 'Smartkits',
      submit_flag: false,
      error: '',
      error_display: true,
      not_admin: true

    }  
    this.onChangeValue = this.onChangeValue.bind(this); 
  }

  componentDidMount() {
    if(false) { // test
      this.setState({users: [          
        { fnm: "Scott", lnm: "Challen", uac: "web_admin", eml: "scott@qhi.com", bnm: "Smartkits"},
        { fnm: "Vic", lnm: "?", uac: "service_tech", eml: "vic@qhi.com", bnm: "Smartkits"}
      ]})
      return
    }
    var session = JSON.parse(window.sessionStorage.getItem('smartkits_token'))
    var token = session.token
    //console.log(session)
    if(session.access == 'web_admin') this.setState({not_admin: false})
    else this.setState({not_admin: true})
    var data = {
      token: token,
      branch: this.state.branch 
    }
    var xhr = new window.XMLHttpRequest()
    xhr.open('POST', '/user/list', true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhr.send(JSON.stringify(data))
    xhr.onreadystatechange = () => { 
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.setState({users: JSON.parse(xhr.responseText)})
        //console.log(this.state.users)
      } else {
        this.setState({users: [          
          { name: "Scott", family: "Challen", access: "Manager", email: "scott@qhi.com"},
          { name: "Vic", family: "?", access: "Manager", email: "vic@qhi.com"}
        ]})
      }
    }
  }

  delete = (i) => {
    console.log("delete: " + this.state.users[i].fnm  + " " + this.state.users[i].lnm + " b: " + this.state.users[i].bnm + " a: " + this.state.users[i].uac)

    var token = JSON.parse(window.sessionStorage.getItem('smartkits_token')).token
    //console.log(token)
    var data = {
      token: token,
      email: this.state.users[i].eml,
      branch: this.state.users[i].bnm,
      access: this.state.users[i].uac
    }
    var xhr = new window.XMLHttpRequest()
    xhr.open('POST', '/user/delete', true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhr.send(JSON.stringify(data))
    xhr.onreadystatechange = () => { 
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.setState({users: JSON.parse(xhr.responseText)})
        console.log(this.state.users)
      } 
    }
  };

  home = () => {
    window.location.replace('/entry');
  }
  toggleEdit = () => {
    this.setState({edit: !this.state.edit})
  }
  add = () => {
    this.setState({add: !this.state.add})
  }
  setFirstName(value) {
    this.setState({firstName: value})
    this.updateError();
  }
  setLastName(value) {
    this.setState({lastName: value})
    this.updateError();
  }
  setEmail(value) {
    this.setState({email: value})
    this.updateError();
  }
  setPassword(value) {
    this.setState({password: value})
    this.updateError();
    
  }
  setConfirm(value) {
    this.setState({confirm: value})  
    this.updateError();
  }
  changePassword() {
    window.location.replace('/password');
  }

  validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email));
  }

  validatePassword(password) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
    return re.test(String(password));
  }

  updateError() {
    setTimeout(() => {
      //console.log('email: ' + this.state.email + ' name: ' + this.state.firstName + ' confirm: ' + this.state.confirm + ' pwd: ' + this.state.password)
      if(this.state.firstName == '' || this.state.lastName == '' || this.state.password == '' || this.state.confirm == ''  || this.state.email == '') {
        this.setState({submit_flag: false})  
        this.setState({error: "Blank field", error_display: false})   
      } else {       
        if(!this.validateEmail(this.state.email)) {
          this.setState({submit_flag: false})
          this.setState({error: "Wrong email format", error_display: false})   
        } else if(!this.validatePassword(this.state.password)) {
          this.setState({submit_flag: false})
          this.setState({error: "Min 8 characters, 1 number, 1 Capital and 1 special sign", error_display: false})   
        } else if(this.state.confirm != this.state.password) {
          //console.log("They different")
          this.setState({submit_flag: false})
          this.setState({error: "Different confirm field", error_display: false})   
        } else {
          this.setState({submit_flag: true})
          this.setState({error: "", error_display: true})   
          //console.log("They NOT different")
        }
      }
    }, 1000);
    
  }

  onChangeValue(event) {
    console.log(event.target.value);
    this.setState({access: event.target.value})
  }

  showAccess(access) {
    var acc = 'Admin'
    if(access == 'branch_tech') acc = 'Sales'
    else if(access == 'web_admin') acc = 'Super Admin'
    return acc
  }

  shoot = (e) => {
    if(!this.state.submit_flag) return
    var token = JSON.parse(window.sessionStorage.getItem('smartkits_token')).token
    e.preventDefault();
    //console.log(token)
    var access = 'branch_tech'
    if(this.state.access == 'Administrator') access = 'branch_admin'
    var data = {
      token: token,
      email: this.state.email,
      password: this.state.password,
      fname: this.state.firstName,
      lname: this.state.lastName,
      branch: this.state.branch,
      access: access
    }
    var xhr = new window.XMLHttpRequest()
    xhr.open('POST', '/user/add', true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhr.send(JSON.stringify(data))
    xhr.onreadystatechange = () => { 
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.setState({users: JSON.parse(xhr.responseText)})
        console.log(this.state.users)
        this.setState({error: "", error_display: true})
        window.location.reload(false);
      } else if (xhr.readyState === 4 && (xhr.status === 401 || xhr.status === 403 || xhr.status === 406 || xhr.status === 412 || xhr.status === 428)) {
        //console.log('Wrong Parameters')        
        this.state.error_display = false
        switch(xhr.status) {
          case 401:
            this.setState({error: "Not authorized", error_display: false})
            break;
          case 403:
              this.setState({error: "The user might exist", error_display: false})
              break;            
          case 406:
            this.setState({error: "Incorrect email format", error_display: false})
            break;  
          case 412:
            this.setState({error: "Blank first or last name", error_display: false})
            break;  
          case 428:
            this.setState({error: "Incorrect password format", error_display: false})
            break;  
        }
      }
    }
  }

  render() {
    return (
      <div className="App">
        <h5> </h5>
    
        <button className="btn btn-primary mx-3 col-md-2" onClick={() => this.home()}>Home &#127969;</button>
        <button className="btn btn-success mx-3 col-md-2" onClick={() => this.add()}>Add User &#9997;</button>
        <button className="btn btn-warning mx-3 col-md-2" onClick={() => this.toggleEdit()}>Edit User &#10014;</button>
        <br/>
        <form hidden={this.state.add} onSubmit={this.shoot}> 
          <div hidden={this.state.error_display}>
            <button className="btn btn-danger col-md-4 mx-auto d-block mt-3" >{this.state.error}</button>
          </div>
          
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>First name</h4>
              <input type="text" className="form-control" placeholder="Enter name" value={this.state.firstName} onChange={event => this.setFirstName(event.target.value)} />
          </div>
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>Family</h4>
              <input type="text" className="form-control" placeholder="Enter family" onChange={event => this.setLastName(event.target.value)} />
          </div>       
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>Email address</h4>
              <input type="email" className="form-control" placeholder="Enter email" onChange={event => this.setEmail(event.target.value)} />
          </div>
          <div hidden={this.state.not_admin}>
            <div className="form-group col-md-4 mx-auto d-block mt-3" onChange={this.onChangeValue} >
              <input type="radio" value="Administrator" name="gender" checked={this.state.access === 'Administrator'}/> Administrator <br/>
              <input type="radio" value="Sales" name="gender" checked={this.state.access === 'Sales'}/> Sales <br/>          
            </div>
          </div>
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>Password</h4>
              <input type="password" className="form-control" placeholder="Enter password" onChange={event => this.setPassword(event.target.value)} />
          </div>
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>Confirm Password</h4>
              <input type="password" className="form-control" placeholder="Confirm password" onChange={event => this.setConfirm(event.target.value)} />
          </div>
          <div className="form-group">
          <button className="btn btn-primary mx-auto d-block mt-3" disabled={!this.state.submit_flag} onClick={this.shoot}>Add User</button>
          </div>
        </form>
        {this.state.users.map((data, idx) => (
          <div className="row mt-3" key={idx * 5}>
            <button className="btn btn-info mx-3 col-md-3" key={idx * 5}>{data.fnm + " " + data.lnm}</button><br/>
            <button className="btn btn-secondary mx-3 col-md-3" key={idx * 5 + 1}>{data.eml}</button><br/>
            <button className="btn btn-secondary mx-3 col-md-3" key={idx * 5 + 2}>{this.showAccess(data.uac)}</button><br/>
            <button className="btn btn-danger mx-3 col-md-1" hidden={this.state.edit} key={idx * 5 + 3} onClick={() => this.delete(idx)}>Delete</button><br/>            
          </div>
        ))}
      </div>
    );
  }
}

export default Users
