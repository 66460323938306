import React, { useState } from "react";
import './Login.css'

export default function Login() {
  const [email, setEmail] = useState("");
  var [password, setPassword] = useState("");
  var [display, setDisplay] = useState(true);
  var [error, setError] = useState("");
  /*function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
  }*/
  const shoot = (e) => {
        if(email === "" || password === "") return
        e.preventDefault();
        var payload = {
            user: email,
            password: password,
            token: ''
        }
        var xhr = new window.XMLHttpRequest()
        xhr.open('POST', '/authenticate', true)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.send(JSON.stringify(payload))
        xhr.onreadystatechange = () => { 
          if (xhr.readyState === 4 && xhr.status === 200 && xhr.responseText !== '') {
            window.sessionStorage.setItem('smartkits_token', xhr.responseText)
            window.location.replace('/entry')
          } else if (xhr.readyState === 4 && xhr.status === 401) {
            setDisplay(false)
            setError('Authentication Failed')
            //this.refs.passwordInput.value = ''
            setPassword('')
          } else if (xhr.readyState === 4 && xhr.status === 404) {
            setDisplay(false)
            setError('Authentication Failed')
            setPassword('')
          }  else if (xhr.readyState === 4 && xhr.status === 500) {
            setDisplay(false)
            setError('Authentication Failed')
            setPassword('')
          } else if (xhr.readyState === 4 && xhr.status === 451) {
            setDisplay(false)
            setError('Try 5 minutes later')
            setPassword('')
          } else {
            setDisplay(true) 
          }
        }
    }

  return (
    <>
        <form onSubmit={shoot}>
        <div >
            <img src="./logo.png" className="rounded mx-auto d-block mt-3 col-md-3" alt="Smartkits Logo" width="400" height="120"/>
        </div>          
        <div className="form-group col-md-4 mx-auto d-block mt-3">
            <h4>Email address</h4>
            <input type="email" className="form-control" placeholder="Enter email" onChange={event => setEmail(event.target.value)} />
        </div>

        <div className="form-group col-md-4 mx-auto d-block mt-3">
            <h4>Password</h4>
            <input type="password" className="form-control" placeholder="Enter password" value={password} onChange={event => setPassword(event.target.value)} />
        </div>
        <div className="form-group">
        <button className="btn btn-primary mx-auto d-block mt-3" onClick={shoot}>Login</button>
        <div hidden={display}>
            <button className="btn btn-danger col-md-4 mx-auto d-block mt-3" >{error}</button>
          </div>
        </div>
        </form>
    </>
  );
}