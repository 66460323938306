import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';
import { building_module } from '../smartkits/building_module';


class SmartSizeSubmenu extends React.Component {

  constructor(props) {
    super(props);
    this.state =  { 
      lengthMin: 3,
      lengthMax: 30,
      lengthStep: 0.1,
      length: 3,
      projectionMin: 3,
      projectionMax: 20,
      projectionStep: 0.1,
      projection: 3,
      backLength: 1,
      stepLength: 0,
      stepSize: 3,
      height: 2.4,
      wingLengt: 3,
      overhang: 0,
      test: 0,
      test1: 0,
      test2: 0
    }   
  }

  setTest = (event) => {
    var product = product_manager_module.products[gui_module.index - 1]
    var v = event.target.valueAsNumber
    product.test = v
    this.setState({
      test: v
    })   
    gui_module.length_gui.setValue(this.state.length) 
  }

  setTest1 = (event) => {
    var product = product_manager_module.products[gui_module.index - 1]
    var v = event.target.valueAsNumber
    product.test1 = v
    this.setState({
      test1: v
    })   
    gui_module.length_gui.setValue(this.state.length) 
  }

  setTest2 = (event) => {
    var product = product_manager_module.products[gui_module.index - 1]
    var v = event.target.valueAsNumber
    product.test2 = v
    this.setState({
      test2: v
    })   
    gui_module.length_gui.setValue(this.state.length) 
  }

  setLength = (event) => {
    var v = event.target.valueAsNumber
    var p = this.state.projection
    var type = this.getProductType()
    if(type == 20 || type == 21) p = v
    this.setState({
      length: v,
      projection: p
    })
    
    if(type < 32) {
      gui_module.length_gui.setValue(v)
      if(type == 20 || type == 21) {
        gui_module.projection_gui.setValue(v)
      }
    } else if(type > 63) {
      shed_module.length_gui.setValue(v)
    } else {
      building_module.length_gui.setValue(v)
    }
    this.props.generatePriceTable()
  }

  setProjection = (event) => {
    var v = event.target.valueAsNumber
    var l = this.state.length
    var type = this.getProductType()
    if(type == 20 || type == 21) l = v
    this.setState({
      projection: v
    })
    if(type < 32) {
      gui_module.projection_gui.setValue(v)
      if(type == 20 || type == 21) {
        gui_module.length_gui.setValue(v)
      }
    } else if(type > 63) {
      shed_module.projection_gui.setValue(v)
    } else {
      building_module.width_gui.setValue(v)
    }
    this.props.generatePriceTable()
  }

  setBackLength = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      backLength: v
    })
    var type = this.getProductType()
    if(type == 13 || type == 14) {
      gui_module.angled_back_length_gui.setValue(v)
    }
  }

  setHeight = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      height: v
    })
    var type = this.getProductType()
    if(type < 32) {
      gui_module.height_gui.setValue(v)
    } else if(type > 63) {
      shed_module.height_gui.setValue(v)
    } else {
      building_module.height_gui.setValue(v)
    }
  }

  setStepLength = (event) =>  {
    var v = event.target.valueAsNumber
    this.setState({
      stepLength: v
    })
    var type = this.getProductType()
    if(type < 64) {
      gui_module.step_length_gui.setValue(v)
    } else {
      shed_module.step_length_gui.setValue(v)
    }
  }

  setStepSize = (event) =>  {
    var v = event.target.valueAsNumber
    this.setState({
      stepSize: v
    })
    var type = this.getProductType()
    if(type < 64) {
      gui_module.step_size_gui.setValue(v)
    } else {
      shed_module.step_size_gui.setValue(v)
    }
  }

  setWingLength = (event) =>  {
    var v = event.target.valueAsNumber
    this.setState({
      wingLength: v
    })
    var type = this.getProductType()
    if(type == 32 || type ==33) {
      building_module.wing_length_gui.setValue(v)
    } 
  }

  setOverhang = (event) =>  {
    var v = event.target.valueAsNumber
    this.setState({
      overhang: v
    })
    var type = this.getProductType()
    if(type == 32 || type ==33) {
      building_module.overhang_gui.setValue(v)
    } 
  }

  
  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type
    if(type < 4) document.getElementById('step_submenu').style.display = 'block';
    else document.getElementById('step_submenu').style.display = 'none';

    if(type == 32 || type == 33) document.getElementById('wing_submenu').style.display = 'block';
    else document.getElementById('wing_submenu').style.display = 'none';

    if(type == 13 || type == 14) document.getElementById('back_length').style.display = 'block';
    else document.getElementById('back_length').style.display = 'none';

    var proj = product?.projection
    if(type == 32 || type == 33) proj = product?.width
    var lenMin = 2
    var projMin = 2
    if(type == 29) {
      lenMin = 1
      projMin = 1
    }

    var lenStep = 0.1
    var prjStep = 0.1
    if(type > 33) {
      lenStep = 0.25
      prjStep = 0.25
    } 

    this.setState({
      length: product?.length,
      projection: proj,
      stepLength: product?.step_length,
      stepSize: product?.step_size,
      lengthMin: lenMin,
      projectionMin: projMin,
      lengthStep: lenStep,
      projectionStep: prjStep
    })
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
        <div id='size'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Length</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min={this.state.lengthMin} 
            max={this.state.lengthMax} 
            step={this.state.lengthStep} 
            value={this.state.length?.toFixed(2)}
            onChange={this.setLength}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min={this.state.lengthMin}  
            max={this.state.lengthMax} 
            step={this.state.lengthStep} 
            value={this.state.length}
            onChange={this.setLength}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Projection</button>
        <input type='number' className="col-md-5 mx-1 my-1"
            min={this.state.projectionMin}  
            max={this.state.projectionMax} 
            step={this.state.projectionStep} 
            value={this.state.projection?.toFixed(2)}
            onChange={this.setProjection}
          />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min={this.state.projectionMin}  
            max={this.state.projectionMax} 
            step={this.state.projectionStep} 
            value={this.state.projection}
            onChange={this.setProjection}
          />
          <div id='back_length'>
          <button className="btn btn-secondary col-md-12 mx-1 my-1">Back Length</button>
         <input type='number' className="col-md-5 mx-1 my-1" 
            min="1" 
            max={this.state.lengthMax} 
            step={this.state.lengthStep} 
            value={this.state.backLength?.toFixed(2)}
            onChange={this.setBackLength}
          />
          <input type='range' className="col-md-5 mx-1 my-1" 
            min="1" 
            max={this.state.lengthMax} 
            step={this.state.lengthStep} 
            value={this.state.backLength}
            onChange={this.setBackLength}
            />
          </div>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Height</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0.2'
            max='8' 
            step='0.1'
            value={this.state.height?.toFixed(2)}
            onChange={this.setHeight}
          />
        <input type='range' className="col-md-5 mx-1 my-1"
            min='0.2'
            max='8' 
            step='0.1'
            value={this.state.height}
            onChange={this.setHeight}
          />
          <div id="step_submenu">
          <button className="btn btn-secondary col-md-12 mx-1 my-1">Step Length</button>
            <input type='number' className="col-md-5 mx-1 my-1" min="0" 
                max={this.state.length} 
                step="1" 
                value={this.state.stepLength}
                onChange={this.setStepLength}
            />
            <input type='range' className="col-md-5 mx-1 my-1" min="0" 
                max={this.state.length} 
                step="1"
                value={this.state.stepLength}
                onChange={this.setStepLength}
            />
          <button className="btn btn-secondary col-md-12 mx-1 my-1">Step Size</button>
            <input type='number' className="col-md-5 mx-1 my-1" min="3" 
                max={this.state.projectionMax} 
                step="0.1" 
                value={this.state.stepSize?.toFixed(2)}
                onChange={this.setStepSize}
            />
            <input type='range' className="col-md-5 mx-1 my-1" min="3" 
                max={this.state.projectionMax} 
                step="0.1"
                value={this.state.stepSize}
                onChange={this.setStepSize}
            />
          </div>
          <div id="wing_submenu">
          <button className="btn btn-secondary col-md-12 mx-1 my-1">Wing Length</button>
            <input type='number' className="col-md-5 mx-1 my-1" 
                min="0" 
                max='20'
                step="1" 
                value={this.state.wingLength}
                onChange={this.setWingLength}
            />
            <input type='range' className="col-md-5 mx-1 my-1"                 
                min="0" 
                max='20'
                step="1" 
                value={this.state.wingLength}
                onChange={this.setWingLength}
            />
          <button className="btn btn-secondary col-md-12 mx-1 my-1">Overhang</button>
            <input type='number' className="col-md-5 mx-1 my-1" 
                min='0'
                max='1'
                step='0.1' 
                value={this.state.overhang}
                onChange={this.setOverhang}
            />
            <input type='range' className="col-md-5 mx-1 my-1"
                min='0'
                max='1'
                step='0.1' 
                value={this.state.overhang}
                onChange={this.setOverhang}
            />
          </div>

        </div>

      </div>
     );  
  }
}

export default SmartSizeSubmenu;

/* <button className="btn btn-secondary col-md-12 mx-1 my-1">Test</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.test}
            onChange={this.setTest}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.test}
            onChange={this.setTest}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Test1</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.test1}
            onChange={this.setTest1}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.test1}
            onChange={this.setTest1}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Test2</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.test2}
            onChange={this.setTest2}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.test2}
            onChange={this.setTest2}
        /> */