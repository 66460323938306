import React from 'react';
import * as THREE from 'three';
import { ChromePicker } from 'react-color';
import { gui_module, sceneItems } from '../smartkits/gui_smartkits';
import { bg_scale_gui, bg_width_gui, bg_height_gui, setGroundVisible } from '../smartkits/image_import';

class SmartOutputSubmenu extends React.Component {

  constructor() {
    super();
    this.state =  { 
      color: '#FF5733',
      backgroundScale: 1,
      x: 0,
      y: 0,
      visible: true,
      grid: false,
      indicator: true,
      file: 'smart'
    }   
  }

  setScreenshot = (event) => {
    gui_module.addImage(this.state.file)
  }

  setBackgroundColor = (target) => {
    var v = target.hex
    this.setState( {
      color: v
    });
    sceneItems.scene.background = new THREE.Color(v)
  }

  loadBackgroundImageD = (event) => {
    document.getElementById('loadImageInput').click()
  }

  scaleBackgroundImage = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      backgroundScale: v
    });
    bg_scale_gui.setValue(v)
  }

  setX = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      x: v
    });
    bg_width_gui.setValue(v)
  }

  setY = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      y: v
    });
    bg_height_gui.setValue(v)
  }

  clearBackgroud  = (event) => {
    sceneItems.scene.background = null;
    sceneItems.scene.environment = null; 
  }

  setVisible = (event) => {
    var v = event.target.checked
    this.setState({
      visible: v
    }) 
    setGroundVisible(v)
  }

  setGrid = (event) => {
    var v = event.target.checked
    this.setState({
      grid: v
    }) 
    sceneItems.grid.visible = v
  }

  setIndicator = (event) => {
    var v = event.target.checked
    this.setState({
      indicator: v
    }) 
    gui_module.indicator_gui.setValue(v)
  }

  setFile = (event) => {
    var v = event.target.value
    this.setState( {
      file: v
    });
  }

  render() {
    return (
      <div>
        <div id='output'>
        <button  className="btn btn-secondary col-md-3 mx-1 my-1">Grid</button>
        <input type="checkbox" checked={this.state.grid} onChange={this.setGrid} />
        <button  className="btn btn-secondary col-md-3 mx-1 my-1">Ground</button>
        <input type="checkbox" checked={this.state.visible} onChange={this.setVisible} />
        <button  className="btn btn-secondary col-md-3 mx-1 my-1">Indicator</button>
        <input type="checkbox" checked={this.state.indicator} onChange={this.setIndicator} />
        <button  className="btn btn-secondary col-md-12 mx-1 my-1">File </button>
        <input type="text"  value={this.state.file} onChange={this.setFile}/>
        <button className="btn btn-success col-md-12 mx-1 my-1" onClick={this.setScreenshot}>Screen Shot</button>
        <button  className="btn btn-info col-md-12 mx-1 my-1" onClick={this.loadBackgroundImageD}>Load Background</button>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Scale</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0.5'
            max='2'
            step='0.1'
            value={this.state.backgroundScale}
            onChange={this.scaleBackgroundImage}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0.5'
            max='2'
            step='0.1'
            value={this.state.backgroundScale}
            onChange={this.scaleBackgroundImage}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">X</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-50'
            max='50'
            step='1'
            value={this.state.x}
            onChange={this.setX}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-50'
            max='50'
            step='1'
            value={this.state.x}
            onChange={this.setX}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Y</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-50'
            max='50'
            step='1'
            value={this.state.y}
            onChange={this.setY}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-50'
            max='50'
            step='1'
            value={this.state.y}
            onChange={this.setY}
        />
        <button className="btn btn-warning col-md-12 mx-1 my-1" onClick={this.clearBackgroud}>Clear Backgroud</button>
        <ChromePicker  color={this.state.color} onChange={this.setBackgroundColor} />
        </div>       
      </div>
     );  
  }
}

export default SmartOutputSubmenu;
