import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';

class Smart2DProjectionSubmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state =  { 
      index: 0,
    }   
  }

  setIndex = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
       index: v
    })
    this.props.setIndex(v)
  }

  runTask = (v) => {
    this.props.runTask(v)
  }

  informSelect = () => {
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='projection_2d_setup'>
        <button className="btn btn-primary col-md-12 mx-1 my-1" onClick={() => this.runTask(1)}>Download Image</button>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">2D Index</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='12'
            step='1'
            value={this.state.index}
            onChange={this.setIndex}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='12'
            step='1'
            value={this.state.index}
            onChange={this.setIndex}
        />
        </div>
      </div>
     );  
  }
}

export default Smart2DProjectionSubmenu;
