import React from 'react';
import { project_specification } from '../smartkits/gui_smartkits'

class SmartPriceSubtoolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state =  { 
        space: 75,
    }   
  }

  setPriceSpace = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      space: v
    })
    this.props.setPriceSpace(v / 100)
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
       <div id='price_toolbar_setup'>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Price Space</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.space}
            onChange={this.setPriceSpace}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='100'
            step='1'
            value={this.state.space}
            onChange={this.setPriceSpace}
        />  
       <button className="btn btn-dark col-md-12 mx-1 my-1">{project_specification.first_name + ' ' + project_specification.family_name}</button>
       <button className="btn btn-warning col-md-12 mx-1 my-1">{project_specification.email }</button>
       <button className="btn btn-dark col-md-12 mx-1 my-1">{project_specification.phone_no }</button>
       <button className="btn btn-light col-md-12 mx-1 my-1">{project_specification.build_street_no }</button>
       <button className="btn btn-light col-md-12 mx-1 my-1">{project_specification.build_street }</button>
       <button className="btn btn-light col-md-12 mx-1 my-1">{project_specification.build_suburb + ' ' +  project_specification.build_post_code }</button>
       <button className="btn btn-light col-md-12 mx-1 my-1">{project_specification.build_state }</button>      
       </div>
      </div>
     );  
  }
}

export default SmartPriceSubtoolbar;
