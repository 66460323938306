import React from 'react';
import { generatePriceTable } from '../smartkits/helper';

class SmartPriceSubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
        priceList: [],
        total: 0
    }   
  }

  informSelect = () => {
    var g = generatePriceTable()
    this.setState({
        priceList: g.row,
        total: g.total.toFixed(0)
    })
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='price_page'>
        {this.state.priceList.map(({model, price, size}, index) => <div>
            <button className="btn btn-primary col-md-1 mx-1 my-1">{index + 1}</button>
            <button className="btn btn-info col-md-5 mx-1 my-1">{model}</button>
            <button className="btn btn-warning col-md-2 mx-1 my-1">{price}</button>
            <button className="btn btn-light col-md-2 mx-1 my-1">{size}m<sup>2</sup></button>
        </div>)}
        <button className="btn btn-warning col-md-2 mx-1 my-1" style={{ position: "fixed", top: 0, left: 0, zIndex: 200, fontSize: 20 }}>Total: ${this.state.total}</button>
        </div>
      </div>
     );  
  }
}

export default SmartPriceSubmenu;
