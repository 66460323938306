import { THREE, RGBELoader, getAssetsAddress, setPriceDialog, loadImage } from './helper.js';

import { gui_module, sceneItems } from "./gui_smartkits.js";

var bg_width_gui
var bg_height_gui
var bg_scale_gui

var image_import = {
    set_import_image_gui: function() {
        window.addEventListener('resize', onWindowResize );
        const inputElement = document.getElementById("loadImageInput");
        if(inputElement) inputElement.addEventListener("change", handleFiles, false);

        const panelInputElement = document.getElementById("loadPanelInput");
        if(panelInputElement) panelInputElement.addEventListener("change", handlePanelFiles, false);

        const sceneFloorInputElement = document.getElementById("loadSceneGroundInput");
        if(sceneFloorInputElement) sceneFloorInputElement.addEventListener("change", handleSceneGroundFile, false);

        const accessoryInputElement = document.getElementById("loadAccessoryInput");
        
        colorToolBar = document.getElementById("info");

        dialog =  document.getElementById("dialog");
        if(dialog) dialog.style.display = "none";

        canva = document.getElementById("c");

        const colorDoneButton =  document.getElementById("colorDoneButton");    
        if(colorDoneButton) colorDoneButton.addEventListener("click", function() {
            forceSideBarVisible(false);
            gui_module.gui.open();
            setPriceDialog(false);
        });
       
        var controlFolder = gui_module.gui.addFolder('Import Image'); 

        var panelGUIFolder = controlFolder.addFolder('As Panel');
    
        panelGUIFolder.add(imageFolderFunctions, 'loadPanel').name('Load');  
        panelGUIFolder.add(imageFolderFunctions, 'scale', 1, 20, 0.1).onChange(imageFolderFunctions.scalePanel);  
        panelZGui =  panelGUIFolder.add(imageFolderFunctions, 'z', -20, 20, 0.1).onChange(imageFolderFunctions.movePanel);
        panelYGui =  panelGUIFolder.add(imageFolderFunctions, 'y', -20, 20, 0.1).onChange(imageFolderFunctions.movePanel);
        panelXGui =  panelGUIFolder.add(imageFolderFunctions, 'x', -20, 20, 0.1).onChange(imageFolderFunctions.movePanel);    
        
        var panelGUIMoreFolder = panelGUIFolder.addFolder('Rotation');
        panelXRotateGui =  panelGUIMoreFolder.add(imageFolderFunctions, 'rotate_x', -90, 90, 0.01).onChange(imageFolderFunctions.rotatePanel);   
        panelYRotateGui =  panelGUIMoreFolder.add(imageFolderFunctions, 'rotate_y', -90, 90, 0.01).onChange(imageFolderFunctions.rotatePanel);   
        panelZRotateGui =  panelGUIMoreFolder.add(imageFolderFunctions, 'rotate_z', -90, 90, 0.01).onChange(imageFolderFunctions.rotatePanel);      
        panelGUIFolder.add(imageFolderFunctions, 'resetPostion').name('Reset Position'); 
    
        panelGUIFolder.add(imageFolderFunctions, 'clearPanel').name('Clear Panel');       
    
        var bgGUIFolder = controlFolder.addFolder('As Backgroud');
        bgGUIFolder.add(imageFolderFunctions, 'loadFile').name('Load'); 
        bg_scale_gui = bgGUIFolder.add(backgroundMatrix, 'scale', 0.5, 2, 0.1).onChange(backgroundChanger);
        bg_width_gui = bgGUIFolder.add(backgroundMatrix, 'x', -50, 50, 1).onChange(backgroundChanger);
        bg_height_gui = bgGUIFolder.add(backgroundMatrix, 'y', -50, 50, 1).onChange(backgroundChanger);  
        bgGUIFolder.add(imageFolderFunctions, 'clearBackgroud').name('Clear Background');

        bgGUIFolder.addColor(backgroundMatrix, 'color').onChange(function() { 
            sceneItems.scene.background = new THREE.Color(backgroundMatrix.color)
        });  
        sceneItems.scene.background = new THREE.Color( 0xffffff );   
      
    
        var sceneFloorGUIFolder = controlFolder.addFolder('As Ground');
        sceneFloorGUIFolder.add(imageFolderFunctions, 'loadSceneGround').name('Load');
        sceneFloorGUIFolder.add(imageFolderFunctions, "visible").onChange(setGroundVisisbleByGUI);

        handleSceneGround(getAssetsAddress('textures/grass.jpg'));      

    },
}

var img;
var path;
var selectedFile;
var selectedPanel;

var dialog;
var canva;
var colorToolBar;
var panelImage;
var panelLoadedImage;

function forceSideBarVisible(flag) {
    if(flag) {
        if(canva) canva.style.display = "block";
        if(dialog) dialog.style.display = "none";  
        if(colorToolBar) colorToolBar.style.display = "block";       
    } else {
        if(colorToolBar) colorToolBar.style.display = "none";
    }
}

function setDialogVisible(flag) {
    if(flag) {
        if(canva) canva.style.display = "none";
        if(dialog) dialog.style.display = "block";
    } else {
        if(canva) canva.style.display = "block";
        if(dialog) dialog.style.display = "none"; 
    }    
}

function onWindowResize() {     
    sceneItems.camera.aspect = sceneItems.sceneX / sceneItems.sceneY;
    sceneItems.camera.updateProjectionMatrix();
    sceneItems.renderer.setSize(sceneItems.sceneX, sceneItems.sceneY);     
    backgroundChanger();              
}

function backgroundUpdateWithHDRPath(path) {
    const pmremGenerator = new THREE.PMREMGenerator(sceneItems.renderer);
    pmremGenerator.compileEquirectangularShader();
          new RGBELoader()
        .setDataType( THREE.UnsignedByteType )                    
        .load( path, function ( texture ) {
          var timeMap = pmremGenerator.fromEquirectangular( texture ).texture;
    
          sceneItems.scene.background = timeMap;
          sceneItems.scene.environment = timeMap;
    
          texture.dispose();
          pmremGenerator.dispose();  
    });
  }
  
  function backgroundUpdateWithHDR() {
    var path = (window.URL || window.webkitURL).createObjectURL(selectedFile);
    backgroundUpdateWithHDRPath(path);  
  }
   
  function handlePanelFiles() {
    selectedPanel = document.getElementById('loadPanelInput').files[0];
    document.getElementById('loadPanelInput').value = ''
    var panelPath = (window.URL || window.webkitURL).createObjectURL(selectedPanel);
    var geometry = new THREE.PlaneGeometry(4, 4);
  
    var loader = new THREE.TextureLoader()
    var panelMaterial =  new THREE.MeshStandardMaterial({
        map: loader.load(panelPath, function (tex) {      
          panelLoadedImage = tex;
          imageFolderFunctions.scalePanel();
          imageFolderFunctions.movePanel();
  
      } )
    });
    removePanel();
    panelImage = new THREE.Mesh(geometry, panelMaterial);
    panelImage.name = 'panel_image';  
    sceneItems.scene.add(panelImage);
  }
  
  function removePanel() {
    var object = sceneItems.scene.getObjectByName('panel_image')
    if(object) {
      sceneItems.scene.remove(object);
    }
  }
  
var selectedFloor;
var sceneFloorMaterial;
var sceneGroundImage = []

function handleSceneGroundFile() {
    selectedFloor = document.getElementById('loadSceneGroundInput').files[0];
    document.getElementById('loadSceneGroundInput').value = ''
    var path = (window.URL || window.webkitURL).createObjectURL(selectedFloor);
    handleSceneGround(path)
}

function handleSceneGround(path) {
    var loader = new THREE.TextureLoader()
    sceneFloorMaterial =  new THREE.MeshStandardMaterial({
        map: loader.load(path, function (tex) {      
            panelLoadedImage = tex;
        } )
    });  
    redrawGround()
}
  

function redrawGround() {
    removeSceneFloor();
    sceneGroundImage = [];
    for(var i = 0; i < 400; ++i) {
        var geometry = new THREE.PlaneGeometry(3, 3);
        sceneGroundImage.push(new THREE.Mesh(geometry, sceneFloorMaterial));    
    }
    for(var i = 0; i < sceneGroundImage.length; ++i) {
        sceneGroundImage[i].position.set(Math.floor(i % 20) * 3 - 30, 0, Math.floor(i / 20) * 3 - 30);
        sceneGroundImage[i].rotation.x = 3 * Math.PI /  2; 
        sceneItems.scene.add(sceneGroundImage[i]);
    }
}
  
  
function setPanelVisible(flag) {
    if(panelImage) {
        panelImage.visible = flag;
    }
    setGroundVisible(flag);
}

function setGroundVisisbleByGUI() {
    setGroundVisible(imageFolderFunctions.visible);
}

function setGroundVisible(flag) {
    for(var i = 0; i < sceneGroundImage.length; ++i) {
        sceneGroundImage[i].visible = flag 
    }   
}

function removeSceneFloor() {
    for(var i = 0; i < sceneGroundImage.length; ++i) {
        sceneItems.scene.remove(sceneGroundImage[i]); 
    }  
}
  
function handleFiles() {
    selectedFile = document.getElementById('loadImageInput').files[0];
    document.getElementById('loadImageInput').value = ''
    if(selectedFile.name.includes(".hdr")) {
    backgroundUpdateWithHDR();      
    } else {
        bg_width_gui.setValue(0)
        bg_height_gui.setValue(0)
        bg_scale_gui.setValue(1)
        backgroundChanger(updateSceneBackgroud);   
    }
}
  
function updateSceneBackgroud(image, canvas) {
    var loader = new THREE.TextureLoader();
    var dataurl = canvas.toDataURL("image/png");        
    loadImage(dataurl, function (img) {
        sceneItems.scene.background = img; 
    });     
    sceneItems.scene.background = loader.load(dataurl);
}

function backgroundChanger(updateSceneBackgroud) {
    if(selectedFile == undefined) return;
    img = new Image();
    path = (window.URL || window.webkitURL).createObjectURL(selectedFile);
    backgroundImageLoad(updateSceneBackgroud);
}
  
var backgroundMatrix = {
    x: 0,
    y: 0,
    scale: 1,
    color: 0xffffff
}

function backgroundImageLoad() {    
    img.onload = function (a) {
        var x2yImageRatio = this.width / this.height;        
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        
        canvas.width = sceneItems.sceneX;
        canvas.height = sceneItems.sceneY;
        
        var scale = Math.min(canvas.width / this.width, canvas.height / this.height) * backgroundMatrix['scale']

        var x = (0.5 + backgroundMatrix.x / 100) * canvas.width - (img.width / 2) * scale
        var y = (0.5 + backgroundMatrix.y / 100) * canvas.height - (img.height / 2) * scale

        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

        updateSceneBackgroud(img, canvas);
    }                    
    img.src = path;    
}

var panelZGui, panelYGui, panelXGui;
var panelXRotateGui, panelYRotateGui, panelZRotateGui;

var imageFolderFunctions = {
    loadFile : function() { 
        document.getElementById('loadImageInput').click();
    },
    loadPanel: function() {
        document.getElementById('loadPanelInput').click();
    },
    loadSceneGround: function() {
      document.getElementById('loadSceneGroundInput').click();    
    },
    visible: true,
    x: 0,
    y: 0,
    z: 0,
    scale: 1,
    movePanel: function() {
      if(panelImage) {                 
        panelImage.position.set(imageFolderFunctions.x, imageFolderFunctions.y, imageFolderFunctions.z - gui_module.projection);
      }          
    },
    scalePanel: function() {
      if(panelImage) {
        var s = Math.max(panelLoadedImage.image.width, panelLoadedImage.image.height) / 1;
        panelImage.scale.set(imageFolderFunctions.scale * panelLoadedImage.image.width / s, imageFolderFunctions.scale * panelLoadedImage.image.height / s, imageFolderFunctions.scale);
      }          
    },
    rotate_x: 0,
    rotate_y: 0,
    rotate_z: 0,
    rotatePanel: function() {
      if(panelImage)    panelImage.rotation.set(imageFolderFunctions.rotate_x * Math.PI / 180, imageFolderFunctions.rotate_y * Math.PI / 180, imageFolderFunctions.rotate_z * Math.PI / 180);
    },
    resetPostion: function() {
      panelXGui.setValue(0);
      panelYGui.setValue(0);
      panelZGui.setValue(0);
  
      panelXRotateGui.setValue(0);
      panelYRotateGui.setValue(0);
      panelZRotateGui.setValue(0);
    },
    clearPanel: function() {
      removePanel();
    },
    clearBackgroud : function () {
      sceneItems.scene.background = null;
      sceneItems.scene.environment = null;
    },
    a: false,        
};

class GuiSmartkits {
    constructor() {
        this.gui = new GUI();
        this.create_import_image_gui();
    }
    create_import_image_gui() {
        
        this.controlFolder = this.gui.addFolder('Import Image'); 

        this.panelGUIFolder = this.controlFolder.addFolder('As Panel');    
        this.panelGUIFolder.add(this, 'load_panel').name('Load'); 
        const panelInputElement = document.getElementById("loadPanelInput");
        if(panelInputElement) panelInputElement.addEventListener("change", this.handle_panel_file, false)
    
    } 
    load_panel() {
        console.log("load panel presssed")
        document.getElementById('loadPanelInput').click();
    }
    handle_panel_file() {
        var selectedPanel = document.getElementById('loadPanelInput').files[0];
        document.getElementById('loadPanelInput').value = ''
        var panelPath = (window.URL || window.webkitURL).createObjectURL(selectedPanel);
        var geometry = new THREE.PlaneGeometry(3, 3);
      
        var loader = new THREE.TextureLoader()
        var panelMaterial =  new THREE.MeshStandardMaterial({
            map: loader.load(panelPath, function (tex) {      
                gui_module.panelLoadedImage = tex;
          } )
        });
        
        this.panelImage = new THREE.Mesh(geometry, panelMaterial);
        this.panelImage.name = 'panel_image';  
        sceneItems.scene.add(this.panelImage);
      }

}

export { image_import, forceSideBarVisible, setPanelVisible, setDialogVisible,  bg_scale_gui, bg_width_gui, bg_height_gui, setGroundVisible}