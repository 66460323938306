import { THREE, getAssetsAddress, OBJLoader, loadMaterial} from './helper.js';

var loader_module = {
    solar_span_type_1_model: null,
    add_solar_span_type_1: function(product, no, call) {
        if(loader_module.solar_span_type_1_model) {
            console.log("solar span type 1 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.solar_span_type_1_model, no, product.roofing_group, call)
        } else {
            console.log("solar span type 1 read from server")
            loader_module.add_solar_span_type_1_from_server(product.roofing_model,                     
                getAssetsAddress('models/obj/Solar-Span-Type-1.obj'), 
                no, product.roofing_group, call) 
        }

    },    
    add_solar_span_type_1_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.solar_span_type_1_model = object;
            loader_module.publish_object(model_buffer, loader_module.solar_span_type_1_model, no, group, call);
        });
    },
    solar_span_type_2_model: null,
    add_solar_span_type_2: function(product, no, call) {
        if(loader_module.solar_span_type_2_model) {
            console.log("solar span type 2 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.solar_span_type_2_model, no, product.roofing_group, call)
        } else {
            console.log("solar span type 2 read from server")
            loader_module.add_solar_span_type_2_from_server(product.roofing_model,                     
                getAssetsAddress('models/obj/Solar-Span-Type-2.obj'), 
                no, product.roofing_group, call) 
        }

    },    
    add_solar_span_type_2_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.solar_span_type_2_model = object;
            loader_module.publish_object(model_buffer, loader_module.solar_span_type_2_model, no, group, call);
        });
    },
    flat_deck_type_1_model: null,
    add_flat_deck_type_1: function(product, no, call) {
        if(loader_module.flat_deck_type_1_model) {
            console.log("flat deck type 1 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.flat_deck_type_1_model, no, product.roofing_group, call)
        } else {
            console.log("flat deck type 1 read from server")
            loader_module.add_flat_deck_type_1_from_server(product.roofing_model,                     
                getAssetsAddress('models/obj/Flat-Deck-Type-1.obj'), 
                no, product.roofing_group, call) 
        }

    },    
    add_flat_deck_type_1_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.flat_deck_type_1_model = object;
            loader_module.publish_object(model_buffer, loader_module.flat_deck_type_1_model, no, group, call);
        });
    },
    span_deck_type_1_model: null,
    add_span_deck_type_1: function(product, no, call) {
        if(loader_module.span_deck_type_1_model) {
            console.log("span deck type 1 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.span_deck_type_1_model, no, product.roofing_group, call)
        } else {
            console.log("span deck type 1 read from server")
            loader_module.add_span_deck_type_1_from_server(product.roofing_model,                     
                getAssetsAddress('models/obj/Span-Deck-Type-1.obj'), 
                no, product.roofing_group, call) 
        }

    },    
    add_span_deck_type_1_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.span_deck_type_1_model = object;
            loader_module.publish_object(model_buffer, loader_module.span_deck_type_1_model, no, group, call);
        });
    },
    custom_orb_model: null,
    add_custom_orb: function(product, no, call) {
        if(loader_module.custom_orb_model) {
            console.log("corrugated read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.custom_orb_model, no, product.roofing_group, call)
        } else {
            console.log("corrugated read from server")
            loader_module.add_custom_orb_from_server(product.roofing_model, 
                getAssetsAddress('models/obj/Custom-Orb-Type-1.obj'),
                no, product.roofing_group, call) 
        }
    },
    add_custom_orb_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.custom_orb_model = object;
            loader_module.publish_object(model_buffer, loader_module.custom_orb_model, no, group, call);
        });
    },
    custom_orb_model_type_1: null,
    add_custom_orb_type_1: function(product, no, call) {
        if(loader_module.custom_orb_model_type_1) {
            console.log("corrugated type 1 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.custom_orb_model_type_1, no, product.roofing_group, call)
        } else {
            console.log("corrugated type 1 read from server")
            loader_module.add_custom_orb_type_1_from_server(product.roofing_model, 
                getAssetsAddress('models/obj/Custom-Orb-Type-1.obj'),
                no, product.roofing_group, call) 
        }
    },
    add_custom_orb_type_1_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.custom_orb_model_type_1 = object;
            loader_module.publish_object(model_buffer, loader_module.custom_orb_model_type_1, no, group, call);
        });
    },
    custom_orb_model_type_2: null,
    add_custom_orb_type_2: function(product, no, call) {
        if(loader_module.custom_orb_model_type_2) {
            console.log("corrugated type 2 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.custom_orb_model_type_2, no, product.roofing_group, call)
        } else {
            console.log("corrugated type 2 read from server")
            loader_module.add_custom_orb_type_2_from_server(product.roofing_model, 
                getAssetsAddress('models/obj/Custom-Orb-Type-2.obj'),
                no, product.roofing_group, call) 
        }
    },
    add_custom_orb_type_2_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.custom_orb_model_type_2 = object;
            loader_module.publish_object(model_buffer, loader_module.custom_orb_model_type_2, no, group, call);
            //call();
        });
    },
    custom_orb_model_type_3: null,
    add_custom_orb_type_3: function(product, no, call) {
        if(loader_module.custom_orb_model_type_3) {
            console.log("corrugated type 3 read from buffer")
           loader_module.publish_object(product.roofing_model, loader_module.custom_orb_model_type_3, no, product.roofing_group, call)
        } else {
            console.log("corrugated type 3 read from server")
            loader_module.add_custom_orb_type_3_from_server(product.roofing_model, 
                getAssetsAddress('models/obj/Custom-Orb-Type-3.obj'),
                no, product.roofing_group, call) 
        }
    },
    add_custom_orb_type_3_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.custom_orb_model_type_3 = object;
            loader_module.publish_object(model_buffer, loader_module.custom_orb_model_type_3, no, group, call);
            //call();
        });
    },
    gutter_model: null,
    add_gutters: function(product, no, call) {
        if(loader_module.gutter_model) {
            console.log("gutters read from buffer")
           loader_module.publish_object(product.gutters_model, loader_module.gutter_model, no, product.scene_group, call)
        } else {
            console.log("gutters read from server")
            loader_module.add_gutters_from_server(product.gutters_model, 
                getAssetsAddress('models/obj/Gutter-1m-Type-1.obj'), 
                no, product.scene_group, call) 
        }
    },
    add_gutters_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.gutter_model = object;
            loader_module.publish_object(model_buffer, loader_module.gutter_model, no, group, call);
            //call();
        });
    },
    beam_model: null,
    add_beams: function(product, no, call) {
        if(loader_module.beam_model) {
            console.log("beams read from buffer")
           loader_module.publish_object(product.beams_model, loader_module.beam_model, no, product.scene_group, call)
        } else {
            console.log("beams read from server")
            loader_module.add_beams_from_server(product.beams_model, 
                getAssetsAddress('models/obj/Beam-1m.obj'), 
                no, product.scene_group, call) 
        }
    },
    add_beams_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.beam_model = object;
            loader_module.publish_object(model_buffer, loader_module.beam_model, no, group, call);
            //call();
        });
    },

    fan_model: null,
    add_fan: function(product, no, call) {
        if(loader_module.fan_model) {
           loader_module.publish_object(product.fans_model, loader_module.fan_model, no, product.scene_group, call)
           console.log("fans read from buffer")
        } else {
            const loader = new OBJLoader();
            loader.load(getAssetsAddress('models/obj/fan-3.obj'), function (object) {
                console.log("fans read from server")
                loader_module.fan_model = object;
                loader_module.publish_object(product.fans_model, loader_module.fan_model, no, product.scene_group, call)                
            });
        }
    },
    downlight_model: null,
    add_downlights: function(product, no, call) {
        if(loader_module.downlight_model) {
           loader_module.publish_object(product.downlights_model, loader_module.downlight_model, no, product.scene_group, call)
           console.log("downlights read from buffer")
        } else {
            const loader = new OBJLoader();
            loader.load(getAssetsAddress('models/obj/downlight-1.obj'), function (object) {
                console.log("downlights read from server")
                loader_module.downlight_model = object;
                loader_module.publish_object(product.downlights_model, loader_module.downlight_model, no, product.scene_group, call)
            });
        }        
    },
    publish_object(model_buffer, object_buffer, no, group, call) {
        for(var i = 0; i < no; ++i) {
            var object = object_buffer.clone()
            model_buffer.push(object);
            group.add(object);
            object.visible = true;
        }
        call();
    },
    logo_model: null,
    get_logo(call) {        
        if(loader_module.logo_model == null) {
            /*addGLTF(getAssetsAddress('models/gltf/logo.glb', '../product/js/libs/draco/gltf/', function(gltf) {        
                loader_module.logo_model = gltf.scene
                loader_module.logo_model.scale.multiplyScalar(0.3)
                call(loader_module.logo_model);
            }); */
            const geometry = new THREE.SphereGeometry(.1, 32, 16);
            const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
            loader_module.logo_model = new THREE.Mesh( geometry, material );
            //scene.add( sphere );
          
        } else {
            call(loader_module.logo_model)
        }    
        return loader_module.logo_model
    },
    woodenPostMaterial: null,
    get_wood_material() {
        if(loader_module.woodenPostMaterial == null) {
            loader_module.woodenPostMaterial = loadMaterial(getAssetsAddress('textures/dark_wood.jpg'), function() {});
            loader_module.woodenPostMaterial.color.convertSRGBToLinear();
        }
        return loader_module.woodenPostMaterial 
    },
    decking_material: null,
    get_decking_material() {
        if(loader_module.decking_material == null) {
            loader_module.decking_material = loadMaterial(getAssetsAddress('textures/dark_wood_h.jpg'), function() {});
            loader_module.decking_material.color.convertSRGBToLinear();
            console.log("decking material read from server")
        }
        return loader_module.decking_material 
    },
    pavement_material: null,
    get_pavement_material() {
        if(loader_module.pavement_material == null) {
            loader_module.pavement_material = loadMaterial(getAssetsAddress('textures/pavement-1.jpg'), function() {});
            loader_module.pavement_material.color.convertSRGBToLinear();
            console.log("pavement material read from server")
        }
        return loader_module.pavement_material 
    },
    tile_material: null,
    get_tile_material() {
        if(loader_module.tile_material == null) {
            loader_module.tile_material = loadMaterial(getAssetsAddress('textures/tile-1.jpg'), function() {});
            loader_module.tile_material.color.convertSRGBToLinear();
            console.log("tile material read from server")
        }
        return loader_module.tile_material 
    },
    concrete_material: null,
    get_concrete_material() {
        if(loader_module.concrete_material == null) {
            loader_module.concrete_material = loadMaterial(getAssetsAddress('textures/concrete-2.jpg'), function() {});
            loader_module.concrete_material.color.convertSRGBToLinear();
            console.log("concrete material read from server")
        }
        return loader_module.concrete_material 
    },
    stone_material: null,
    get_stone_material() {
        if(loader_module.stone_material == null) {
            loader_module.stone_material = loadMaterial(getAssetsAddress('textures/pavement.jpg'), function() {});
            loader_module.stone_material.color.convertSRGBToLinear();
            console.log("stone material read from server")
        }
        return loader_module.stone_material 
    },
    custom_material: null,
    get_custom_material() {
        if(loader_module.custom_material == null) {
            loader_module.custom_material = loadMaterial(getAssetsAddress('textures/custom.jpg'), function() {});
            loader_module.custom_material.color.convertSRGBToLinear();
            console.log("custom material read from server")
        }
        return loader_module.custom_material 
    },    
    get_wall_material(no) {
        switch(no) {
            case 2:
                return loader_module.get_wall_2_material()
                break;
            case 3:
                return loader_module.get_wall_3_material()
                break;    
            case 4:
                return loader_module.get_wall_4_material()
                break;                              
            default:
                return loader_module.get_wall_1_material()
                break;
        }
    },
    wall_1_material: null,
    get_wall_1_material() {
        if(loader_module.wall_1_material == null) {
            loader_module.wall_1_material = loadMaterial(getAssetsAddress('textures/wall-1.jpg'), function() {});
            loader_module.wall_1_material.color.convertSRGBToLinear();
            console.log("wall 1 material read from server")
        }
        return loader_module.wall_1_material 
    },
    wall_2_material: null,
    get_wall_2_material() {
        if(loader_module.wall_2_material == null) {
            loader_module.wall_2_material = loadMaterial(getAssetsAddress('textures/wall-2.jpg'), function() {});
            loader_module.wall_2_material.color.convertSRGBToLinear();
            console.log("wall 2 material read from server")
        }
        return loader_module.wall_2_material 
    },
    wall_3_material: null,
    get_wall_3_material() {
        if(loader_module.wall_3_material == null) {
            loader_module.wall_3_material = loadMaterial(getAssetsAddress('textures/wall-3.jpg'), function() {});
            loader_module.wall_3_material.color.convertSRGBToLinear();
            console.log("wall 3 material read from server")
        }
        return loader_module.wall_3_material 
    },
    wall_4_material: null,
    get_wall_4_material() {
        if(loader_module.wall_4_material == null) {
            loader_module.wall_4_material = loadMaterial(getAssetsAddress('textures/wall-4.jpg'), function() {});
            loader_module.wall_4_material.color.convertSRGBToLinear();
            console.log("wall 4 material read from server")
        }
        return loader_module.wall_4_material 
    },
    add_solar_span_wall_type_1: function(product, no, call) {
        if(loader_module.solar_span_type_1_model) {
            console.log("solar span wall type 1 read from buffer")
           loader_module.publish_object(product.walls_model, loader_module.solar_span_type_1_model, no, product.scene_group, call)
        } else {
            console.log("solar span wall type 1 read from server")
            loader_module.add_solar_span_wall_type_1_from_server(product.walls_model,                     
                getAssetsAddress('models/obj/Solar-Span-Type-1.obj'), 
                no, product.scene_group, call) 
        }
    },    
    add_solar_span_wall_type_1_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.solar_span_type_1_model = object;
            loader_module.publish_object(model_buffer, loader_module.solar_span_type_1_model, no, group, call);
        });
    },
    add_solar_span_roller_door_type_1: function(product, model_buffer, no, call) {
        if(loader_module.solar_span_type_1_model) {
            console.log("solar span roller door type 1 read from buffer")
           loader_module.publish_object(model_buffer, loader_module.solar_span_type_1_model, no, product.scene_group, call)
        } else {
            console.log("solar span roller door type 1 read from server")
            loader_module.add_solar_span_roller_door_type_1_from_server(model_buffer,                     
                getAssetsAddress('models/obj/Solar-Span-Type-1.obj'), 
                no, product.scene_group, call) 
        }

    },    
    add_solar_span_roller_door_type_1_from_server: function(model_buffer, model, no, group, call) {
        const loader = new OBJLoader();
        loader.load( model, function (object) {
            loader_module.solar_span_type_1_model = object;
            loader_module.publish_object(model_buffer, loader_module.solar_span_type_1_model, no, group, call);
        });
    },
    load_solar_span_type_1: function() {
        const loader = new OBJLoader();
        loader.load(getAssetsAddress('models/obj/Solar-Span-Type-1.obj'), function (object) {
            loader_module.solar_span_type_1_model = object
        });
    }, 
    load_gutters: function() {
        const loader = new OBJLoader();
        loader.load(getAssetsAddress('models/obj/Gutter-1m-Type-1.obj'), function (object) {
            loader_module.gutter_model = object
        });
    },
}

export { loader_module }