import React from 'react';
import { THREE, getMaterial, loadMaterial } from './helper.js';

import { selectPostsColorbond, convertColor2Hex } from './color.js';
import { gui_module, sceneItems } from './gui_smartkits.js';
import { loader_module } from './loader_module.js';
import { gable_hip_module } from './gable_hip_module.js';
import { flyover_module } from './flyover_module.js';

var roof_slope = Math.PI / 8
var cos22p5 = Math.cos(roof_slope)
var sin22p5 = Math.sin(roof_slope)

var v1 = new THREE.Vector3();
var v2 = new THREE.Vector3(); 

class Building {
    constructor(type) {
        this.position = new THREE.Vector3(-10, 0, -10);

        this.type = type;
        this.length = building_module.length;
        this.width = building_module.width;  
        this.height = building_module.height;
        this.wing_width = building_module.wing_width;
        this.wing_length = building_module.wing_length;
        
        this.scene_group = new THREE.Group();  
        this.roofing_group = null;      

        this.wall_thickness = 0.02;
        this.gutter_scale = 1.5

        this.parts = { "list": [], "parent": this, "index": 0 };
        this.windows = [];
        this.walls = [];

        this.wall_material = null;

        this.selected_window = null;
        this.selected_window_mesh = null;

        this.roofing = gui_module.roofing;
        this.profile = 1;
        this.overhang = 0.5;
        this.roofing_model = [];
        this.gutters_model = [];

        this.lock = false;

        
        this.left_2_right_unit_vector = new THREE.Vector3(0, 0, 1)
        this.front_2_end_unit_vector = new THREE.Vector3(1, 0, 0)
        this.rotate = 0,
        this.price_index = 0

        this.canvasRef = React.createRef()
        this.scaledCanvasRef = React.createRef()
        this.canvasRef1 = React.createRef()
        this.scaledCanvasRef1 = React.createRef()
        this.canvasRef2 = React.createRef()
        this.scaledCanvasRef2 = React.createRef()
        this.canvasRef3 = React.createRef()
        this.scaledCanvasRef3 = React.createRef()
        this.postLocationCanvasRef = React.createRef()
        this.scaledPostLocationCanvasRef = React.createRef()
    }
    set_visible(flag) {
        this.scene_group.visible = flag
    }
}

class Window {
    constructor(type, length, width, height) {
        this.position = new THREE.Vector3(0, 0, 0);

        this.type = type;
        this.length = length;
        this.width = width;          
        this.height = height;
        this.padding = 0.0;
        this.view = 0;     

        this.thickness = 0.2;

        this.parts = [];

        this.visible = true;

        this.hide = false;
    }
}

var building_module = {
    building: null,
    length: 12,
    height: 3,
    width: 5,
    wing_width: 5,
    wing_length:4,
    wall_index: 1,
    window_length: 2,
    window_width: 1,
    window_padding: 0,
    window_type: 0,
    window_hide: false,    
    overhang: .3,
    test: 0,

    length_gui: null,
    width_gui: null,
    wing_width_gui: null,
    wing_length_gui: null,
    overhang_gui: null,
    height_gui: null,
    window_padding_gui: null,
    wall_index_gui: null,
    window_lenght_gui: null,
    window_width_gui: null,
    window_type_gui: null,

    lock: false,
    product_lock_gui: null,

    rotation: 0,
    rotation_gui: null,

    set_product: function(building) {
        if(building) {
            building_module.building = building
            building_module.length_gui.setValue(building.length)
            building_module.width_gui.setValue(building.width)
            //building_module.wing_width_gui.setValue(building.wing_width)
            building_module.wing_length_gui.setValue(building.wing_length)
            building_module.overhang_gui.setValue(building.overhang)
            building_module.height_gui.setValue(building.height)
            building_module.product_lock_gui.setValue(building.lock)
            building_module.rotation_gui.setValue(building.rotate)
            //building_module.wall_index_gui.setValue(product.wall_index)
        }
    },
    add_gui: function(gui) {
        var building_gui = gui.addFolder('Building Setup');

        building_module.product_lock_gui = building_gui.add(building_module, "lock").onChange(function() {
            if(building_module.building) {
                building_module.building.lock = building_module.lock
            }
        });

        building_module.length_gui = building_gui.add(building_module, 'length', 6, 20, 1).onChange(function() {
            if(building_module.building) {
                building_module.building.length = building_module.length;
                building_module.setup(building_module.building)                
            }        
        });
        building_module.width_gui = building_gui.add(building_module, 'width', 3, 10, 1).onChange(function() {
            if(building_module.building) {
                building_module.building.width = building_module.width;
                building_module.building.wing_width = building_module.width;
                building_module.setup(building_module.building)                
            }        
        });
        building_module.rotation_gui = building_gui.add(building_module, 'rotation', 0, 360, 1).onChange(function() {
            building_module.rotate(building_module.building, building_module.rotation)
        });
        building_module.wing_length_gui = building_gui.add(building_module, 'wing_length', 0, 20, 1).onChange(function() {
            if(building_module.building) {
                building_module.building.wing_length = building_module.wing_length;
                building_module.setup(building_module.building)                
            }        
        });
        building_module.overhang_gui = building_gui.add(building_module, 'overhang', 0, 1, 0.1).onChange(function() {
            if(building_module.building) {
                building_module.building.overhang = building_module.overhang;
                building_module.setup(building_module.building)                
            }        
        }); 
        building_module.height_gui = building_gui.add(building_module, 'height', 3, 6, 1).onChange(function() {
            if(building_module.building) {
                building_module.building.height = building_module.height;
                building_module.setup(building_module.building)                
            }        
        }); 
        
        building_module.wall_index_gui = building_gui.add(building_module, 'wall_index', 1, 4, 1).onChange(function() {
            if(building_module.building) {               
                var material = loader_module.get_wall_material(building_module.wall_index)
                building_module.update_wall_material(material);                
            }        
        }); 

        building_gui.add(building_module, 'load_wall_material').name('custom wall');
        const loadBuildingImageInput1 = document.getElementById("loadBuildingImageInput1");
        if(loadBuildingImageInput1) loadBuildingImageInput1.addEventListener("change", function() {
            var file = document.getElementById('loadBuildingImageInput1').files[0];
            document.getElementById('loadBuildingImageInput1').value = ''
            var path = (window.URL || window.webkitURL).createObjectURL(file);
            building_module.update_wall_material(loadMaterial(path));
        }, false);

        building_gui.add(building_module, 'window_hide').onChange(function() {
            if(building_module.building) {               
                if(building_module.building.selected_window) {
                    building_module.building.selected_window.hide = building_module.window_hide
                    building_module.setup(building_module.building)                    
                }
            }        
        });

        building_module.window_length_gui = building_gui.add(building_module, 'window_length', 1, 8, 0.1).onChange(function() {
            if(building_module.building) {               
                if(building_module.building.selected_window) {
                    building_module.building.selected_window.length = building_module.window_length
                    building_module.setup(building_module.building)                    
                }
            }        
        }); 

        building_module.window_width_gui = building_gui.add(building_module, 'window_width', 1, 2.5, 0.1).onChange(function() {
            if(building_module.building) {               
                if(building_module.building.selected_window) {
                    building_module.building.selected_window.width = building_module.window_width
                    building_module.setup(building_module.building)                   
                }
            }        
        });

        building_module.window_padding_gui = building_gui.add(building_module, 'window_padding', 0, 2, 0.1).onChange(function() {
            if(building_module.building) {               
                if(building_module.building.selected_window) {
                    building_module.building.selected_window.padding = building_module.window_padding
                    building_module.setup(building_module.building)                    
                }
            }        
        });

        building_module.window_type_gui = building_gui.add(building_module, 'window_type', 0, 8, 1).onChange(function() {
            if(building_module.building) {               
                if(building_module.building.selected_window) {
                    building_module.building.selected_window.type = building_module.window_type
                    building_module.setup(building_module.building)                    
                }
            }        
        }); 
        return building_gui
    },
    add: function(type, len) {        
        var building = new Building(type)
        building.position.z -= len

        for(var i = 0; i < 24; ++i) {
            building_module.add_window(building, 0)
        }        
        building_module.add_window(building, 0)
        building_module.windows_color(building, 1, gui_module.posts)      
        building_module.add_walls(building)
        gable_hip_module.add_gutters(building)   
        sceneItems.scene.add(building.scene_group);
        building_module.building = building;
        gable_hip_module.add_roofing(building, function() { building_module.setup(building) })
        return building
    },
    rotate(building, rotation) {
        if(building && rotation) {
            var view_radian = Math.PI * rotation / 180
            var cos_view = Math.cos(view_radian);
            var sin_view = Math.sin(view_radian);
            building.left_2_right_unit_vector.set(sin_view, 0, cos_view)
            building.front_2_end_unit_vector.set(cos_view, 0, -1 * sin_view)
            building.rotate = rotation
            building_module.rotation = rotation
            building_module.setup(building)                
        } 
    },
    add_walls: function(building) {
        var wall = new THREE.BoxGeometry(1, 1, 1); 
        var material = loader_module.get_wall_material(building_module.wall_index); 
        for(var i = 0; i < 720; ++i) {          
            var obj = new THREE.Mesh(wall, material);
            obj.visible = false;
            building.walls.push(obj)
            building.scene_group.add(obj); 
        } 
    },
    load_wall_material: function () {
        document.getElementById('loadBuildingImageInput1').click();
    },
    update_wall_material: function(material) {
        for(var i = 0; i < building_module.building.walls.length; ++i) {                               
            building_module.building.walls[i].traverse( function (child) {
                if(child.isMesh) child.material = material             
            });
        } 
        building_module.setup(building_module.building)
    },
    setup_walls: function(building) {
        var pos = 0

        var w_1_cnt = 0
        var w_2_cnt = 0
        var w_3_cnt = 0
        var w_4_cnt = 0
        var w_5_cnt = 0
        var w_6_cnt = 0

        var yAngle = building.rotate * Math.PI / 180
         
        for(var i = 0; i < building.walls.length; ++i) {
            var obj = building.walls[i]     
            v1.copy(building.front_2_end_unit_vector) 
            v2.copy(building.left_2_right_unit_vector)        
            if(w_1_cnt < building.height * building.length) {
                obj.scale.set(1, 1, building.wall_thickness)                
                obj.rotation.set(0, yAngle, 0)
                obj.position.copy(building.position)
                pos = w_1_cnt % building.length
                if(building.type == 33) pos -= building.wing_width
                v1.multiplyScalar(pos)                 
                v2.multiplyScalar(-0.9 * building.wall_thickness)
                v2.add(v1)
                obj.position.add(v2)  
                obj.position.y =  (1 + 2 * Math.floor(w_1_cnt / building.length)) / 2
                obj.visible = true;
                ++w_1_cnt;
            } else if(w_2_cnt < building.height * building.width) {
                obj.scale.set(building.wall_thickness, 1, 1)                
                obj.rotation.set(0, yAngle, 0)
                obj.position.copy(building.position)
                pos = -1 * (building.wing_width + 0.5)
                if(building.type == 32) pos = building.length - 0.5
                v1.multiplyScalar(pos)                 
                v2.multiplyScalar(- 1 * w_2_cnt % building.width - 0.5 - 0.4 * building.wall_thickness)
                v2.add(v1)
                obj.position.add(v2)  
                obj.position.y = (1 + 2 * Math.floor(w_2_cnt / building.width)) / 2
                obj.visible = true;
                ++w_2_cnt
            } else if(w_3_cnt < building.height * (building.length + building.wing_width)) {
                obj.scale.set(1, 1, building.wall_thickness)
                obj.rotation.set(0, yAngle, 0)
                obj.position.copy(building.position)
                
                v1.multiplyScalar(building.length - w_3_cnt % (building.length + building.wing_width) - 1)                 
                v2.multiplyScalar(- 0.9 * building.wall_thickness - building.width)
                v2.add(v1)
                obj.position.add(v2)  
                obj.position.y =  (1 + 2 * Math.floor(w_3_cnt / (building.length + building.wing_width))) / 2
                obj.visible = true;
                ++w_3_cnt
            } else if(w_4_cnt < building.height * (building.width + building.wing_length)) {
                obj.scale.set(building.wall_thickness, 1, 1)
                obj.rotation.set(0, yAngle, 0)
                obj.position.copy(building.position)
                pos = building.length - 0.5
                if(building.type == 32) pos = -1 *(building.wing_width + 0.5)
                v1.multiplyScalar(pos)                 
                v2.multiplyScalar(1 * w_4_cnt % (building.width + building.wing_length) + 0.5 - 0.4 * building.wall_thickness  - building.width)
                v2.add(v1)
                obj.position.add(v2)  
                obj.position.y = (1 + 2 * Math.floor(w_4_cnt /(building.width + building.wing_length))) / 2
                obj.visible = true;
                ++w_4_cnt
            } else if(w_5_cnt < building.height * (building.wing_width)) {
                obj.scale.set(1, 1, building.wall_thickness)
                obj.rotation.set(0, yAngle, 0)
                obj.position.copy(building.position)
                pos = w_5_cnt % (building.wing_width) - building.wing_width
                if(building.type == 33) pos += building.length
                v1.multiplyScalar(pos)                 
                v2.multiplyScalar(-0.9 * building.wall_thickness + building.wing_length)
                v2.add(v1)
                obj.position.add(v2)  
                obj.position.y = (1 + 2 * Math.floor(w_5_cnt / (building.wing_width))) / 2
                obj.visible = true;
                ++w_5_cnt
            } else if(w_6_cnt < building.height * (building.wing_length)) {
                obj.scale.set(building.wall_thickness, 1, 1)
                obj.rotation.set(0, yAngle, 0)
                obj.position.copy(building.position)
                pos = -0.5
                if(building.type == 33) pos += building.length - building.wing_width
                v1.multiplyScalar(pos)                 
                v2.multiplyScalar(w_6_cnt % (building.wing_length) + 0.5 - 0.4 * building.wall_thickness)
                v2.add(v1)
                obj.position.add(v2)  
                obj.position.y = (1 + 2 * Math.floor(w_6_cnt /(building.wing_length))) / 2
                obj.visible = true;
                ++w_6_cnt
            } else  {
                obj.visible = false;
            }
        }
    },
    add_window: function(building, no) {       
        var window = new Window(6, 2.5, 2, 0.5)
        var post = new THREE.BoxGeometry(1, 1, 1); 
        var material = getMaterial(0xffffff, 0)
        for(var i = 0; i < 10; ++i) {           
            var obj            
            if(i != 9) obj = new THREE.Mesh(post, material);
            else {
                obj = new THREE.Mesh(post, getMaterial(0xffff00, 0));
                building.parts["list"].push(obj)
            }
            window.parts.push(obj)
            building.scene_group.add(obj); 
        } 
        building.windows.push(window)
    },
    find_windows_position(building) {
        
        var distance, offset, window_no     
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 1; i += 1) {
            var window =  building.windows[i]

            if(building.height > 2) {
                window.position.set(0, 0, 0) //building.position)

                window.view = 90
                 
                if(building.type == 32) window.position.x -= 0.5 - window.thickness / 2 
                else window.position.x += building.length - building.wing_width - window.thickness / 2 - 0.5       
                window.position.y = window.height + building.height - 3   
                window.position.z += distance

                window.visible = false
                if(!window.hide) {
                    var window_space = window.padding + window.length 
                    if(distance + window_space <= building.wing_length) {
                        distance += window_space
                        ++window_no
                        window.visible = true
                    } 
                }
            } else window.visible = false
        }

        offset = (building.wing_length - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 1; ++i) {
                var window = building.windows[i]
                if(!window.hide) {
                    window.position.z += (j++ + 1) * offset                    
                }
        }    
        ////////////////////////////////////////////////////////////
        for(var i = 0, window_no = 0, distance = 0; i < 1; i += 1) {
            var window =  building.windows[i + 1]

            window.position.set(0, 0, 0) //building.position)
 
            if(building.height > 2) {         
                window.position.y = window.height + building.height - 3  
                if(building.type == 32) window.position.x += distance + window.padding - 0.5 + window.thickness / 2
                else window.position.x += window.padding - 0.5 + window.thickness / 2 - building.wing_width

                window.visible = false
                if(!window.hide) {
                    var window_space = window.padding + window.length 
                    if(distance + window_space <= building.length - window.thickness) {
                        distance += window_space
                        ++window_no
                        window.visible = true
                    } 
                }
            } else {
                window.visible = false
            }
        }

        offset = (building.length - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 1; ++i) {
            var window = building.windows[i + 1]
            if(!window.hide) {
                window.position.x += (j++ + 1) * offset                    
            }   
        }
        
        for(var i = 2; i < building.windows.length; ++i) {            
            var window =  building.windows[i]
            window.visible = false
            
        }   
    },
    setup_windows: function(building) {  
        var x, y, z     
        var yAngle = building.rotate * Math.PI / 180
        building_module.find_windows_position(building)    
        for(var i = 0; i < building.windows.length; ++i) {
            var window =  building.windows[i]
            for(var j = 0; j < window.parts.length; ++j) {
                var obj = window.parts[j]
                obj.position.copy(building.position)  
                v1.copy(building.front_2_end_unit_vector) 
                v2.copy(building.left_2_right_unit_vector) 
                obj.rotation.set(0, yAngle, 0)

                if(!window.visible) {
                    obj.visible = false
                } else {  
                    obj.visible = true; 
                    switch(j) {
                        case 0: 
                        case 1:
                        case 2:    
                            if(window.view == 0) {
                                obj.scale.set(window.length, window.thickness, window.thickness)   
                                x = window.position.x + (window.length) / 2
                                y = window.position.y
                                z = window.position.z

                            } else {                            
                                obj.scale.set(window.thickness, window.thickness, window.length)  
                                z = window.position.z + (window.length) / 2
                                x = window.position.x 
                                y = window.position.y                                             
                            }    
                            if(j == 1) y += window.width 
                            else if(j == 2)  {
                                if(window.type & 0x01) {
                                    obj.visible = false;
                                } else {
                                    y += 3 * window.width / 4
                                }                              
                            }                                                 
                        break;
                        case 3:
                        case 4:
                        case 5: 
                        case 6: 
                        case 7:                         
                            obj.scale.set(window.thickness, window.width, window.thickness)
                            x = window.position.x
                            y = window.position.y + window.width / 2
                            z = window.position.z
                            if(window.view == 0) {                             
                                if(j == 4) x += window.length 
                                if(j == 5) {
                                    if(window.type & 0x02) {
                                        obj.visible = false;
                                    } else {
                                        x += window.length / 2
                                    }                                
                                }
                                if(j == 6) {
                                    if(window.type & 0x04) {
                                        obj.visible = false;
                                    } else {
                                        x += window.length / 4
                                    }                                
                                }
                                if(j == 7) {
                                    if(window.type & 0x04) {
                                        obj.visible = false;
                                    } else {
                                        x += 3 * window.length / 4
                                    }                                
                                } 
                            } else {   
                                z += window.length / 2  
                                if(j == 3) z -= window.length / 2           
                                if(j == 4) z += window.length / 2 
                                if(j == 5) {
                                    if(window.type & 0x02) {
                                        obj.visible = false;
                                    } else {
                                        z = 0
                                    }                                
                                }     
                                if(j == 6) {
                                    if(window.type & 0x04) {
                                        obj.visible = false;
                                    } else {
                                        z -= window.length / 4
                                    }                                
                                }
                                if(j == 7) {
                                    if(window.type & 0x04) {
                                        obj.visible = false;
                                    } else {
                                        z += window.length / 4
                                    }                                
                                }                       
                            }                        
                        break; 
                        case 9:
                            if(window.view == 0) {
                                obj.scale.set(window.length, window.width, window.thickness / 2) 
                                x = window.position.x + window.length / 2
                                y = window.position.y + window.width / 2 
                                z = window.position.z - window.thickness / 4   
                            } else {                            
                                obj.scale.set(window.thickness / 2, window.width , window.length) 
                                z = window.position.z + window.length / 2
                                y = window.position.y + window.width / 2 
                                x = window.position.x + window.thickness / 4  
                                if(building.type == 32) x -= window.thickness / 2                
                            }  
                        break;  
                        default:
                            obj.visible = false;
                            break;                                                        
                    }

                    v1.multiplyScalar(x)                 
                    v2.multiplyScalar(z)
                    v2.add(v1)
                    obj.position.add(v2)  
                    obj.position.y = y
                }
            }
        }
    },
    move: function(building, position) {
        building.position.copy(position)
        building.position.y = 0
        //building.scene_group.position.copy(building.position)
        building_module.setup(building)
    },
    ray_move: function(building, object, position) {
        var found = false
        if(building.lock) return
        for(var i = 0; i < building.windows.length && !found; ++i) {
            var window = building.windows[i]            
            window.parts[9].traverse( function (child) {                    
                if(child.isMesh && object == child) {                                                   
                    found = true   
                }      
            });
            if(found) {
                building.selected_window = window
                building_module.window_length_gui.setValue(window.length)
                building_module.window_width_gui.setValue(window.width)
                building_module.window_padding_gui.setValue(window.padding)
                //console.log("len: " + window.padding)
                building.selected_window.parts[9].traverse( function (child) {                        
                    if(child.isMesh && object == child) {                               
                        if(building.selected_window_mesh)  {
                            building.selected_window_mesh.material = getMaterial(0xffff00, 0) 
                        }    
                        building.selected_window_mesh = child
                        building.selected_window_mesh.material = getMaterial(0x000000, 0)                
                        found = true  
                    }      
                });                    
            }            
        }
        if(!found) {            
            building.position.copy(position)
            building.position.y = 0
            //building.scene_group.position.copy(building.position) 
            building_module.setup(building)
        }       
    },
    setup: function(building) {        
        building_module.setup_walls(building)
        gable_hip_module.setup_gutters_building(building)
        gable_hip_module.setup_roofing_building(building)
        building_module.setup_windows(building)
        gui_module.set_dim_text()
    },
    scale: function(building, scale) {
       
    },
    windows_color: function(building, type, color) {
        var materail
        if(type == 0) materail = getMaterial(convertColor2Hex(selectPostsColorbond(color)), 0);
        else materail = loader_module.get_wood_material()
        for(var i = 0; i < building.windows.length; ++i) {
            var window = building.windows[i]
            for(var j = 0; j < window.parts.length - 1; ++j) {                
                var obj = window.parts[j]
                obj.material = materail;                
            }
        } 

    },    
    gutters_color: function(product, color) {
        flyover_module.gutters_color(product, color)
    },
    roofing_color: function(product, color) {
        flyover_module.roofing_color(product, color)
    }
}

export { building_module }

/*
 ////////////////////////////////////////////////////////////         
        /*for(var i = 0, window_no = 0, distance = 0; i < 2; i += 1) {
            var window =  building.windows[i]

            window.position.copy(building.position)

            window.position.z += building.wing_length            
            window.position.y = window.height   
            window.position.x += distance + window.padding - building.wing_width - 0.5 

            window.visible = false
            if(!window.hide) {
                var window_space = window.padding + window.length 
                if(distance + window_space <= building.wing_width) {
                    distance += window_space
                    ++window_no
                    window.visible = true
                } 
            }
        }

        offset = (building.wing_width - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 2; ++i) {
                var window = building.windows[i]
                if(!window.hide) {
                    window.position.x += (j++ + 1) * offset                    
                }
        }
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 2; i += 1) {
            var window =  building.windows[i + 2]

            if(building.height > 5) {
                window.position.copy(building.position)

                window.position.z += building.wing_length           
                window.position.y = window.height + building.height / 2 
                window.position.x += distance + window.padding - building.wing_width - 0.5 
    
                window.visible = false
                if(!window.hide) {
                    var window_space = window.padding + window.length 
                    if(distance + window_space <= building.wing_width) {
                        distance += window_space
                        ++window_no
                        window.visible = true
                    } 
                }
            } else window.visible = false;
            
        }

        offset = (building.wing_width - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 2; ++i) {
            var window = building.windows[i + 2]
            if(!window.hide) {
                window.position.x += (j++ + 1) * offset                    
            }
        }
        //= distance;
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 4; i += 1) {
            var window =  building.windows[i + 4]

            window.position.copy(building.position)

            window.view = 90
            window.position.x -= 0.5 - window.thickness / 2          
            window.position.y = window.height   
            window.position.z += distance

            window.visible = false
            if(!window.hide) {
                var window_space = window.padding + window.length 
                if(distance + window_space <= building.wing_length) {
                    distance += window_space
                    ++window_no
                    window.visible = true
                } 
            }
        }

        offset = (building.wing_length - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 4; ++i) {
                var window = building.windows[i + 4]
                if(!window.hide) {
                    window.position.z += (j++ + 1) * offset                    
                }
        }
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 4; i += 1) {
            var window =  building.windows[i + 8]

            if(building.height > 5) {
                window.position.copy(building.position)

                window.view = 90
                window.position.x -= 0.5 - window.thickness / 2          
                window.position.y = window.height + building.height / 2   
                window.position.z += distance

                window.visible = false
                if(!window.hide) {
                    var window_space = window.padding + window.length 
                    if(distance + window_space <= building.wing_length) {
                        distance += window_space
                        ++window_no
                        window.visible = true
                    } 
                }
            } else window.visible = false
        }

        offset = (building.wing_length - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 4; ++i) {
                var window = building.windows[i + 8]
                if(!window.hide) {
                    window.position.z += (j++ + 1) * offset                    
                }
        }
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 4; i += 1) {
            var window =  building.windows[i + 12]

            window.position.copy(building.position)

            
            //window.position.z += 0            
            window.position.y = window.height   
            window.position.x += distance + window.padding - 0.5 + window.thickness / 2

            window.visible = false
            if(!window.hide) {
                var window_space = window.padding + window.length 
                if(distance + window_space <= building.length - window.thickness) {
                    distance += window_space
                    ++window_no
                    window.visible = true
                } 
            }
        }

        offset = (building.length - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 4; ++i) {
                var window = building.windows[i + 12]
                if(!window.hide) {
                    window.position.x += (j++ + 1) * offset                    
                }
        }
        ////////////////////////////////////////////////////////////
        for(var i = 0, window_no = 0, distance = 0; i < 4; i += 1) {
            var window =  building.windows[i + 16]

            window.position.copy(building.position)

            //window.position.z += 0   
            if(building.height > 5) {         
                window.position.y = window.height + building.height / 2  
                window.position.x += distance + window.padding - 0.5 + window.thickness / 2

                window.visible = false
                if(!window.hide) {
                    var window_space = window.padding + window.length 
                    if(distance + window_space <= building.length - window.thickness) {
                        distance += window_space
                        ++window_no
                        window.visible = true
                    } 
                }
            } else {
                window.visible = false
            }
        }

        offset = (building.length - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 4; ++i) {
            var window = building.windows[i + 16]
            if(!window.hide) {
                window.position.x += (j++ + 1) * offset                    
            }   
        }
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 2; i += 1) {
            var window =  building.windows[i + 20]

            window.position.copy(building.position)

            window.view = 90
            window.position.x -= 0.5 - window.thickness / 2  - building.length        
            window.position.y = window.height   
            window.position.z += distance - building.width

            window.visible = false
            if(!window.hide) {
                var window_space = window.padding + window.length 
                if(distance + window_space <= building.width) {
                    distance += window_space
                    ++window_no
                    window.visible = true
                } 
            }
        }

        offset = (building.width - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 2; ++i) {
                var window = building.windows[i + 20]
                if(!window.hide) {
                    window.position.z += (j++ + 1) * offset                    
                }
        }
        ////////////////////////////////////////////////////////////         
        for(var i = 0, window_no = 0, distance = 0; i < 2; i += 1) {
            var window =  building.windows[i + 22]

            window.position.copy(building.position)
            if(building.height > 5) {
                window.view = 90
                window.position.x -= 0.5 - window.thickness / 2  - building.length        
                window.position.y = window.height + building.height / 2  
                window.position.z += distance - building.width
    
                window.visible = false
                if(!window.hide) {
                    var window_space = window.padding + window.length 
                    if(distance + window_space <= building.width) {
                        distance += window_space
                        ++window_no
                        window.visible = true
                    } 
                }
            } else {
                window.visible = false
            }            
        }

        offset = (building.width - distance) / (window_no + 1)     

        for(var i = 0, j = 0; i < 2; ++i) {
                var window = building.windows[i + 22]
                if(!window.hide) {
                    window.position.z += (j++ + 1) * offset                    
                }
        }
        
        for(var i = 36; i < building.windows.length; ++i) {            
            var window =  building.windows[i]
            //window.visible = false
            
        }    */    