import React from 'react';
import axios from 'axios';
import { product_manager_module } from '../smartkits/product_manager_module';
import { project_specification } from '../smartkits/gui_smartkits'
import { findModelTypeStringGlobal, getJsonTemplate, getOffering, getDesignType, getRoofingSheetMap, getGuttersMap, getWindImportanceLevelsMap, getWindBlockingsMap, getDownpipesMap, getPostsMap,
          getPurlinsMap, getRaftersMap, getFasciasMap, getAttachmentsMap} from './LysaghtHelper.js'
import { config } from '../smartkits/initSmartkits.js'
import '../App.css'

class SmartEngineeringMain extends React.Component {

  constructor() {
    super();
    this.state =  { 
        visibility: true,
        products: product_manager_module.products,
        lysaght_token: '',
        update: true
    }   
    var session = JSON.parse(window.sessionStorage.getItem('smartkits_token'))
    var token = null
    if(session != null) token = session.lysaght_token
    if(token != null) {
        this.state.lysaght_token = token
        console.log('LYSAGHT TOKEN SET')
    } else if(!config.deployment) {
      this.state.lysaght_token = '6cd37887caa3604532085148d3312fe1'
      console.log('LYSAGHT TOKEN MANUALLY SET')
      project_specification.build_street_no = '2'
      project_specification.build_street = 'Hobby Court'
      project_specification.build_suburb = 'Mango Hill'
      project_specification.build_post_code = '4508'
      project_specification.build_state = 'QLD'
    } else {
      console.log('ERROR: LYSAGHT TOKEN NOT SET!!')
    }
  }

  findModelType = (index) => {
    var model = 1
    if(this.state.products.length >= index) model = this.state.products[index].price_index
    return findModelTypeStringGlobal(model, true)
  }

  setRoofSheetType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].roof_sheet_type = v
    this.setState({ //dummy
        visibility: true
    })
  }

  setGutterType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].gutter_type = v
    this.setState({ 
        visibility: true
    })
  }

  setWindImportanceLevel = (event, index) => {
    var v = event.target.value
    product_manager_module.products[index].wind_options_importance_level = parseInt(v)
    this.setState({ 
        visibility: true
    })
  }

  setBlocking = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].wind_options_blocking = parseInt(v)
    this.setState({ 
        visibility: true
    })
  }

  setDownpipeType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].downpipe_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].attachment_type = v
    this.setState({ 
        visibility: true
    })
  }

  setPartialAttachmentType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].partial_attachment_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentSubType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].attachment_sub_type = v
    this.setState({ 
        visibility: true
    })
  }

  setPostType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].post_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentTop = (event, index) => {
    var v = event.target.checked 
    if(v) product_manager_module.products[index].attachment_top = 1
    else product_manager_module.products[index].attachment_top = 0
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentBottom = (event, index) => {
    var v = event.target.checked 
    if(v) product_manager_module.products[index].attachment_bottom = 1
    else product_manager_module.products[index].attachment_bottom = 0
    this.setState({
        visibility: true
    })
  }

  setAttachmentLeft = (event, index) => {
    var v = event.target.checked 
    if(v) product_manager_module.products[index].attachment_left = 1
    else product_manager_module.products[index].attachment_left = 0
    this.setState({
        visibility: true
    })
  }

  setAttachmentRight = (event, index) => {
    var v = event.target.checked 
    if(v) product_manager_module.products[index].attachment_right = 1
    else product_manager_module.products[index].attachment_right = 0
    this.setState({
        visibility: true
    })
  }

  setBracketType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].bracket_type = v
    this.setState({ 
        visibility: true
    })
  }

  setFasciaType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].fascia_type = v
    this.setState({ 
        visibility: true
    })
  }

  setFasciaBeamType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].fascia_beam_type = v
    this.setState({ 
        visibility: true
    })
  }

  setPurlinBeamType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].purlin_beam_type = v
    this.setState({ 
        visibility: true
    })
  }

  setRafterBeamType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].rafter_beam_type = v
    this.setState({ 
        visibility: true
    })
  }

  setFootingType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].footing_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentTopType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].attachment_top_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentBottomType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].attachment_bottom_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentRightType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].attachment_right_type = v
    this.setState({ 
        visibility: true
    })
  }

  setAttachmentLeftType = (event, index) => {
    var v = event.target.value 
    product_manager_module.products[index].attachment_left_type = v
    this.setState({ 
        visibility: true
    })
  }

  findIconClass = (value, index) => {
    var f = product_manager_module.products[index].eng_details
    var s = product_manager_module.products[index].eng_validation_state
    switch(value) {
      case 0:
        if(f) {
            return "bi bi-dash-circle mx-1 my-1 text-secondary" 
        } else {
            return "bi bi-plus-circle mx-1 my-1 text-dark"
        }
      break
      case 1:
        switch(s) {
            case 0:
                return "btn btn-primary col-md-7 mx-1 my-1" 
            case 1:
                return "btn btn-secondary col-md-7 mx-1 my-1" 
            case 2:
                return "btn btn-success col-md-7 mx-1 my-1" 
            case 3:
                return "btn btn-danger col-md-7 mx-1 my-1" 
            case 4:
                return "btn btn-warning col-md-7 mx-1 my-1" 
            case 5:
                return "btn btn-info col-md-7 mx-1 my-1" 
        }
        break
    }
  }

  showDetails = (index) => {
    var f = product_manager_module.products[index].eng_details
    product_manager_module.products[index].eng_details = !f
    this.setState({ 
        visibility: true
    })
  }


  getLysaghtURL = (index) => {
    //const url = 'https://preprod.lysaghtlivingdesigner.lysaght.com/api/Smartkits/GetSmartkits'  
    //const url1 = 'https://preprod.lysaghtlivingdesigner.lysaght.com/api/Smartkits/ImportSmartkits?GetPricing=true&GetEngineering=true'  
    const url = 'https://lysaghtlivingdesigner.lysaght.com/api/Smartkits/GetSmartkits'  
    const url1 = 'https://lysaghtlivingdesigner.lysaght.com/api/Smartkits/ImportSmartkits?GetPricing=true&GetEngineering=true'
    const url3 = 'https://preprod.lysaghtlivingdesigner.lysaght.com/api/Smartkits/ImportSmartkits?GetPricing=true&GetEngineering=true'
    const url4 = 'https://preprod.lysaghtlivingdesigner.lysaght.com/api/Smartkits/GetPricing?JobNumber='  

    if(index == 0) return url3
    else if(index == 1) return url4
    else return url
  }

  updateValidateStatus = () => {
    this.setState({ 
      update: true
  })   
  }

  getLysaght = (index) => {
    product_manager_module.products[index].eng_validation_state = 1
    product_manager_module.products[index].eng_validation_msg = 'Trying Reach to Service'

    const headers = {
        apiKey: this.state.lysaght_token,
      }

    axios.get(this.getLysaghtURL(1), {
        headers: headers,
        timeout: 5000
      })
      .then(response => {
        console.log('Response status:', response.status)
        console.log('Response data:', response.data)
        product_manager_module.products[index].eng_validation_state = 5
        product_manager_module.products[index].eng_validation_msg = 'Service Online'
      })
      .catch(error => {
        if (error.code === 'ECONNABORTED') {
          console.error('Request timed out');
          product_manager_module.products[index].eng_validation_state = 3
          product_manager_module.products[index].eng_validation_msg = 'Service Timeout'
          this.updateValidateStatus()
        } else {
          console.error('Error making GET request:', error.message);
          product_manager_module.products[index].eng_validation_state = 3
          product_manager_module.products[index].eng_validation_msg = 'Service Response: ' + error.message
          this.updateValidateStatus()
        }
      });
  }

  makeLysahgtJsonFile = (index) => {
    var product = product_manager_module.products[index]
    var json = getJsonTemplate(index)

    var offering = getOffering(3)
    json.Offerings.label = offering.label
    json.Offerings.value = offering.value
    json.Offerings.poa = offering.poa
    json.Offerings.state = offering.state

    json.DesignType = getDesignType(product.type)
    json.Customer.Address.StreetNumber = project_specification.build_street_no
    json.Customer.Address.Street = project_specification.build_street
    json.Customer.Address.Suburb = project_specification.build_suburb
    json.Customer.Address.State = project_specification.build_state
    json.Customer.Address.PostCode = project_specification.build_post_code
    json.Roofing.Roofsheet = product.roof_sheet_type
    json.Dimensions.Length = product.length * 1000
    json.Dimensions.Depth = product.projection * 1000
    json.Dimensions.Height = product.height * 1000
    json.Gutters.GutterType = product.gutter_type
    json.WindOptions.ImportanceLevel = product.wind_options_importance_level
    json.WindOptions.Blocking = product.wind_options_blocking
    json.Posts.PostType = product.post_type
    json.Beams.Purlin.BeamType = product.purlin_beam_type
    json.Beams.Rafter.BeamType = product.rafter_beam_type
    //json.Beams['Fascia Beam'].BeamType = product.fascia_beam_type
    json.Downpipes.DownpipeType = product.downpipe_type
    if(product.attachment_top == 1) json.Attachments.Top.AttachmentType = product.attachment_top_type
    else delete json.Attachments.Top
    if(product.attachment_bottom == 1) json.Attachments.Bottom.AttachmentType = product.attachment_bottom_type
    else delete json.Attachments.Bottom
    if(product.attachment_right == 1) json.Attachments.Right.AttachmentType = product.attachment_right_type
    else delete json.Attachments.Right
    if(product.attachment_left == 1) json.Attachments.Left.AttachmentType = product.attachment_left_type
    else delete json.Attachments.Left

    //console.log('JSON FILE:' + json.Attachments.Left)
    console.log('TEST1 ' + ':\n\rDesign Type: ' + json.DesignType + '\n\r' +
      'Offering value: ' + json.Offerings.value + '\n\r' + 
      'Offering label: ' + json.Offerings.label + '\n\r' + 
      'Offering poa: ' + json.Offerings.poa + '\n\r' + 
      'Offering state: ' + json.Offerings.state + '\n\r' + 

      'Roofsheet:   ' + json.Roofing.Roofsheet + '\n\r' + 
      'Lenght:      ' + json.Dimensions.Length + '\n\r' + 
      'Depth:      ' + json.Dimensions.Depth + '\n\r' + 
      'Height:      ' + json.Dimensions.Height + '\n\r' + 
      'Gutter:   ' + json.Gutters.GutterType + '\n\r' + 
      'Wind Importance:   ' + json.WindOptions.ImportanceLevel + '\n\r' + 
      'Wind Blocking:   ' + json.WindOptions.Blocking + '\n\r' + 
      'Downpipes:   ' + json.Downpipes.DownpipeType + '\n\r' + 
      'Posts:   ' + json.Posts.PostType + '\n\r' + 
      //'Fascia Beam:   ' + json.Beams['Fascia Beam'].BeamType + '\n\r' + 
      'Purlin Beam:   ' + json.Beams.Purlin.BeamType + '\n\r' + 
      'Rafter Beam:   ' + json.Beams.Rafter.BeamType + '\n\r'  +
      'Attachment Top: ' + json.Attachments.Top?.AttachmentType + '\n\r'  +
      'Attachment Bottom: ' + json.Attachments.Bottom?.AttachmentType + '\n\r' +
      'Attachment Left: ' + json.Attachments.Left?.AttachmentType + '\n\r'  +
      'Attachment Right: ' + json.Attachments.Right?.AttachmentType + '\n\r'  
    );
    return json
  }

  getPriceFromLysaght = (product, headers, msg) => {
    let job = msg.substring(msg.indexOf('id: ') + 4)
    let url = 'https://preprod.lysaghtlivingdesigner.lysaght.com/api/Smartkits/GetPricing?JobNumber=' + job 
    console.log('URL GET:', url)
    axios.get(url, {
      headers: headers,
      timeout: 60000
    })
    .then(response => {
      console.log('Response status:', response.status)
      console.log('Response status:', response.data)
      product.eng_validation_state = 5
      product.eng_validation_msg = response.data?.Pricing?.Notes
      product.eng_price_total_Inc_GST = response.data?.Pricing?.TotalIncGST
      product.eng_price_total_Ex_GST = response.data?.Pricing?.TotalExGST
      product.eng_price_packing_delivery = response.data?.Pricing?.PackingAndDel
      product.eng_price_ready = true
      this.updateValidateStatus()
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timed out');
      } else {
        console.error('Error making GET request:', error.message);
      }
    });
  }

  postLysaght = (index) => {
    var product = product_manager_module.products[index]
    var json = this.makeLysahgtJsonFile(index)

    product.eng_validation_state = 1
    product.eng_validation_msg = 'Waiting for Service Response'
    product.eng_pricing_view = true
    const headers = {
        apiKey: this.state.lysaght_token,
      }

    axios.post(this.getLysaghtURL(0), json, {
      headers: headers,
      timeout: 150000
    })
    .then(response => {
      console.log('Response status:', response.status)
      console.log('Response data:', response.data)
      setTimeout(() => this.getPriceFromLysaght(product, headers, response.data.Message), 45000);

    })
    .catch(error => {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timed out')
        product.eng_validation_state = 3
        product.eng_validation_msg = 'Service Timeout'
        product.eng_price_ready = false
        this.updateValidateStatus()
      } else {
        console.error('Error making POST request:', error)
        product.eng_validation_state = 3
        product.eng_validation_msg = error.message
        product.eng_price_ready = false
        this.updateValidateStatus()
      }
    })
  }

  validateModel = (index) => {
    this.setState({ 
        visibility: true
    })   
    //this.getLysaght(index) 
    this.postLysaght(index)    
  }

  reset = () => {
    var products = product_manager_module.products
    for(var i = 0; i < products.length; ++i) {
        products[i].eng_validation_state = 0
        products[i].eng_validation_msg = 'Ready to Try Again'
        products[i].eng_pricing_view = false
        products[i].eng_price_ready = false
        if( products[i].type < 13)  products[i].eng_pricing_button_visible = true
        else  products[i].eng_pricing_button_visible = false
    }
    this.setState({ 
        update: true
    }) 
  }

  informSelect = () => {
  }

  componentDidMount() {
    this.informSelect()
    this.reset()
  }

  render() {
    return (
      <div>
       <div id='engineering'>
       <button  className="btn btn-success col-md-3 mx-1 my-1" onClick={this.reset}>Reset</button>
       <button className="btn btn-light col-md-12 mx-1 my-1">{'Customer Name: ' + project_specification.first_name + ' ' + project_specification.family_name}</button>
       <button className="btn btn-light col-md-12 mx-1 my-1">{'Customer Address: ' + project_specification.build_street_no  + ' ' +  project_specification.build_street + ' ' + project_specification.build_suburb + ' ' +  project_specification.build_post_code + ' ' + project_specification.build_state}</button>
       {this.state.products.map(({length, projection, height, angle, front_posts_no, brackets_no}, index) => 
       this.state.products[index].type != 32 && this.state.products[index].type != 33 &&  this.state.products[index].type != 100 && 
       (<div>
            {product_manager_module.products[index].eng_pricing_button_visible && (<div><i className={this.findIconClass(0, index)} title="Show Details" style={{ fontSize: 20 }} onClick={() => this.showDetails(index)}></i>
            <b>Model {index + 1}:</b><button className="btn btn-light col-md-3 mx-1 my-1">{this.findModelType(index)}</button>
            <button className="btn btn-warning col-md-3 mx-1 my-1" disabled={ product_manager_module.products[index].eng_pricing_view } onClick={() => this.validateModel(index)}>Get Price</button><br/></div>)}
            {product_manager_module.products[index].eng_pricing_view && (<div id='pricing_eng_view'>
              <button id={'modelStatus' + index} className={this.findIconClass(1, index)}>{product_manager_module.products[index].eng_validation_msg}</button>
              </div>)
            }
            {product_manager_module.products[index].eng_price_ready && (<div id='eng_price_ready'>
              <button id={'modelStatus' + index} className="btn btn-success col-md-3 mx-1 my-1">{'Total Price (Inc GST): $' + Math.ceil(product_manager_module.products[index].eng_price_total_Inc_GST)}</button>
              <button id={'modelStatus' + index} className="btn btn-light col-md-3 mx-1 my-1">{'Total Price (Exc GST): $' +  Math.ceil(product_manager_module.products[index].eng_price_total_Ex_GST)}</button>
              <button id={'modelStatus' + index} className="btn btn-dark col-md-3 mx-1 my-1">{'Packing and Delivery Price: $' + Math.ceil(product_manager_module.products[index].eng_price_packing_delivery)}</button>
              </div>)
            }

            {product_manager_module.products[index].eng_details && (<div id='eng_details'>
            <table border="solid" width='100%'>
            <tr>
                <th width='20%'></th>
                <th width='10%'></th>
                <th width='20%'></th>
                <th width='50%'></th>
            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Length</button></td>
                <td><button className="btn eng-length-btn col-md-8 mx-1 my-1">{length?.toFixed(2)}</button></td>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Projection</button></td>
                <td><button className="btn eng-projection-btn col-md-2 mx-1 my-1">{projection?.toFixed(2)}</button></td>
            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Height</button></td>
                <td> <button className="btn eng-height-btn col-md-8 mx-1 my-1">{height?.toFixed(2)}</button></td>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Pitch</button></td>
                <td><button className="btn eng-pitch-btn col-md-2 mx-1 my-1">{angle}</button></td>
            </tr>           
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Posts No</button></td>
                <td><button className="btn eng-posts-no-btn col-md-8 mx-1 my-1">{front_posts_no}</button></td>                
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Posts Type</button></td>
                <td>
                <select  className="custom-list eng-post-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].post_type} 
                    onChange={(event) => this.setPostType(event, index)}
                >
                {getPostsMap(this.state.products[index].type).map(({value}, index) => <option  className="btn eng-attachment-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Bracket No</button></td>
                <td><button className="btn eng-brackets-no-btn col-md-8 mx-1 my-1">{brackets_no}</button></td>              

            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Roofing Sheet Type</button></td>
                <td></td>
                <td></td>
                <td>
                    <select  className="btn eng-roof-sheet-type-dropbox col-md-8 mx-1 my-1" 
                        value={product_manager_module.products[index].roof_sheet_type} 
                        onChange={(event) => this.setRoofSheetType(event, index)}
                    >
                    {getRoofingSheetMap(this.state.products[index].type).map(({ value }, index) => <option className="btn eng-roof-sheet-type-dropbox" key={index} value={value} >{value}</option>)}
                    </select>
                </td>
            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Gutter Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="btn eng-gutter-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].gutter_type} 
                    onChange={(event) => this.setGutterType(event, index)}
                >
                {getGuttersMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-gutter-type-dropbox" key={index} value={value} >{value}</option>)}
                </select><br/>
                </td>
            </tr>  
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Wind Importance Level</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="btn eng-wind-importance-level-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].wind_options_importance_level} 
                    onChange={(event) => this.setWindImportanceLevel(event, index)}
                >
                {getWindImportanceLevelsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-wind-importance-level-dropbox" key={index} value={value} >{value}</option>)}
                </select><br/>
                </td>
            </tr>   
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Wind Blocking</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="btn eng-wind-blocking-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].wind_options_blocking} 
                    onChange={(event) => this.setBlocking(event, index)}
                >
                {getWindBlockingsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-wind-blocking-dropbox" key={index} value={value} >{value}</option>)}
                </select><br/>
                </td>
            </tr>  
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Downpipe Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="btn eng-downpipe-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].downpipe_type} 
                    onChange={(event) => this.setDownpipeType(event, index)}
                >
                {getDownpipesMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-downpipe-type-dropbox" key={index} value={value} >{value}</option>)}
                </select><br/>
                </td>
            </tr>       
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Top</button></td>
                <td><input type="checkbox" defaultChecked={product_manager_module.products[index].attachment_top == 1} onChange={(event) => this.setAttachmentTop(event, index)} /></td>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Bottom</button></td>
                <td><input type="checkbox" defaultChecked={product_manager_module.products[index].attachment_bottom == 1} onChange={(event) => this.setAttachmentBottom(event, index)} /></td>
            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Left</button></td>
                <td><input type="checkbox" defaultChecked={product_manager_module.products[index].attachment_left == 1} onChange={(event) => this.setAttachmentLeft(event, index)} /></td>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Right</button></td>
                <td> <input type="checkbox" defaultChecked={product_manager_module.products[index].attachment_right == 1} onChange={(event) => this.setAttachmentRight(event, index)} /></td>
            </tr>                
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Fascia Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-fascia-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].fascia_beam_type} 
                    onChange={(event) => this.setFasciaBeamType(event, index)}
                >
                {getFasciasMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-fascia-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr>   
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Purlin Beam Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-purlin-beam-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].purlin_beam_type} 
                    onChange={(event) => this.setPurlinBeamType(event, index)}
                >
                {getPurlinsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-purlin-beam-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr> 
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Rafter Beam Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-rafter-beam-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].rafter_beam_type} 
                    onChange={(event) => this.setRafterBeamType(event, index)}
                >
                {getRaftersMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-rafter-beam-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr> 
            {(this.state.products[index].attachment_top == 1) && (<tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Top Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-attachment-top-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].attachment_top_type} 
                    onChange={(event) => this.setAttachmentTopType(event, index)}
                >
                {getAttachmentsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-attachment-top-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr>)}   
            {(this.state.products[index].attachment_bottom == 1) && (<tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Bottom Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-attachment-bottom-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].attachment_bottom_type} 
                    onChange={(event) => this.setAttachmentBottomType(event, index)}
                >
                {getAttachmentsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-attachment-bottom-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr> )}
            {(this.state.products[index].attachment_left == 1) && (<tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Left Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-attachment-left-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].attachment_left_type} 
                    onChange={(event) => this.setAttachmentLeftType(event, index)}
                >
                {getAttachmentsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-attachment-left-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr>)}
            {(this.state.products[index].attachment_right == 1) && (<tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Right Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-attachment-right-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].attachment_right_type} 
                    onChange={(event) => this.setAttachmentRightType(event, index)}
                >
                {getAttachmentsMap(this.state.products[index].type).map(({ value }, index) => <option  className="btn eng-attachment-right-type-dropbox" key={index} value={value} >{value}</option>)}
                </select>
                </td>
            </tr> )}     
            </table>
            </div>)}
        </div>))}
       </div>
      </div>
     );  
  }
}

export default SmartEngineeringMain;

/*
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Footing Type</button></td>
                <td></td>
                <td></td>
                <td>

                </td>
            </tr>
            <tr>
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Sub Type</button></td>
                <td></td>
                <td></td>
                <td>

                </td>
            </tr> 
                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Bracket Type</button></td>
                <td>
                <select  className="custom-list eng-bracket-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].bracket_type} 
                    onChange={(event) => this.setBracketType(event, index)}
                >
                {bracketTypes.map(({ value, label }, index) => <option  className="btn eng-bracket-type-dropbox" key={index} value={value} >{label}</option>)}
                </select>
                </td>


                                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Attachment Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="btn eng-attachment-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].attachment_type} 
                    onChange={(event) => this.setAttachmentType(event, index)}
                >
                {attachmentTypes.map(({ value, label }, index) => <option  className="btn eng-attachment-type-dropbox" key={index} value={value} >{label}</option>)}
                </select>
                </td>

                                <td><button className="btn btn-dark col-md-8 mx-1 my-1">Fascia Beam Type</button></td>
                <td></td>
                <td></td>
                <td>
                <select  className="custom-list eng-fascia-beam-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].fascia_beam_type} 
                    onChange={(event) => this.setFasciaBeamType(event, index)}
                >
                {fasciaBeamTypes.map(({ value, label }, index) => <option  className="btn eng-fascia-beam-type-dropbox" key={index} value={value} >{label}</option>)}
                </select>
                </td>

                                <select  className="custom-list eng-partial-attachment-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].partial_attachment_type} 
                    onChange={(event) => this.setPartialAttachmentType(event, index)}
                >
                {partialAttachmentTypes.map(({ value, label }, index) => <option  className="btn eng-partial-attachment-type-dropbox" key={index} value={value} >{label}</option>)}
                </select>

                                <select  className="custom-list eng-attachment-sub-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].attachment_sub_type} 
                    onChange={(event) => this.setAttachmentSubType(event, index)}
                >
                {attachmentSubTypes.map(({ value, label }, index) => <option  className="btn eng-attachment-sub-type-dropbox" key={index} value={value} >{label}</option>)}
                </select>

                                <select  className="custom-list eng-footing-type-dropbox col-md-8 mx-1 my-1" 
                    value={product_manager_module.products[index].footing_type} 
                    onChange={(event) => this.setFootingType(event, index)}
                >
                {footingTypes.map(({ value, label }, index) => <option  className="btn eng-footing-type-dropbox" key={index} value={value} >{label}</option>)}
                </select>
*/