import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { project_specification } from '../smartkits/gui_smartkits'
import { findModelTypeStringGlobal } from './LysaghtHelper';

class Smart2DProjectionPage extends React.Component {
  constructor(props) {
    super(props);
      this.state =  { 
      index: 0,
      products: product_manager_module.products,
      width: window.innerWidth * 0.8,
      canvasRes: 440,
      shedCanvasRes: 400,
      gutterThickness: 16,
    }   
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  findYOffset = (model, height, i) => {
    var l = i * 0.1
    if(model.profile < 10) l *= 2.5
    var o = 0
    if(model.step_len == 0) return 0
    if(model.proj < model.step_size) {
      if(l >= model.step_len) o =   height - model.proj * height / model.step_size
      else o = 0
    } else {
      if(l >= model.step_len) o = 0
      else o = o =   height - model.step_size * height / model.proj
    }
    return o
  }

  draw2DRoofing = (i, context, x, y, width, height) => {
    switch(this.state.products[i].type) {
      case 0:
      case 1:
      case 2:
      case 3:
        this.draw2DPatio(i, context, x, y, width, height)
       break
      case 4:
      case 5:
      case 6:
        this.draw2DHip(i, context, x, y, width, height)
        break
      case 7:
      case 8:
      case 9:
      case 67:
        this.draw2DGable(i, context, x, y, width, height)
        break
      case 10:
      case 11:
      case 12:
        this.draw2DDutch(i, context, x, y, width, height)
          break
      case 13:
      case 14:
        this.draw2DAngled(i, context, x, y, width, height)
        break
    }    
  }

  draw2DPatio = (m, context, x, y, width, height) => {
    var model = this.getModelSize(m)
    var bar_ratio = 4
    if(model.profile > 9) bar_ratio = 10
    var roof_bar_no = Math.floor((bar_ratio * model.len))
    var roof_bar_w = (width / roof_bar_no)
    
    //console.log('w: ' + width + ' h: ' + height + ' no: ' + roof_bar_no)
    context.lineWidth = 2
    context.strokeStyle = 'black';
    switch(model.profile) {
      case 0:
        for(var i = 0; i < roof_bar_no; ++i) {  
          var o = this.findYOffset(model, height, i)     
          var yy = o + y
          var hh = height - o
          context.fillStyle = 'white'
          context.fillRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          context.fillStyle = 'grey'
          context.fillRect(x + (i + 0.75) * roof_bar_w, yy, roof_bar_w * 0.06, hh)
          context.fillStyle = 'black'
          context.fillRect(x + (i + 0.94) * roof_bar_w, yy, roof_bar_w * 0.06, hh)
        }   
        break
      case 1:
        context.fillStyle = 'white'
        for(var i = 0; i < roof_bar_no; ++i) {   
          var o = this.findYOffset(model, height, i)     
          var yy = o + y
          var hh = height - o     
          context.fillRect(x + i * roof_bar_w , yy, roof_bar_w, hh)  
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
        }   
        break
      case 2:
        context.fillStyle = 'white'
        var l = model.step_len * width / model.len
        if(model.step_len == 0) context.rect(x, y, width, height)
        else {
          if(model.proj < model.step_size) {
            var o = (model.step_size - model.proj) * height / model.step_size            
            context.fillRect(x, y, l, height)
            context.strokeRect(x, y, l, height)
            context.fillRect(x + l, y + o, width - l, height - o)
            context.strokeRect(x + l, y + o, width - l, height - o)
          } else {
            var o = (model.proj - model.step_size) * height / model.proj
            context.fillRect(x, y + o, l, height - o)
            context.strokeRect(x, y + o, l, height - o)
            context.fillRect(x + l, y, width - l, height)
            context.strokeRect(x + l, y, width - l, height)
          }          
        }
        break
      case 10:
      case 11:
        context.fillStyle = 'white'
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {      
          var o = this.findYOffset(model, height, i)     
          var yy = o + y
          var hh = height - o      
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          for(var j = 0; j < 3; ++j) {
            switch(j % 3) {
              case 0:
                context.fillStyle = 'grey'
                break
              case 1:
                context.fillStyle = 'white'
                break
              case 2:
                context.fillStyle = 'darkGrey'
                break
            }
            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
          }
        }   
        break
      case 12:
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {    
          var o = this.findYOffset(model, height, i)  
          var yy = o + y
          var hh = height - o   
          context.fillStyle = 'white'
          context.fillRect(x + i * roof_bar_w , yy, roof_bar_w, hh) 
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
        }   
        break
      default:
        break
    }   
  }

  draw2DHip = (m, context, x, y, width, height) => {
    var symmetric = this.state.products[m].symmetric
    var model = this.getModelSize(m)
    var bar_ratio = 10
    var roof_bar_no = Math.floor(bar_ratio * model.len)
    var roof_bar_w = (width / roof_bar_no)
    var roof_bar_prj_no = Math.ceil(height / (roof_bar_w * 2)) 

    context.lineWidth = 8
    context.strokeStyle = 'black';
    switch(model.profile) {
      case 0:
      case 1:
      case 2:
      case 10:
      case 11:
        context.fillStyle = 'white'
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {      
          var o = this.findYOffset(model, height, i)     
          var yy = o + y
          var hh = (height - o - this.state.gutterThickness) / 2    
          if(i < roof_bar_prj_no - 1) {
            hh = (i + 1) * roof_bar_w
            context.strokeRect(x, yy + (i + 1) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x, yy + height - (i + 2) * roof_bar_w, hh, roof_bar_w)
          } else if(symmetric && i > roof_bar_no - roof_bar_prj_no) {
            hh = (roof_bar_no - i) * roof_bar_w
            context.strokeRect(x + width - hh, yy + (roof_bar_no - i) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x + width - hh, yy + height - (roof_bar_no - i + 1) * roof_bar_w, hh, roof_bar_w)
          }
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          context.strokeRect(x + i * roof_bar_w , yy + height - hh, roof_bar_w, hh)
          for(var j = 0; j < 3; ++j) {
            switch(j % 3) {
              case 0:
                context.fillStyle = 'grey'
                break
              case 1:
                context.fillStyle = 'white'
                break
              case 2:
                context.fillStyle = 'darkGrey'
                break
            }

            if(i < roof_bar_prj_no - 1) {
              context.fillRect(x, yy + (i + 1 + 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2) 
              context.fillRect(x, yy + height - (i + 2 - 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2)
            } 

            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy + height - hh, roof_bar_w * 0.2, hh)

            if(symmetric && i > roof_bar_no - roof_bar_prj_no) {
              context.fillRect(x + width - hh, yy + (roof_bar_no - i + 1 - 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2)
              context.fillRect(x + width - hh, yy + height - (roof_bar_no - i + 1 - 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2)
            }
          }
        }   
        break
      case 12:
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {    
          var o = this.findYOffset(model, height, i)  
          var yy = o + y
          var hh = (height - o - this.state.gutterThickness) / 2    
          if(i < roof_bar_prj_no - 1) {
            hh = (i + 1) * roof_bar_w
            context.strokeRect(x, yy + (i + 1) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x, yy + (i + 1 + 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
            context.strokeRect(x, yy + height - (i + 2) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x, yy + height - (i + 2 - 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
          } else if(symmetric && i > roof_bar_no - roof_bar_prj_no) {
            hh = (roof_bar_no - i) * roof_bar_w 
            context.strokeRect(x + width - hh, yy + (roof_bar_no - i) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x + width - hh, yy + (roof_bar_no - i + 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
            context.strokeRect(x + width - hh, yy + height - (roof_bar_no - i + 1) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x + width - hh, yy + height - (roof_bar_no - i + 1 - 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
          }
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
          context.strokeRect(x + i * roof_bar_w ,  yy + height - hh, roof_bar_w, hh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w,  yy + height - hh, roof_bar_w * 0.2, hh)
        }   
        break
      default:
        break
    }   
  }

  draw2DGable = (m, context, x, y, width, height) => {
    var model = this.getModelSize(m)
    var bar_ratio = 10
    var roof_bar_no = Math.floor((bar_ratio * model.len))
    var roof_bar_w = (width / roof_bar_no)
    
    context.lineWidth = 8
    context.strokeStyle = 'black';
    switch(model.profile) {
      case 0:
      case 1:
      case 2:
      case 10:
      case 11:
        context.fillStyle = 'white'
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {      
          var o = this.findYOffset(model, height, i)     
          var yy = o + y
          var hh = (height - o - this.state.gutterThickness) / 2    
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          context.strokeRect(x + i * roof_bar_w , yy + hh + this.state.gutterThickness, roof_bar_w, hh)
          for(var j = 0; j < 3; ++j) {
            switch(j % 3) {
              case 0:
                context.fillStyle = 'grey'
                break
              case 1:
                context.fillStyle = 'white'
                break
              case 2:
                context.fillStyle = 'darkGrey'
                break
            }
            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy + hh + this.state.gutterThickness, roof_bar_w * 0.2, hh)
          }
        }   
        break
      case 12:
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {    
          var o = this.findYOffset(model, height, i)  
          var yy = o + y
          var hh = (height - o - this.state.gutterThickness) / 2    
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
          context.strokeRect(x + i * roof_bar_w , yy + hh + this.state.gutterThickness, roof_bar_w, hh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy + hh + this.state.gutterThickness, roof_bar_w * 0.2, hh)
        }   
        break
      default:
        break
    }   
  }

  draw2DDutch = (m, context, x, y, width, height) => {
    var symmetric = this.state.products[m].symmetric
    var model = this.getModelSize(m)
    var bar_ratio = 10
    var roof_bar_no = Math.floor(bar_ratio * model.len)
    var roof_bar_w = (width / roof_bar_no)
    var roof_bar_prj_half_no = Math.ceil(height / (roof_bar_w * 4)) 
    var roof_bar_prj_no = 2 * roof_bar_prj_half_no

    context.lineWidth = 8
    context.strokeStyle = 'black';
    switch(model.profile) {
      case 0:
      case 1:
      case 2:
      case 10:
      case 11:
        context.fillStyle = 'white'
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {      
          var o = this.findYOffset(model, height, i)     
          var yy = o + y
          var hh = 0  
          var hhh = (height - o - this.state.gutterThickness) / 2  
          if(i < roof_bar_prj_no - 1) {
            if(i < roof_bar_prj_half_no - 1) {
              hh = (i + 1) * roof_bar_w
              hhh = hh
            } else {
              hh = (roof_bar_prj_half_no - 1) * roof_bar_w
            }    
            context.strokeRect(x, yy + (i + 1) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x, yy + height - (i + 2) * roof_bar_w, hh, roof_bar_w)
          } else if(symmetric && i > roof_bar_no - roof_bar_prj_no) {
            if(i > roof_bar_no - roof_bar_prj_half_no) {
              hh = (roof_bar_no - i) * roof_bar_w
              hhh = hh
            } else {
              hh = (roof_bar_prj_half_no - 1) * roof_bar_w
            }  
            context.strokeRect(x + width - hh, yy + (roof_bar_no - i) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x + width - hh, yy + height - (roof_bar_no - i + 1) * roof_bar_w, hh, roof_bar_w)
          } 
          
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hhh)
          context.strokeRect(x + i * roof_bar_w , yy + height - hhh, roof_bar_w, hhh)
          for(var j = 0; j < 3; ++j) {
            switch(j % 3) {
              case 0:
                context.fillStyle = 'grey'
                break
              case 1:
                context.fillStyle = 'white'
                break
              case 2:
                context.fillStyle = 'darkGrey'
                break
            }

            if(i < roof_bar_prj_no - 1) {
              context.fillRect(x, yy + (i + 1 + 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2) 
              context.fillRect(x, yy + height - (i + 2 - 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2)
            } 

            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy, roof_bar_w * 0.2, hhh)
            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy + height - hhh, roof_bar_w * 0.2, hhh)

            if(symmetric && i > roof_bar_no - roof_bar_prj_no) {
              context.fillRect(x + width - hh, yy + (roof_bar_no - i + 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2)
              context.fillRect(x + width - hh, yy + height - (roof_bar_no - i + 1 - 0.33 * j) * roof_bar_w, hh, roof_bar_w * 0.2)
            }
          }
        }   
        break
      case 12:
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {    
          var o = this.findYOffset(model, height, i)  
          var yy = o + y
          var hh = 0  
          var hhh = (height - o - this.state.gutterThickness) / 2  
          if(i < roof_bar_prj_no - 1) {
            if(i < roof_bar_prj_half_no - 1) {
              hh = (i + 1) * roof_bar_w
              hhh = hh
            } else {
              hh = (roof_bar_prj_half_no - 1) * roof_bar_w
            }  
            context.strokeRect(x, yy + (i + 1) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x, yy + (i + 1 + 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
            context.strokeRect(x, yy + height - (i + 2) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x, yy + height - (i + 2 - 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
          } else if(symmetric && i > roof_bar_no - roof_bar_prj_no) {
            if(i > roof_bar_no - roof_bar_prj_half_no) {
              hh = (roof_bar_no - i) * roof_bar_w 
              hhh = hh
            } else {
              hh = (roof_bar_prj_half_no - 1) * roof_bar_w
            }
            context.strokeRect(x + width - hh, yy + (roof_bar_no - i) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x + width - hh, yy + (roof_bar_no - i + 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
            context.strokeRect(x + width - hh, yy + height - (roof_bar_no - i + 1) * roof_bar_w, hh, roof_bar_w)
            context.strokeRect(x + width - hh, yy + height - (roof_bar_no - i + 1 - 0.4) * roof_bar_w, hh, roof_bar_w * 0.2)
          }
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hhh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy, roof_bar_w * 0.2, hhh)
          context.strokeRect(x + i * roof_bar_w ,  yy + height - hhh, roof_bar_w, hhh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w,  yy + height - hhh, roof_bar_w * 0.2, hhh)
        }   
        break
      default:
        break
    }   
  }

  draw2DAngled = (m, context, x, y, width, height) => {
    var product = this.state.products[m]
    var model = this.getModelSize(m)
    var bar_ratio = 4
    if(model.profile > 9) bar_ratio = 10
    var roof_bar_no = Math.floor((bar_ratio * model.len))
    var roof_flat_bar_no = Math.floor((bar_ratio * model.back_len))
    if(roof_bar_no < roof_flat_bar_no) {
      var swap = roof_bar_no
      roof_bar_no = roof_flat_bar_no
      roof_flat_bar_no = swap
    }
    var roof_bar_w = (width / roof_bar_no)
    
    context.lineWidth = 2
    context.strokeStyle = 'black';
    switch(model.profile) {
      case 0:
      case 1:
      case 2:
      case 10:
      case 11:
        context.fillStyle = 'white'
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {      
          var o = 0    
          if(i > roof_flat_bar_no - 1) {
            o = (i - roof_flat_bar_no) * (height + 2 * this.state.gutterThickness) / (roof_bar_no - roof_flat_bar_no)     
          }
          var yy = o + y
          var hh = height - o      
          if(model.back_len > model.len) {
            yy = y
          }
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh)
          for(var j = 0; j < 3; ++j) {
            switch(j % 3) {
              case 0:
                context.fillStyle = 'grey'
                break
              case 1:
                context.fillStyle = 'white'
                break
              case 2:
                context.fillStyle = 'darkGrey'
                break
            }
            context.fillRect(x + (i + 0.33 * j) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
          }
        } 
        break
      case 12:
        context.lineWidth = 1
        for(var i = 0; i < roof_bar_no; ++i) {    
          var o = 0    
          if(i > roof_flat_bar_no - 1) {
            o = (i - roof_flat_bar_no) * (height + 2 * this.state.gutterThickness) / (roof_bar_no - roof_flat_bar_no)     
          }
          var yy = o + y
          var hh = height - o      
          if(model.back_len > model.len) {
            yy = y
          }  
          context.fillStyle = 'white'
          context.fillRect(x + i * roof_bar_w , yy, roof_bar_w, hh) 
          context.strokeRect(x + i * roof_bar_w , yy, roof_bar_w, hh) 
          context.strokeRect(x + (i + 0.4) * roof_bar_w, yy, roof_bar_w * 0.2, hh)
        }   
        break
      default:
        break
    }   
  }

  find2DCorners = (i) => {
    var ret 
    var model = this.getModelSize(i)
    var width = this.getLength(i) - this.state.canvasRes
    var height = this.getHeight(i) - 2 * this.state.canvasRes
    var product = this.state.products[i]

    switch(product.type) {
      case 13:
      case 14:
        var backLength = width * product.back_length / product.length
        if(product.back_length > product.length) {
          backLength = this.getBackLength(i) - this.state.canvasRes
          width = backLength * product.length / product.back_length
        } 
        ret = [
          { x: 0, y: 0 },
          { x: 0, y: height + 2 * this.state.gutterThickness },
          { x: width + 2 * this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
          { x: backLength + 2 * this.state.gutterThickness, y: 0 },
          { x: 0, y: 0 },
        ];
        break
        case 20:
          var cos = Math.cos(Math.PI / 3) 
          var sin = Math.sin(Math.PI / 3) 
          var c = cos * width / 2
          var s = sin * width / 2
          var h = this.state.gutterThickness
          var hx = h * sin
          var hy = h * cos 
          ret = [
            { x: 0, y: c },
            { x: 0, y: width / 2 + c },
            { x: s, y: width / 2 + 2 * c },
            { x: 2 * s, y: width / 2 + c },
            { x: 2 * s, y: c },
            { x: s, y: 0 },
            { x: 0, y: c },

            { x: hx, y: c + hy },
            { x: hx, y: width / 2 + c - hy},
            { x: s, y: width / 2 + 2 * c - 2 * hy },
            { x: 2 * s - hx, y: width / 2 + c - hy},
            { x: 2 * s - hx, y: c + hy },
            { x: s, y: 2 * hy },
            { x: hx, y: c + hy },

            { x: 0, y: c },
            { x: s, y: width / 2 },
            { x: 0, y: width / 2 + c },
            { x: s, y: width / 2 },
            { x: s, y: width / 2 + 2 * c },
            { x: s, y: width / 2 },
            { x: 2 * s, y: width / 2 + c },
            { x: s, y: width / 2 },
            { x: 2 * s, y: c },
            { x: s, y: width / 2 },
            { x: s, y: 0 },
            { x: s, y: width / 2 },
            { x: 0, y: c },
          ];
          break
        case 21:
            var cos = Math.cos(3 * Math.PI / 8) 
            var sin = Math.sin(3 * Math.PI / 8) 
            var t = width * Math.tan(Math.PI / 8)
            var h = this.state.gutterThickness
            var hx = sin * h
            var hy = cos * h
            ret = [
              { x: 0, y: (width - t) / 2 },
              { x: 0, y: (width + t) / 2 },
              { x: (width - t) / 2, y: width },
              { x: (width + t) / 2, y: width },
              { x: width, y: (width + t) / 2 },
              { x: width, y: (width - t) / 2 },
              { x: (width + t) / 2, y: 0 },
              { x: (width - t) / 2, y: 0 },
              { x: 0, y: (width - t) / 2 },

              { x: hx, y: (width - t) / 2 + hy},
              { x: hx, y: (width + t) / 2 - hy},
              { x: (width - t) / 2 + hy, y: width - hx },
              { x: (width + t) / 2 - hy, y: width - hx },
              { x: width - hx, y: (width + t) / 2 - hy },
              { x: width - hx, y: (width - t) / 2 + hy },
              { x: (width + t) / 2 - hy, y: hx },
              { x: (width - t) / 2 + hy, y: hx },
              { x: hx, y: (width - t) / 2 + hy},

              { x: 0, y: (width - t) / 2 },
              { x: width / 2, y: width / 2 },
              { x: 0, y: (width + t) / 2 },
              { x: width / 2, y: width / 2 },
              { x: (width - t) / 2, y: width },
              { x: width / 2, y: width / 2 },
              { x: (width + t) / 2, y: width },
              { x: width / 2, y: width / 2 },
              { x: width, y: (width + t) / 2 },
              { x: width / 2, y: width / 2 },
              { x: width, y: (width - t) / 2 },
              { x: width / 2, y: width / 2 },
              { x: (width + t) / 2, y: 0 },
              { x: width / 2, y: width / 2 },
              { x: (width - t) / 2, y: 0 },
              { x: width / 2, y: width / 2 },
              { x: 0, y: (width - t) / 2 },
            ];
          break
      default:
        if(model.step_len == 0) {
          ret = [
            { x: 0, y: 0 },
            { x: 0, y: height + 2 * this.state.gutterThickness },
            { x: width + 2 * this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + 2 * this.state.gutterThickness, y: 0 },
            { x: 0, y: 0 },
          ];
        } else {
          if(model.step_size > model.proj) {
            ret = [
              { x: 0, y: 0 },
              { x: 0, y: height + 2 * this.state.gutterThickness },
              { x: width + 2 * this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
              { x: width + 2 * this.state.gutterThickness, y: height * (1 - model.proj / model.step_size)},
              { x: width * (1 - (model.len - model.step_len) / model.len) + 2 * this.state.gutterThickness, y: height * (1 - model.proj / model.step_size)},
              { x: width * (1 - (model.len - model.step_len) / model.len) + 2 * this.state.gutterThickness, y: 0 },
              { x: 0, y: 0 },
            ];
          } else {
            ret = [
              { x: 0, y: height * (1 - model.step_size / model.proj)},
              { x: 0, y: height + 2 * this.state.gutterThickness },
              { x: width + 2 * this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
              { x: width + 2 * this.state.gutterThickness, y: 0},
              { x: width * (1 - (model.len - model.step_len) / model.len), y: 0},
              { x: width * (1 - (model.len - model.step_len) / model.len), y: height * (1 - model.step_size / model.proj)},
              { x: 0, y: height * (1 - model.step_size / model.proj)},
            ];
          }
        }
        break
    }
    
    return ret
  }

  findShedRoofing2DCorners = (i, side) => {
    var ret 
    var product = this.state.products[i]
    var length = this.getLength(i) - this.state.shedCanvasRes
    var overhang = this.getSideOverhangSize(i)
    var width = product.length * this.state.shedCanvasRes + 2 * overhang - this.state.shedCanvasRes
    var height = this.getRoofElevation(i) 
    var start = (length - width) / 2
    var sideWidth = product.projection * this.state.shedCanvasRes - this.state.shedCanvasRes
    var startWidth =  (length - sideWidth) / 2 
    var frontOverhangProjotion = this.getFrontOverhangProjection(i)
    var frontOverhangHeight = this.getFrontOverhangHeight(i)
    switch(product.type) {
      case 64:
      case 65:
      case 66:
        if(side == 0 || side == 2) {          
          ret = [
            { x: start, y: 0 },
            { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
            { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
            { x: width + start + 2 * this.state.gutterThickness, y: 0 },
            { x: start, y: 0 },
          ];
        } else { 
          ret = [
            { x: startWidth - frontOverhangProjotion, y: height + frontOverhangHeight},
            { x: startWidth - frontOverhangProjotion, y: height + frontOverhangHeight + this.state.gutterThickness},            
            { x: sideWidth + this.state.gutterThickness + startWidth + frontOverhangProjotion, y: height + frontOverhangHeight + this.state.gutterThickness},
            { x: sideWidth + this.state.gutterThickness + startWidth + frontOverhangProjotion, y: height + frontOverhangHeight + 0 * this.state.gutterThickness},
            { x: sideWidth + this.state.gutterThickness + startWidth, y: height },
            { x: sideWidth / 2 + this.state.gutterThickness + startWidth, y: 0 },
            { x: startWidth, y: height },
          ];
        }
        break
      case 75:
        if(side == 0) {          
          ret = [
            { x: start, y: 0 },
            { x: start, y: height + frontOverhangHeight + this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: height + frontOverhangHeight + this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: 0 },
            { x: start, y: 0 },
          ];
        } else if(side == 2) {          
          ret = [
            { x: start, y: 0 },
            { x: start, y: this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: 0 },
            { x: start, y: 0 },
          ];
        } else if(side == 1)  { 
          ret = [
            { x: startWidth - frontOverhangProjotion, y: height + 2 * frontOverhangHeight},
            { x: startWidth + sideWidth + frontOverhangProjotion, y: 0 },
            { x: startWidth + sideWidth + frontOverhangProjotion, y: this.state.gutterThickness},
            { x: startWidth - frontOverhangProjotion, y: height + 2 * frontOverhangHeight + this.state.gutterThickness},
          ];
        } else { 
          ret = [
            { x: startWidth - frontOverhangProjotion, y: 0},
            { x: startWidth + sideWidth + frontOverhangProjotion, y: height + 2 * frontOverhangHeight},            
            { x: startWidth + sideWidth + frontOverhangProjotion, y: height + 2 * frontOverhangHeight + this.state.gutterThickness},
            { x: startWidth - frontOverhangProjotion, y: this.state.gutterThickness},
          ];
        }
        break
      case 80:
        if(side == 0 || side == 2) {     
          if(product.symmetric) {
            ret = [
              { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
              { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
              { x: width + start + 2 * this.state.gutterThickness - sideWidth / 2, y: 0 },
              { x: start + sideWidth / 2, y: 0 },
            ];
          } else {
            if(side == 0) {
              ret = [
                { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: width + start + 2 * this.state.gutterThickness - sideWidth / 2, y: 0 },
                { x: start, y: 0 },
              ];
            } else {
              ret = [
                { x: start + sideWidth / 2, y: 0 },
                { x: width + start + 2 * this.state.gutterThickness, y: 0 },
                { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
              ];
            }
          }  
        } else {       
          ret = [
            { x: startWidth - frontOverhangProjotion, y: height + frontOverhangHeight},
            { x: startWidth - frontOverhangProjotion, y: height + frontOverhangHeight + this.state.gutterThickness},           
            { x: sideWidth + this.state.gutterThickness + startWidth + frontOverhangProjotion, y: height + frontOverhangHeight + this.state.gutterThickness},
            { x: sideWidth + this.state.gutterThickness + startWidth + frontOverhangProjotion, y: height + frontOverhangHeight + 0 * this.state.gutterThickness},
            { x: sideWidth + this.state.gutterThickness + startWidth, y: height },
            { x: sideWidth / 2 + this.state.gutterThickness + startWidth, y: 0 },
            { x: startWidth, y: height },
          ];
        }
        break
      case 85:
        if(side == 0 || side == 2) {    
          if(product.symmetric) {
            ret = [
              { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
              { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
              { x: width + start + 2 * this.state.gutterThickness - sideWidth / 4, y: height / 2 },
              { x: width + start + 2 * this.state.gutterThickness - sideWidth / 4, y: 0 },
              { x: start + sideWidth / 4, y: 0 },
              { x: start + sideWidth / 4, y: height / 2 },
            ];
          } else {
            if(side == 0) {
              ret = [
                { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: width + start + 2 * this.state.gutterThickness - sideWidth / 4, y: height / 2 },
                { x: width + start + 2 * this.state.gutterThickness - sideWidth / 4, y: 0 },
                { x: start, y: 0 },
                { x: start, y: height / 2 },
              ];
            } else {
              ret = [
                { x: start, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: width + start + 2 * this.state.gutterThickness, y: height + frontOverhangHeight + 2 * this.state.gutterThickness },
                { x: width + start + 2 * this.state.gutterThickness, y: height / 2 },
                { x: width + start + 2 * this.state.gutterThickness, y: 0 },
                { x: start + sideWidth / 4, y: 0 },
                { x: start + sideWidth / 4, y: height / 2 },
              ];
            }
          }    
        } else {       
          ret = [
            { x: startWidth - frontOverhangProjotion, y: height + frontOverhangHeight},
            { x: startWidth - frontOverhangProjotion, y: height + frontOverhangHeight + this.state.gutterThickness},          
            { x: sideWidth + this.state.gutterThickness + startWidth + frontOverhangProjotion, y: height + frontOverhangHeight + this.state.gutterThickness},
            { x: sideWidth / 2 + this.state.gutterThickness + startWidth, y: 0 },
            { x: startWidth, y: height },
          ];
        }
        break
      default:
        break
    }
    return ret
  }

  find2DWallCorners = (i, side) => {
    var ret 
    var product = this.state.products[i]
    var length = this.getLength(i) - this.state.shedCanvasRes
    var overhang = this.getSideOverhangSize(i)
    var width = product.length * this.state.shedCanvasRes - this.state.shedCanvasRes
    var start = (length - width) / 2
    var elevation = this.getRoofElevation(i)
    var height = this.getHeight(i) - 2 * this.state.shedCanvasRes
    var sideWidth = product.projection * this.state.shedCanvasRes - this.state.shedCanvasRes
    var startWidth =  (length - sideWidth) / 2 
    var frontOverhangHeight = this.getFrontOverhangHeight(i)
    switch(product.type) {
      case 64:
      case 65:
      case 66:
        if(side == 0 || side == 2) {
          ret = [
            { x: start + this.state.gutterThickness, y: elevation },
            { x: start + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: elevation },
            { x: start + this.state.gutterThickness, y: elevation },
          ];
        } else { 
          ret = [
            { x: startWidth + this.state.gutterThickness, y: elevation + frontOverhangHeight},
            { x: startWidth + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: sideWidth + startWidth, y: height + 2 * this.state.gutterThickness },
            { x: sideWidth + startWidth, y:  elevation + frontOverhangHeight },
            { x: startWidth, y:  elevation + frontOverhangHeight },
          ];
        }
        break
      case 75:
        if(side == 0) {
          ret = [
            { x: start + this.state.gutterThickness, y: elevation },
            { x: start + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: elevation },
            { x: start + this.state.gutterThickness, y: elevation },
          ];
        } else if(side == 2) {
          ret = [
            { x: start + this.state.gutterThickness, y: this.state.gutterThickness },
            { x: start + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: height + 2 * this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: this.state.gutterThickness },
            { x: start + this.state.gutterThickness, y: this.state.gutterThickness },
          ];
        } else { 
          if(side == 1) {
            ret = [
              { x: startWidth, y: elevation + frontOverhangHeight + 0.3 * this.state.gutterThickness },
              { x: startWidth, y: height },
              { x: sideWidth + startWidth, y: height },
              { x: sideWidth + startWidth, y: frontOverhangHeight + 1 * this.state.gutterThickness },
            ];
          } else {
            ret = [
              { x: startWidth, y: frontOverhangHeight + 1 * this.state.gutterThickness },
              { x: startWidth, y: height },
              { x: sideWidth + startWidth, y: height },
              { x: sideWidth + startWidth, y: frontOverhangHeight + elevation + 0.3 * this.state.gutterThickness },
            ];
          }
        }
        break
      case 80:
      case 85:
        if(side == 0 || side == 2) {
          ret = [
            { x: start + this.state.gutterThickness, y:  2 * this.state.gutterThickness + elevation + frontOverhangHeight },
            { x: start + this.state.gutterThickness, y: height + this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y: height + this.state.gutterThickness },
            { x: width + start + this.state.gutterThickness, y:  2 * this.state.gutterThickness + elevation + frontOverhangHeight },
            { x: start + this.state.gutterThickness, y:  2 * this.state.gutterThickness + elevation + frontOverhangHeight },
          ];
        } else {          
          ret = [
            { x: startWidth, y: elevation + frontOverhangHeight + this.state.gutterThickness },
            { x: startWidth, y: height + 2 * this.state.gutterThickness },
            { x: sideWidth + 1 * this.state.gutterThickness + startWidth, y: height + 2 * this.state.gutterThickness },
            { x: sideWidth + 1 * this.state.gutterThickness + startWidth, y: elevation + frontOverhangHeight + this.state.gutterThickness },
            { x: startWidth, y: elevation + frontOverhangHeight + this.state.gutterThickness },
          ];
        }
        break
      default:
        break
    }
    return ret
  }

  drawPolygon = (context, vertices, x, y) => {
    context.beginPath()
    context.moveTo(vertices[0].x +  x - this.state.gutterThickness, vertices[0].y + y - this.state.gutterThickness)

    for (let j = 1; j < vertices.length; j++) {
      context.lineTo(vertices[j].x +  x - this.state.gutterThickness, vertices[j].y +  y - this.state.gutterThickness)
    }  
    context.closePath()
    context.fill()
    context.stroke()
  }

  draw2DGutter = (i, context, vertices, x, y) => {
    context.fillStyle = 'white'
    context.strokeStyle = 'green'
    context.lineWidth = 4
    this.drawPolygon(context, vertices, x, y)
  }

  findDeckingRowsNo = (product) => {
    var no = 0
    if(product.projection > 8.3) {
      no = 2
    } else if(product.projection > 4.1) {
      no = 1
    } 
    return no
  }

  draw2DBeamsDecking = (i, type, context, vertices, x, y) => {
    var product = this.state.products[i]
    var no = Math.ceil(product.length * 2)
    var no1 = this.findDeckingRowsNo(product)

    context.fillStyle = 'white'
    context.strokeStyle = 'silver'
    context.lineWidth = 12
    this.drawPolygon(context, vertices, x, y)

    if(type == 0) return
    var x1 = vertices[0].x + x - this.state.gutterThickness 
    var x2 = vertices[2].x + x - this.state.gutterThickness
    var y1 = vertices[0].y + y - this.state.gutterThickness
    var y2 = vertices[1].y + y - this.state.gutterThickness

    context.beginPath()
    for (let j = 0; j < no; j++) {
      var o = (j + 1) * vertices[2].x / no
      context.moveTo(x1 + o, y1)
      context.lineTo(x1 + o, y2)
    } 
    for (let j = 0; j < no1; j++) {
      var o = (j + 1) * vertices[1].y / (no1 + 1)
      context.moveTo(x1, y1 + o)
      context.lineTo(x2, y1 + o)
    } 
    context.stroke()
  }

  drawArrowGazebo = (i, type, context, vertices, x, y) => {
    var product =  this.state.products[i]
    context.beginPath()
    context.fillStyle = 'Blue'
    context.strokeStyle = 'Brown'
    var sx = vertices[0].x + x - 4 * this.state.gutterThickness
    var sy = vertices[0].y + y
    var ex = vertices[1].x + x - 4 * this.state.gutterThickness
    var ey = vertices[1].y + y - 2 * this.state.gutterThickness
    this.drawHorizontalArrow(context, sx, sy, ex, ey, this.state.gutterThickness)

    var w = product.length / 2
    if(product.type == 21) {
      w = w * Math.cos(Math.PI / 8) 
    }
    var size = 48
    this.setFontSize(context, size)
    context.fillText((w).toFixed(1) + 'm', sx - 3 * size, (sy + ey) / 2)
    context.stroke()
  }
  
  draw2DRoofingGazebo = (i, type, context, vertices, x, y) => {
    var product = this.state.products[i]
    var gno = Math.floor(product.length * 3)
    if(gno == 1) gno = 2
    var pitch = (vertices[1].y - vertices[0].y) / (2 * gno - 2)
    var no = 6
    if(product.type == 21) {
      no = 8
    }

    context.fillStyle = 'white'
    context.strokeStyle = 'green'
    context.lineWidth = 4
    this.drawPolygon(context, vertices, x, y)
    this.drawArrowGazebo(i, type, context, vertices, x, y)

    context.beginPath()
    context.strokeStyle = 'gray'
    context.fillStyle = 'black'
    context.lineWidth = 8

    for (let j = 0; j < no; j++) {    
      var sx = vertices[j].x + x - this.state.gutterThickness
      var sy = vertices[j].y + y - this.state.gutterThickness
      var ex = vertices[j + 1].x + x - this.state.gutterThickness
      var ey = vertices[j + 1].y + y - this.state.gutterThickness

      var angle = Math.PI * (0.5 - j * 2 / no)
      
      var cos = Math.cos(angle) 
      var sin =  Math.sin(angle)

      for(var k = 0; k < gno; ++k) {
        var f1 = k * pitch 
        var f2 = f1 * Math.tan(Math.PI * (0.5 - 1 / no))
        context.moveTo(sx + f1 * cos, sy + f1 * sin)
        context.lineTo(sx + f1 * cos + f2 * sin, sy + f1 * sin - f2 * cos)
      }

      for(var k = 0; k < gno; ++k) {
        var f1 = k * pitch 
        var f2 = f1 * Math.tan(Math.PI * (0.5 - 1 / no))
        context.moveTo(ex - f1 * cos, ey - f1 * sin)
        context.lineTo(ex - f1 * cos + f2 * sin, ey - f1 * sin - f2 * cos)
      }
    } 
 
    context.closePath()
    context.fill()
    context.stroke()
  }

  draw2PostsGazebo = (i, type, context, vertices, x, y) => {
    var product = this.state.products[i]
    var postSize = this.state.canvasRes * product.front_post_width
    var no = 6
    if(product.type == 21) no = 8

    context.fillStyle = 'white'
    context.strokeStyle = 'green'
    context.lineWidth = 4
    this.drawPolygon(context, vertices.slice(0, no), x, y)
    this.drawArrowGazebo(i, type, context, vertices, x, y)

    context.fillStyle = 'orange'
    for(var j = 0; j < no; ++j) {
      context.fillRect(x + vertices[j + no + 1].x - postSize/ 2 - this.state.gutterThickness, y + vertices[j + no + 1].y - postSize/ 2 - this.state.gutterThickness, postSize, postSize)
    }    
  }

  drawShed2DRoofing = (i, side, context, x, y) => {
    var product = this.state.products[i]
    var dis = this.state.gutterThickness

    context.fillStyle = 'white'
    context.strokeStyle = 'Olive'
    context.lineWidth = 4
    var vertices = this.findShedRoofing2DCorners(i, side)
    this.drawPolygon(context, vertices, x, y)

    if(side == 1 || side == 3) {
      context.beginPath()
      context.fillStyle = 'blue'
      context.strokeStyle = 'DimGray';

      var xx = vertices[1].x
      var yy = vertices[1].y
      var yOffset = 0
      if(product.type == 75) {
        xx = vertices[0].x
        yy = vertices[0].y + 2 * dis
        yOffset = 2 * dis
      }
      if(product.type != 75 || side != 3) {
        context.arc(xx + x - this.state.gutterThickness, yy + y - 2 * dis, 0.25 * this.state.shedCanvasRes, -1 * product.angle * Math.PI / 180, 0)
        context.moveTo(xx + x - this.state.gutterThickness, yy + y - 2 * dis)
        context.lineTo(xx + x - this.state.gutterThickness + 0.5 * this.state.shedCanvasRes, yy + y - 2 * dis)
        context.fillText(product.angle.toFixed(0) + '°', xx + x - this.state.gutterThickness, yy + y - 5 * dis - yOffset)
        context.stroke()
      }
    }


    context.beginPath()
    context.fillStyle = 'white'
    context.strokeStyle = 'brown';
    context.lineWidth = 4
    
    var sx = vertices[0].x + x - 4.5 * dis
    var ex = vertices[1].x + x - 4.5 * dis
    var sy = vertices[0].y + y 
    var ey = vertices[1].y + y - 2 * dis
    
    var size = 48
    this.setFontSize(context, size)
    context.fillStyle = 'blue'
    var textSy = (sy + ey) / 2 
    var textSx = sx - 2.5 * size
    switch(product.type) {
      case 64:
      case 65:
      case 66:
        if(side == 1 || side == 3) {
          sx = vertices[2].x + x + 1 * dis
          ex = sx
          sy = vertices[5].y + y + 0 * dis  
          ey = vertices[2].y + y - 3 * dis
          textSx = sx + 0.5 * size
          textSy = (sy + ey) / 2 
        }
        break 
      case 75:
        if(side == 1) {
          ex = sx
          sy = vertices[1].y + y   
          ey = vertices[0].y + y - 2 * dis
          textSx = sx - 2.5 * size
          textSy = (sy + ey) / 2 
        } else if(side == 3) {
          sx =  vertices[1].x + x 
          ex = sx
          sy = vertices[0].y + y + 0 * dis  
          ey = vertices[1].y + y - 2 * dis
          textSx = sx + 0.5 * size
          textSy = (sy + ey) / 2 
        }
        break
      case 80:        
        if(side == 1 || side == 3) {
          sx = vertices[0].x + x - 3 * dis
          ex = vertices[0].x + x - 3 * dis
          sy = vertices[5].y + y + 0 * dis  
          ey = vertices[1].y + y - 2 * dis
          textSx = sx - 2.5 * size - 2 * dis
          textSy = (sy + ey) / 2 
        } else {
          if(product.symmetric) {
            sx = vertices[0].x + x - 3 * dis 
            ex = vertices[0].x + x - 3 * dis 
            sy = vertices[2].y + y + 0 * dis 
            ey = vertices[0].y + y - 2 * dis
          } else if(side == 0) {
            sx = vertices[0].x + x - 3 * dis
            ex = vertices[0].x + x - 3 * dis
            sy = vertices[2].y + y + 0 * dis  
            ey = vertices[0].y + y - 2 * dis
          } else {
            sx = vertices[3].x + x - 3 * dis
            ex = vertices[3].x + x - 3 * dis
            sy = vertices[0].y + y + 0 * dis  
            ey = vertices[2].y + y - 2 * dis
          }  
          textSx = sx - 2.5 * size
          textSy = (sy + ey) / 2 
        }
        break
      case 85:      
        if(side == 1 || side == 3) {
          sx = vertices[0].x + x - 4.5 * dis
          ex = sx
          sy = vertices[3].y + y + 2 * dis  
          ey = vertices[0].y + y - 2 * dis
          textSx = sx - 2.5 * size - 2 * dis
          textSy = (sy + ey) / 2 
        } else {
          sx = vertices[0].x + x - 4.5 * dis 
          ex = sx
          sy = vertices[3].y + y + 0 * dis 
          ey = vertices[0].y + y - 2 * dis
          textSx = sx - 2.5 * size
          textSy = (sy + ey) / 2 
        }
        break
    }

    var height = (product.projection * 0.5 + product.front_overhang) * Math.sin(product.angle * Math.PI / 180)
    switch(product.type) {
      case 75:
        if(side != 2) {
          this.drawHorizontalArrow(context, sx, sy, ex, ey, dis)
          height *= 2
          context.fillText((height).toFixed(1) + 'm', textSx, textSy)
          context.stroke()
        }
        break
      default:
        this.drawHorizontalArrow(context, sx, sy, ex, ey, dis)
        context.fillText((height).toFixed(1) + 'm', textSx, textSy)
        context.stroke()
        break
    }

    context.fillStyle = 'white'
    sx = vertices[1].x + x - 0 * dis
    ex = vertices[2].x + x - 2 * dis
    sy = vertices[0].y + y - 3 * dis  
    ey = vertices[0].y + y - 3 * dis
    switch(product.type) {
      case 64:
      case 65:
      case 66:
        if(side == 1 || side == 3) {
          sy = vertices[5].y + y - 1.5 * dis 
          ey = sy
        }
        break 
      case 75:
        if(side == 1) {
          sx = vertices[0].x + x - 2 * dis
          ex = vertices[1].x + x - 1 * dis
          sy = vertices[1].y + y - 3 * dis  
          ey = sy
        } else if(side == 3) {
          sx = vertices[0].x + x + 1 * dis
          ex = vertices[1].x + x - 1 * dis
          sy = vertices[0].y + y - 3 * dis  
          ey = sy
        }
        break
      case 80:   
        switch(side) {
          case 0:
          case 2:
            if(product.symmetric) {
              sx = vertices[0].x + x + 1 * dis
              ex = vertices[1].x + x - 1 * dis
              sy = vertices[3].y + y - 2 * dis  
              ey = sy
            } else {
              if(side == 0) {
                sx = vertices[0].x + x - 1 * dis
                ex = vertices[1].x + x - 1 * dis
                sy = vertices[3].y + y - 3 * dis  
                ey = sy
              } else {
                sx = vertices[3].x + x - 1 * dis
                ex = vertices[2].x + x - 1 * dis
                sy = vertices[0].y + y - 3 * dis  
                ey = sy
              }
            }
            break
          case 1:
          case 3:
            sx = vertices[0].x + x + 1 * dis
            ex = vertices[2].x + x - 1 * dis
            sy = vertices[5].y + y - 2 * dis  
            ey = sy
            break
        }     
        break
      case 85:    
      switch(side) {
        case 0:
        case 2:
          if(product.symmetric) {
            sx = vertices[0].x + x + 1 * dis
            ex = vertices[1].x + x - 1 * dis
            sy = vertices[3].y + y - 2 * dis  
            ey = sy
          } else {
            sx = vertices[0].x + x - 1 * dis
            ex = vertices[1].x + x - 1 * dis
            sy = vertices[3].y + y - 3 * dis  
            ey = sy
          }
          break
        case 1:
        case 3:
          sx = vertices[0].x + x + 1 * dis
          ex = vertices[2].x + x - 1 * dis
          sy = vertices[3].y + y - 2 * dis  
          ey = sy
          break
      }   
        break
    }
    this.drawVerticalArrow(context, sx, sy, ex, ey, dis)
    context.fillStyle = 'blue'
    textSx = (sx + ex) / 2 
    textSy = sy - 1 * size
    var length = product.length + product.back_overhang * 2
    switch(side) {
      case 1:
      case 3: 
        length = product.projection +  product.front_overhang * 2
        break
    }
    context.fillText((length).toFixed(1) + 'm', textSx, textSy)
    context.stroke()
  }

  draw2DWall = (i, side, context, x, y) => {
    var product = this.state.products[i]
    context.fillStyle = 'white'
    context.strokeStyle = 'Peru'
    context.lineWidth = 4
    var vertices = this.find2DWallCorners(i, side)
    this.drawPolygon(context, vertices, x, y)

    context.beginPath()
    context.fillStyle = 'white'
    context.strokeStyle = 'brown';
    context.lineWidth = 4
    var dis = this.state.gutterThickness
    var sx = vertices[0].x + x - 4 * dis
    var ex = vertices[1].x + x - 4 * dis
    var sy = vertices[0].y + y + 2 * dis  
    var ey = vertices[1].y + y - 2 * dis
    this.drawHorizontalArrow(context, sx, sy, ex, ey, dis)
    context.fillStyle = 'blue'
    var size = 48
    this.setFontSize(context, size)
    var textSy = (sy + ey) / 2 
    var textSx = sx - 2.5 * size
    var height = product.height
    switch(product.type) {
      case 75:
        if(side == 2 || side == 3) height += product.projection * Math.sin(product.angle * Math.PI / 180)
        break
    }
    context.fillText((height).toFixed(1) + 'm', textSx, textSy)
    context.stroke()

    context.fillStyle = 'white'
    sx = vertices[1].x + x - 0 * dis
    ex = vertices[2].x + x - 2 * dis
    sy = vertices[1].y + y + dis  
    ey = vertices[2].y + y + dis
    this.drawVerticalArrow(context, sx, sy, ex, ey, dis)
    context.fillStyle = 'blue'
    textSx = (sx + ex) / 2 
    textSy = sy + 1 * size
    var length = product.length
    switch(side) {
      case 1:
      case 3: 
        length = product.projection
        break
    }
    context.fillText((length).toFixed(1) + 'm', textSx, textSy)
    context.stroke()
  }

  getAccessoryColor  = (type) => {
    var color = 'Yellow'
    switch(type) {
      case 1:
        color = 'Salmon'
        break
      case 2:
        color = 'MediumSpringGreen'
        break
      case 3:
        color = 'SaddleBrown'
        break
      case 4:
        color = 'Purple'
        break
      case 5:
        color = 'Teal'
        break
      case 6:
        color = 'Navy'
        break
      default:
        color = 'Yellow'
        break
    }
    return color
  }

  drawAccessory = (context, type, xStart, yStart, xEnd, yEnd) =>  {
    context.fillStyle = 'white'
    context.strokeStyle = this.getAccessoryColor(type)
    
    context.lineWidth = 4
  
    context.beginPath()
    context.moveTo(xStart, yStart)
    context.lineTo(xEnd, yStart)
    context.lineTo(xEnd, yEnd)
    context.lineTo(xStart, yEnd)
    context.lineTo(xStart, yStart)
 
    context.fill()
    context.stroke()
  }

  draw2DAccessories = (i, side, context, x, y) => {
    var product = this.state.products[i]
    var accessories = product.accessories_model
    var vertices = this.find2DWallCorners(i, side)

    var xStart = vertices[1].x +  x - this.state.gutterThickness
    var yStart = vertices[1].y + y - this.state.gutterThickness
    var length = vertices[2].x - vertices[1].x
    var height = vertices[1].y - vertices[3].y

    if(product.type == 75) {
      if(side == 1) height = vertices[1].y - vertices[0].y
      else if(side == 3) height = vertices[1].y - vertices[3].y
    }

    var sortedAccessories = [].concat(accessories).sort((a, b) => a.location > b.location ? 1 : -1)
    var oldPlace = 0
    var oldAccLen = 0
    //prnt('sorted ', sortedAccessories)    
    var sArrow = xStart + this.state.gutterThickness
    for(var j = 0; j < sortedAccessories.length; ++j) {
      var accType = sortedAccessories[j].type
      if(sortedAccessories[j].side == side && accType != 0 && accType != undefined) {
        var sideLength = product.length
        if(side == 1 || side == 3) sideLength = product.projection
        var place = sortedAccessories[j].place
        var xOffset = length * place / sideLength
        var accLen = sortedAccessories[j].length
        var accHorizon = sortedAccessories[j].horizon
        var l = length * (accLen - 0.25)/ sideLength
        var h = height * sortedAccessories[j].height / product.height
        var horizon = height * accHorizon / product.height
        this.drawAccessory(context, accType, xStart + xOffset, yStart - horizon, xStart + xOffset + l, yStart - h - horizon)
        context.fillStyle = 'white'
        context.strokeStyle = 'Maroon'
        context.beginPath();
        if(place - oldPlace - oldAccLen> 0.20) {
          this.drawVerticalArrow(context, sArrow, yStart - 3 * this.state.gutterThickness, xStart + xOffset - this.state.gutterThickness, yStart - 3 * this.state.gutterThickness, this.state.gutterThickness)
        }        
        this.drawVerticalArrow(context, xStart + xOffset + this.state.gutterThickness, yStart - h - horizon + 5 * this.state.gutterThickness, xStart + xOffset + l - this.state.gutterThickness, yStart - h - horizon + 5 * this.state.gutterThickness, this.state.gutterThickness)
        this.drawHorizontalArrow(context, xStart + xOffset + 3 * this.state.gutterThickness, yStart - h - horizon + 1 * this.state.gutterThickness, xStart + xOffset + 3 * this.state.gutterThickness, yStart - horizon - 1 * this.state.gutterThickness, this.state.gutterThickness)
        if(accHorizon > 0.25) this.drawHorizontalArrow(context, xStart + xOffset, yStart - horizon +  1 * this.state.gutterThickness, xStart + xOffset + 0 * this.state.gutterThickness, yStart - 1 * this.state.gutterThickness, this.state.gutterThickness)
        context.stroke()
        // dimensions
        context.beginPath();
        context.fillStyle = 'blue'
        if(place - oldPlace - oldAccLen > 0.20) {         
          context.fillText((place - oldPlace - oldAccLen).toFixed(2) + 'm', (xStart + xOffset + sArrow) / 2 - 4 * this.state.gutterThickness, yStart - 5 * this.state.gutterThickness)
        }
        context.fillText((accLen).toFixed(2) + 'm', xStart + xOffset + l / 2 - 3 * this.state.gutterThickness, yStart - h - horizon + 4 * this.state.gutterThickness)      
        context.fillText((sortedAccessories[j].height).toFixed(2) + 'm', xStart + xOffset + 4 * this.state.gutterThickness, yStart - h / 2 - horizon + 3 * this.state.gutterThickness)
        if(accHorizon > 0.25) {
          context.fillText(accHorizon.toFixed(2) + 'm', xStart + xOffset + 1 * this.state.gutterThickness, yStart - horizon / 2 +  1 * this.state.gutterThickness)
        }
        context.fill()
        context.stroke()

        sArrow = xStart + xOffset + l + this.state.gutterThickness
        oldPlace = place
        oldAccLen = accLen
      }
    }

    function prnt(info, accessories) {
      for(var i = 0; i < accessories.length; ++i) {
        if(side == accessories[i].side && accessories[i].type != 0 && accessories[i].type != undefined)
        console.log(info + ' i: ' + i + 
        ' location: ' + accessories[i].location, 
        ' place: ' + accessories[i].place, 
        ' side: ' + accessories[i].side, 
        ' type: ' + accessories[i].type, 
        ' length: ' + accessories[i].length, 
        ' height: ' + accessories[i].height, 
        ' horizon: ' + accessories[i].horizon, 
        )
      }
    }
  }

  getAccessoryTypeName = (type) => {
    var accName = 'Roller Door'
    switch(type) {
      case 1:
        break
      case 2:
        accName = 'Window'
        break
      case 3:
        accName = 'Farm Door'
        break
      case 4:
        accName = 'PA Door'
        break
      case 5:
        accName = 'Sliding Door'
        break
      case 6:
        accName = 'Skylight'
        break
      case 7:
        accName = 'Window'
        break
    }
    return accName
  }

  drawLegends = (i, side, context, x, y) => {
    var product = this.state.products[i]
    var type = product.type
    var startY = this.getHeight(i) -  1 * this.state.shedCanvasRes
    var startX = 0

    context.fillStyle = 'brown'
    context.strokeStyle = 'Red'
    context.lineWidth = 4
    var size = 42 
    this.setFontSize(context, size)

    context.fillText('[' + (i + 1) + '] ', 10 + startX, startY)

    switch(side) {
      case 0:
        s = 'Posts ~ '
        break 
      default:
        s = 'Roofing ~ '
        if(type == 28) s = 'Top view ~ '
        break 
    }
    context.fillText(s , 72 + startX, startY)

    context.fillStyle = 'GoldenRod'
    var s = this.makeModelDescription(i, context)
    context.fillText(s , 280 + startX, startY)

    if(type == 20 || type == 21 || type == 28) return
    for(var i = 0; i < 2; ++i) {
      var x = 280 + startX + 320 * (i % 3)
      var fill = 'Orange'
      var name = 'Front Post'
      if(i == 1) {
        fill = 'Pink'
        name = 'Back Post'
      }
      context.fillStyle = fill
      context.beginPath();
      context.fillRect(x, startY + 2.2 * size, size, size)
      context.fillText(name, 5 + x + size, startY + 3 * size)
      context.stroke()
    }
  }

  drawShedLegends = (i, side, context, x, y) => {
    var startY = this.getHeight(i) -  1 * this.state.shedCanvasRes
    var startX = this.state.shedCanvasRes / 2

    context.fillStyle = 'brown'
    context.strokeStyle = 'Red'
    context.lineWidth = 4
    var size = 48 
    this.setFontSize(context, size)

    context.fillText('[' + (i + 1) + '] ', 10 + startX, startY)

    //context.fillStyle = 'Gold'
    switch(side) {
      case 0:
        s = 'Front Side ~ '
        break 
      case 1:
        s = 'Right Side ~ '
        break 
      case 2:
        s = 'Back Side ~ '
        break 
      case 3:
        s = 'Left Side ~ '
        break 
    }
    context.fillText(s , 72 + startX, startY)

    context.fillStyle = 'GoldenRod'
    var s = this.makeModelDescription(i, context)
    context.fillText(s , 360 + startX, startY)
    for(var i = 1; i < 7; ++i) {
      var x = 100 + startX + 320 * (i % 3)
      var y = 3 * size
      if(i > 3) y = 5 * size 
      context.fillStyle = this.getAccessoryColor(i)
      context.beginPath();
      context.fillRect(x, startY + y, size, size)
      context.fillText(this.getAccessoryTypeName(i), 5 + x + size, startY + y + size)
      context.stroke()
    }

  }

  draw2DAttachment = (i, context, vertices, x, y) => {
    context.fillStyle = 'white'
    context.strokeStyle = 'orange';
    var dis = this.state.gutterThickness
    var space = dis

    if(this.state.products[i].attachment_left == 1) {
      var sx = vertices[0].x + x - 2 * dis - space
      var ex = vertices[1].x + x - 2 * dis - space
      var sy = vertices[0].y + y - 3 * dis
      var ey = vertices[1].y + y - 3 * dis
      var l = vertices[1].y - vertices[0].y
      var n = l / space + 1

      context.beginPath()
      context.lineWidth = 5
      context.moveTo(sx, sy)
      context.lineTo(ex, ey)
      context.stroke()

      context.beginPath()
      context.lineWidth = 1 
      for(var j = 0; j < n; ++j) {
        context.moveTo(sx, sy + j * space)
        context.lineTo(sx + 2 * space, sy + (j + 2) * space)
      }
      context.stroke()     
    } 

    if(this.state.products[i].attachment_bottom == 1) {
      var sx = vertices[1].x + x + 1 * dis 
      var ex = vertices[2].x + x + 1 * dis
      var sy = vertices[1].y + y + space
      var ey = vertices[2].y + y + space
      var l = vertices[2].x - vertices[1].x
      var n = l / space + 1

      context.lineWidth = 4
      context.beginPath()
      context.moveTo(sx, sy)
      context.lineTo(ex, ey)
      context.stroke()

      context.lineWidth = 1
      context.beginPath()
      for(var j = 0; j < n; ++j) {
        context.moveTo(sx + (j) * space, sy )
        context.lineTo(sx + (j - 2) * space, sy - 2 * space)
      }
      context.stroke()
    } 

    if(this.state.products[i].attachment_right == 1) {
      var sx = vertices[2].x + x + space
      var ex = vertices[3].x + x + space
      var sy = vertices[2].y + y - 3 * dis
      var ey = vertices[3].y + y - 3 * dis
      var l = vertices[2].y - vertices[3].y
      var n = l / space  

      var t = 0 

      context.beginPath()
      context.lineWidth = 1

      switch(this.state.products[i].type) {
        case 13:
        case 14:
          t = (ex - sx) / n  
          if(ex > sx) {
            var ratio = (ex - sx) / (sy - ey)
            sx += ratio * 10
            ex += ratio * 10
          } 
          break
        default:    
          break
      }

      for(var j = 0; j < n; ++j) {
        context.moveTo(ex - j * t, ey + j * space)
        context.lineTo(ex - 2 * space - j * t, ey + (j + 2) * space)
      }  
      context.stroke()
      context.beginPath()
      context.lineWidth = 5
      context.moveTo(sx, sy)
      context.lineTo(ex, ey)
      context.stroke();

    }

    if(this.state.products[i].attachment_top == 1) {
      if(this.state.products[i].step_length == 0) {
        var sx = vertices[0].x + x - 3 * dis 
        var ex = vertices[3].x + x - 3 * dis
        var sy = vertices[0].y + y - 2 * dis - space
        var ey = vertices[3].y + y - 2 * dis - space
        var l = vertices[3].x - vertices[0].x
        var n = l / space + 1
  
        context.lineWidth = 4
        context.beginPath()
        context.moveTo(sx, sy)
        context.lineTo(ex, ey)
        context.stroke();
  
        context.lineWidth = 1
        context.beginPath()
        for(var j = 0; j < n; ++j) {
          context.moveTo(sx + (j + 2) * space, sy + 2 * space)
          context.lineTo(sx + (j ) * space, sy )
        }
        context.stroke() 
      } else {
        var sx = vertices[3].x + x - 3 * dis 
        var ex = vertices[4].x + x - 3 * dis
        var sy = vertices[3].y + y - 2 * dis - space
        var ey = vertices[4].y + y - 2 * dis - space
        var l = vertices[3].x - vertices[4].x
        var n = l / space + 1
  
        context.lineWidth = 4
        context.beginPath()
        context.moveTo(sx, sy)
        context.lineTo(ex, ey)
        context.stroke()
  
        context.lineWidth = 1
        context.beginPath()
        for(var j = 0; j < n; ++j) {
          context.moveTo(ex + (j + 2) * space, sy + 2 * space)
          context.lineTo(ex + (j ) * space, sy )
        }
        context.stroke()

        sx = vertices[5].x + x - 3 * dis 
        ex = vertices[6].x + x - 3 * dis
        sy = vertices[5].y + y - 2 * dis - space
        ey = vertices[6].y + y - 2 * dis - space
        l = vertices[5].x - vertices[6].x
        n = l / space + 1
  
        context.lineWidth = 4
        context.beginPath()
        context.moveTo(sx, sy)
        context.lineTo(ex, ey)
        context.stroke();
  
        context.lineWidth = 1
        context.beginPath()
        for(var j = 0; j < n; ++j) {
          context.moveTo(ex + (j + 2) * space, sy + 2 * space)
          context.lineTo(ex + (j ) * space, sy )
        }
        context.stroke()
      }
    }
  }

  drawVerticalArrow = (context, sx, sy, ex, ey, dis) => {
    context.moveTo(sx, sy)
    context.lineTo(sx, sy - dis)
    context.lineTo(sx - dis, sy)
    context.lineTo(sx, sy + dis)
    context.lineTo(sx, sy)
    context.lineTo(ex, ey)
    context.lineTo(ex, ey - dis)
    context.lineTo(ex + dis, ey)
    context.lineTo(ex, ey + dis)
    context.lineTo(ex, ey)
  }

  drawHorizontalArrow = (context, sx, sy, ex, ey, dis) => {
    context.moveTo(sx, sy)
    context.lineTo(sx + dis, sy)
    context.lineTo(sx, sy - dis)
    context.lineTo(sx - dis, sy)
    context.lineTo(sx, sy)
    context.lineTo(ex, ey)
    context.lineTo(ex + dis, ey)
    context.lineTo(ex, ey + dis)
    context.lineTo(ex - dis, ey)
    context.lineTo(ex, ey)
  }
  
  draw2DDimension = (i, context, vertices, x, y) => {
    var dis = this.state.gutterThickness
    var space = dis

    var m = 2
    if(this.state.products[i].attachment_left == 1) {
      m = 4
    } 
    var sx = vertices[0].x + x - m * dis - space
    var ex = vertices[1].x + x - m * dis - space
    var sy = vertices[0].y + y - 0 * dis
    var ey = vertices[1].y + y - 2 * dis

    context.beginPath()
    context.fillStyle = 'white'
    context.strokeStyle = 'brown';
    context.lineWidth = 4
    this.drawHorizontalArrow(context, sx, sy, ex, ey, dis)
 
    m = 0
    if(this.state.products[i].attachment_bottom == 1) {
      m = 2
    } 
    sx = vertices[1].x + x - 0 * dis 
    ex = vertices[2].x + x - 2 * dis
    sy = vertices[1].y + y + space + dis * m
    ey = vertices[2].y + y + space + dis * m

    this.drawVerticalArrow(context, sx, sy, ex, ey, dis)

    switch(this.state.products[i].type) {
      case 13:
      case 14:
          m = 2
          if(this.state.products[i].attachment_top == 1) {
            m = 4
          } 
          sx = vertices[3].x + x - 2 * dis 
          ex = vertices[4].x + x - 0 * dis
          sy = vertices[3].y + y - m * dis - space
          ey = vertices[4].y + y - m * dis - space
      
          this.drawVerticalArrow(context, ex, ey, sx, sy, dis)
          context.stroke()
        break
      default:
        if(this.state.products[i].step_length == 0) {

        } else {
          m = 0
          if(this.state.products[i].attachment_right == 1) {
            m = 2
          } 
          sx = vertices[2].x + x + space + m * dis
          ex = vertices[3].x + x + space + m * dis
          sy = vertices[2].y + y - 2 * dis
          ey = vertices[3].y + y + dis
      
          this.drawHorizontalArrow(context, ex, ey, sx, sy, dis)
    
          m = 2
          if(this.state.products[i].attachment_top == 1) {
            m = 4
          } 
          sx = vertices[3].x + x - 2 * dis 
          ex = vertices[4].x + x - 0 * dis
          sy = vertices[3].y + y - m * dis - space
          ey = vertices[4].y + y - m * dis - space
    
          this.drawVerticalArrow(context, ex, ey, sx, sy, dis)
    
        }
        context.stroke()
        break
    }
    
  }

  draw2DPosts = (i, context, vertices, x, y, len, width) => {
    switch(this.state.products[i].type) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 13:
      case 14:
      case 67:
        this.draw2DPostsPatio(i, context, vertices, x, y, len, width)
        break
      default:
        break
    }
  }

  draw2DPostsPatio = (i, context, vertices, x, y, len, width) => {
    var model = this.getModelSize(i)
    var size = this.findDescriptionFontSize(model)

    var product = this.state.products[i]
    var dis = this.state.gutterThickness
    var postSize = this.state.canvasRes * product.front_post_width
    var frontOverhangSize = this.state.canvasRes * product.front_overhang * product.projection / 2

    var m = 4

    var sx = vertices[1].x + x 
    var ey = vertices[1].y + y - m * dis
    var sy = ey - frontOverhangSize 
    

    context.beginPath()
    context.strokeStyle = 'brown'
    context.lineWidth = 4

  
    var front_post_pitch = len / (product.front_posts_no * 2) 
    var front_post_pitch_in_meter = product.length / (product.front_posts_no * 2)

    if(product.type == 13 || product.type == 14) {
      if(product.back_length > product.length) {
        front_post_pitch = front_post_pitch * product.length / product.back_length
      }
    }

    var oldSx = postSize / 2
    var oldDim = 0
    for(var i = 0; i < product.front_posts_no; ++i) {
      var post_place_in_percent = (1 + 2 * (i + product.front_posts_offset[i]))
      var scalar =  post_place_in_percent * front_post_pitch 
      var dim = post_place_in_percent * front_post_pitch_in_meter
      context.fillStyle = 'orange'
      context.fillRect(sx + scalar - postSize / 2, sy, postSize, postSize)
      this.drawVerticalArrow(context, sx + oldSx + dis, sy - 0.6 * postSize, sx + scalar - dis, sy - 0.6 * postSize, dis)
      if(i == 1 && product.type < 4) {
        this.drawHorizontalArrow(context, sx + oldSx + postSize, sy + 0.5 * postSize + dis, sx + oldSx + postSize, ey + 1.5 * dis, dis)
      }
      context.fillStyle = 'blue'
      var textSx = sx + (oldSx + scalar) / 2 - 1.5 * size
      var textSy = sy + 0.5 *  postSize
      if(i == 0) {
        if(dim < 0.9) {
          textSx += 1 * size
          textSy -= 1.5 *  size
        }
      } 
      context.fillText((dim - oldDim).toFixed(2) + 'm', textSx, textSy)
      oldSx = scalar
      oldDim = dim
      if(i == 0 && product.type < 4) {
        context.fillText((product.projection * product.front_overhang / 2).toFixed(2) + 'm',  sx + oldSx + 2 * postSize, (ey + sy) / 2 + size)
      }
    }

    if(product.type == 3 || product.type == 4 || product.type == 7 || product.type == 10 || product.type == 14) {
      var ratio = 1
      var back_post_pitch = len / (product.back_posts_no * 2) 
      var backOverhangSize = this.state.canvasRes * product.back_overhang  * product.projection / 2
      var back_post_pitch_in_meter = product.length / (product.back_posts_no * 2)

      switch(product.type) {
        case 14:
          back_post_pitch = product.back_length * len / (product.length * product.back_posts_no * 2) 
          back_post_pitch_in_meter = product.back_length / (product.back_posts_no * 2)

          if(product.back_length > product.length) {
            back_post_pitch =  len / (product.back_posts_no * 2)
          } 
          sx = vertices[0].x + x 
          sy = vertices[3].y + y + backOverhangSize
          ey = vertices[0].y + y 
          break
        default:
          if(product.step_length == 0) {
            sx = vertices[0].x + x 
            sy = vertices[0].y + y + backOverhangSize
            ey = vertices[0].y + y 
          } else {
            if(product.step_size < product.projection) {
              sx = vertices[0].x + x 
              sy = vertices[0].y + y + backOverhangSize
              ey = vertices[0].y + y
              ratio = product.step_length / product.length
            } else {
              sx = vertices[4].x + x 
              sy = vertices[4].y + y + backOverhangSize
              ey = vertices[4].y + y
              ratio = (product.length - product.step_length) / product.length
            }
          }
          break
      }

      oldSx = 0
      oldDim = 0
      for(var i = 0; i < product.back_posts_no; ++i) {
        var post_place_in_percent = (1 + 2 * (i + product.back_posts_offset[i]))
        var scalar =  post_place_in_percent * back_post_pitch * ratio
        var dim = post_place_in_percent * back_post_pitch_in_meter * ratio
        context.fillStyle = 'pink'
        context.fillRect(sx + scalar - postSize / 2, sy, postSize, postSize)
        this.drawVerticalArrow(context, sx + oldSx + dis, sy + 1.6 * postSize, sx + scalar - dis, sy + 1.6 * postSize, dis)
        if(i == 1 && product.type == 3) {
          this.drawHorizontalArrow(context, sx + oldSx + postSize, ey + dis, sx + oldSx + postSize, sy + 0.5 * postSize - dis, dis)
        }

        context.fillStyle = 'blue'
        var textSx = sx + (oldSx + scalar) / 2 - 1.5 * size
        var textSy = sy + 2.2 *  size 
        if(product.projection < 3 || product.step_size < 3) {
          textSy -= 1 * size
        }

        if(i == 0 && dim < 0.9) {
          textSx += 1 * size
        }

        context.fillText((dim - oldDim).toFixed(2) + 'm', textSx, textSy)
        oldSx = scalar
        oldDim = dim
        if(i == 0 && product.type == 3) {
          context.fillText((product.projection * product.back_overhang / 2).toFixed(2) + 'm',  sx + oldSx + 2 * postSize, (ey + sy) / 2 + size / 2)
        }
      }
    }
    context.stroke()
  }

  draw2DPostsDecking = (i, context, vertices, x, y, len, width) => {
    var model = this.getModelSize(i)
    var size = this.findDescriptionFontSize(model)

    var product = this.state.products[i]
    var dis = this.state.gutterThickness
    var postSize = this.state.canvasRes * product.front_post_width

    var m = 4

    var sx = vertices[1].x + x 
    var ey = vertices[1].y + y - m * dis
    var sy = ey  
    
    context.beginPath()
    context.strokeStyle = 'brown'
    context.lineWidth = 4

    var front_post_pitch = len / (product.front_posts_no * 2) 
    var front_post_pitch_in_meter = product.length / (product.front_posts_no * 2)

    var no = this.findDeckingRowsNo(product)
    
    for(var j = 0; j < no + 2; ++ j) {
      var oldDim = 0
      var oldSx = postSize / 2
      var sy = ey - (j) * ey / (no + 1)
      var oy = 0
      switch(j) {
        case 1:
          sy = y 
          oy = 5 * dis
          break
        case 2:
          if(no == 1) sy = ey - 0.5 * ey 
          else if(no == 2) sy = ey - 0.33 * ey 
          break
        case 3:
          sy = ey - 0.66 * ey 
          break
        case 0:
          sy = ey
          break
      }
      for(var i = 0; i < product.front_posts_no; ++i) {
        var post_place_in_percent = (1 + 2 * (i + product.front_posts_offset[i]))
        var scalar =  post_place_in_percent * front_post_pitch 
        var dim = post_place_in_percent * front_post_pitch_in_meter
        context.fillStyle = 'orange'
        context.fillRect(sx + scalar - postSize / 2, sy, postSize, postSize)
        this.drawVerticalArrow(context, sx + oldSx + dis, sy - 0.6 * postSize + oy, sx + scalar - dis, sy - 0.6 * postSize + oy, dis)
        if(i == 1) {
          var hArrowY1 = ey + 0.5 * dis
          var hArrowY2 = y + 2 * dis          
          if(no == 1) {
            if(j == 0) {              
              hArrowY2 = hArrowY1 - ey * 0.5 + 2 * dis
            } else {              
              hArrowY1 = hArrowY1 - ey * 0.5 + 0 * dis
              hArrowY2 = y + 2 * dis
            }            
          } else if(no == 2){
            if(j == 0) {               
              hArrowY2 = hArrowY1 - ey * 0.33 + 2 * dis     
            } else if(j == 1) {   
              hArrowY1 = ey * 0.66 + 3.5 * dis             
              hArrowY2 = ey * 0.33 + 5 * dis     
            } else {               
              hArrowY1 = ey * 0.33 + 3 * dis             
              hArrowY2 = y + 2 * dis     
            }
          }
          this.drawHorizontalArrow(context, sx + oldSx + postSize, hArrowY2, sx + oldSx + postSize, hArrowY1, dis)
          context.fillStyle = 'blue'
          context.fillText(((product.projection - product.front_post_width)/ (no + 1)).toFixed(2) + 'm', sx + oldSx + 1.5 * postSize, (hArrowY1 + hArrowY2) / 2)
        }
        context.fillStyle = 'blue'
        var textSx = sx + (oldSx + scalar) / 2 - 1.5 * size
        var textSy = sy + 0.5 *  postSize
        if(i == 0 && j != 1) {
          if(dim < 0.9) {
            textSx += 1 * size
            textSy -= 1.5 *  size
          }
        } 
        context.fillText((dim - oldDim).toFixed(2) + 'm', textSx, textSy + oy)
        oldSx = scalar
        oldDim = dim

      }
    }


    context.stroke()
  }

  findDescriptionFontSize = (model) => {
    var size = 44
    return size
  }

  setFontSize = (context, size) => {
    context.font = size + 'px Arial'
  }

  makeModelDimension = (i, context, vertices, x, y) => {
    var product = this.state.products[i]
    var dis = this.state.gutterThickness
    var space = dis
    var model = this.getModelSize(i)
    var size = this.findDescriptionFontSize(model)
    this.setFontSize(context, size)

    context.fillStyle = 'blue'

    var m = 1
    if(product.attachment_left == 1) {
      m = 0
    } 
    var xx = x - size * 4.5 + m * dis
    var yy = (vertices[1].y + vertices[0].y) / 2 + y - size
    var s = model.proj.toFixed(1) + 'm'

    if(model.step_len != 0) {
      s = model.step_size.toFixed(1) + 'm'
    } 

    if(product.type > 63 && product.type < 100) {
      s = product.height.toFixed(1) + 'm'
    }

    context.fillText(s , xx, yy + size)

    m = 0
    if(product.attachment_bottom == 1) {
      m = 1
    } 
    xx = (vertices[1].x + vertices[2].x) / 2 + x - 2 * size
    yy = vertices[1].y + y + size  + m * dis
    s = model.len.toFixed(1) + 'm'

    context.fillText(s , xx, yy + size)

    switch(product.type) {
      case 13:
      case 14:
        m = 5
        if(product.attachment_top == 1) {
          m = 8
        } 
        var xx = (vertices[3].x + vertices[0].x) / 2 + x - 2 * size
        var yy = vertices[3].y + y - m * dis - size
        var s = (model.back_len).toFixed(1) + 'm'
        context.fillText(s , xx, yy + size)
        break
      default:
        if(model.step_len != 0) {
          m = 2
          if(product.attachment_right == 1) {
            m = 4
          } 
          var xx = vertices[2].x + x + m * dis
          var yy = (vertices[2].y + vertices[3].y) / 2 + y - size
          var s = model.proj.toFixed(1) + 'm'
          context.fillText(s , xx, yy + size)

          m = 5
          if(product.attachment_top == 1) {
            m = 8
          } 
          var xx = (vertices[3].x + vertices[4].x) / 2 + x - 2 * size
          var yy = vertices[3].y + y - m * dis - size
          var s = (model.len - model.step_len).toFixed(1) + 'm'
          context.fillText(s , xx, yy + size)
        }
        break
      }
  }

  makeModelDescription = (i) => {
    var model = this.getModelSize(i)
    var s = findModelTypeStringGlobal(this.state.products[i].price_index, true)
    /*s += ' ~ '
    switch(model.profile) {
      case 0:
        s += 'TrimDeck'
        break
      case 1:
        s += 'FlatDeck'
        break
      case 2:
        s += 'Plane'
        break
      case 10:
        s += 'Custom Orb'
        break
      case 11:
        s += 'Insulated Custom Orb'
        break
      case 12:
        s += 'SpanDeck'
        break
    }*/
    if(model.step_len == 0) s += ' ~ ' + (model.len).toFixed(2) + 'm * ' + (model.proj).toFixed(2) + 'm'
    else {
      s += ' ~ ' + (model.step_len).toFixed(2) + 'm * ' + (model.step_size).toFixed(2) + 'm + ' +
          + (model.len - model.step_len).toFixed(2) + 'm * ' + (model.proj).toFixed(2) + 'm'
    }    
    return s
  }
  
  drawSkeleton2D = (i, type) => {
    if(this.state.products[i].type == 32 || this.state.products[i].type == 33 || this.state.products[i].type == 100) return -1
    var canvas = null
    switch(type) {
      case 0:
        canvas = this.state.products[i].postLocationCanvasRef.current 
        break
      case 1:
        canvas = this.state.products[i].canvasRef.current 
        break
    }
    const context = canvas.getContext('2d',  { willReadFrequently: true })

    var width = this.getWidth(i)
    var height = this.getHeight(i) - this.state.canvasRes

    context.fillStyle = 'white'
    context.fillRect(0, 0, width, height + this.state.canvasRes)
    this.setFontSize(context, 20)
    context.fillStyle = "black"

    var vertices = this.find2DCorners(i)
    var roofingOffset = this.state.canvasRes / 2
    if(this.state.products[i].type < 12 || this.state.products[i].type == 67) {      
      this.makeModelDimension(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DDimension(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DAttachment(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DGutter(i, context, vertices, roofingOffset, roofingOffset)
      if(type == 1) this.draw2DRoofing(i, context, roofingOffset, roofingOffset, width - roofingOffset * 2, height - roofingOffset * 2)
      else this.draw2DPosts(i, context, vertices, roofingOffset, roofingOffset, width - roofingOffset * 2, height - roofingOffset * 2)
      this.drawLegends(i, type, context, roofingOffset, roofingOffset)
    } else if(this.state.products[i].type < 15) {      
      this.makeModelDimension(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DDimension(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DAttachment(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DGutter(i, context, vertices, roofingOffset, roofingOffset)
      if(type == 1) this.draw2DRoofing(i, context, roofingOffset, roofingOffset, width - roofingOffset * 2, height - roofingOffset * 2)
      else this.draw2DPosts(i, context, vertices, roofingOffset, roofingOffset, width - roofingOffset * 2, height - roofingOffset * 2)
      this.drawLegends(i, type, context, roofingOffset, roofingOffset)
    } else if(this.state.products[i].type == 20 || this.state.products[i].type == 21) {      
      if(type == 1) this.draw2DRoofingGazebo(i, type, context, vertices, roofingOffset, roofingOffset)    
      else this.draw2PostsGazebo(i, type, context, vertices, roofingOffset, roofingOffset) 
      this.drawLegends(i, type, context, roofingOffset, roofingOffset)
    } else if(this.state.products[i].type == 28) {      
      this.makeModelDimension(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DDimension(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DAttachment(i, context, vertices, roofingOffset, roofingOffset)
      this.draw2DBeamsDecking(i, type, context, vertices, roofingOffset, roofingOffset)
      if(type == 0) this.draw2DPostsDecking(i, context, vertices, roofingOffset, roofingOffset, width - roofingOffset * 2, height - roofingOffset * 2)      
      this.drawLegends(i, type, context, roofingOffset, roofingOffset)
    } else {
      var s = '[' + (i + 1) + '] ' + findModelTypeStringGlobal(this.state.products[i].price_index, true)
      context.fillText(s + ': Sorry! 2D Drawing of this Model Not Ready Yet', 12, 24)
    }
  }

  drawShedSkeleton2D = (i, type) => {
    var canvas = null
    switch(type) {
      case 0:
        canvas = this.state.products[i].canvasRef.current 
        break
      case 1:
        canvas = this.state.products[i].canvasRef1.current 
        break
      case 2:
        canvas = this.state.products[i].canvasRef2.current 
        break
      case 3:
        canvas = this.state.products[i].canvasRef3.current 
        break
    }
    const context = canvas.getContext('2d',  { willReadFrequently: true })

    var width = this.getWidth(i)
    var height = this.getHeight(i) - this.state.canvasRes

    context.fillStyle = 'white'
    context.fillRect(0, 0, width, height + this.state.canvasRes)
    this.setFontSize(context, 20)
    context.fillStyle = "black"
    
    var shedRoofingOffset = this.state.shedCanvasRes / 2
    if(this.state.products[i].type > 63 && this.state.products[i].type < 100) {      
      this.draw2DWall(i, type, context, shedRoofingOffset, shedRoofingOffset)
      this.draw2DAccessories(i, type, context, shedRoofingOffset, shedRoofingOffset)
      this.drawShed2DRoofing(i, type, context, shedRoofingOffset, shedRoofingOffset)
      if(type == 1) this.draw2DRoofing(i, context, shedRoofingOffset, shedRoofingOffset, width - shedRoofingOffset * 2, height - shedRoofingOffset * 2)
      else this.draw2DPosts(i, context, shedRoofingOffset, shedRoofingOffset, width - shedRoofingOffset * 2, height - shedRoofingOffset * 2)
      this.drawShedLegends(i, type, context, shedRoofingOffset, shedRoofingOffset)
    } else {
      var s = '[' + (i + 1) + '] ' + findModelTypeStringGlobal(this.state.products[i].price_index, true)
      context.fillText(s + ': Sorry! 2D Drawing of this Model Not Ready Yet', 12, 24)
    }
  }


  draw2D = (i) => {
    this.drawSkeleton2D(i, 1)    
  }

  drawPostLocation2D = (i) => {
    this.drawSkeleton2D(i, 0) 
  }

  setIndex(i) {
    var n = i
    this.setState({
        index: n
    })
  }

  getBlob = (blob, name) => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }

  download2DImage = (product) => {
    var canvas = product.canvasRef.current
    canvas.toBlob((blob) => {
      this.getBlob(blob, project_specification.family_name + '_' + project_specification.first_name + '_drw_2d.png')
    }, 'image/png');
  }

  download2DImage1 = (product) => {
    var canvas = product.canvasRef.current
    canvas.toBlob((blob) => {
      this.getBlob(blob, project_specification.family_name + '_' + project_specification.first_name + '_drw_1_2d.png')
    }, 'image/png');
  }

  download2DImage2 = (product) => {
    var canvas = product.canvasRef1.current
    canvas.toBlob((blob) => {
      this.getBlob(blob, project_specification.family_name + '_' + project_specification.first_name + '_drw_2_2d.png')
    }, 'image/png');
  }

  download2DImage3 = (product) => {
    var canvas = product.canvasRef2.current
    canvas.toBlob((blob) => {
      this.getBlob(blob, project_specification.family_name + '_' + project_specification.first_name + '_drw_3_2d.png')
    }, 'image/png');
  }

  download2DImage4 = (product) => {
    var canvas = product.canvasRef3.current
    canvas.toBlob((blob) => {
      this.getBlob(blob, project_specification.family_name + '_' + project_specification.first_name + '_drw_4_2d.png')
    }, 'image/png');
  }

  downloadPostLocation2DImage = (product) => {
    var canvas = product.postLocationCanvasRef.current
    canvas.toBlob((blob) => {
      this.getBlob(blob, project_specification.family_name + '_' + project_specification.first_name + '_post_drw_2d.png')
    }, 'image/png');
  }

  downloadImage = () => {
    for(var i = 0; i < product_manager_module.products.length; ++i) {
      var product = product_manager_module.products[i]
      if(product.type < 15 || this.state.products[i].type == 20 || this.state.products[i].type == 21  || product.type == 28 || product.type == 67) {
        this.download2DImage(product)
        this.downloadPostLocation2DImage(product)
      } else if(product.type > 63 && product.type < 100) {
        this.download2DImage1(product)
        this.download2DImage2(product)
        this.download2DImage3(product)
        this.download2DImage4(product)
      }
    }
  }

  runTask = (v) => {
    switch(v) {
      case 1:      
        this.downloadImage()
        break
      default:
        break
    }
  }

  getModelSize = (i) => {
    var type = this.state.products[i].type
    var len =  this.state.products[i].length
    var proj =  this.state.products[i].projection
    var step_len =  this.state.products[i].step_length
    var step_size =  this.state.products[i].step_size
    var back_len = this.state.products[i].back_length
    var profile = this.state.products[i].profile
    return { type: type, len: len, proj: proj, step_len: step_len, step_size: step_size, back_len: back_len, profile: profile }
  }

  getFrontOverhangProjection = (i) => {
    var product = this.state.products[i]
    switch(product.type) {
      case 64:
      case 65:
      case 66:
      case 75:
      case 80:
      case 85:
        return product.front_overhang * this.state.shedCanvasRes * Math.cos((Math.PI * product.angle) / 180)
        break
      default:
        return 0
    }
  }

  getFrontOverhangHeight = (i) => {
    var product = this.state.products[i]
    switch(product.type) {
      case 64:
      case 65:
      case 66:
      case 75:
      case 80:
      case 85:
        return product.front_overhang * this.state.shedCanvasRes * Math.sin((Math.PI * product.angle) / 180)
        break
      default:
        return 0
    }
  }

  getRoofElevation = (i) => {
    var product = this.state.products[i]
    var e = product.projection * Math.sin((Math.PI * product.angle) / 180) * this.state.shedCanvasRes 
    switch(product.type) {
      case 64:
      case 65:
      case 66:
        return e / 2
      case 75:
        return e
      case 80:
      case 85:
        return e / 2
      default:
        return 0
    }
  }

  getHeight = (i) => {
    var product = this.state.products[i]
    switch(product.type) {
      case 64:
      case 65:
      case 66:      
      case 80:
      case 85:
        return product.height * this.state.shedCanvasRes + this.getRoofElevation(i) + 3 * this.state.shedCanvasRes
      case 75:
        return product.height * this.state.shedCanvasRes + 1 * this.getRoofElevation(i) + 3 * this.state.shedCanvasRes
      default:        
        return product.projection * this.state.canvasRes + 2 * this.state.shedCanvasRes
    }
  }

  getSideOverhangSize = (i) => {
    var product = this.state.products[i]
    switch(product.type) {
      case 64:
      case 65:
      case 66:
      case 75:
      case 80:
      case 85:
        return product.back_overhang * this.state.shedCanvasRes
        break
      default:
        return 0
    }
  }

  getLength = (i) => {    
    var product = this.state.products[i]
    switch(product.type) {
      case 20:
      case 21:
        return 1.2 * product.length * this.state.shedCanvasRes 
        break
      case 64:
      case 65:
      case 66:
      case 75:
      case 80:
      case 85: 
        var l = product.length * this.state.shedCanvasRes + this.getSideOverhangSize(i) * 2
        var w = product.projection * this.state.shedCanvasRes + this.getFrontOverhangProjection(i) * 2 
        if(l > w) return l
        else return w
      default:        
        return product.length * this.state.canvasRes
    }
  }

  getProjection = (i) => {    
    var product = this.state.products[i]
    switch(product.type) {
      case 64:
      case 65:
      case 66:
      case 75:
      case 80:
      case 85:
        return product.projection * this.state.shedCanvasRes + this.getFrontOverhangProjection(i) * 2 
      default:        
        return product.projection * this.state.canvasRes
    }
  }

  getBackLength = (i) => {
    return this.state.products[i].back_length * this.state.canvasRes
  }

  getWidth = (i) => {
    switch(this.state.products[i].type) {
      case 13:
      case 14:
        if(this.state.products[i].back_length < this.state.products[i].length) return this.getLength(i)
        else  return this.getBackLength(i)
        break
      default:
        return this.getLength(i)
        break
    }
  }

  informSelect = () => {
  }

  getScaledHeight = (i) => {
    return (this.state.width * this.state.products[i].projection / this.state.products[i].length)
  }
  
  componentDidMount() {
    for(var i = 0; i < this.state.products.length; ++i) {
      if(this.state.products[i].type < 32 || this.state.products[i].type == 67) {
        this.draw2D(i)
        this.drawPostLocation2D(i)
      } else if(this.state.products[i].type > 63 && this.state.products[i].type < 100) {    
        this.drawShedSkeleton2D(i, 0)    
        this.drawShedSkeleton2D(i, 1) 
        this.drawShedSkeleton2D(i, 2) 
        this.drawShedSkeleton2D(i, 3) 
       }
    }
    this.informSelect()
  }

  render() {
   const canvasStyle = {
      width: this.state.width,   
      height: '360px',  
      overflow: 'auto',
      border: '1px solid #000',
    };

    const canvasStyle1 = {
      width: '1px',   
      height: '1px',  
      overflow: 'auto',
      border: '1px solid #000',
    };
    
    const canvasDivStyle = {
      margin: '5px'
    };

    return (
      <div>
       <div id='projection_2d_page_setup'>
       {this.state.products.map(({scaledCanvasRef, scaledPostLocationCanvasRef}, index) => 
       (this.state.products[index].type < 32 || this.state.products[index].type == 67) && 
       (<div style={canvasDivStyle}>
        <div style={canvasStyle}>
         <canvas ref={scaledCanvasRef} width={this.state.width} height={this.getScaledHeight(index)}/>
        </div>
        <div style={canvasStyle}>
         <canvas ref={scaledPostLocationCanvasRef} width={this.state.width} height={this.getScaledHeight(index)}/>
        </div> 
        </div>))} 
        {this.state.products.map(({scaledCanvasRef, scaledCanvasRef1, scaledCanvasRef2, scaledCanvasRef3}, index) => 
        this.state.products[index].type > 63 && this.state.products[index].type != 67 &&  this.state.products[index].type != 100 && 
        (<div style={canvasDivStyle}>
          <div style={canvasStyle}>
          <canvas ref={scaledCanvasRef} width={this.state.width} height={this.getScaledHeight(index)}/>
          </div>
          <div style={canvasStyle}>
          <canvas ref={scaledCanvasRef1} width={this.state.width} height={this.getScaledHeight(index)}/>
          </div> 
          <div style={canvasStyle}>
          <canvas ref={scaledCanvasRef2} width={this.state.width} height={this.getScaledHeight(index)}/>
          </div> 
          <div style={canvasStyle}>
          <canvas ref={scaledCanvasRef3} width={this.state.width} height={this.getScaledHeight(index)}/>
          </div>       
          </div>))} 
        {this.state.products.map(({canvasRef, postLocationCanvasRef}, index) => 
        (this.state.products[index].type < 32 || this.state.products[index].type == 67) && 
        (<div style={canvasDivStyle}>
        <div style={canvasStyle1}>
         <canvas ref={canvasRef} width={this.getWidth(index)} height={this.getHeight(index)}/>
        </div>
        <div>
        <div style={canvasStyle1}>
         <canvas ref={postLocationCanvasRef} width={this.getWidth(index)} height={this.getHeight(index)}/>
        </div>
        </div>
       </div>))}  
       {this.state.products.map(({canvasRef, canvasRef1, canvasRef2, canvasRef3}, index) => 
        this.state.products[index].type > 63 && this.state.products[index].type != 67 &&  this.state.products[index].type != 100 && 
        (<div style={canvasDivStyle}>
          <div style={canvasStyle1}>
          <canvas ref={canvasRef} width={this.getWidth(index)} height={this.getHeight(index)}/>
          </div>
          <div style={canvasStyle1}>
          <canvas ref={canvasRef1} width={this.getWidth(index)} height={this.getHeight(index)}/>
          </div> 
          <div style={canvasStyle1}>
          <canvas ref={canvasRef2} width={this.getWidth(index)} height={this.getHeight(index)}/>
          </div> 
          <div style={canvasStyle1}>
          <canvas ref={canvasRef3} width={this.getWidth(index)} height={this.getHeight(index)}/>
          </div>       
          </div>))} 
       </div>
      </div>
     );  
  }
}

export default Smart2DProjectionPage;
