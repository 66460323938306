
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./pages/Login"
import Entry from "./pages/Entry"
import Users from "./pages/Users"
import Password from "./pages/Password"
import SmartMain from './pages/SmartMain'

import { config } from './smartkits/initSmartkits.js'

const root = ReactDOM.createRoot(document.getElementById('root'));

function setLandingPage() {
  if(config.deployment) {
    return(
      <BrowserRouter>
          <Routes>      
          <Route path="/" element={<Login />} />  
          <Route path="/password" element={<Password />} />  
          <Route path="/users" element={<Users />} />
          <Route path="/entry" element={<Entry />} />
          <Route path="/smart" element={<SmartMain />} />
          </Routes>
        </BrowserRouter>
    )} else {
      return(
        <React.StrictMode>
        <SmartMain />
        </React.StrictMode>
      )
    }
  }



root.render(
  setLandingPage()
);
