import { THREE, OrbitControls } from './helper.js'
import { gui_module, sceneItems } from './gui_smartkits.js';
import { initNewRaycaster } from './raycaster.js';
import { animate } from './model_modules.js';

var config = {
    deployment: true
}

function configureLight(informSelect) {
    sceneItems.camera.position.set(4, 4, 6);
    sceneItems.renderer.setSize(sceneItems.sceneX, sceneItems.sceneY);

    sceneItems.light = new THREE.DirectionalLight(0xffffff, 0);
    sceneItems.light.shadow.mapSize.width = 512;
    sceneItems.light.shadow.mapSize.height = 512; 
    sceneItems.scene.add(sceneItems.light);
    sceneItems.light_2nd = new THREE.DirectionalLight(0xffffff, 0);
    sceneItems.scene.add(sceneItems.light_2nd);

    sceneItems.ambient = new THREE.AmbientLight(0xffffff, 0); 
    sceneItems.scene.add(sceneItems.ambient);
    
    sceneItems.grid = new THREE.GridHelper(50, 50);
    sceneItems.grid.position.set(0, 0, 0);
    sceneItems.scene.add(sceneItems.grid);   
    sceneItems.grid.visible = false;         

    sceneItems.controler = new OrbitControls(sceneItems.camera, sceneItems.renderer.domElement);
    sceneItems.controler.target.set(0, 0, 0);

    gui_module.create_product_gui(informSelect);
    gui_module.gui.hide()
    initNewRaycaster();
}

function init() {
    sceneItems.sceneX = window.innerWidth;
    sceneItems.sceneY = window.innerHeight;

    sceneItems.scene = new THREE.Scene();
    sceneItems.camera = new THREE.PerspectiveCamera(60, sceneItems.sceneX / sceneItems.sceneY, 1, 1000);
    const canvas = document.querySelector('#c');                
    sceneItems.renderer = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true , preserveDrawingBuffer: true });

    configureLight(function () {})

    sceneItems.renderer.setAnimationLoop(() => {
        sceneItems.renderer.render(sceneItems.scene, sceneItems.camera);
        animate();
    });
}

function initSmartkits(scene, camera, renderer, informSelect) {
           
    sceneItems.sceneX = window.innerWidth * 0.3
    sceneItems.sceneY = window.innerHeight * 0.3

    sceneItems.scene = scene 
    sceneItems.camera = camera          
    sceneItems.renderer = renderer 

    configureLight(informSelect)
}

function resizeScene(w, h) {
    sceneItems.sceneX = window.innerWidth * w
    sceneItems.sceneY = window.innerHeight * h
    sceneItems.renderer?.setSize(sceneItems.sceneX, sceneItems.sceneY);
}

export { init, initSmartkits, resizeScene, config };

