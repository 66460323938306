import React from 'react';
import { THREE } from './helper.js';

import { gui_module, sceneItems } from './gui_smartkits.js';
import { flyover_module } from './flyover_module.js';
import { loader_module } from './loader_module.js';
import  { building_module } from './building_module.js';
import { gable_hip_module } from './gable_hip_module.js';
import { shed_module } from './shed_module.js';

class Product {
    constructor(type) {
        this.type = type;
        this.price_index = 0;
        this.width = 10;  
        this.scale = 1;

        if(gui_module.length) this.length = gui_module.length;
        else this.length = 3
        if(gui_module.back_length) this.back_length = gui_module.back_length;
        else this.back_length = 3
        if(gui_module.projection) this.projection = gui_module.projection;
        else this.projection = 3
        if(gui_module.step_length) this.step_length = gui_module.step_length;
        else this.step_length = 0
        if(gui_module.step_size) this.step_size = gui_module.step_size;
        else this.step_size = 3
        if(gui_module.bracket_size) this.bracket_size = gui_module.bracket_size;
        else this.bracket_size = 0.2
        
        this.height = gui_module.height;

        this.angle = 0 
        this.rotate = 90 
        this.symmetric = gui_module.symmetric;

        this.profile = gui_module.profile;
   
        this.roofing = gui_module.roofing;
        this.gutters = gui_module.gutters;
        this.beams = gui_module.beams;
        this.posts = gui_module.posts;
        this.walls = gui_module.walls;
        this.roller_doors = gui_module.roller_doors;

        this.scene_group = new THREE.Group(); 
        this.roofing_group = null;

        this.position = new THREE.Vector3(0, 0, 0)

        this.left_2_right_unit_vector = new THREE.Vector3(1, 0, 0)
        this.front_2_end_unit_vector = new THREE.Vector3(0, 0, -1)
        this.angle_60
        this.front_2_end_60_unit_vector = new THREE.Vector3()
        this.left_2_right_60_unit_vector = new THREE.Vector3()

        this.angle_120
        this.front_2_end_120_unit_vector = new THREE.Vector3()
        this.left_2_right_120_unit_vector = new THREE.Vector3()

        this.angle_45
        this.front_2_end_45_unit_vector = new THREE.Vector3()
        this.left_2_right_45_unit_vector = new THREE.Vector3()

        this.front_posts_model = []; 
        this.front_posts_offset = [];
        this.brackets_model = []; 
        this.bracket_offset = [];
        this.bracket_length = [];
        this.back_posts_model = []; 
        this.back_posts_offset = [];
        this.right_posts_model = []; 
        this.right_posts_offset = [];
        this.left_posts_model = []; 
        this.left_posts_offset = [];
        this.decking_posts_model = []; 
        this.decking_posts_offset = [];
        this.rafters_model = []
        this.rafter_offset = []
        this.purlins_model = []
        this.purlin_offset = []

        this.roofing_model = [];

        this.ceiling_model = [];
        this.gutters_model = [];
        this.beams_model = [];

        this.posts_base_model = [];


        this.downlights_model = [];

        this.parts = {"list": [], "parent": this, "index": 0};  

        this.front_post_width = gui_module.front_post_width
        this.forced_front_posts_no = gui_module.forced_front_posts_no
        this.front_posts_no = 2;
        this.front_posts_no_buffer = 0;
        this.forced_brackets_no = gui_module.forced_brackets_no;
        this.brackets_no = 2;
        this.brackets_no_buffer = 0;
        this.forced_back_posts_no = gui_module.forced_back_posts_no
        this.back_posts_no = 2;
        this.back_posts_no_buffer = 0;
        this.forced_right_posts_no = gui_module.forced_right_posts_no
        this.right_posts_no = 2;
        this.right_posts_no_buffer = 0;
        this.forced_left_posts_no = gui_module.forced_left_posts_no
        this.left_posts_no = 2;
        this.left_posts_no_buffer = 0;
        this.forced_decking_posts_no = gui_module.forced_decking_posts_no;
        this.decking_posts_no = 2;
        this.decking_posts_no_buffer = 0;
        this.decking_post_width = gui_module.decking_post_width
        this.forced_rafters_no = 0
        this.rafters_no_buffer = 2
        this.rafter_width = gui_module.rafter_width
        this.forced_purlins_no = 0
        this.purlins_no_buffer = 2
        this.purlin_width = gui_module.purlin_width

        this.cos_angle = 1;
        this.sin_angle = 0;

        this.roofing_type = 1
        this.front_overhang = 0.1
        this.back_overhang = 0.1
        this.step_overhang = 0.1
        this.gable_type = 0        
        this.fans_model = []
        this.fan = gui_module.fan
        this.color = gui_module.color
        this.fan_speed = 0

        this.downlights_model = []
        this.number = gui_module.number

        this.landscaping_model = []
        this.landscaping_visible = 0,
        this.landscaping_height = 0,
        this.landscaping_type = "pavement",
        this.landscaping_x_offset = 0,
        this.landscaping_z_offset = 0,
        this.landscaping_custom_material = null,
        
        this.horizon = 0;

        this.lock = false;

        this.walls_model = [];
        this.accessories_model = [];

        this.model_type = "insulated_flyover";
        this.wind_category = 'CYCLONIC 1';
        this.roof_sheet_type = "FLATDEK II (No Foot Traffic)";
        this.attachment_type = 'TIMBER FASCIA DROP';
        this.attachment_sub_type = 'TIMBER WALL';
        this.partial_attachment_type = 'TOP';
        this.attachment_top = 0;
        this.attachment_right = 0;
        this.attachment_left = 0;
        this.attachment_bottom = 0;
        this.attachment_top_type = 'Rafter Brackets supporting Beam';
        this.attachment_top_fascia_type = 'Beam';
        this.attachment_top_variation = 'Gutter';
        this.attachment_bottom_type = 'Rafter Brackets supporting Beam';
        this.attachment_bottom_fascia_type = 'Beam';
        this.attachment_bottom_variation = 'Gutter';
        this.attachment_right_type = 'Rafter Brackets supporting Beam';
        this.attachment_right_fascia_type = 'Beam';
        this.attachment_right_variation = 'Gutter';
        this.attachment_left_type = 'Rafter Brackets supporting Beam';
        this.attachment_left_fascia_type = 'Beam';
        this.attachment_left_variation = 'Gutter';
        this.fascia_type = 'TIMBER FASCIA DROP';
        this.post_type = 'SHS 75mm x 2mm';
        this.gutter_type = 'Trimline Gutter';
        this.footing_type = 'CONCRETE_IN';
        this.bracket_type = 'TIMBER WALL';
        this.fascia_beam_type = 'FL15015';
        this.purlin_beam_type = 'FL10011';
        this.rafter_beam_type = 'FL10011';
        this.wind_options_importance_level = 2;
        this.wind_options_blocking = 1;
        this.downpipe_type = 'Lysaght 100mm x 75mm Square Downpipe QLD';
        this.gutter_front = 1;
        this.gutter_back = 1;
        this.gutter_left = 1;
        this.gutter_right = 1;
        this.allow_RSBLC = 1;
        this.offering_type = 0;

        this.eng_details = false;
        this.eng_validation_state = 0;
        this.eng_validation_msg = "Not Requested Yet";
        this.eng_price_ready = false;
        this.eng_price_total_Ex_GST = "";
        this.eng_price_total_Inc_GST = "";
        this.eng_price_packing_delivery = "";
        this.eng_supplier = "Lysaght";
        this.eng_pricing_view = false;
        this.eng_pricing_button_visible = true;

        this.canvasRef = React.createRef()
        this.scaledCanvasRef = React.createRef()
        this.canvasRef1 = React.createRef()
        this.scaledCanvasRef1 = React.createRef()
        this.canvasRef2 = React.createRef()
        this.scaledCanvasRef2 = React.createRef()
        this.canvasRef3 = React.createRef()
        this.scaledCanvasRef3 = React.createRef()
        this.postLocationCanvasRef = React.createRef()
        this.scaledPostLocationCanvasRef = React.createRef()
        
        this.test = 0
        this.test1 = 0
        this.test2 = 0
    }

    set_visible(flag) {
        this.scene_group.visible = flag
    }
}

var product_manager_module = {
    index: 0,
    products: [],
    maximum: 12,
    cursor: null,
    set_cursor(product) {
        if(product_manager_module.cursor == null) { 
            loader_module.get_logo(function(logo) {
                product_manager_module.cursor = logo
                sceneItems.scene.add(product_manager_module.cursor)
            })   
            var intervalId = setInterval(function() {
                product_manager_module.periodic_event();
                gui_module.periodic_event();
            }, 100);  
        } else {
            if(product) {
                var pos = product.position
                product_manager_module.cursor.position.set(pos.x + 0.5, 0.05 /*product.height * 1.5*/, pos.z + 0.5);
            }            
        }      
        
    },
    add: function(type, index) {
        var len = this.products.length;
        
        if(len > product_manager_module.maximum - 1) {
            console.log("Error: Product Overflow!!");
            return;
        }
        var position = new THREE.Vector3();
        if(type < 32) {
            
            //position.set(-5 + type / 2, gui_module.altitude, 2 + len);
            
            position.set(0, gui_module.altitude, -8 + len);
            var product = new Product(type)
            product.price_index = index  
            this.products.push(product)
            gui_module.index_gui.setValue(len + 1)
            if(type < 4) {
                flyover_module.add(product)                        
                flyover_module.move(product, position);
            } else {
                gable_hip_module.add(product)                      
                gable_hip_module.move(product, position);
            }
           
            product_manager_module.set_cursor(product)
            
        } else if(type > 63) {            
            position.set(0, gui_module.altitude, -8 + len);
            var product = new Product(type)
            product.price_index = index  
            this.products.push(product)
            gui_module.index_gui.setValue(len + 1)
            shed_module.add(product)                        
            shed_module.move(product, position);           
           
            product_manager_module.set_cursor(product)
          
        } else {
            var building = building_module.add(type, len);
            this.products.push(building)  
            gui_module.index_gui.setValue(len + 1)
            product_manager_module.set_cursor(building)
            
        }       
        return len  
    },
    remove: function(index) {
        var product = product_manager_module.products[index]
        if(product) {
            var p = sceneItems.scene.remove(product.scene_group);
            product_manager_module.products.splice(index, 1);
        }
    },
    move: function(index, object, position) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.move(product, object, position);
            } else if(product.type > 63) {
                shed_module.move(product, object, position);
            } else if(product.type > 31) {
                building_module.move(product, object, position);
            }
        }
    },
    lock: function(index, state) {
        var product = this.products[index]
        //console.log('i: ' + index + ' state: ' + state)
        if(product) {
            product.lock = state
        }        
    }, 
    ray_move: function(index, object, position) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.ray_move(product, object, position);
            } else if(product.type < 32) {
                gable_hip_module.ray_move(product, object, position);
            } else if(product.type > 63) {
                shed_module.ray_move(product, object, position);
            } else if(product.type > 31) {
                building_module.ray_move(product, object, position);
            }            
        }
        gui_module.index_gui.setValue(index + 1)        
    },    
    scale: function(index, scale) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.scale(product, scale);
            } 
        }
    },
    length: function(index, length) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.length(product, length);
            } else if(product.type < 32) {
                gable_hip_module.length(product, length);
            } 
        }
    },
    back_length: function(index, back_length) {
        var product = this.products[index]
        if(product) {
            if(product.type < 32) {
                gable_hip_module.back_length(product, back_length);
            } 
        }
    },    
    projection: function(index, projection) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.projection(product, projection);
            } else if(product.type < 32) {
                gable_hip_module.projection(product, projection);
            }            
        }
    },
    height: function(index, height) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.height(product, height);
            } else if(product.type < 32) {
                gable_hip_module.height(product, height);
            } 
        }
    },
    set_horizon: function(index, horizon) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_horizon(product, horizon);
            } else if(product.type < 32) {
                gable_hip_module.set_horizon(product, horizon);
            } 
        }
    },
    step_length: function(index, step_length) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.step_length(product, step_length);
            } else if(product.type < 32) {
                gable_hip_module.step_length(product, step_length);
            } 
        }
    },
    step_size: function(index, step_size) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.step_size(product, step_size);
            } else if(product.type < 32) {
                gable_hip_module.step_size(product, step_size);
            } 
        }
    },
    bracket_size: function(no, index, bracket_size) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.bracket_size(no, product, bracket_size);
            } 
        }
    },
    angle: function(index, angle) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.angle(product, angle);
            } 
        }
    },
    rotate: function(index, rotate) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.rotate(product, rotate);
            } else if(product.type < 32) {
                gable_hip_module.rotate(product, rotate);
            }  else if(product.type > 63) {
                shed_module.rotate(product, rotate);
            }  
        }
    },
    set_symmetric: function(index, flag) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {                
            } else if(product.type < 32) {
                gable_hip_module.set_symmetric(product, flag)
            } 
        }
    },
    profile: function(index, profile) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.profile(product, profile);
            } else if(product.type < 32) {
                gable_hip_module.profile(product, profile);
            }
        }
    },
    front_overhang: function(index, front_overhang) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.front_overhang(product, front_overhang);
            } else if(product.type < 32) {
                gable_hip_module.front_overhang(product, front_overhang);
            } 
        }
    },
    back_overhang: function(index, back_overhang) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.back_overhang(product, back_overhang);
            } 
        }
    },
    step_overhang: function(index, step_overhang) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.step_overhang(product, step_overhang);
            } 
        }
    },
    gable_type: function(index, type) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {                
            } else if(product.type < 32) {
                gable_hip_module.gable_type(product, type)
            } 
        }
    },
    forced_front_posts_no: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_front_posts_no(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_front_posts_no(product, value);
            } 
        }
    },
    front_post_width: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_front_post_width(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_front_post_width(product, value);
            } 
        }
    },
    front_post_offset: function(front_post_index, index, front_post_offset) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_front_post_offset(front_post_index, product, front_post_offset);
            } else if(product.type < 32) {
                gable_hip_module.set_front_post_offset(front_post_index, product, front_post_offset);
            } else if(product.type > 63) {
                shed_module.set_front_post_offset(front_post_index, product, front_post_offset);
            } 
        }
    },
    forced_back_posts_no: function(index, no) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_back_posts_no(product, no);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_back_posts_no(product, no);
            } 
        }
    },
    forced_right_posts_no: function(index, no) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_right_posts_no(product, no);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_right_posts_no(product, no);
            } 
        }
    },
    forced_left_posts_no: function(index, no) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_left_posts_no(product, no);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_left_posts_no(product, no);
            } 
        }
    },
    back_post_offset: function(back_post_index, index, back_post_offset) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_back_post_offset(back_post_index, product, back_post_offset);
            } else if(product.type < 32) {
                gable_hip_module.set_back_post_offset(back_post_index, product, back_post_offset);
            } 
        }
    },
    right_post_offset: function(right_post_index, index, right_post_offset) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_right_post_offset(right_post_index, product, right_post_offset);
            } else if(product.type < 32) {
                gable_hip_module.set_right_post_offset(right_post_index, product, right_post_offset);
            } 
        }
    },
    left_post_offset: function(left_post_index, index, left_post_offset) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_left_post_offset(left_post_index, product, left_post_offset);
            } else if(product.type < 32) {
                gable_hip_module.set_left_post_offset(left_post_index, product, left_post_offset);
            } 
        }
    },
    forced_brackets_no: function(index, no) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_brackets_no(product, no);
            } 
        }
    },
    bracket_offset: function(bracket_index, index, bracket_offset) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_bracket_offset(bracket_index, product, bracket_offset);
            } 
        }
    },
    forced_decking_posts_no: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_decking_posts_no(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_decking_posts_no(product, value);
            } 
        }
    },
    decking_post_width: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_decking_post_width(product, value); 
            } else if(product.type < 32) {
                gable_hip_module.set_decking_post_width(product, value); 
            } 
        }
    },
    decking_post_offset: function(decking_post_index, index, decking_post_offset) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_decking_post_offset(decking_post_index, product, decking_post_offset); 
            } else if(product.type < 32) {
                gable_hip_module.set_decking_post_offset(decking_post_index, product, decking_post_offset); 
            } 
        }
    },
    forced_rafters_no: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_rafters_no(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_rafters_no(product, value);
            } 
        }
    },
    rafter_offset: function(rafter_index, index, rafter_offset_) {
        var rafter_offset = 0
        if(rafter_offset_) rafter_offset = rafter_offset_
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_rafter_offset(rafter_index, product, rafter_offset); 
            } else if(product.type < 32) {
                gable_hip_module.set_rafter_offset(rafter_index, product, rafter_offset); 
            } 
        }
    },
    forced_purlins_no: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_forced_purlins_no(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_forced_purlins_no(product, value);
            } 
        }
    },
    purlin_offset: function(purlin_index, index, purlin_offset_) {
        var purlin_offset = 0
        if(purlin_offset_) purlin_offset = purlin_offset_
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_purlin_offset(purlin_index, product, purlin_offset); 
            } else if(product.type < 32) {
                gable_hip_module.set_purlin_offset(purlin_index, product, purlin_offset); 
            } 
        }
    },
    roofing_color: function(index, color) {
        var product = this.products[index]
        if(product) {
            if(product.type < 32) {
                flyover_module.roofing_color(product, color);
            } else if(product.type > 31) {
                building_module.roofing_color(product, color);
            }            
        }
    },   
    gutters_color: function(index, color) {
        var product = this.products[index]
        if(product) {
            if(product.type < 32) {
                flyover_module.gutters_color(product, color);
            } else if(product.type > 31) {
                building_module.gutters_color(product, color);
            }           
        }
    },
    beams_color: function(index, color) {
        var product = this.products[index]
        if(product) {
            if(product.type < 32) {
                flyover_module.beams_color(product, color);
            } else if(product.type > 31) {
                building_module.beams_color(product, color);
            }           
        }
    },
    posts_color: function(index, color) {
        var product = this.products[index]
        if(product) {
            if(product.type < 32 || product.type > 63) {
                flyover_module.posts_color(product, color);
            } else if(product.type > 31) {
                building_module.posts_color(product, color);
            }            
        }
    },
    walls_color: function(index, color) {
        var product = this.products[index]
        if(product) {
           if(product.type > 63) {
                shed_module.walls_color(product, color);
            }           
        }
    },
    rollerDoors_color: function(index, color) {
        var product = this.products[index]
        if(product) {
           if(product.type > 63) {
                shed_module.rollerDoors_color(product, color);
            }           
        }
    },
    set_fan: function(index, flag) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_fan(product, flag)
            } else if(product.type < 28) {
                gable_hip_module.set_fan(product, flag)
            } 
        }
    },
    set_fan_color: function(index, color) {
        var product = this.products[index]
        if(product) {
            if(product.type < 32) {
                flyover_module.set_fan_color(product, color);
            } 
        }
    },
    set_downlights_number: function(index, number) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_downlights_number(product, number);
            } else if(product.type < 32) {
                gable_hip_module.set_downlights_number(product, number);
            } 
        }
    },
    set_landscaping_visible: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_landscaping_visible(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_landscaping_visible(product, value);
            } 
        }
    }, 
    set_landscaping_height: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_landscaping_height(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_landscaping_height(product, value);
            } 
        }
    }, 
    set_landscaping_type: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_landscaping_type(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_landscaping_type(product, value);
            } 
        }
    }, 
    set_landscaping_x_offset: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_landscaping_x_offset(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_landscaping_x_offset(product, value);
            } 
        }
    }, 
    set_landscaping_z_offset: function(index, value) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_landscaping_z_offset(product, value);
            } else if(product.type < 32) {
                gable_hip_module.set_landscaping_z_offset(product, value);
            } 
        }
    }, 
    set_landscaping_custom_material: function(index, material) {
        var product = this.products[index]
        if(product) {
            if(product.type < 4) {
                flyover_module.set_landscaping_custom_material(product, material);
            } else if(product.type < 32) {
                gable_hip_module.set_landscaping_custom_material(product, material);
            } 
        }
    }, 
    set_accessories_parameters(index, access_index, type, length, height, location, horizon) {
        var product = this.products[index] 
        if(product) {
            if(product.type > 63) shed_module.set_accessories_parameters(product, access_index, type, length, height, location, horizon)           
        }
    },
    show_gallery: function(index, no) {
        var product = this.products[index]
        if(product) {
            flyover_module.show_gallery(no);
            
        } else {
            flyover_module.show_gallery(no);
        }
    }, 
    periodic_event: function() {
        for(var i = 0; i < product_manager_module.products.length; ++i) {
            var product = product_manager_module.products[i]
            if(product) {
                if(product.type < 32) {
                    flyover_module.periodic_event(product);
                } 
            }
        }
    },
    add_from_obj(p) {
        var type = parseInt(p.type)
        var index = product_manager_module.add(type)
        var product = product_manager_module.products[index];

        if(type < 32 || type > 63) {
           
            if(p.price_index) {
                product.price_index = parseInt(p.price_index)
                //console.log("PRICE INDEX: " + product.price_index)
            } else {
                product.price_index = 0
            }
            product.length = parseFloat(p.length)
            product.back_length = 1
            if(p.back_length) product.back_length = parseFloat(p.back_length)
            product.projection = parseFloat(p.projection)
            product.height = parseFloat(p.height)
            product.step_length = parseFloat(p.step_length)
            product.step_size = parseFloat(p.step_size)
            product.bracket_size = parseFloat(p.bracket_size)
            product.angle = parseInt(p.angle)
            product.rotate = parseInt(p.rotate)     
            product.position.x = parseFloat(p.x)                   
            product.position.y = parseFloat(p.y)
            product.position.z = parseFloat(p.z)
            product.horizon = parseFloat(p.horizon)
            product.front_overhang = parseFloat(p.front_overhang)
            product.back_overhang = parseFloat(p.front_overhang)
            product.step_overhang = parseFloat(p.step_overhang)
            product.roofing_type = parseFloat(p.roofing_type)
    
            var gable_type = parseInt(p.gable_type)
            if(gable_type == 1 || gable_type == 2) product.gable_type = gable_type
            else product.gable_type == 0

            var symmetric = parseInt(p.symmetric)
            if(symmetric == 1) product.symmetric = true
            else product.symmetric = false        
            
           
            product.number = parseInt(p.number)
    
            var landscaping_visible = parseInt(p.landscaping_visible)
            if(landscaping_visible == 1) product.landscaping_visible = true
            else product.landscaping_visible = false
    
            product.landscaping_type = p.landscaping_type
            product.landscaping_x_offset = parseFloat(p.landscaping_x_offset)
            product.landscaping_z_offset = parseFloat(p.landscaping_z_offset)
           
            product.front_post_width = parseFloat(p.front_post_width)
            product.forced_front_posts_no = parseInt(p.forced_front_posts_no)
            if(p.forced_right_posts_no) {
                product.forced_right_posts_no = parseInt(p.forced_right_posts_no)
            } else product.forced_right_posts_no = 0
            if(p.forced_left_posts_no) {
                product.forced_left_posts_no = parseInt(p.forced_left_posts_no)
            } else product.forced_left_posts_no = 0
            
            
            product.decking_post_width = parseFloat(p.decking_post_width)
            product.forced_decking_posts_no = parseInt(p.forced_decking_posts_no)
            product.forced_brackets_no = parseInt(p.forced_brackets_no)
            product.forced_back_posts_no = parseInt(p.forced_back_posts_no)
    
    
            product.roofing = parseInt(p.roofing)
            product.gutters = parseInt(p.gutters)
            product.beams = parseInt(p.beams)
            product.posts = parseInt(p.posts)
            product.walls = parseInt(p.walls)
            product.roller_doors = parseInt(p.roller_doors)

            gui_module.set_gui_parameters_by_product(product)    
    
            var fan = parseInt(p.fan)
            if(fan == 1) product_manager_module.set_fan(index, true)

            if(p.forced_rafters_no) {
                product.forced_rafters_no = parseInt(p.forced_rafters_no)
            } else product.forced_rafters_no = 0
            product.rafters_no_buffer = product.forced_rafters_no

            if(p.forced_purlins_no) {
                product.forced_purlins_no = parseInt(p.forced_purlins_no)
            } else product.forced_purlins_no = 0
            product.purlins_no_buffer = product.forced_purlins_no

            product_manager_module.front_post_offset(0, index, parseFloat(p.front_posts_offset_1)) 
            product_manager_module.front_post_offset(1, index, parseFloat(p.front_posts_offset_2)) 
            product_manager_module.front_post_offset(2, index, parseFloat(p.front_posts_offset_3)) 
            product_manager_module.front_post_offset(3, index, parseFloat(p.front_posts_offset_4)) 
            product_manager_module.front_post_offset(4, index, parseFloat(p.front_posts_offset_5)) 
            product_manager_module.front_post_offset(5, index, parseFloat(p.front_posts_offset_6)) 


            product_manager_module.back_post_offset(0, index, parseFloat(p.back_posts_offset_1)) 
            product_manager_module.back_post_offset(1, index, parseFloat(p.back_posts_offset_2)) 
            product_manager_module.back_post_offset(2, index, parseFloat(p.back_posts_offset_3)) 
            product_manager_module.back_post_offset(3, index, parseFloat(p.back_posts_offset_4)) 
            product_manager_module.back_post_offset(4, index, parseFloat(p.back_posts_offset_5)) 
            product_manager_module.back_post_offset(5, index, parseFloat(p.back_posts_offset_6))          

            product_manager_module.right_post_offset(0, index, parseFloat(p.right_posts_offset_1)) 
            product_manager_module.right_post_offset(1, index, parseFloat(p.right_posts_offset_2)) 
            product_manager_module.right_post_offset(2, index, parseFloat(p.right_posts_offset_3)) 
            product_manager_module.right_post_offset(3, index, parseFloat(p.right_posts_offset_4)) 
            product_manager_module.right_post_offset(4, index, parseFloat(p.right_posts_offset_5)) 
            product_manager_module.right_post_offset(5, index, parseFloat(p.right_posts_offset_6)) 
           

            product_manager_module.left_post_offset(0, index, parseFloat(p.left_posts_offset_1)) 
            product_manager_module.left_post_offset(1, index, parseFloat(p.left_posts_offset_2)) 
            product_manager_module.left_post_offset(2, index, parseFloat(p.left_posts_offset_3)) 
            product_manager_module.left_post_offset(3, index, parseFloat(p.left_posts_offset_4)) 
            product_manager_module.left_post_offset(4, index, parseFloat(p.left_posts_offset_5)) 
            product_manager_module.left_post_offset(5, index, parseFloat(p.left_posts_offset_6)) 

            product_manager_module.decking_post_offset(0, index, parseFloat(p.decking_posts_offset_1)) 
            product_manager_module.decking_post_offset(1, index, parseFloat(p.decking_posts_offset_2))
            product_manager_module.decking_post_offset(2, index, parseFloat(p.decking_posts_offset_3))
            product_manager_module.decking_post_offset(3, index, parseFloat(p.decking_posts_offset_4))
            product_manager_module.decking_post_offset(4, index, parseFloat(p.decking_posts_offset_5))
            product_manager_module.decking_post_offset(5, index, parseFloat(p.decking_posts_offset_6))

            product_manager_module.bracket_offset(0, index, parseFloat(p.bracket_offset_1)) 
            product_manager_module.bracket_offset(1, index, parseFloat(p.bracket_offset_2)) 
            product_manager_module.bracket_offset(2, index, parseFloat(p.bracket_offset_3)) 
            product_manager_module.bracket_offset(3, index, parseFloat(p.bracket_offset_4)) 
            product_manager_module.bracket_offset(4, index, parseFloat(p.bracket_offset_5)) 
            product_manager_module.bracket_offset(5, index, parseFloat(p.bracket_offset_6)) 

            product_manager_module.bracket_size(0, index, parseFloat(p.bracket_length_1)) 
            product_manager_module.bracket_size(1, index, parseFloat(p.bracket_length_2)) 
            product_manager_module.bracket_size(2, index, parseFloat(p.bracket_length_3)) 
            product_manager_module.bracket_size(3, index, parseFloat(p.bracket_length_4)) 
            product_manager_module.bracket_size(4, index, parseFloat(p.bracket_length_5)) 
            product_manager_module.bracket_size(5, index, parseFloat(p.bracket_length_6)) 

            product_manager_module.rafter_offset(0, index, parseFloat(p.rafter_offset_1)) 
            product_manager_module.rafter_offset(1, index, parseFloat(p.rafter_offset_2)) 
            product_manager_module.rafter_offset(2, index, parseFloat(p.rafter_offset_3)) 
            product_manager_module.rafter_offset(3, index, parseFloat(p.rafter_offset_4)) 
            product_manager_module.rafter_offset(4, index, parseFloat(p.rafter_offset_5)) 
            product_manager_module.rafter_offset(5, index, parseFloat(p.rafter_offset_6)) 

            product_manager_module.purlin_offset(0, index, parseFloat(p.purlin_offset_1)) 
            product_manager_module.purlin_offset(1, index, parseFloat(p.purlin_offset_2)) 
            product_manager_module.purlin_offset(2, index, parseFloat(p.purlin_offset_3)) 
            product_manager_module.purlin_offset(3, index, parseFloat(p.purlin_offset_4)) 
            product_manager_module.purlin_offset(4, index, parseFloat(p.purlin_offset_5)) 
            product_manager_module.purlin_offset(5, index, parseFloat(p.purlin_offset_6)) 

            product_manager_module.profile(index, parseInt(p.profile))


            if(p.model_type) product.model_type = p.model_type;
            if(p.wind_category) product.wind_category = p.wind_category;
            if(p.roof_sheet_type) product.roof_sheet_type = p.roof_sheet_type;
            if(p.attachment_type) product.attachment_type = p.attachment_type;
            if(p.attachment_sub_type) product.attachment_sub_type = p.attachment_sub_type;
            if(p.partial_attachment_type) product.partial_attachment_type = p.partial_attachment_type;
            if(p.attachment_top) {
                product.attachment_top = parseInt(p.attachment_top)
            } else product.attachment_top = 0
            if(p.attachment_right) {
                product.attachment_right = parseInt(p.attachment_right)
            } else product.attachment_right = 0
            if(p.attachment_left) {
                product.attachment_left = parseInt(p.attachment_left)
            } else product.attachment_left = 0
            if(p.attachment_bottom) {
                product.attachment_bottom = parseInt(p.attachment_bottom)
            } else product.attachment_bottom = 0
            if(p.attachment_top_type) product.attachment_top_type = p.attachment_top_type;
            if(p.attachment_top_fascia_type) product.attachment_top_fascia_type = p.attachment_top_fascia_type;
            if(p.attachment_top_variation) product.attachment_top_variation = p.attachment_top_variation;
            if(p.attachment_right_type) product.attachment_right_type = p.attachment_right_type;
            if(p.attachment_right_fascia_type) product.attachment_right_fascia_type = p.attachment_right_fascia_type;
            if(p.attachment_right_variation) product.attachment_right_variation = p.attachment_right_variation;
            if(p.attachment_left_type) product.attachment_left_type = p.attachment_left_type;
            if(p.attachment_left_fascia_type) product.attachment_left_fascia_type = p.attachment_left_fascia_type;
            if(p.attachment_left_variation) product.attachment_left_variation = p.attachment_left_variation;
            if(p.attachment_bottom_type) product.attachment_bottom_type = p.attachment_bottom_type;
            if(p.attachment_bottom_fascia_type) product.attachment_bottom_fascia_type = p.attachment_bottom_fascia_type;
            if(p.attachment_bottom_variation) product.attachment_bottom_variation = p.attachment_bottom_variation;
            if(p.fascia_type) product.fascia_type = p.fascia_type;
            if(p.post_type) product.post_type = p.post_type;
            if(p.gutter_type) product.gutter_type = p.gutter_type;
            if(p.gutter_front) {
                product.gutter_front = parseInt(p.gutter_front)
            } else product.gutter_front = 0
            if(p.gutter_back) {
                product.gutter_back = parseInt(p.gutter_back)
            } else product.gutter_back = 0
            if(p.gutter_left) {
                product.gutter_left = parseInt(p.gutter_left)
            } else product.gutter_left = 0
            if(p.gutter_right) {
                product.gutter_right = parseInt(p.gutter_right)
            } else product.gutter_right = 0
            if(p.footing_type) product.footing_type = p.footing_type;
            if(p.bracket_type) product.bracket_type = p.bracket_type;
            if(p.fascia_beam_type) product.fascia_beam_type = p.fascia_beam_type;
            if(p.purlin_beam_type) product.purlin_beam_type = p.purlin_beam_type;
            if(p.rafter_beam_type) product.rafter_beam_type = p.rafter_beam_type;
            if(p.downpipe_type) product.downpipe_type = p.downpipe_type;
            if(p.wind_options_importance_level) {
                product.wind_options_importance_level = parseInt(p.wind_options_importance_level)
            } else product.wind_options_importance_level = 2
            if(p.wind_options_blocking) {
                product.wind_options_blocking = parseInt(p.wind_options_blocking)
            } else product.wind_options_blocking = 1
            if(p.allow_RSBLC) {
                product.allow_RSBLC = parseInt(p.allow_RSBLC)
            } else product.allow_RSBLC = 0
            
            if(p.acc_1_type && p.acc_1_length && p.acc_1_height && p.acc_1_location) {            
                product_manager_module.set_accessories_parameters(
                    index, 0, 
                    parseInt(p.acc_1_type), 
                    parseFloat(p.acc_1_length), 
                    parseFloat(p.acc_1_height), 
                    parseFloat(p.acc_1_location),
                    parseFloat(p.acc_1_horizon)
                    )
                
            }

            if(p.acc_2_type && p.acc_2_length && p.acc_2_height && p.acc_2_location) {
                product_manager_module.set_accessories_parameters(
                    index, 1, 
                    parseInt(p.acc_2_type), 
                    parseFloat(p.acc_2_length), 
                    parseFloat(p.acc_2_height), 
                    parseFloat(p.acc_2_location),
                    parseFloat(p.acc_2_horizon))
            }

            if(p.acc_3_type && p.acc_3_length && p.acc_3_height && p.acc_3_location) {
                product_manager_module.set_accessories_parameters(
                    index, 2, 
                    parseInt(p.acc_3_type), 
                    parseFloat(p.acc_3_length), 
                    parseFloat(p.acc_3_height), 
                    parseFloat(p.acc_3_location),
                    parseFloat(p.acc_3_horizon))
            }

            if(p.acc_4_type && p.acc_4_length && p.acc_4_height && p.acc_4_location) {
                product_manager_module.set_accessories_parameters(
                    index, 3, 
                    parseInt(p.acc_4_type), 
                    parseFloat(p.acc_4_length), 
                    parseFloat(p.acc_4_height), 
                    parseFloat(p.acc_4_location),
                    parseFloat(p.acc_4_horizon))
            }

            if(p.acc_5_type && p.acc_5_length && p.acc_5_height && p.acc_5_location) {
                product_manager_module.set_accessories_parameters(
                    index, 4, 
                    parseInt(p.acc_5_type), 
                    parseFloat(p.acc_5_length), 
                    parseFloat(p.acc_5_height), 
                    parseFloat(p.acc_5_location),
                    parseFloat(p.acc_5_horizon))
            }

            if(p.acc_6_type && p.acc_6_length && p.acc_6_height && p.acc_6_location) {
                product_manager_module.set_accessories_parameters(
                    index, 5, 
                    parseInt(p.acc_6_type), 
                    parseFloat(p.acc_6_length), 
                    parseFloat(p.acc_6_height), 
                    parseFloat(p.acc_6_location),
                    parseFloat(p.acc_6_horizon))
            }

            if(p.acc_7_type && p.acc_7_length && p.acc_7_height && p.acc_7_location) {
                product_manager_module.set_accessories_parameters(
                    index, 6, 
                    parseInt(p.acc_7_type), 
                    parseFloat(p.acc_7_length), 
                    parseFloat(p.acc_7_height), 
                    parseFloat(p.acc_7_location),
                    parseFloat(p.acc_7_horizon))
            }

            if(p.acc_8_type && p.acc_8_length && p.acc_8_height && p.acc_8_location) {
                product_manager_module.set_accessories_parameters(
                    index, 7, 
                    parseInt(p.acc_8_type), 
                    parseFloat(p.acc_8_length), 
                    parseFloat(p.acc_8_height), 
                    parseFloat(p.acc_8_location),
                    parseFloat(p.acc_8_horizon))
            }

            if(p.acc_9_type && p.acc_9_length && p.acc_9_height && p.acc_9_location) {
                product_manager_module.set_accessories_parameters(
                    index, 8, 
                    parseInt(p.acc_9_type), 
                    parseFloat(p.acc_9_length), 
                    parseFloat(p.acc_9_height), 
                    parseFloat(p.acc_9_location),
                    parseFloat(p.acc_9_horizon))
            }

            if(p.acc_10_type && p.acc_10_length && p.acc_10_height && p.acc_10_location) {
                product_manager_module.set_accessories_parameters(
                    index, 9, 
                    parseInt(p.acc_10_type), 
                    parseFloat(p.acc_10_length), 
                    parseFloat(p.acc_10_height), 
                    parseFloat(p.acc_10_location),
                    parseFloat(p.acc_10_horizon))
            }

            if(p.acc_11_type && p.acc_11_length && p.acc_11_height && p.acc_11_location) {
                product_manager_module.set_accessories_parameters(
                    index, 10, 
                    parseInt(p.acc_11_type), 
                    parseFloat(p.acc_11_length), 
                    parseFloat(p.acc_11_height), 
                    parseFloat(p.acc_11_location),
                    parseFloat(p.acc_11_horizon))
            }

            if(p.acc_12_type && p.acc_12_length && p.acc_12_height && p.acc_12_location) {
                product_manager_module.set_accessories_parameters(
                    index, 11, 
                    parseInt(p.acc_12_type), 
                    parseFloat(p.acc_12_length), 
                    parseFloat(p.acc_12_height), 
                    parseFloat(p.acc_12_location),
                    parseFloat(p.acc_12_horizon))
            }

            if(p.acc_13_type && p.acc_13_length && p.acc_13_height && p.acc_13_location) {
                product_manager_module.set_accessories_parameters(
                    index, 12, 
                    parseInt(p.acc_13_type), 
                    parseFloat(p.acc_13_length), 
                    parseFloat(p.acc_13_height), 
                    parseFloat(p.acc_13_location),
                    parseFloat(p.acc_13_horizon))
            }

            if(p.acc_14_type && p.acc_14_length && p.acc_14_height && p.acc_14_location) {
                product_manager_module.set_accessories_parameters(
                    index, 13, 
                    parseInt(p.acc_14_type), 
                    parseFloat(p.acc_14_length), 
                    parseFloat(p.acc_14_height), 
                    parseFloat(p.acc_14_location),
                    parseFloat(p.acc_14_horizon))
            }

            if(p.acc_15_type && p.acc_15_length && p.acc_15_height && p.acc_15_location) {
                product_manager_module.set_accessories_parameters(
                    index, 14, 
                    parseInt(p.acc_15_type), 
                    parseFloat(p.acc_15_length), 
                    parseFloat(p.acc_15_height), 
                    parseFloat(p.acc_15_location),
                    parseFloat(p.acc_15_horizon))
            }

            if(p.acc_16_type && p.acc_16_length && p.acc_16_height && p.acc_16_location) {
                product_manager_module.set_accessories_parameters(
                    index, 15, 
                    parseInt(p.acc_16_type), 
                    parseFloat(p.acc_16_length), 
                    parseFloat(p.acc_16_height), 
                    parseFloat(p.acc_16_location),
                    parseFloat(p.acc_16_horizon))
            }           

        } else {
            product.length = parseFloat(p.length)
            product.width = parseFloat(p.width)
            product.height = parseFloat(p.height)
            product.wing_length = parseFloat(p.wing_length)
            product.wing_width = parseFloat(p.wing_width)
            product.overhang = parseFloat(p.overhang)   
            if(p.rotate) product.rotate = parseInt(p.rotate) 
            product.position.x = parseFloat(p.x)                   
            product.position.y = parseFloat(p.y)
            product.position.z = parseFloat(p.z)
            product.roofing = parseInt(p.roofing)
            gui_module.set_gui_parameters_by_product(product)  
            //console.log(p.rotate)
            if(p.rotate) building_module.rotate(product, parseInt(p.rotate))  
        }
       
    },
    add_from_database(p) {
        var index = product_manager_module.add(1)
        var product = product_manager_module.products[index];

        product_manager_module.length(index, 10)
        product_manager_module.projection(index, 4.3)
        product_manager_module.roofing_color(index, 4)
        product_manager_module.rotate(index, 34)

        gui_module.set_gui_parameters_by_product(product)        
    }
}

export { product_manager_module } 
