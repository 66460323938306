import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';

const accessoryTypes = [
    { value: 'roller_door', label: 'Roller Door' },
    { value: 'farm_door', label: 'Farm Door' },
    { value: 'window', label: 'Window' },
    { value: 'PA_door', label: 'PA Door' },
    { value: 'glass_sliding_door', label: 'Glass Sliding Door' },
    { value: 'skylight', label: 'Skylight' },
    { value: 'upper_window', label: 'Upper Window' },
  ];

class SmartAccessorySubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      width: 1,
      height: 2,
      horizon: 1,
      location: 1,
      accessoryType: accessoryTypes[0].value,
      base: true,
    }   
  }

  add = () => {  
    shed_module.shed_acc_type = this.state.accessoryType
    shed_module.shed_add_accessory()
  }

  remove= () => {  
    shed_module.shed_remove_accessory()
  }

  setAccessorType = ({ target }) => {
    this.setState({
        accessoryType: target.value
    });
  }

  setWidth = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      width: v
    })
    var type = this.getProductType()
    if(type > 63) {
      shed_module.shed_accessory_length_gui.setValue(v)
    } 
  }

  setHeight = (event) => {
    var v = event.target.valueAsNumber
    var type = this.getProductType()
    if(type > 63) {
      shed_module.shed_accessory_height_gui.setValue(v)
    } 
    this.setState({
        height: shed_module.acc_height
    })
  }

  setHorizon = (event) => {
    var v = event.target.valueAsNumber
    var type = this.getProductType()
    if(type > 63) {
      shed_module.shed_accessory_horizon_gui.setValue(v)
    } 
    this.setState({
        horizon: shed_module.acc_horizon
    })
  }

  setLocation = (event) => {
    var v = event.target.valueAsNumber
    var type = this.getProductType()
    if(type > 63) {
      shed_module.shed_accessory_location_gui.setValue(v)
    } 
    this.setState({
        location: shed_module.acc_location
    })
  }

  setBase = (event) => {
    var v = event.target.checked
    this.setState({
      base: v
    }) 
    var type = this.getProductType()
    if(type > 63) {
      shed_module.shed_base_gui.setValue(v)
    } 
  }

  informSelect = () => {
    var acc
    var product = product_manager_module.products[gui_module.index - 1]
    if(shed_module.shed) {
        acc = shed_module.shed.accessories_model[shed_module.acc_index]
    }
    var type = 1
    type = product?.type
    this.setState({
      width: acc?.length,
      height: acc?.height,
      horizon: acc?.horizon,
      location: acc?.location,
      base: product?.base,
    })
    if(type < 64) {
      document.getElementById('accessory_setup_sub').style.display = 'none'
    } else {
      document.getElementById('accessory_setup_sub').style.display = 'block'
    }
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div id='accessory_setup'>
       <div id='accessory_setup_sub'>
       <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.accessoryType} 
            onChange={this.setAccessorType}
          >
          {accessoryTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <button className="btn btn-success col-md-5 mt-1 mx-1 mb-2" onClick={this.add}>Add</button>
        <button className="btn btn-warning col-md-6 mt-1 my-1 mb-2" onClick={this.remove}>Remove</button>
        
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Accessory Width</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='1'
            max='6'
            step='0.1'
            value={this.state.width}
            onChange={this.setWidth}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='1'
            max='6'
            step='0.1'
            value={this.state.width}
            onChange={this.setWidth}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Accessory Height</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0.5'
            max='5'
            step='0.1'
            value={this.state.height}
            onChange={this.setHeight}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0.5'
            max='5'
            step='0.1'
            value={this.state.height}
            onChange={this.setHeight}
        />  
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Accessory Location</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='160'
            step='0.25'
            value={this.state.location}
            onChange={this.setLocation}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='160'
            step='0.25'
            value={this.state.location}
            onChange={this.setLocation}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Accessory Horizon</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='5'
            step='0.1'
            value={this.state.horizon}
            onChange={this.setHorizon}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='5'
            step='0.1'
            value={this.state.horizon}
            onChange={this.setHorizon}
        />
        <button  className="btn btn-secondary col-md9 mx-1 my-1">Base</button>
        <input type="checkbox" checked={this.state.base} onChange={this.setBase} />        
        </div>
      </div>
     );  
  }
}

export default SmartAccessorySubmenu;
