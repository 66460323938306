import { THREE } from './helper.js';

import { smartkitsObjects } from './model_modules.js';
import { product_manager_module } from './product_manager_module.js';
import { initAccessories, setAccessoryPosition, updateAccessories } from './model_modules.js';
import { gui_module, sceneItems } from './gui_smartkits.js';

var maxPostion = new THREE.Vector3(40, 40, 40);
var minPostion = new THREE.Vector3(-40, -40, -40);

var pNormal = new THREE.Vector3(0, 1, 0); 
var raycaster = new THREE.Raycaster();
var mouse
var plane
var planeIntersect
var pIntersect


var isProductDragging
var productIndex
var accessoriesDragging
var accessoriesType
var dragObject

function initVariables() {

    mouse = new THREE.Vector2();
    plane = new THREE.Plane();
    planeIntersect = new THREE.Vector3(); 
    pIntersect = new THREE.Vector3(); 

    
    isProductDragging = false;
    productIndex = 0;
    accessoriesDragging = false;
    accessoriesType = 0;
    dragObject = null; 
}

function initNewRaycaster() {
    initVariables()

    initAccessories();

    document.addEventListener("pointermove", event => {
        var verticalScrollPosition = document.documentElement.scrollTop
        var horizontalScrollPosition =  document.documentElement.scrollLeft

        mouse.x = ( (event.clientX + horizontalScrollPosition) / sceneItems.sceneX ) * 2 - 1;
        mouse.y = - ( (event.clientY + verticalScrollPosition)/ sceneItems.sceneY ) * 2 + 1;
        raycaster.setFromCamera(mouse, sceneItems.camera);
            
        if (isProductDragging) {                              
            raycaster.ray.intersectPlane(plane, planeIntersect);
            product_manager_module.ray_move(productIndex - 1, dragObject, planeIntersect.clamp(minPostion, maxPostion))
        } else if (accessoriesDragging) {
            raycaster.ray.intersectPlane(plane, planeIntersect);
            setAccessoryPosition(accessoriesType, planeIntersect.clamp(minPostion, maxPostion));                     
        } 
    });

    function objectSelected(intersects) {
        sceneItems.controler.enabled = false;
        pIntersect.copy(intersects[0].point);
        plane.setFromNormalAndCoplanarPoint(pNormal, pIntersect);
        dragObject = intersects[0].object;
    }
    
    document.addEventListener("pointerdown", () => {
        if(gui_module.global_lock) return;
        var intersects = null;
        productIndex = 0;
        for(var i = 0; i < product_manager_module.products.length; ++i) {
            var parts = product_manager_module.products[i].parts["list"] 
            intersects = raycaster.intersectObjects(parts);                    
            if(intersects.length > 0) {                
                productIndex = i + 1;                  
                break;
            }
        }        
        if(intersects && productIndex > 0) {                                         
            isProductDragging = true;
            objectSelected(intersects);             
        } else {
            accessoriesType = 0;
            for(var i = smartkitsObjects.accessories.length - 1; i > -1; --i) {
                var parts = smartkitsObjects.accessories[i]["parts"];
                intersects = raycaster.intersectObjects(parts);                    
                if (intersects.length > 0) {
                    accessoriesType = i + 1;                        
                    accessoriesDragging = true;
                    objectSelected(intersects);                        
                    break;
                }
            }            
        }

    } );

    document.addEventListener("pointerup", () => {
        initVariables()
        sceneItems.controler.enabled = true;
        updateAccessories();
    } );
}

export { initNewRaycaster };