//import { data } from 'jquery';
import React from 'react'
//import { validatePassword } from "./Users";

class Password extends React.Component {
  
  constructor() {
    super();
    this.state =  {
      password: '',
      confirm: '',
      submit_flag: false,
      error: '',
      error_display: true,
    }  
  }

  componentDidMount() {       
  }


  home = () => {
    window.location.replace('/entry');
  }

  setPassword(value) {
    this.setState({password: value})
    this.updateError();
    
  }
  setConfirm(value) {
    this.setState({confirm: value})  
    this.updateError();
  }

  validatePassword(password) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
    return re.test(String(password));
  }

  updateError() {
    setTimeout(() => {
      //console.log('email: ' + this.state.email + ' name: ' + this.state.firstName + ' confirm: ' + this.state.confirm + ' pwd: ' + this.state.password)
      if(this.state.password == '' || this.state.confirm == '') {
        this.setState({submit_flag: false})  
        this.setState({error: "Blank field", error_display: false})   
      } else {       
        if(!this.validatePassword(this.state.password)) {
          this.setState({submit_flag: false})
          this.setState({error: "Min 8 characters, 1 number, 1 Capital and 1 special sign", error_display: false})   
        } else if(this.state.confirm != this.state.password) {
          //console.log("They different")
          this.setState({submit_flag: false})
          this.setState({error: "Different confirm field", error_display: false})   
        } else {
          this.setState({submit_flag: true})
          this.setState({error: "", error_display: true})   
          //console.log("They NOT different")
        }
      }
    }, 1000);
    
  }

  shoot = (e) => {
    var token = JSON.parse(window.sessionStorage.getItem('smartkits_token')).token
    e.preventDefault();
    //console.log(token)
    var access = 'service_tech'
    if(this.state.access == 'Administrator') access = 'service_admin'
    var data = {
      token: token,
      password: this.state.password,
    }
    var xhr = new window.XMLHttpRequest()
    xhr.open('POST', '/user/change/password', true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhr.send(JSON.stringify(data))
    xhr.onreadystatechange = () => { 
      if (xhr.readyState === 4 && xhr.status === 200) {       
        this.setState({error: "", error_display: true})
        //window.location.reload(false);
        window.location.replace('/');

      } else if (xhr.readyState === 4 && (xhr.status === 401 || xhr.status === 403 || xhr.status === 406 || xhr.status === 412 || xhr.status === 428)) {
        //console.log('Wrong Parameters')        
        this.state.error_display = false
        switch(xhr.status) {
          case 401:
            this.setState({error: "Not authorized", error_display: false})
            break;
          case 403:
              this.setState({error: "The user might exist", error_display: false})
              break;            
          case 406:
            this.setState({error: "Incorrect email format", error_display: false})
            break;  
          case 412:
            this.setState({error: "Blank first or last name", error_display: false})
            break;  
          case 428:
            this.setState({error: "Incorrect password format", error_display: false})
            break;  
        }
      }
    }
  }

  render() {
    return (
      <div className="App">
        <h5> </h5>
    
        <button className="btn btn-primary mx-3 col-md-2" onClick={() => this.home()}>Back &#8592;</button>        
        <form onSubmit={this.shoot}> 
          <div hidden={this.state.error_display}>
            <button className="btn btn-danger col-md-4 mx-auto d-block mt-3" >{this.state.error}</button>
          </div>         
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>Password</h4>
              <input type="password" className="form-control" placeholder="Enter password" onChange={event => this.setPassword(event.target.value)} />
          </div>
          <div className="form-group col-md-4 mx-auto d-block mt-3">
              <h4>Confirm Password</h4>
              <input type="password" className="form-control" placeholder="Confirm password" onChange={event => this.setConfirm(event.target.value)} />
          </div>
          <div className="form-group">
          <button className="btn btn-warning mx-auto d-block mt-3" disabled={!this.state.submit_flag} onClick={this.shoot}>Update Password</button>
          </div>
        </form>

      </div>
    );
  }
}

export default Password
