import React from 'react';
import { ChromePicker } from 'react-color';

import { furnitureModule } from '../smartkits/model_modules';

class Smart3DSubmenu extends React.Component {

  constructor() {
    super();
    this.state =  { 
        rotation: 0,
        visibility: true,
        lock: false,
        height: 0,
        scale: 1,
        color: '#FF0000',
    }   
  }

  load3D = (event) => {
    document.getElementById('loadFurnitureInput').click()
  }

  rotate = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      rotation: v
    })
    furnitureModule.rotation_gui.setValue(v)
  }

  setHeight = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      height: v
    })
    furnitureModule.height_gui.setValue(v)
  }

  scale = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
      scale: v
    })
    furnitureModule.scale_gui.setValue(v)
  }

  remove  = (event) => {
    furnitureModule.destroy()
  }

  setColor = (target) => {
    var v = target.hex
    this.setState( {
      color: v
    });
    furnitureModule.furnitureColor(v)
  }

  visible = (event) => {
    var v = event.target.checked 
    this.state.visibility = v
    furnitureModule.visibleGUI.setValue(v)
  }

  lock = (event) => {
    var v = event.target.checked 
    this.state.lock = v 
    furnitureModule.lock_gui.setValue(v)
  }

  informSelect = () => {
    //this.state.lock = furnitureModule.lock
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='3d'>
       <button  className="btn btn-primary col-md-12 mx-1 my-1" onClick={this.load3D}>Load 3D</button>
       <button  className="btn btn-secondary col-md9 mx-1 my-1">Visible</button>
       <input type="checkbox" defaultChecked={this.state.visibility} onChange={this.visible} />
       <button  className="btn btn-secondary col-md9 mx-1 my-1">Lock</button>
       <input type="checkbox" defaultChecked={this.state.lock} onChange={this.lock} />
       <button className="btn btn-secondary col-md-12 mx-1 my-1">3D Height</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='6'
            step='0.11'
            value={this.state.height}
            onChange={this.setHeight}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='6'
            step='0.11'
            value={this.state.height}
            onChange={this.setHeight}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">3D Rotate</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotation}
            onChange={this.rotate}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='360'
            step='1'
            value={this.state.rotate}
            onChange={this.rotate}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">3D Scale</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0.2'
            max='4'
            step='0.1'
            value={this.state.scale}
            onChange={this.scale}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0.2'
            max='4'
            step='0.1'
            value={this.state.scale}
            onChange={this.scale}
        />
        <button  className="btn btn-warning col-md-12 mx-1 my-1" onClick={this.remove}>Remove 3D</button>
        <ChromePicker  color={this.state.color} onChange={this.setColor} />
        </div>
      </div>
     );  
  }
}

export default Smart3DSubmenu;
