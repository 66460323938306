import React from 'react';
import '../App.css';
import Smart from './Smart'
import SmartMenu from './SmartMenu'
import SmartToolbar from './SmartToolbar'
import SmartPriceSubmenu from './SmartPriceSubmenu' 

import { resizeScene, config } from '../smartkits/initSmartkits.js'
import SmartEngineeringMain from './SmartEngineeringMain';
import SmartQuoteInformationSubmenu from './SmartQuoteInformationSubmenu.js';
import Smart2DProjectionPage from './Smart2DProjectionPage.js';
import SmartQuotePage from './SmartQuotePage.js'

class SmartMain extends React.Component { 
  
  constructor() {
    super();
    this.state =  { 
      users_button_hidden: false,
      session: [],
      smartVisible: false,
      smartMenuVisible: true,
      pricePageVisible: false,
      priceToolbarSpace: 0.75,
      engineeringMainVisible: false,
      projection2DPageVisible: false,
      QuotePageVisible: false
    } 
    if(config.deployment) {
      this.state.session = JSON.parse(window.sessionStorage.getItem('smartkits_token')) 
      if(this.state.session.access !== 'web_admin' && this.state.session.access !== 'service_admin' && this.state.session.access !== 'branch_admin' && this.state.session.access !== 'branch_tech') window.location.replace('/');   
    } 
  }

  callSelectMenuFromParent = (value, space) => {
    switch(value) {
      case 0:
        resizeScene(0.8, this.state.priceToolbarSpace)
        this.setState({
          pricePageVisible: true,
          engineeringMainVisible: false,
          projection2DPageVisible: false,
          QuotePageVisible: false
        })
        break
      case 1:
        resizeScene(1, 1)
        this.setState({
          smartMenuVisible: false,
          pricePageVisible: false,
          engineeringMainVisible: false,
          projection2DPageVisible: false,
          QuotePageVisible: false
        })
        break
      case 2:
        resizeScene(0.8, 1)
        this.setState({
          smartMenuVisible: true,
          pricePageVisible: false,
          engineeringMainVisible: false,
          projection2DPageVisible: false,
          QuotePageVisible: false
        })
        break
      case 3:
        resizeScene(0.8, 0.5)
        this.setState({
          smartMenuVisible: true,
          pricePageVisible: false,
          engineeringMainVisible: true,
          projection2DPageVisible: false,
          QuotePageVisible: false
        })
        break
      case 4:
        resizeScene(0.8, 1)
        this.setState({
          smartVisible: true,
          smartMenuVisible: true,
          pricePageVisible: false,
          engineeringMainVisible: false,
          projection2DPageVisible: false,
          QuotePageVisible: false
        })
          break
      case 5:
        resizeScene(0.8, 0.5)
        this.setState({
          smartVisible: true,
          smartMenuVisible: true,
          pricePageVisible: false,
          engineeringMainVisible: false,
          projection2DPageVisible: true,
          QuotePageVisible: false
        })
          break
        case 6:
          resizeScene(0.8, 0.3)
          this.setState({
            smartVisible: true,
            smartMenuVisible: true,
            pricePageVisible: false,
            engineeringMainVisible: false,
            projection2DPageVisible: false,
            QuotePageVisible: true
          })
            break
    }
  };

  informSelect = () => {
    this.smartMenuRef?.informSelect()
    this.smartPriceMenuRef?.informSelect()
    this.SmartQuoteInformationMenuRef?.informSelect()
    this.Smart2DProjectionPage?.informSelect()
    this.SmartQuotePageRef?.informSelect()
  }

  generatePriceTable = () => {
    this.informSelect()
  }

  pullOutMenu = () => {
    resizeScene(0.8, 1)
    this.setState({
      smartMenuVisible: true,
    })
  }

  setPriceSpace = (space) => {
    resizeScene(0.8, space)
    this.setState({
      priceToolbarSpace: space,
    })
  }

  setIndex = (i) => {
    resizeScene(0.8, 0.5)
    this.Smart2DProjectionPage.setIndex(i)
  }

  runTask = (v) => {
    this.Smart2DProjectionPage.runTask(v)
  }

  makePDF = () => {
    this.SmartQuotePageRef.convertToPdf()  
  }

  downloadImage = () => {
    this.SmartQuotePageRef.downloadImage()
  }

  componentDidMount = () => {
    if(!config.deployment) {
      this.callSelectMenuFromParent(5)
    }
  }

  render() {
    return (
        <React.StrictMode>
        {!this.state.smartVisible &&<SmartQuoteInformationSubmenu ref={ref => this.SmartQuoteInformationMenuRef = ref} callSelectMenuFromParent={this.callSelectMenuFromParent}/>}
        {!this.state.smartMenuVisible && (
        <div>
            <i className='bi bi-arrow-left-circle text-warning mx-1 my-1' title="Show Menu" style={{ position: "fixed", top: 0, right: 0, zIndex: 200, fontSize: 32 }} onClick={() => this.pullOutMenu()}></i>
        </div>
        )} 
        <div>
        {<div className='side-by-side'>
          <div>
            <Smart informSelect={this.informSelect} />
            {this.state.engineeringMainVisible && <SmartEngineeringMain ref={ref => this.SmartEngineeringMainRef = ref}/>}
            {this.state.pricePageVisible && <SmartPriceSubmenu ref={ref => this.smartPriceMenuRef = ref}/>}
            {this.state.projection2DPageVisible && <Smart2DProjectionPage ref={ref => this.Smart2DProjectionPage = ref}/>}
            {this.state.QuotePageVisible && <SmartQuotePage ref={ref => this.SmartQuotePageRef = ref}/>}
            
          </div>  
          {this.state. smartVisible && this.state.smartMenuVisible && (
            <div>
              <SmartToolbar callSelectMenuFromParent={this.callSelectMenuFromParent}/>
              <SmartMenu  makePDF={this.makePDF}  downloadImage={this.downloadImage} runTask={this.runTask} setIndex={this.setIndex} generatePriceTable={this.generatePriceTable} setPriceSpace={this.setPriceSpace} ref={ref => this.smartMenuRef = ref} />   
            </div>
          )} 
          </div>}
         
        </div>
      </React.StrictMode>
    );
  }
}

export default SmartMain

  
