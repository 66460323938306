import React, { userState } from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';

const colors = [
    { name:  'Dover White', color: '#F9FBF1', textColor: 'black' },  //0    
    { name:  'Surfmist', color: '#E4E2D5', textColor: 'black' },  //1
    { name:  'Southerly', color: '#D2D1CB', textColor: 'black' },  //2
    { name:  'Shale Grey', color: '#BDBFBA', textColor: 'black' },  //3    
    { name:  'Evening Haze', color: '#C5C2AA', textColor: 'black' },  //4
    { name:  'Domain', color: '#E8DBAE', textColor: 'black' },  //5
    { name:  'Paperbark', color: '#CABFA4', textColor: 'black' },  //6
    { name:  'Classic Cream', color: '#E9DCB0', textColor: 'black' },  //7
    { name:  'Thredbo White', color: '#F5F6EB', textColor: 'black' },  //8
    { name:  'Dune', color: '#B1ADA3', textColor: 'white' },  //9
    { name:  'Riversand', color: '#9D8D76', textColor: 'white' },  //10   
    { name:  'Jasper', color: '#6C6153', textColor: 'white' },  //11
    { name:  'Basalt', color: '#6D6C6E', textColor: 'white' },  //12
    { name:  'Bluegum', color: '#969799', textColor: 'white' },  //13
    { name:  'Ironstone', color: '#3E434C', textColor: 'white' },  //14
    { name:  'Monument', color: '#323233', textColor: 'white' },  //15
    { name:  'Woodland Grey', color: '#4B4C46', textColor: 'white' },  //16
    { name:  'Pale Eucalypt', color: '#7C846A', textColor: 'white' },  //17
    { name:  'Wilderness', color: '#64715E', textColor: 'white' },  //18
    { name:  'Manor Red', color: '#6b372f', textColor: 'white' },  //19
    { name:  'Night Sky', color: '#000000', textColor: 'white' },  //20
];

class SmartColourSubmenu extends React.Component {
    constructor() {
      super();
      this.state =  { 
        roofingTextColor: colors[15].textColor,
        roofingColor: colors[15].color,
        roofingColorName: colors[15].name,
        gutterTextColor: colors[16].textColor,
        gutterColor: colors[16].color,
        gutterColorName: colors[16].name,
        beamTextColor: colors[17].textColor,
        beamColor: colors[17].color,
        beamColorName: colors[17].name,
        postTextColor: colors[5].textColor,
        postColor: colors[5].color,
        postColorName: colors[5].name,
        wallTextColor: colors[3].textColor,
        wallColor: colors[3].color,
        wallColorName: colors[3].name,
        rollerTextColor: colors[4].textColor,
        rollerColor: colors[4].color,
        rollerColorName: colors[4].name
      }   
    }

    findColor = (name) => {
        var index = 0
        for(var i = 0; i < colors.length; ++i) {
            if(colors[i].name == name) {
                index = i
                break
            }
        }
        return index
    }

    roofingColorChanged = ({ target }) => {
        var index = this.findColor(target.value)
        this.setState({
            roofingTextColor: colors[index].textColor,
            roofingColorName: colors[index].name,
            roofingColor: colors[index].color
        })
         gui_module.roofingColorElement?.setValue(index + 1)
    }

    gutterColorChanged = ({ target }) => {
        var index = this.findColor(target.value)
        this.setState({
            gutterTextColor: colors[index].textColor,
            gutterColorName: colors[index].name,
            gutterColor: colors[index].color
        })
         gui_module.gutterColorElement?.setValue(index + 1)
    }

    beamColorChanged = ({ target }) => {
      var index = this.findColor(target.value)
      this.setState({
          beamTextColor: colors[index].textColor,
          beamColorName: colors[index].name,
          beamColor: colors[index].color
      })
       gui_module.beamColorElement?.setValue(index + 1)
    }

    postColorChanged = ({ target }) => {
      var index = this.findColor(target.value)
      this.setState({
          postTextColor: colors[index].textColor,
          postColorName: colors[index].name,
          postColor: colors[index].color
      })
        gui_module.postColorElement?.setValue(index + 1)
    }

    wallColorChanged = ({ target }) => {
      var index = this.findColor(target.value)
      this.setState({
          wallTextColor: colors[index].textColor,
          wallColorName: colors[index].name,
          wallColor: colors[index].color
      })
      gui_module.wallColorElement?.setValue(index + 1)
    }

    rollerColorChanged = ({ target }) => {
      var index = this.findColor(target.value)
      this.setState({
          rollerTextColor: colors[index].textColor,
          rollerolorName: colors[index].name,
          rollerColor: colors[index].color
      })
      gui_module.rollerDoorColorElement?.setValue(index + 1)
    }

    informSelect = () => {
      var product = product_manager_module.products[gui_module.index - 1]
      var type = 1
      type = product?.type
  
      if(type < 32) {
        document.getElementById('wall_roller').style.display = 'none'
        document.getElementById('beam_post').style.display = 'block'
      } else if(type < 64) {
        document.getElementById('wall_roller').style.display = 'none'
        document.getElementById('beam_post').style.display = 'none'
      } else {
        document.getElementById('wall_roller').style.display = 'block'
        document.getElementById('beam_post').style.display = 'none'
      }
    }

    componentDidMount() {
      this.informSelect()
    }

    render() {
      return (
        <div id='colour'>
          <div>
          <button className="btn btn-light col-md-12 mx-1 my-1">Roofing Colour</button>
          <select  className="ntn col-md-12 mx-1 my-1" 
            value={this.state.roofingColorName} 
            style={ {backgroundColor: this.state.roofingColor, color: this.state.roofingTextColor}}
            onChange={this.roofingColorChanged}
          >
          {colors.map(({ color, name, textColor }, index) => <option className="col-md-12 mx-1 my-1" style={ {backgroundColor: color, color: textColor} } key={index} >{name}</option>)}
          </select>     
          </div>
          <div>
          <button className="btn btn-light col-md-12 mx-1 my-1">Gutter Colour</button> 
          <select  className="ntn col-md-12 mx-1 my-1" 
            value={this.state.gutterColorName} 
            style={ {backgroundColor: this.state.gutterColor, color: this.state.gutterTextColor}}
            onChange={this.gutterColorChanged}
          >
          {colors.map(({ color, name, textColor }, index) => <option className="col-md-12 mx-1 my-1" style={ {backgroundColor: color, color: textColor} } key={index} >{name}</option>)}
          </select>     
          </div>
          <div id='beam_post'>
            <button className="btn btn-light col-md-12 mx-1 my-1">Beam Colour</button>
            <select  className="ntn col-md-12 mx-1 my-1" 
              value={this.state.beamColorName} 
              style={ {backgroundColor: this.state.beamColor, color: this.state.beamTextColor}}
              onChange={this.beamColorChanged}
            >
            {colors.map(({ color, name, textColor }, index) => <option className="col-md-12 mx-1 my-1" style={ {backgroundColor: color, color: textColor} } key={index} >{name}</option>)}
            </select>   
            <button className="btn btn-light col-md-12 mx-1 my-1">Post Colour</button>
            <select  className="ntn col-md-12 mx-1 my-1" 
              value={this.state.postColorName} 
              style={ {backgroundColor: this.state.postColor, color: this.state.postTextColor}}
              onChange={this.postColorChanged}
            >
            {colors.map(({ color, name, textColor }, index) => <option className="col-md-12 mx-1 my-1" style={ {backgroundColor: color, color: textColor} } key={index} >{name}</option>)}
            </select>    
          </div>
          <div id='wall_roller'>
            <button className="btn btn-light col-md-12 mx-1 my-1">Wall Colour</button>
            <select  className="ntn col-md-12 mx-1 my-1" 
              value={this.state.wallColorName} 
              style={ {backgroundColor: this.state.wallColor, color: this.state.wallTextColor}}
              onChange={this.wallColorChanged}
            >
            {colors.map(({ color, name, textColor }, index) => <option className="col-md-12 mx-1 my-1" style={ {backgroundColor: color, color: textColor} } key={index} >{name}</option>)}
            </select>   
            <button className="btn btn-light col-md-12 mx-1 my-1">Roller Door Colour</button>
            <select  className="ntn col-md-12 mx-1 my-1" 
              value={this.state.rollerColorName} 
              style={ {backgroundColor: this.state.rollerColor, color: this.state.rollerTextColor}}
              onChange={this.rollerColorChanged}
            >
            {colors.map(({ color, name, textColor }, index) => <option className="col-md-12 mx-1 my-1" style={ {backgroundColor: color, color: textColor} } key={index} >{name}</option>)}
           </select>    
          </div>
        </div>
       );  
    }
  }

export default SmartColourSubmenu;