import { THREE, FBXLoader, getAssetsAddress, addObj, addGLTF, loadMaterial, 
    getObjectsKeys, getNewAccessoryObjectHolder, hideElement } from './helper.js';
import { sceneItems } from './gui_smartkits.js';
import { product_manager_module } from './product_manager_module.js';


var smartkitsMatrix = {
    position: null,
    scale: 1,
    height: 2.5,
    horizon: 0,
    decking_height: 0,
}

var smartkitsObjects = {
    accessories: []
}

function initAccessories() {

    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("car"));

    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("furniture"));    

    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("BBQ"));

    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("pool"));

    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("house"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("house"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("house"));
    smartkitsObjects.accessories.push(getNewAccessoryObjectHolder("house"));
}

function updateAccessories() {
    vehicleModule.scale();
    vehicleModule.setHorizon();

    bbqModule.scale();

    houseModule.scale();
    houseModule.setHorizon();
    //furnitureModule.scale();

    poolModule.scale();
    poolModule.setHorizon();
}

function setAccessoryPosition(type, planeIntersect) {

    switch (type) {
        case 0:
            //if(!smartkitsObjects.carLock) break;
            break;
        case 1:
            vehicleModule.setPosition(planeIntersect);
            break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:            
            furnitureModule.setPosition(type - 2, planeIntersect);
            break;

        case 18:
            bbqModule.setPosition(planeIntersect)
            break;
        case 19:
            poolModule.setPosition(planeIntersect)
            break;
        case 20:
        case 21:
        case 22:
        case 23:
            houseModule.setPosition(type - 20, planeIntersect)
            break;

    }
}

function addAccessoriesGUI(accessories, updateModel) {
   
    //houseModule.addGUI(accessories);
    ////////
    //vehicleModule.addGUI(accessories);

    furnitureModule.addGUI(accessories);
    //bbqModule.addGUI(accessories);
    //poolModule.addGUI(accessories);
}

function setVisibleDecModels(flag) {
    if (flag) {
        vehicleModule.setVisible();
        bbqModule.setVisible();        
        furnitureModule.setVisible();
        poolModule.setVisible();
        houseModule.setVisible();
    } else {
        vehicleModule.invisible();
        bbqModule.invisible();
        furnitureModule.invisible();
        poolModule.invisible();
        houseModule.invisible();        
    }
}

function setYPosition2Ground() {
    //updateAccessories();
    //var level = smartkitsMatrix.horizon - smartkitsMatrix.decking_height * smartkitsMatrix.scale;
    vehicleModule.setHorizon();
    poolModule.setHorizon();
    houseModule.setHorizon();
}

var animationObject;
var animationSceneId;
function addAnimation(name) {

    const loader = new FBXLoader();
    loader.load(name, function (object) {

        mixer = new THREE.AnimationMixer(object);

        const action = mixer.clipAction(object.animations[0]);
        action.play();

        object.traverse(function (child) {
            if (child.isMesh) {
                //child.castShadow = true;
                //child.receiveShadow = true;
                //child.material = new THREE.MeshStandardMaterial( { color: 0xff0000, flatShading: true } );
            }

        });
        object.scale.multiplyScalar(0.01);
        //object.position.y = smartkitsMatrix.horizon;
        //object.position.x = smartkitsMatrix.position.x - 4;
        animationObject = object;
        animationSceneId = sceneItems.scene.add(animationObject);
    });
}

var mixer;
const clock = new THREE.Clock();

function animate() {
    const delta = clock.getDelta();
    if (mixer) mixer.update(delta);
    if (sceneItems.wheels) {
        for (var i = 0; i < sceneItems.wheels.length; ++i) {
            sceneItems.wheels[i].rotation.x -= 0.1;
        }
    }
}


var vehicleModule = {
    typeGUI: null,
    visibleGUI: null,
    visible: false,
    color: '#F70000',
    lock: true,
    rotation: 0,
    type: 1,
    carModelsData: null,
    carModel: null,
    ferrari: null,
    importCar: null,
    checkVisible: function () {
        vehicleModule.toggleVisible(vehicleModule.visible);   
    },
    lockCar: function () {
        this.lock = !this.lock;
    },
    selectType: function () {
        //$(vehicleModule.typeGUI.domElement)?.attr("hidden", true);
        var parts = smartkitsObjects.accessories[0]["parts"];
        parts = [];
        sceneItems.scene.remove(vehicleModule.carModel);
        vehicleModule.add(function () {
            //$(vehicleModule.typeGUI.domElement)?.attr("hidden", false);
        });
    },
    rotate: function(value) {
        if (vehicleModule.carModel) vehicleModule.carModel.rotation.y = value * Math.PI / 2;
    },    
    add2Scene: function() {   
        vehicleModule.carModel.position.set(smartkitsMatrix.position.x, smartkitsMatrix.horizon, smartkitsMatrix.position.z);
        vehicleModule.carModel.scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        sceneItems.scene.add(vehicleModule.carModel);
        vehicleModule.carModel.visible = true;
        vehicleModule.changeColor();
    },   
    //var vehicleModule.ferrari;
    //var vehicleModule.importCar;  
    importModel: function(model, call) {
        if (vehicleModule.importCar) {
            sceneItems.scene.remove(vehicleModule.importCar)
        }
        addGLTF(model, '../product/js/libs/draco/gltf/', function (gltf) {
            vehicleModule.importCar = gltf.scene;
            sceneItems.wheels = [];
            vehicleModule.importCar.traverse(function (object) {
                //console.log("i: " + object.name);            
                if (object.isMesh) {
                    var parts = smartkitsObjects.accessories[0]["parts"];
                    parts.push(object);
                }
            });
            vehicleModule.carModel = vehicleModule.importCar;
            vehicleModule.add2Scene();
            call();
        });
    },    
    loadInput1Function: function() {    
        var f = document.getElementById('loadVehicleInput').files[0];
        document.getElementById('loadVehicleInput').value = ''
        var p = (window.URL || window.webkitURL).createObjectURL(f);
        vehicleModule.importModel(p, function () {
    
        })
    },    
    toggleVisible: function(flag) {
        if (vehicleModule.carModel) {
            vehicleModule.carModel.visible = flag;
        } else {
            vehicleModule.add(function () { });
        }
    },    
    changeColor: function() {
        if (vehicleModule.carModel) {
            var bodyMaterial = new THREE.MeshPhysicalMaterial({
                color: vehicleModule.color, metalness: 0.1, roughness: 0.4, clearcoat: 0.05, clearcoatRoughness: 0.05
            });
    
            var glassMaterial = new THREE.MeshPhysicalMaterial({
                color: 0xffffff, metalness: 0, roughness: 0.1, transmission: 0.9, transparent: true
            });
    
            vehicleModule.carModel.traverse(function (object) {
                //console.log("i: " + object.name);            
                if (object.isMesh) {
                    if (object.name.startsWith('body')) {
                        object.material = bodyMaterial;
                    } else if (object.name.startsWith("glass")) {
                        object.material = glassMaterial;
                    }
                }
            });
        }
    },
    callLoad: function () {
        if (vehicleModule.carModelsData.model == 'import') {
            document.getElementById('loadVehicleInput').click();
            vehicleModule.visibleGUI.setValue(true);
        }
    },
    add: function(call) {
        switch (vehicleModule.type) {
            case 2:
                if (vehicleModule.importCar) {
                    vehicleModule.carModel = vehicleModule.importCar;
                    //carBody = uteBody; 
                    vehicleModule.add2Scene();
                    call();
                }
                //$(loadVehicleInputGUI.domElement)?.attr("hidden", false);
                break;
            default:
                if (vehicleModule.ferrari) {
                    vehicleModule.carModel = vehicleModule.ferrari;
                    //carBody = sedanBody; 
                    vehicleModule.add2Scene();
                    call();
                } else {
                    $(vehicleModule.typeGUI.domElement)?.attr("hidden", true);
                    addGLTF(getAssetsAddress('models/gltf/ferrari.glb'), '../product/js/libs/draco/gltf/', function (gltf) {
                        vehicleModule.ferrari = vehicleModule.addFerrari(gltf);
                        vehicleModule.ferrari.traverse(function (object) {
                            //if(object.isMesh) smartkitsObjects.carParts.push(object);                
                            if (object.isMesh) {
                                var parts = smartkitsObjects.accessories[0]["parts"];
                                parts.push(object);
                            }
                        });
                        vehicleModule.carModel = vehicleModule.ferrari;
                        vehicleModule.add2Scene();
                        call();
                        $(vehicleModule.typeGUI.domElement)?.attr("hidden", false);
                    });
                }
                //$(loadVehicleInputGUI.domElement)?.attr("hidden", true);
                break;
        }
    },
    addFerrari: function(gltf) {
        //sceneItems.scene.remove(animationObject);
        const detailsMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff, metalness: 1.0, roughness: 0.5
        });
        var _carModel = gltf.scene.children[0];
    
        //sedanBody  = _carModel.getObjectByName( 'body' );
        //carBody = sedanBody;
        _carModel.getObjectByName('rim_fl').material = detailsMaterial;
        _carModel.getObjectByName('rim_fr').material = detailsMaterial;
        _carModel.getObjectByName('rim_rr').material = detailsMaterial;
        _carModel.getObjectByName('rim_rl').material = detailsMaterial;
        _carModel.getObjectByName('trim').material = detailsMaterial;
    
        //carGlass = _carModel.getObjectByName( 'glass' ); //.material = glassMaterial;
        sceneItems.wheels = [];
        sceneItems.wheels.push(
            _carModel.getObjectByName('wheel_fl'),
            _carModel.getObjectByName('wheel_fr'),
            _carModel.getObjectByName('wheel_rl'),
            _carModel.getObjectByName('wheel_rr')
        );
    
        // shadow
        /*const mesh = new THREE.Mesh(
            new THREE.PlaneGeometry( 0.655 * 4, 1.3 * 4 ),
            new THREE.MeshBasicMaterial( {
                map: shadow, blending: THREE.MultiplyBlending, toneMapped: false, transparent: true
            } )
        );
        mesh.rotation.x = - Math.PI / 2;
        mesh.renderOrder = 2;
        _carModel.add( mesh );*/
    
        //_carModel.scale.set(2, 2, 2);
        //_carModel.visible = false;    
        return _carModel;
    },
    addGUI: function(accessories) {
        var carAttributes = accessories.addFolder("Vehical");
        vehicleModule.visibleGUI = carAttributes.add(vehicleModule, "visible").onChange(vehicleModule.checkVisible);
        //carAttributes.add(vehicleModule, 'type', 1, 2, 1).onChange(vehicleModule.selectType);
        //carAttributes.add(vehicleModule, "lock").onChange(vehicleModule.lockCar);
        const carModels = (function () {
            return {
                sedan: "Ferrari",
                import: "Import",
            };
        })();
        
        const carModleKeys = getObjectsKeys(carModels);
    
        vehicleModule.carModelsData = {
            model: carModleKeys[0],
        };

        vehicleModule.typeGUI = carAttributes.add(vehicleModule.carModelsData, 'model', carModleKeys).onChange(function () {
            if (vehicleModule.carModelsData.model == "sedan") {
                vehicleModule.type = 1;
            } else {
                vehicleModule.type = 2;
                vehicleModule.visibleGUI.setValue(true);
            }
            //vehicleModule.typeGUI.visible = false; 
            vehicleModule.selectType();
        });
        vehicleModule.typeGUI.domElement.id = 'vehicleTypeGUI';
    
        var loadVehicleInputGUI = carAttributes.add(vehicleModule, 'callLoad').name('Load');
        const loadVehicleInput = document.getElementById("loadVehicleInput");
        loadVehicleInput.addEventListener("change", vehicleModule.loadInput1Function, false);
        //$(loadVehicleInputGUI.domElement)?.attr("hidden", true);
    
        carAttributes.add(vehicleModule, 'rotation', 0, 3, 1).onChange(vehicleModule.rotate);
        carAttributes.addColor(vehicleModule, 'color').onChange(vehicleModule.changeColor);
    },
    scale: function() {
        if(vehicleModule.carModel) {
            vehicleModule.carModel.scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        }
    },
    setHorizon() {
        if(vehicleModule.carModel) {
            vehicleModule.carModel.position.y = smartkitsMatrix.horizon - smartkitsMatrix.decking_height * smartkitsMatrix.scale;
        }
    },
    setPosition(planeIntersect) {
        if (vehicleModule.carModel) {
            //vehicleModule.carModel.position.addVectors(vehicleModule.carModel.position, shift);
            vehicleModule.carModel.position.copy(planeIntersect);
            vehicleModule.carModel.position.y = smartkitsMatrix.horizon - smartkitsMatrix.decking_height * smartkitsMatrix.scale;
            //keepObjectInsideScene(vehicleModule.carModel.position);
        }
    },
    setVisible() {
        if (vehicleModule.carModel && vehicleModule.visible) vehicleModule.carModel.visible = true;
    },
    invisible() {
        if (vehicleModule.carModel) vehicleModule.carModel.visible = false;
    }
}

var houseModule = {
    indexGUI: null,
    visibleGUI: null, 
    visible: false,
    color: '#F70000',
    lock: false,
    rotation: 0,
    index: 0,
    maximum: 3,
    model: [],

    loadHouseImageInput1Function: function () {
        //console.log("Ha Ha");
        var file = document.getElementById('loadHouseImageInput1').files[0];
        document.getElementById('loadHouseImageInput1').value = ''
        houseModule.loadHouseImageInputFunction(file, 0);
    },    
    loadHouseImageInput2Function: function () {
        //console.log("Ha Ha");
        var file = document.getElementById('loadHouseImageInput2').files[0];
        document.getElementById('loadHouseImageInput2').value = ''
        houseModule.loadHouseImageInputFunction(file, 1);
    },    
    loadHouseImageInput3Function: function () {
        //console.log("Ha Ha");
        var file = document.getElementById('loadHouseImageInput3').files[0];
        document.getElementById('loadHouseImageInput3').value = ''
        houseModule.loadHouseImageInputFunction(file, 2);
    },    
    loadHouseImageInputFunction: function (file, type) {
        var filePath = (window.URL || window.webkitURL).createObjectURL(file);
        houseModule.loadHouseImageInputFunctionFromPath(filePath, type);
    },    
    loadHouseImageInputFunctionFromPath: function (filePath, type) {
        var loader = new THREE.TextureLoader()
        var fileMaterial = new THREE.MeshStandardMaterial({
            map: loader.load(filePath)
        });
    
        if(houseModule.model[houseModule.index - 1]) {//for(var i = 0; i < houseModule.model.length; ++i) {
            houseModule.model[houseModule.index - 1].traverse(function (object) {
                if (object.isMesh) {
                    switch (type) {
                        case 0:
                            if (object.name.startsWith("Roofing")) {
                                object.material = fileMaterial
                            }
                            break;
                        case 1:
                            if (object.name.startsWith("Wall")) {
                                object.material = fileMaterial
                            }
                            break;
                        case 2:
                            if (object.name.startsWith("Window")) {
                                object.material = fileMaterial
                            }
                            break;
                    }
                }
            });
        }
    
        return fileMaterial
    },
    loadHouseImageInput1Call: function () {
        document.getElementById('loadHouseImageInput1').click();
    },
    loadHouseImageInput2Call: function () {
        document.getElementById('loadHouseImageInput2').click();
    },
    loadHouseImageInput3Call: function () {
        document.getElementById('loadHouseImageInput3').click();
    },
    loadHouseImageInput4Call: function () {
        document.getElementById('loadHouseImageInput4').click();
    },
    checkVisible: function () {    
        if (houseModule.model.length == 0) {
        } else {
            for (var i = 0; i < houseModule.model.length; ++i) {
                houseModule.model[i].visible = houseModule.visible;
            }
        }
    },
    setIndexVisisble: function () {
        houseModule.toggleVisible(houseModule.index);
    },
    rotate: function () {
        if (houseModule.index != 0 && houseModule.model[houseModule.index - 1]) {
            houseModule.model[houseModule.index - 1].rotation.y = houseModule.rotation // * Math.PI / 2;
        }
    },
    load: function () {
        houseModule.visibleGUI.setValue(true);
        document.getElementById('loadHouseInput').click();
    },
    destroy: function () {
        for (var i = 0; i < houseModule.model.length; ++i) {
            sceneItems.scene.remove(houseModule.model[i]);
            smartkitsObjects.accessories[i + 19] = getNewAccessoryObjectHolder("house");
        }
        houseModule.model = [];
        houseModule.indexGUI.setValue(0);
    },
    add_building: function() {        
        product_manager_module.add("building");
        //gui_module.landscaping_visible_gui.setValue(false)
    },
    addGUI: function(accessories) {
        smartkitsMatrix.position = new THREE.Vector3()
        
        //var houseFolder = accessories.addFolder("House");
        //houseFolder.add(houseModule, 'add_building').name('Add Building');
        //building_module.add_gui(houseFolder);

        var houseAttributes = accessories.addFolder("Import House");
        houseAttributes.add(houseModule, "lock").onChange(function() {});
        houseModule.visibleGUI = houseAttributes.add(houseModule, "visible").onChange(houseModule.checkVisible);
        var loadHouseInputGUI = houseAttributes.add(houseModule, 'load').name('Load');
        const loadHouseInput = document.getElementById("loadHouseInput");
        loadHouseInput.addEventListener("change", houseModule.loadInput1Function, false);
    
        houseModule.indexGUI = houseAttributes.add(houseModule, 'index', 0, houseModule.maximum, 1).onChange(houseModule.setIndexVisisble);
        houseAttributes.add(houseModule, 'rotation', 0, 360, 1).onChange(houseModule.rotate);
    
        houseAttributes.add(houseModule, 'loadHouseImageInput1Call').name('roofing');
        const loadHouseImageInput1 = document.getElementById("loadHouseImageInput1");
        loadHouseImageInput1.addEventListener("change", houseModule.loadHouseImageInput1Function, false);
    
        houseAttributes.add(houseModule, 'loadHouseImageInput2Call').name('wall');
        const loadHouseImageInput2 = document.getElementById("loadHouseImageInput2");
        loadHouseImageInput2.addEventListener("change", houseModule.loadHouseImageInput2Function, false);
    
        houseAttributes.add(houseModule, 'loadHouseImageInput3Call').name('window');
        const loadHouseImageInput3 = document.getElementById("loadHouseImageInput3");
        loadHouseImageInput3.addEventListener("change", houseModule.loadHouseImageInput3Function, false);
    
    
        houseAttributes.add(houseModule, 'destroy').name('Clear House');
    },
    setup: function(i) {
        houseModule.model[i].position.set(smartkitsMatrix.position.x, smartkitsMatrix.horizon, smartkitsMatrix.position.z - 15);
        houseModule.model[i].scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        
        houseModule.model[i].traverse(function (object) {
            if (object.isMesh) {
                //console.log("name: " + object.name);
                var parts = smartkitsObjects.accessories[i + 19]["parts"];
                parts.push(object);
            }
        });
    
        if(houseModule.index < houseModule.maximum) houseModule.indexGUI.setValue(houseModule.index + 1)
        //loadHouseImageInputFunctionFromPath(getAssetsAddress('textures/smartkits_logo.jpg'), 0);
        //loadHouseImageInputFunctionFromPath(getAssetsAddress('textures/smartkits_logo.jpg'), 1);
        //loadHouseImageInputFunctionFromPath(getAssetsAddress('textures/dark_wood.jpg'), 2);
    
        houseModule.model[i].visible = true;    
    },    
    toggleVisible: function(no) {       
        if (houseModule.model.length) {
            for (var i = 0; i < houseModule.model.length; ++i) {
                if (i >= no) houseModule.model[i].visible = false;
                else houseModule.model[i].visible = true;
            }        
        }
    },    
    loadInput1Function: function() {
        var f = document.getElementById('loadHouseInput').files[0];
        document.getElementById('loadHouseInput').value = ''
        var p = (window.URL || window.webkitURL).createObjectURL(f);
        var cnt = houseModule.model.length;
        if (cnt > houseModule.maximum - 1) return;
        //console.log("C: " + cnt);
        addGLTF(p, '../product/js/libs/draco/gltf/', function (gltf) {
            var object = gltf.scene;
            houseModule.model.push(object);
            sceneItems.scene.add(object);
            houseModule.setup(cnt);
        });
    },
    scale: function() {
        for (var i = 0; i < houseModule.model.length; ++i) {
            houseModule.model[i].scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        }
    },
    setHorizon: function() {
        for (var i = 0; i < houseModule.model.length; ++i) {
            houseModule.model[i].position.y = smartkitsMatrix.horizon - smartkitsMatrix.decking_height * smartkitsMatrix.scale;
        }
    },
    setPosition: function(type, planeIntersect) {
        if(houseModule.lock) return;
        if(houseModule.model[type]) {
            houseModule.model[type].position.copy(planeIntersect);
            houseModule.model[type].position.y = smartkitsMatrix.horizon;
            //keepObjectInsideScene(houseModule.model[type].position);
        }
    },
    setVisible: function() {
        for (var i = 0; i < houseModule.model.length; ++i) {
            houseModule.model[i].visible = houseModule.visible;
        }
    },
    invisible: function() {
        for (var i = 0; i < houseModule.model.length; ++i) {
            houseModule.model[i].visible = false;
        }
    }
}

var furnitureModule = {
    indexGUI: null,
    visibleGUI: null,
    visible: false,
    color: '#F70000',
    lock: false,
    lock_gui: null,
    rotation: 0,
    rotation_gui: null,
    index: 0,
    maximum: 16,
    model: [],
    height: 0,
    height_gui: null,
    scale: 1,
    scale_gui: null,

    loadFurnitureImageInput1Function: function() {
        var file = document.getElementById('loadFurnitureImageInput1').files[0];
        document.getElementById('loadFurnitureImageInput1').value = ''
        furnitureModule.loadFurnitureImageInputFunction(file, 0);
    },    
    loadFurnitureImageInput2Function: function() {
        var file = document.getElementById('loadFurnitureImageInput2').files[0];
        document.getElementById('loadFurnitureImageInput2').value = ''
        furnitureModule.loadFurnitureImageInputFunction(file, 1);
    },    
    loadFurnitureImageInput3Function: function() {
        var file = document.getElementById('loadFurnitureImageInput3').files[0];
        document.getElementById('loadFurnitureImageInput3').value = ''
        furnitureModule.loadFurnitureImageInputFunction(file, 2);
    },    
    loadFurnitureImageInputFunction: function(file, type) {
        var filePath = (window.URL || window.webkitURL).createObjectURL(file);
        furnitureModule.loadFurnitureImageInputFunctionFromPath(filePath, type);
    },    
    loadFurnitureImageInputFunctionFromPath: function(filePath, type) {
        var loader = new THREE.TextureLoader()
        var fileMaterial = new THREE.MeshStandardMaterial({
            map: loader.load(filePath)
        });
    
        if(furnitureModule.model[furnitureModule.index - 1]) {
            furnitureModule.model[furnitureModule.index - 1].traverse(function (object) {
                if (object.isMesh) {
                    switch (type) {
                        case 0:
                            if (object.name.startsWith("Cushion")) {
                                object.material = fileMaterial
                            }
                            break;
                        case 1:
                            if (object.name.startsWith("Frame")) {
                                object.material = fileMaterial
                            }
                            break;
                        case 2:
                            if (object.name.startsWith("Leg")) {
                                object.material = fileMaterial
                            }
                            break;
                    }
                }
            });
        }
    
        return fileMaterial
    },
    loadFurnitureImageInput1Call: function () {
        document.getElementById('loadFurnitureImageInput1').click();
    },
    loadFurnitureImageInput2Call: function () {
        document.getElementById('loadFurnitureImageInput2').click();
    },
    loadFurnitureImageInput3Call: function () {
        document.getElementById('loadFurnitureImageInput3').click();
    },
    loadFurnitureImageInput4Call: function () {
        document.getElementById('loadFurnitureImageInput4').click();
    },
    checkVisible: function () {  
        if (furnitureModule.model.length == 0) {
        } else {
            for (var i = 0; i < furnitureModule.model.length; ++i) {
                furnitureModule.model[i].visible = furnitureModule.visible;
            }
        }
    },
    setIndexVisible: function () {
        //furnitureModule.toggleVisible(furnitureModule.index);
        var obj = furnitureModule.model[furnitureModule.index - 1]
        if(obj) {
            furnitureModule.height_gui.setValue(obj.position.y)
            var parts = smartkitsObjects.accessories[furnitureModule.index]["parts"];  
            //console.log(parts.rotate)          
            if(parts.rotate) furnitureModule.rotation_gui.setValue(parts.rotate)
            if(parts.lock != null) furnitureModule.lock_gui.setValue(parts.lock)
            if(parts.scale != null) furnitureModule.scale_gui.setValue(parts.scale)
        }
        
    },
    rotate: function () {
        if (furnitureModule.index != 0 && furnitureModule.model[furnitureModule.index - 1]) {
            var parts = smartkitsObjects.accessories[furnitureModule.index]["parts"];
            parts.rotate = furnitureModule.rotation
            furnitureModule.model[furnitureModule.index - 1].rotation.y = parts.rotate * Math.PI / 180;
        }
    },
    callLoadInput: function () {
        furnitureModule.visibleGUI.setValue(true);
        document.getElementById('loadFurnitureInput').click();
    },
    destroy: function () {
        /*for (var i = 0; i < furnitureModule.model.length; ++i) {
            sceneItems.scene.remove(furnitureModule.model[i]);
            smartkitsObjects.accessories[i + 1] = getNewAccessoryObjectHolder("furniture");           
        }
        furnitureModule.model = [];*/
        var i = furnitureModule.index
        if(i > 0) i = i - 1
        sceneItems.scene.remove(furnitureModule.model[i]);
        furnitureModule.model.splice(i, 1);
        smartkitsObjects.accessories.splice(i + 1, 1)
        smartkitsObjects.accessories.splice(16, 0, getNewAccessoryObjectHolder("furniture"));        
        furnitureModule.indexGUI.setValue(i)
    },
    furnitureColor: function (color) {
        var bodyMaterial = new THREE.MeshPhysicalMaterial({
            color: color, metalness: 0.1, roughness: 0.4, clearcoat: 0.05, clearcoatRoughness: 0.05
        });

        if(furnitureModule.model[furnitureModule.index - 1]) {
            furnitureModule.model[furnitureModule.index - 1].traverse(function (object) {
                //console.log("i: " + object.name);            
                if (object.isMesh) {
                    if (object.name.startsWith('body')) {
                        object.material = bodyMaterial;
                    } 
                }
            });
        }
    },
    setup: function (i) {
        //furnitureModule.model[i].position.set(smartkitsMatrix.position.x, smartkitsMatrix.horizon, smartkitsMatrix.position.z);
        //furnitureModule.model[i].scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        //sceneItems.scene.add(furnitureModule.model[i]);
    
        var glassMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff, metalness: 0, roughness: 0.1, transmission: 0.9, transparent: true
        });

        furnitureModule.model[i].traverse(function (object) {
            if (object.isMesh) {
                //console.log("name: " + object.name);
                var parts = smartkitsObjects.accessories[i + 1]["parts"];
                parts.push(object);
                if (object.name.startsWith("glass")) {
                    object.material = glassMaterial;
                }
            }
        });
    
        if(furnitureModule.index < furnitureModule.maximum) {
            furnitureModule.indexGUI.setValue(furnitureModule.index + 1)
        }
        furnitureModule.loadFurnitureImageInputFunctionFromPath(getAssetsAddress('textures/smartkits_logo.jpg'), 0);
        furnitureModule.loadFurnitureImageInputFunctionFromPath(getAssetsAddress('textures/smartkits_logo.jpg'), 1);
        furnitureModule.loadFurnitureImageInputFunctionFromPath(getAssetsAddress('textures/dark_wood.jpg'), 2);
    
        furnitureModule.rotation_gui.setValue(0)
        furnitureModule.lock_gui.setValue(false)
        furnitureModule.scale_gui.setValue(1);

        furnitureModule.model[i].visible = true;
        //else furnitureModule.model[i].visible = false;
    }, 
    toggleVisible: function (no) {
        //console.log("hiii " + no);   
        if (furnitureModule.model.length) {
            for (var i = 0; i < furnitureModule.model.length; ++i) {
                if (i >= no) furnitureModule.model[i].visible = false;
                else furnitureModule.model[i].visible = true;
            }
            //console.log("hi");       
        }
    },    
    load: function () {
        var f = document.getElementById('loadFurnitureInput').files[0];
        document.getElementById('loadFurnitureInput').value = ''
        var p = (window.URL || window.webkitURL).createObjectURL(f);
        var cnt = furnitureModule.model.length;
        if (cnt > furnitureModule.maximum - 1) return;
        furnitureModule.indexGUI.setValue(cnt)
        //console.log("C: " + cnt);
        addGLTF(p, '../product/js/libs/draco/gltf/', function (gltf) {
            var object = gltf.scene;
            furnitureModule.model.push(object);
            sceneItems.scene.add(object);
            furnitureModule.setup(cnt);
        });
    },
    addGUI(accessories) {
        var furnitureAttributes = accessories.addFolder("Import 3D Model");
        furnitureModule.visibleGUI = furnitureAttributes.add(furnitureModule, "visible").onChange(furnitureModule.checkVisible);
        furnitureModule.lock_gui = furnitureAttributes.add(furnitureModule, "lock").onChange(function() {
            if(furnitureModule.model[furnitureModule.index - 1]) {
                //furnitureModule.model[furnitureModule.index - 1].position.y = furnitureModule.height
                var parts = smartkitsObjects.accessories[furnitureModule.index]["parts"];
                parts.lock = furnitureModule.lock
            }
        });
        var loadFurnitureInputGUI = furnitureAttributes.add(furnitureModule, 'callLoadInput').name('Load');
        const loadFurnitureInput = document.getElementById("loadFurnitureInput");
        if(loadFurnitureInput) loadFurnitureInput.addEventListener("change", furnitureModule.load, false);
    

        furnitureModule.height_gui = furnitureAttributes.add(furnitureModule, 'height', 0, 6, 0.1).onChange(function() {
            if(furnitureModule.model[furnitureModule.index - 1]) {
                //furnitureModule.model[furnitureModule.index - 1].position.y = furnitureModule.height
                var parts = smartkitsObjects.accessories[furnitureModule.index]["parts"];
                parts.height = furnitureModule.height
                furnitureModule.model[furnitureModule.index - 1].position.y = parts.height
            }
        });
        
        furnitureModule.rotation_gui = furnitureAttributes.add(furnitureModule, 'rotation', 0, 360, 1).onChange(furnitureModule.rotate);

        furnitureModule.scale_gui = furnitureAttributes.add(furnitureModule, 'scale', 0.2, 4, 0.1).onChange(function() {
            if(furnitureModule.model[furnitureModule.index - 1]) {
                //furnitureModule.model[furnitureModule.index - 1].position.y = furnitureModule.height
                var parts = smartkitsObjects.accessories[furnitureModule.index]["parts"];
                parts.scale = furnitureModule.scale
                furnitureModule.model[furnitureModule.index - 1].scale.set(parts.scale, parts.scale, parts.scale)
            }
        });

        furnitureAttributes.add(furnitureModule, 'destroy').name('Remove');

        furnitureAttributes.addColor(furnitureModule, 'color').onChange(function() {
            furnitureModule.setFurnitureColor(furnitureModule.color)
        });

        furnitureAttributes.add(furnitureModule, 'loadFurnitureImageInput1Call').name('1st Texture');
        const loadFurnitureImageInput1 = document.getElementById("loadFurnitureImageInput1");
        if(loadFurnitureImageInput1) loadFurnitureImageInput1.addEventListener("change", furnitureModule.loadFurnitureImageInput1Function, false);
    
        furnitureAttributes.add(furnitureModule, 'loadFurnitureImageInput2Call').name('2nd Texture');
        const loadFurnitureImageInput2 = document.getElementById("loadFurnitureImageInput2");
        if(loadFurnitureImageInput2) loadFurnitureImageInput2.addEventListener("change", furnitureModule.loadFurnitureImageInput2Function, false);
    
        furnitureAttributes.add(furnitureModule, 'loadFurnitureImageInput3Call').name('3rd Texture');
        const loadFurnitureImageInput3 = document.getElementById("loadFurnitureImageInput3");
        if(loadFurnitureImageInput3) loadFurnitureImageInput3.addEventListener("change", furnitureModule.loadFurnitureImageInput3Function, false);

        var furnitureIndexFolder = furnitureAttributes.addFolder('Index')
        furnitureModule.indexGUI = furnitureIndexFolder.add(furnitureModule, 'index', 0, furnitureModule.maximum, 1).onChange(furnitureModule.setIndexVisible);
        hideElement(furnitureModule.indexGUI.domElement, true);
        
    },
    /*scale: function() {
        for (var i = 0; i < furnitureModule.model.length; ++i) {
            furnitureModule.model[i].scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        }
    },*/
    setHorizon: function() {
        for (var i = 0; i < furnitureModule.model.length; ++i) {
            furnitureModule.model[i].position.y = smartkitsMatrix.horizon;
        }
    },
    setPosition: function(type, planeIntersect) {
        //console.log('no: ' + type)
        furnitureModule.indexGUI.setValue(type + 1)
        if(furnitureModule.model[type]) {                       
            var parts = smartkitsObjects.accessories[type + 1]["parts"];
            //console.log('lock: ' + parts.lock)
            
            if(parts.lock == false) {
                furnitureModule.model[type].position.copy(planeIntersect); 
                furnitureModule.model[type].position.y = parts.height
            }
            //keepObjectInsideScene(furnitureModule.model[type].position)
        }       
    },
    setVisible: function() {
        for (var i = 0; i < furnitureModule.model.length; ++i) {
            furnitureModule.model[i].visible = furnitureModule.visible;
        }
    },
    invisible: function() {
        for (var i = 0; i < furnitureModule.model.length; ++i) {
            furnitureModule.model[i].visible = false;
        }
    }
}

var bbqModule = {
    visible: false,
    color: '#F70000',
    lock: true,
    rotation: 0,
    number: 1,
    model: [],
    height: 0,
    height_gui: null,
    checkVisible: function () {
        if (bbqModule.model.length == 0) {
            bbqModule.add();
        } else {
            bbqModule.model[0].visible = bbqModule.visible;
            //this.visible = !this.visible;
        }
    },
    rotate: function () {
        if (bbqModule.model[0]) {
            bbqModule.model[0].rotation.y = bbqModule.rotation * Math.PI / 2;
        }
    },
    //var bbqModule.model = [];
    setup: function(i, map) {
        bbqModule.model[i].position.set(smartkitsMatrix.position.x, smartkitsMatrix.horizon, smartkitsMatrix.position.z);
        bbqModule.model[i].scale.set(smartkitsMatrix.scale * 1, smartkitsMatrix.scale * 1, smartkitsMatrix.scale * 1);
        //bbqModule.model[i].rotation.set(3 * Math.PI / 2, 0, 0);          
        sceneItems.scene.add(bbqModule.model[i]);
        bbqModule.model[i].traverse(function (object) {
            //if(object.isMesh) smartkitsObjects.carParts.push(object);  
            //console.log("BBQ No: " + i);              
            if (object.isMesh) {
                var parts = smartkitsObjects.accessories[17]["parts"];
                parts.height = bbqModule.model[i].position.y
                parts.push(object);
                object.material = map;
            }
        });
        bbqModule.model[i].visible = true;
    }, 
    add: function() {
        var map = loadMaterial(getAssetsAddress('textures/BBQ.jpg'), function () { });
        addObj(bbqModule.model, getAssetsAddress('models/obj/BBQ-1.obj'), 1, sceneItems.scene, function () {
            bbqModule.setup(0, map);
        });
    },
    invisible: function() {
        for (var i = 0; i < bbqModule.model.length; ++i) {
            bbqModule.model[i].visible = false;
        }
    },
    scale: function() {
        for (var i = 0; i < bbqModule.model.length; ++i) {
            bbqModule.model[i].scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        }
    },
    setPosition: function(planeIntersect) {
        bbqModule.model[0].position.copy(planeIntersect);
        //bbqModule.model[0].position.y = smartkitsMatrix.horizon;
        var parts = smartkitsObjects.accessories[17]["parts"];
        bbqModule.model[0].position.y = parts.height
        //keepObjectInsideScene(bbqModule.model[0].position)
    },
    setVisible: function() {
        for (var i = 0; i < bbqModule.model.length; ++i) {
            bbqModule.model[i].visible = bbqModule.visible;
        }
    },
    setHorizon: function() {
        if(bbqModule.model[0]) bbqModule.model[0].position.y = smartkitsMatrix.horizon;
    },
    addGUI: function(accessories) {
        var bbqAttributes = accessories.addFolder("BBQ");
        bbqAttributes.add(bbqModule, "visible").onChange(bbqModule.checkVisible);
        bbqAttributes.add(bbqModule, 'rotation', 0, 3, 1).onChange(bbqModule.rotate);
        bbqModule.height_gui = bbqAttributes.add(bbqModule, 'height', 0, 4, 0.1).onChange(function() {
            if(bbqModule.model[0]) {
                bbqModule.model[0].position.y = bbqModule.height
                var parts = smartkitsObjects.accessories[17]["parts"];
                parts.height = bbqModule.model[0].position.y 
            }
        });
    }
}

var poolModule = {
    modelTypeGUI: null,
    visible: false,
    color: '#F70000',
    lock: true,
    rotation: 0,
    type: 1,
    rectangularPool: null,
    kidneyPool: null,
    model: null,
    modelTypeData: null,

    selectType: function () {
        $(poolModule.modelTypeGUI.domElement)?.attr("hidden", true);
        sceneItems.scene.remove(poolModule.model);
        poolModule.add(function () {
            $(poolModule.modelTypeGUI.domElement)?.attr("hidden", false);
        });
    },

    checkVisible: function () {
        //poolModule.visible = !poolModule.visible;
        poolModule.toggleVisible(poolModule.visible);

    },
    add: function(call) {
        var model;
        if (poolModule.visible == false) {
            call();
            return;
        }

        if(poolModule.modelTypeData.type == "kidney") {
            model = getAssetsAddress('models/gltf/Pool-3.glb')
            if(poolModule.rectangularPool != null) {
                console.log(" Pool Rect in Cache")
                poolModule.model = poolModule.kidneyPool;
                poolModule.add2Scene();
                call();
            } else {
                poolModule.readFromServer(model, call)
                poolModule.rectangularPool = poolModule.model
                //model = smartkitsObjects.rectangularPool
                //call();
            }
        } else {
            model = getAssetsAddress('models/gltf/Pool-1.glb')
            if (poolModule.kidneyPool != null) {
                console.log(" Pool kidney in Cache")
                poolModule.model = poolModule.rectangularPool;
                poolModule.add2Scene();
                call();
            } else {
                poolModule.readFromServer(model, call)
                poolModule.kidneyPool = poolModule.model
            }
        }
    },     
    readFromServer: function(model, call) {
        addGLTF(model, '../product/js/libs/draco/gltf/', function (gltf) {
            poolModule.model = gltf.scene;
            poolModule.add2Scene();
            call();
        });
    },    
    toggleVisible: function(flag) {
        if (poolModule.model) {
            poolModule.model.visible = flag;
        } else {
            poolModule.add(function () { });
        }
    },
    rotate: function(value) {
        if (poolModule.model) poolModule.model.rotation.y = value * Math.PI / 2;
    },    
    add2Scene: function() {
        smartkitsObjects.accessories[18] = getNewAccessoryObjectHolder("pool") //{ "type": "pool", "parts": [] };
        var parts = smartkitsObjects.accessories[18]["parts"];
    
        poolModule.model.traverse(function (object) {
            //console.log("i: " + object.name);            
            if (object.isMesh) {
                parts.push(object);
            }
        });
        poolModule.model.position.set(smartkitsMatrix.position.x, smartkitsMatrix.horizon + 0.1, smartkitsMatrix.position.z);
        poolModule.model.scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        sceneItems.scene.add(poolModule.model);
        poolModule.model.visible = true;
    },
    addGUI: function(accessories) {
        var poolAttributes = accessories.addFolder("Pool");
        poolAttributes.add(poolModule, "visible").onChange(poolModule.checkVisible);
        const poolModels = (function () {
            return {
                kidney: "Kidney",
                rectangular: "Rectangular",
            };
        })();
    
        const poolModeleKeys = getObjectsKeys(poolModels);
    
        poolModule.modelTypeData = {
            type: poolModeleKeys[1],
        };
    
        poolModule.modelTypeGUI = poolAttributes.add( poolModule.modelTypeData, 'type', poolModeleKeys).onChange(function () {
            
            poolModule.selectType();
        });
    
    
        poolModule.modelTypeGUI.domElement.id = 'poolModelGUI';
        poolAttributes.add(poolModule, 'rotation', 0, 3, 1).onChange(poolModule.rotate);
    },
    scale: function() {
        if (poolModule.model) {
            poolModule.model.scale.set(smartkitsMatrix.scale, smartkitsMatrix.scale, smartkitsMatrix.scale);
        }
    },
    setHorizon: function() {
        if(poolModule.model) poolModule.model.position.y = smartkitsMatrix.horizon - smartkitsMatrix.decking_height * smartkitsMatrix.scale + 0.2;
    },
    setPosition: function(planeIntersect) {
        poolModule.model.position.copy(planeIntersect);
        poolModule.model.position.y = smartkitsMatrix.horizon - smartkitsMatrix.decking_height * smartkitsMatrix.scale + 0.2;
        //keepObjectInsideScene(poolModule.model.position)
    },
    setVisible: function() {
        if(poolModule.model) {
            poolModule.model.visible = poolModule.visible;
        }
    },
    invisible: function() {
        if (poolModule.model) {
            poolModule.model.visible = false;
        }
    }
}

//export { vehicleModule, houseModule, furnitureModule, bbqModule, poolModule, smartkitsObjects }
export { initAccessories, addAnimation, animate, addAccessoriesGUI, setAccessoryPosition, updateAccessories, setVisibleDecModels, smartkitsObjects, houseModule, furnitureModule };
