import { colorSetType } from './helper.js';

var palette = [
    ['Dover_White',  '#F9FBF1'], //0    
    ['Surfmist',  '#E4E2D5'], //1
    ['Southerly',  '#D2D1CB'], //2
    ['Shale_Grey',  '#BDBFBA'], //3    
    ['Evening_Haze',  '#C5C2AA'], //4
    ['Domain',  '#E8DBAE'], //5
    ['Paperbark',  '#CABFA4'], //6
    ['Classic_Cream',  '#E9DCB0'], //7
    ['Thredbo_White',  '#F5F6EB'], //8
    ['Dune',  '#B1ADA3'], //9
    ['Riversand',  '#9D8D76'], //10   
    ['Jasper',  '#6C6153'], //11
    ['Basalt',  '#6D6C6E'], //12
    ['Bluegum',  '#969799'], //13
    ['Ironstone',  '#3E434C'], //14
    ['Monument',  '#323233'], //15
    ['Woodland_Grey',  '#4B4C46'], //16
    ['Pale_Eucalypt',  '#7C846A'], //17
    ['Wildernes',  '#64715E'], //18
    ['Manor_Red',  '#6b372f'], //19
    ['Night_Sky',  '#000000'], //20
];

var postsLookup = [16, 15, 4, 11, 10, 17];

var roofingLookup = [0, 1, 4, 5, 8, 11, 14];

  import { gui_module } from './gui_smartkits.js';

  
  function selectColorbond(index) {
    return palette[index - 1][1];
  }
 
  function selectRoofingColorbond(index) {
    return palette[index - 1][1];
  }

  function selectPostsColorbond(index) {
    return palette[index - 1][1];
  }

  function convertColor2Hex(input) {
    return parseInt(input.substr(1), 16);
  }

  const roofingDropDown = document.getElementById("roofingDropDown_"); 

  function setSmartkitsColor() {
    if(roofingButton) roofingButton.innerHTML = "Roofing -> " + palette[gui_module.roofing - 1][0];
    roofingButton.style.backgroundColor = selectColorbond(gui_module.roofing);  
    if(gui_module.roofing > 11) roofingButton.style.color = "aliceblue";
    else roofingButton.style.color = "black";

    if(postsButton) postsButton.innerHTML = "Posts -> " + palette[gui_module.posts - 1][0];
    postsButton.style.background = selectColorbond(gui_module.posts);  
    if(gui_module.posts > 11) postsButton.style.color = "aliceblue";
    else postsButton.style.color = "black";

    if(beamsButton) beamsButton.innerHTML = "Beams -> " + palette[gui_module.beams - 1][0]; 
    beamsButton.style.backgroundColor = selectColorbond(gui_module.beams);  
    if(gui_module.beams > 11) beamsButton.style.color = "aliceblue";
    else beamsButton.style.color = "black";

    if(guttersButton) guttersButton.innerHTML = "Gutter -> " + palette[gui_module.gutters - 1][0]; 
    guttersButton.style.backgroundColor = selectColorbond(gui_module.gutters);  
    if(gui_module.gutters > 11) guttersButton.style.color = "aliceblue";
    else guttersButton.style.color = "black";

    if(wallsButton) wallsButton.innerHTML = "Wall -> " + palette[gui_module.walls - 1][0]; 
    wallsButton.style.backgroundColor = selectColorbond(gui_module.walls);  
    if(gui_module.walls > 11) wallsButton.style.color = "aliceblue";
    else wallsButton.style.color = "black";

    if(rollerDoorsButton) rollerDoorsButton.innerHTML = "Roller Door -> " + palette[gui_module.roller_doors - 1][0]; 
    rollerDoorsButton.style.backgroundColor = selectColorbond(gui_module.roller_doors);  
    if(gui_module.roller_doors > 11) rollerDoorsButton.style.color = "aliceblue";
    else rollerDoorsButton.style.color = "black";
      
  } 


  function initColorButton() {
    const roofingButton = document.getElementById("roofingButton");
    const postsButton = document.getElementById("postsButton");
    const beamsButton = document.getElementById("beamsButton");
    const guttersButton = document.getElementById("guttersButton");
    const wallsButton = document.getElementById("wallsButton");
    const rollerDoorsButton = document.getElementById("rollerDoorsButton");
    
    setSmartkitsColor();
    populateSmartkitsColorSelect();
    
    if(gui_module.user_access == 'ezyblox_auth') { 
      colorSetType(64)   
    } else {
      colorSetType(1)
    }
  }

function populateSmartkitsColorSelect() {
    initRoofingColor();
    initGutterColor();
    initBeamColor();
    initPostColor();
    initWallColor();
    initRollerDoorColor();
}

function initPostColor() {
  var postOptions = ["", "post1Color_", "post2Color_", "post3Color_", "post4Color_", "post5Color_", "post6Color_", "post7Color_", "post8Color_", "post9Color_", "post10Color_",
                         "post11Color_", "post12Color_", "post13Color_", "post14Color_", "post15Color_", "post16Color_", "post17Color_", "post18Color_", "post19Color_", "post20Color_",
                         "post21Color_"];
  var postColor = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
  
  for(var i = 1; i < postOptions.length; ++i) {
    postColor[i] = document.getElementById(postOptions[i]); 
    postColor[i].style.backgroundColor = selectColorbond(i);
    if(i < 11) postColor[i].style.color = "black";
    else postColor[i].style.color = "aliceblue";
    if(postColor[i]) postColor[i].innerHTML = palette[i - 1][0];    
  }

  postColor[1].addEventListener("click", function() {     
    gui_module.postColorElement.setValue(1);
    setSmartkitsColor();
  });

  postColor[2].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(2);
    setSmartkitsColor();
  });

  postColor[3].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(3); 
    setSmartkitsColor();
  });

  postColor[4].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(4); 
    setSmartkitsColor();
  });

  postColor[5].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(5); 
    setSmartkitsColor();
  });

  postColor[6].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(6); 
    setSmartkitsColor();
  });

  postColor[7].addEventListener("click", function() {     
    gui_module.postColorElement.setValue(7);
    setSmartkitsColor();
  });

  postColor[8].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(8);
    setSmartkitsColor();
  });

  postColor[9].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(9); 
    setSmartkitsColor();
  });

  postColor[10].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(10); 
    setSmartkitsColor();
  });

  postColor[11].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(11); 
    setSmartkitsColor();
  });

  postColor[12].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(12);
    setSmartkitsColor();
  });

  postColor[13].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(13); 
    setSmartkitsColor();
  });

  postColor[14].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(14); 
    setSmartkitsColor();
  });

  postColor[15].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(15); 
    setSmartkitsColor();
  });

  postColor[16].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(16); 
    setSmartkitsColor();
  });

  postColor[17].addEventListener("click", function() {     
    gui_module.postColorElement.setValue(17);
    setSmartkitsColor();
  });

  postColor[18].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(18);
    setSmartkitsColor();
  });

  postColor[19].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(19); 
    setSmartkitsColor();
  });

  postColor[20].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(20); 
    setSmartkitsColor();
  });

  postColor[21].addEventListener("click", function() { 
    gui_module.postColorElement.setValue(21); 
    setSmartkitsColor();
  });
}

function initBeamColor() {
  var beamOptions = ["", "beam1Color_", "beam2Color_", "beam3Color_", "beam4Color_", "beam5Color_", "beam6Color_", "beam7Color_", "beam8Color_", "beam9Color_", "beam10Color_", 
                         "beam11Color_", "beam12Color_", "beam13Color_", "beam14Color_", "beam15Color_", "beam16Color_", "beam17Color_", "beam18Color_", "beam19Color_", "beam20Color_",
                         "beam21Color_"];
  var beamColor = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
  
  for(var i = 1; i < beamOptions.length; ++i) {
    beamColor[i] = document.getElementById(beamOptions[i]); 
    beamColor[i].style.backgroundColor = selectColorbond(i);
    if(i < 11) beamColor[i].style.color = "black";
    else beamColor[i].style.color = "aliceblue";
    if(beamColor[i]) beamColor[i].innerHTML = palette[i - 1][0];    
  }

  beamColor[1].addEventListener("click", function() {       
    gui_module.beamColorElement.setValue(1);
    setSmartkitsColor();
  });

  beamColor[2].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(2);
    setSmartkitsColor();
  });

  beamColor[3].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(3); 
    setSmartkitsColor();
  });

  beamColor[4].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(4); 
    setSmartkitsColor();
  });

  beamColor[5].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(5); 
    setSmartkitsColor();
  });

  beamColor[6].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(6); 
    setSmartkitsColor();
  });

  beamColor[7].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(7); 
    setSmartkitsColor();
  });

  beamColor[8].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(8); 
    setSmartkitsColor();
  });

  beamColor[9].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(9); 
    setSmartkitsColor();
  });

  beamColor[10].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(10); 
    setSmartkitsColor();
  });

  beamColor[11].addEventListener("click", function() {       
    gui_module.beamColorElement.setValue(11);
    setSmartkitsColor();
  });

  beamColor[12].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(12);
    setSmartkitsColor();
  });

  beamColor[13].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(13); 
    setSmartkitsColor();
  });

  beamColor[14].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(14); 
    setSmartkitsColor();
  });

  beamColor[15].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(15); 
    setSmartkitsColor();
  });

  beamColor[16].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(16); 
    setSmartkitsColor();
  });

  beamColor[17].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(17); 
    setSmartkitsColor();
  });

  beamColor[18].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(18); 
    setSmartkitsColor();
  });

  beamColor[19].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(19); 
    setSmartkitsColor();
  });

  beamColor[20].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(20); 
    setSmartkitsColor();
  });

  beamColor[21].addEventListener("click", function() { 
    gui_module.beamColorElement.setValue(21); 
    setSmartkitsColor();
  });
}

function initGutterColor() {
    // Gutter
    var gutterOptions = ["", "gutter1Color_", "gutter2Color_", "gutter3Color_", "gutter4Color_", "gutter5Color_", "gutter6Color_", 
                              "gutter7Color_", "gutter8Color_", "gutter9Color_", "gutter10Color_", "gutter11Color_", "gutter12Color_", 
                              "gutter13Color_", "gutter14Color_" , "gutter15Color_", "gutter16Color_", "gutter17Color_", "gutter18Color_",
                              "gutter19Color_", "gutter20Color_" , "gutter21Color_"];
    var gutterColor = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
    
    for(var i = 1; i < gutterOptions.length; ++i) {
      gutterColor[i] = document.getElementById(gutterOptions[i]); 
      gutterColor[i].style.backgroundColor = selectColorbond(i);
      if(i < 11) gutterColor[i].style.color = "black";
      else gutterColor[i].style.color = "aliceblue";
      if(gutterColor[i]) gutterColor[i].innerHTML = palette[i - 1][0];     
    }

    gutterColor[1].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(1);
      setSmartkitsColor();
    });

    gutterColor[2].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(2);
      setSmartkitsColor();
    });    

    gutterColor[3].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(3);
      setSmartkitsColor();
    });    

    gutterColor[4].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(4);
      setSmartkitsColor();
    });

    gutterColor[5].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(5);
      setSmartkitsColor();
    });

    gutterColor[6].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(6);
      setSmartkitsColor();
    });    

    gutterColor[7].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(7);
      setSmartkitsColor();
    });    

    gutterColor[8].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(8);
      setSmartkitsColor();
    }); 
    
    gutterColor[9].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(9);
      setSmartkitsColor();
    });

    gutterColor[10].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(10);
      setSmartkitsColor();
    });    

    gutterColor[11].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(11);
      setSmartkitsColor();
    });    

    gutterColor[12].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(12);
      setSmartkitsColor();
    });

    gutterColor[13].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(13);
      setSmartkitsColor();
    });

    gutterColor[14].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(14);
      setSmartkitsColor();
    });   
    
    gutterColor[15].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(15);
      setSmartkitsColor();
    });

    gutterColor[16].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(16);
      setSmartkitsColor();
    });    

    gutterColor[17].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(17);
      setSmartkitsColor();
    });    

    gutterColor[18].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(18);
      setSmartkitsColor();
    }); 
    
    gutterColor[19].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(19);
      setSmartkitsColor();
    });

    gutterColor[20].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(20);
      setSmartkitsColor();
    });    

    gutterColor[21].addEventListener("click", function() {       
      gui_module.gutterColorElement.setValue(21);
      setSmartkitsColor();
    });    
}

function initRoofingColor() {
  var roofingOptions = ["", "roofing1Color_", "roofing2Color_", "roofing3Color_", "roofing4Color_", "roofing5Color_", "roofing6Color_", "roofing7Color_", 
                             "roofing8Color_",  "roofing9Color_", "roofing10Color_", "roofing11Color_", "roofing12Color_", "roofing13Color_", "roofing14Color_",
                            "roofing15Color_", "roofing16Color_", "roofing17Color_", "roofing18Color_", "roofing19Color_", "roofing20Color_", "roofing21Color_"];
  var roofingColor = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
  
  for(var i = 1; i < roofingOptions.length; ++i) {
    roofingColor[i] = document.getElementById(roofingOptions[i]); 
    roofingColor[i].style.backgroundColor = selectColorbond(i);
    if(i < 11) roofingColor[i].style.color = "black";
    else roofingColor[i].style.color = "aliceblue";
    if(roofingColor[i]) roofingColor[i].innerHTML = palette[i - 1][0];    
  }

  roofingColor[1].addEventListener("click", function() {     
    gui_module.roofingColorElement.setValue(1);
    setSmartkitsColor();
  });

  roofingColor[2].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(2);
    setSmartkitsColor();
  });

  roofingColor[3].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(3); 
    setSmartkitsColor();
  });

  roofingColor[4].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(4); 
    setSmartkitsColor();
  });

  roofingColor[5].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(5); 
    setSmartkitsColor();
  });

  roofingColor[6].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(6); 
    setSmartkitsColor();
  });

  roofingColor[7].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(7);
    setSmartkitsColor();
  });

  roofingColor[8].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(8); 
    setSmartkitsColor();
  });

  roofingColor[9].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(9); 
    setSmartkitsColor();
  });

  roofingColor[10].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(10);
    setSmartkitsColor();
  });

  roofingColor[11].addEventListener("click", function() {     
    gui_module.roofingColorElement.setValue(11);
    setSmartkitsColor();
  });

  roofingColor[12].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(12);
    setSmartkitsColor();
  });

  roofingColor[13].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(13); 
    setSmartkitsColor();
  });

  roofingColor[14].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(14); 
    setSmartkitsColor();
  });

  roofingColor[15].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(15); 
    setSmartkitsColor();
  });

  roofingColor[16].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(16); 
    setSmartkitsColor();
  });

  roofingColor[17].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(17);
    setSmartkitsColor();
  });

  roofingColor[18].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(18); 
    setSmartkitsColor();
  });

  roofingColor[19].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(19); 
    setSmartkitsColor();
  });

  roofingColor[20].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(20);
    setSmartkitsColor();
  });

  roofingColor[21].addEventListener("click", function() { 
    gui_module.roofingColorElement.setValue(21);
    setSmartkitsColor();
  });
}

function initWallColor() {
    // Gutter
    var wallOptions = ["", "wall1Color_", "wall2Color_", "wall3Color_", "wall4Color_", "wall5Color_", "wall6Color_", 
                           "wall7Color_", "wall8Color_", "wall9Color_", "wall10Color_", "wall11Color_", "wall12Color_", 
                           "wall13Color_", "wall14Color_", "wall15Color_", "wall16Color_", "wall17Color_", "wall18Color_", "wall19Color_", "wall20Color_", "wall21Color_"];
    var wallColor = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
    
    for(var i = 1; i < wallOptions.length; ++i) {
      wallColor[i] = document.getElementById(wallOptions[i]); 
      wallColor[i].style.backgroundColor = selectColorbond(i);
      if(i < 11) wallColor[i].style.color = "black";
      else wallColor[i].style.color = "aliceblue";
      if(wallColor[i]) wallColor[i].innerHTML = palette[i - 1][0];     
    }

    wallColor[1].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(1);
      setSmartkitsColor();
    });

    wallColor[2].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(2);
      setSmartkitsColor();
    });    

    wallColor[3].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(3);
      setSmartkitsColor();
    });    

    wallColor[4].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(4);
      setSmartkitsColor();
    });

    wallColor[5].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(5);
      setSmartkitsColor();
    });

    wallColor[6].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(6);
      setSmartkitsColor();
    });    

    wallColor[7].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(7);
      setSmartkitsColor();
    });    

    wallColor[8].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(8);
      setSmartkitsColor();
    }); 
    
    wallColor[9].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(9);
      setSmartkitsColor();
    });

    wallColor[10].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(10);
      setSmartkitsColor();
    });    

    wallColor[11].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(11);
      setSmartkitsColor();
    });    

    wallColor[12].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(12);
      setSmartkitsColor();
    });

    wallColor[13].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(13);
      setSmartkitsColor();
    });

    wallColor[14].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(14);
      setSmartkitsColor();
    });        

    wallColor[15].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(15);
      setSmartkitsColor();
    });

    wallColor[16].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(16);
      setSmartkitsColor();
    });    

    wallColor[17].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(17);
      setSmartkitsColor();
    });    

    wallColor[18].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(18);
      setSmartkitsColor();
    }); 
    
    wallColor[19].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(19);
      setSmartkitsColor();
    });

    wallColor[20].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(20);
      setSmartkitsColor();
    });    

    wallColor[21].addEventListener("click", function() {       
      gui_module.wallColorElement.setValue(21);
      setSmartkitsColor();
    });    
}

function initRollerDoorColor() {
  // Roller Door
  var rollerDoorOptions = ["", "rollerDoor1Color_", "rollerDoor2Color_", "rollerDoor3Color_", "rollerDoor4Color_", "rollerDoor5Color_", "rollerDoor6Color_", 
                             "rollerDoor7Color_", "rollerDoor8Color_", "rollerDoor9Color_", "rollerDoor10Color_", "rollerDoor11Color_", "rollerDoor12Color_", 
                             "rollerDoor13Color_", "rollerDoor14Color_", "rollerDoor15Color_", "rollerDoor16Color_", "rollerDoor17Color_", "rollerDoor18Color_",
                             "rollerDoor19Color_", "rollerDoor20Color_", "rollerDoor21Color_"];
  var rollerDoorColor = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null];
  
  for(var i = 1; i < rollerDoorOptions.length; ++i) {
    rollerDoorColor[i] = document.getElementById(rollerDoorOptions[i]); 
    rollerDoorColor[i].style.backgroundColor = selectColorbond(i);
    if(i < 11) rollerDoorColor[i].style.color = "black";
    else rollerDoorColor[i].style.color = "aliceblue";
    if(rollerDoorColor[i]) rollerDoorColor[i].innerHTML = palette[i - 1][0];     
  }

  rollerDoorColor[1].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(1);
    setSmartkitsColor();
  });

  rollerDoorColor[2].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(2);
    setSmartkitsColor();
  });    

  rollerDoorColor[3].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(3);
    setSmartkitsColor();
  });    

  rollerDoorColor[4].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(4);
    setSmartkitsColor();
  });

  rollerDoorColor[5].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(5);
    setSmartkitsColor();
  });

  rollerDoorColor[6].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(6);
    setSmartkitsColor();
  });    

  rollerDoorColor[7].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(7);
    setSmartkitsColor();
  });    

  rollerDoorColor[8].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(8);
    setSmartkitsColor();
  }); 
  
  rollerDoorColor[9].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(9);
    setSmartkitsColor();
  });

  rollerDoorColor[10].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(10);
    setSmartkitsColor();
  });    

  rollerDoorColor[11].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(11);
    setSmartkitsColor();
  });    

  rollerDoorColor[12].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(12);
    setSmartkitsColor();
  });

  rollerDoorColor[13].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(13);
    setSmartkitsColor();
  });

  rollerDoorColor[14].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(14);
    setSmartkitsColor();
  });    
  rollerDoorColor[15].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(15);
    setSmartkitsColor();
  });    

  rollerDoorColor[16].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(16);
    setSmartkitsColor();
  });    

  rollerDoorColor[17].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(17);
    setSmartkitsColor();
  });

  rollerDoorColor[18].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(18);
    setSmartkitsColor();
  });

  rollerDoorColor[19].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(19);
    setSmartkitsColor();
  });      
  
  rollerDoorColor[20].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(20);
    setSmartkitsColor();
  }); 

  rollerDoorColor[21].addEventListener("click", function() {       
    gui_module.rollerDoorColorElement.setValue(21);
    setSmartkitsColor();
  }); 
}

  export { palette, initColorButton, setSmartkitsColor, selectColorbond, selectRoofingColorbond, selectPostsColorbond, convertColor2Hex };